import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRange, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { MoveToOtherJobComponent } from 'src/app/shared/global_components/chat/dialogs/move-to-other-job/move-to-other-job.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { EditOperationChatComponent } from '../../atTasksDialog/edit-operation-chat/edit-operation-chat.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { TargetParametersComponent } from '../../atTasksDialog/target-parameters/target-parameters.component';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { ChatsComponent } from '../../chats/chats.component';
import { MultiEditJobsTypeComponent } from '../jobs/dialogs/multi-edit-jobs-type/multi-edit-jobs-type.component';
import { AddChatToBoardComponent } from './dialogs/add-chat-to-board/add-chat-to-board.component';
import { ChatConnectionsComponent } from './dialogs/chat-connections/chat-connections.component';
import { MultiEditExecutorsComponent } from './dialogs/multi-edit-executors/multi-edit-executors.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { MobQuestionComponent } from 'src/app/shared/global_components/mob-question/mob-question.component';
import { CreateOperationChatComponent } from '../../atTasksDialog/create-operation-chat/create-operation-chat.component';
import { ChatStatusesComponent } from 'src/app/shared/global_components/chat/dialogs/chat-statuses/chat-statuses.component';

@Component({
  selector: 'app-chats-page',
  templateUrl: './chats-page.component.html',
  styleUrls: ['./chats-page.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ChatsPageComponent extends BaseClass implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("chatsContainer") chatsContainer: ElementRef;

  
  @ViewChild('filterParameterMenu') filterParameterMenu: TemplateRef<any>;
  public values: any;
  public parameters: any;
  public allValues: any;
  public contentTypePagination: any;
  public parameterFilterSub: Subscription;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  overlayParameterRefFilter: OverlayRef | null;

  public createdDateRange = new DateRange("", "");
  public postsDateRange = new DateRange("", "");
  public deadlineDateRange = new DateRange("", "");
  public completedDateRange = new DateRange("", "");

  @ViewChild('chatMenu') chatMenu: TemplateRef<any>;
  public overlayRefChatMenu: OverlayRef | null;
  public subChatMenu: Subscription;

  overlayDateRefFilter: OverlayRef | null;
  public dateFilterSub: Subscription;
  @ViewChild('chatFilterDateMenu') chatFilterDateMenu: TemplateRef<any>;
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public origin = window.location.origin;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public chatCollection: any;
  public currMonthName: any;  
  public allContentTypes: any;
  public company_id: any;
  public groups_partner_id: any;
  public partnerGroups: any;
  public activeLang: any;
  public currencyes: any;
  public contentTypePage: number = 1;
  public page: number = 1;
  public filterCount: number = 1;
  public pagination: object = {};
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public chats: any[] = [];
  public profiles: any;
  public employees: any;
  public sortValue: string = 'updated_desc';
  public activeStatus: number[] = [0];
  public prioritys = prioritys;
  public groups: any;
  public partners: any;
  public platforms: any;
  public fileChatsCount: boolean = false;
  public isLoad: boolean = false;
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public searchIsOpen: boolean = false;
  public chatsSub: Subscription;
  public me: any;
  public curYear = (new Date()).getFullYear();
  public chatsDataSub: Subscription;
  public parametersSub: Subscription;
  public changingSub: Subscription;
  public subFilter: Subscription;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public bookmarks: any;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public statusesForFilter: any = [
    {
      name: "All",
      id: 0,
      hasChats: true
    },
    {
      name: "Waiting",
      id: 1,
      hasChats: false
    },
    {
      name: "In process",
      id: 2,
      hasChats: false
    },
    {
      name: "To approve",
      id: 3,
      hasChats: false
    },
    {
      name: "Approved",
      id: 4,
      hasChats: false
    },
    {
      name: "To correct",
      id: 5,
      hasChats: false
    },
    {
      name: "Sources needed",
      id: 6,
      hasChats: false
    },
    {
      name: "On hold",
      id: 97,
      hasChats: false
    },
    {
      name: "Ready",
      id: 98,
      hasChats: false
    },
    {
      name: "Canceled",
      id: 99,
      hasChats: false
    },
  ];

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    status_id: [],
    discussion_other_employee_status_id: 0,
    group_id: [[]],
    content_type_id: '',
    preset_id: '',
    channel_platform_id: '',
    channel_id: [],
    no_channel_id: [],
    discussion_channel_id: [],
    data_file_id: '',
    is_content_url: '',
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
    priority: [],
    operation_employee_id: [],
    no_operation_id: '',
    origin_company_id: '',
    operation_partner_company_id: '',
    task_status_id: '',
    content_status_id: '',
    client_employee_id: [],
    task_operation_operation_id: [],
    id: [],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    created_at_from : '',
    created_at_to : '',
    posted_at_from : '',
    posted_at_to : '',
    discussion_planned_completed_at_from : '',
    discussion_planned_completed_at_to : '',
    discussion_completed_at_from : '',
    discussion_completed_at_to : '',
    order_by: 'updated_desc',
    q_name : '',
    task_custom_id : '',
    q_text  : '',
  });

  overlayRefFilter: OverlayRef | null;
  @ViewChild('filterMenu') filterMenu: TemplateRef<any>;

  @Input() useOut:boolean = false;
  @Input() task_id:any = false;
  @Input() task:any;
  @Input() job:any;
  @Input() tasks:any;
  @Input() initCompanyId:any;
  @Input() initChats:any = false;
  @Input() task_operation_id:any = false;
  @Output() ChatAdded = new EventEmitter<any>();
  @Input() changing: Subject<boolean>;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  public clientControl: FormControl = new FormControl();
  
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private chatService: ChatService,
    public activatedRoute: ActivatedRoute,
    public taskBarService: TaskBarService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private taskService: TaskService,
    private companyService: CompanyService,
    private minimizeService: MinimizeService,
    private parametersService: ParametersService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private _dateAdapter: DateAdapter<Moment>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private _adapter: DateAdapter<any>,
    private membersService: MembersService,
    private refreshService: RefreshService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (!!this.initCompanyId) {
      this.company_id = this.initCompanyId
    }

    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    if (!!this.groups_partner_id) {
      this.getPartnerGroups()
    }

    this.ifAllStatuses();
    this.checkIsMobile();
    
    this.getPlatforms()
    this.getPartners()
    this.getProfiles()
    
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    
    if (!this.useOut) {
      this.getContentTypes(undefined, this.contentTypePage);
      this.onRouteChange();
    } else {
      if (this.initChats) {
        this.chats = this.initChats.slice()
      }
      this.filterValuesChange();
      this.filterCount++
      this.filter.patchValue({
        count: this.filterCount
      })

      if (this.changingSub) {
        this.changingSub.unsubscribe()
      }

      this.changingSub = this.changing.subscribe(() => {
        this.filterCount++
        this.filter.patchValue({
          count: this.filterCount
        })
      })
    }


    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Chats") {
          this.getBookmarks()
        }
      })
    )
    
    this.attachSubscriptions(
      this.chatService.getDeletedChats().subscribe(resp => {
        if (this.chats.filter(x => x.id == resp).length != 0) {
          let deletedChat = this.chats.find(x => x.id == resp);
          if (!deletedChat.opened) {
            let indexDeletedChat = this.chats.indexOf(deletedChat);
            this.chats.splice(indexDeletedChat, 1)
          }
        }
      })
    )


    this.getOperationsStatus();
    this.getOperations();
    this.refresh();
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }
  
  getPartnerGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerGroups = [].concat(...res);
        console.log("getPartnerGroups", this.partnerGroups);
      })
    )
  }

  getPartnerGroup(id) {
    if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
      return false;
    }
    return this.partnerGroups.find(el => el.id == id)
  }  

  jobHasChat(chats, id) {
    if (chats && chats.filter(x => x.id == id).length > 0) {
      return true
    } else {
      return false 
    }
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  // editChatStatus(chat, status_id) {
  //   if (!status_id) {
  //     status_id = Math.floor(Math.random() * 6);
  //   }
  //   console.log(chat, status_id);
  //   this.attachSubscriptions(
  //     this.chatService.editChat(chat.id, {status_id: status_id}, this.company.id).subscribe(resp => {
  //       console.log(resp)
  //     })
  //   )
  // }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
      })
    )
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  logChats() {
    console.log(this.chats)
  }
  toggleMobSearch() {
    this.searchIsOpen = !this.searchIsOpen
  }

  getContentTypes(platform_id, contentTypePage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentTypePage).pipe(
        tap(el => {
          this.contentTypePagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        this.contentTypePage = this.contentTypePage + 1;

        if (this.contentTypePagination.pageCount >= this.contentTypePage) {
          this.getContentTypes(platform_id, this.contentTypePage);
        }
      })
    )
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  moveChat(chat) {
    const dialogRef = this.dialog.open(MoveToOtherJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: chat.taskOperation,
        chat: chat,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: chat.id})
      })
    )
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      chat: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }
  
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toShortCut(e, item, type) {
    if (type == 'chat') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.task_operation_id, discussion_id: item.id}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else if (type == 'job') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.id, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    }
  }

  getFileChatsCount() {
    this.fileChatsCount = false;
    this.attachSubscriptions(
      forkJoin([
        this.chatService.getAllFileChatsCount(this.company_id, this.task_id, this.task_operation_id, 'isset', this.filter.value).pipe(
          map((z:any) => +z.headers.get('x-pagination-total-count'))
        ),
        this.chatService.getAllFileChatsCount(this.company_id, this.task_id, this.task_operation_id, '0', this.filter.value).pipe(
          map((z:any) => +z.headers.get('x-pagination-total-count'))
        )
      ]).subscribe(resp => {
        console.log("getFileChatsCount", resp)
        this.fileChatsCount = !!(resp[0]*resp[1]);
      })
    )
  }

  getChatsCount() {
    // this.statusesForFilter.filter(y => y.id).map(x => x.hasChats = false)
    this.attachSubscriptions(
      forkJoin(this.statusesForFilter.filter(y => y.id).map(x => this.chatService.getAllChatsCount(this.company_id, x.id, this.task_id, this.task_operation_id, this.filter.value).pipe(
        tap(z => {
          console.log("getChatsCount", this.company_id, x.id, this.task_id, this.task_operation_id, this.filter.value)
          if (z.headers.get('x-pagination-total-count') > 0) {
            x.hasChats = true;
          } else {
            x.hasChats = false;
          }
        })
      ))).subscribe(resp => {
        console.log(this.statusesForFilter)
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.useOut) {
      console.log("ChatsPageComponent changes", changes)
      if (changes.task_operation_id && changes.task_operation_id.previousValue != changes.task_operation_id.currentValue) {
        this.filterCount++
        this.filter.patchValue({
          count: this.filterCount
        })
      }
    }
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshChats().pipe(
        concatMap(res => {
          if ( this.chats.filter(x => x.id == res.chat_id).length > 0 && this.company_id == res.company_id ) {
            return this.chatService.getChatsExpand(1, this.company_id, {id: [res.chat_id]}, !this.is_mobile ? '20' : '10').pipe(
              map(x => x.body[0]),
              switchMap(val => this.neededData(val).pipe(map(() => val))),
              tap(val => {
                this.chats.splice(this.chats.indexOf(this.chats.find(b => b.id == val.id)),1, val)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshChats", resp)
      })
    )
  }

  // addToBoard(chat) {
  //   const goToChat = this.dialog.open(AddChatToBoardComponent, {
  //     panelClass: 'add_to_board_modal',
  //     data: {
  //       company: this.company,
  //       user: this.user,
  //       imgRoute: this.imgRoute,
  //       host: this.host,
  //       activeLang: this.activeLang, 
  //       chat: chat,
  //       employees: chat.employees
  //     }
  //   });
  // }

  checkIsManager(task, company, _user) { 
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }

  neededTaskData(task, needChats:boolean = true) {
    let arr = [];
    if (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user)) {
      arr.push(this.taskService.getTaskClients(task.id, this.company_id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }
    if (needChats) {
      arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ))
    }
    if (arr.length) {
      return forkJoin(arr)
    } else {
      return of(task)
    }
  }

  
  toPersonalStatuses(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val, false).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(ChatStatusesComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }
  
  openInNew(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    console.log("openInNew", chat);
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }

  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
          })
        )
      })
    )

  }
  
  changeViewChatTypes(e: MatButtonToggleChange) {
    this.filter.patchValue({
      data_file_id: e.value
    })
    this.filterChats()
  }

  changeView(e: MatButtonToggleChange) {
    if (e.value == "executor") {
      // let idsArr = [...this.filter.value.employee_id]
      // idsArr.push(this.me.id)
      this.filter.patchValue({
        operation_employee_id: [this.me.id]
      })
      this.filterChats()
    } else {
      let idsArr = [...this.filter.value.operation_employee_id]
      idsArr.splice(idsArr.indexOf(this.me.id), 1)
      this.filter.patchValue({
        operation_employee_id: idsArr
      })
      this.filterChats()
    }
  }

  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.company_id).pipe(
        tap(res => {
          let moreEmpl = res.filter(x => chat.employees.filter(b => b.employee_id == x.employee_id).length == 0 && (x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id));
          moreEmpl.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
          // console.log("chat.employees", chat.employees);
          // console.log("moreEmpl", moreEmpl);
          chat.employees.unshift(...moreEmpl)
        }),
        catchError(err => of(err))
      ),
      // this.membersService.getTaskPartners({task_id: chat.task_id, task_operation_id: chat.task_operation_id}, this.company.id).pipe(
      //   tap(res => {
      //     let partners = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
      //     partners.forEach(el => {
      //       el.is_partner = 1;
      //       if (chat.partnerCompaniesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
      //         el.employeeStatus = chat.partnerCompaniesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
      //       } else {
      //         el.employeeStatus = null
      //       }
      //     })
         
      //   }),
    ])
  }

  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
        chats: this.chats,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      goToChatCon.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        }
      })
    )
  }

  goToChat(chat, job) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
      position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
      autoFocus: this.is_mobile ? false : true,
      data: {
        company: this.company,
        task: this.task,
        tasks: this.tasks,
        work: this.job,
        dialogType: 'operation_chat',
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
        chats: job?.chatsInfo,
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        }
      })
    )
  }
  
  openChat(chat) {
    chat.opened = true;
    const goToChat = this.dialog.open(ChatsComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
      position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
      autoFocus: this.is_mobile ? false : true,
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        dialogType: 'global_chat',
        activeLang: this.activeLang, 
        chat: chat,
        closeFull: this.is_mobile,
        initData: {
          page: this.page,
          pagination: this.pagination,
          filter: this.filter.value,
          chats: this.chats,
          activeStatus: this.activeStatus
        }
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        console.log(this.pagination)
        console.log(this.page)
        chat.opened = false;
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
            chat.is_read = 1;
          })
        }
      })
    )
  }



  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  toggleChat(chat): void {
    this.chatCollection.toggle(chat);
    console.log(this.chatCollection);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.chatCollection.isSelected(row.id) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.chatCollection.selected.length;
    const numRows = this.chats.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.chatCollection.clear() :
        this.chats.forEach(chat => this.chatCollection.select(chat));
  }

  togglePersonal() {
    console.log(this.filter.value)
    this.filter.patchValue({
      discussion_other_employee_status_id: !!this.filter.value.discussion_other_employee_status_id ? 0 : 1
    })
    this.filterChats();
  }

  multiEditExecutors() {
    const dialogRef = this.dialog.open(MultiEditExecutorsComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        selectedChats: this.chatCollection.selected,

      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "change") {
          this.chatCollection.selected.forEach((chat, ind) => {
            this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
            if (ind == this.chatCollection.selected.length - 1) {
              this.chatCollection.clear()
            }
          })
        }
      })
    )
  }

  deleteChats() {
    if (!this.is_mobile) {
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          target: `${this.chatCollection.selected.length} chat(s)`
        }
      });
  
      deleteAlert.afterDismissed().pipe(
        switchMap(data => {
          if (data && data.message == 'no') {
            return of('no')
          } else if (data && data.message == 'yes') {
            return forkJoin(this.chatCollection.selected.map(x => this.chatService.deleteChat(x.id, this.company_id).pipe(
              tap(el => {
                
                this.chats.splice(this.chats.indexOf(this.chats.find(u => u.id == x.id)), 1)
                this.taskBarService.removeItems$.next({
                  task_id: x.task_id,
                  task_operation_id: x.task_operation_id,
                  discussion_id: x.id,
                  company_id: x.company_id
                })
              })
            ))).pipe(map(() => 'yes'))
          }
        })
      ).subscribe(res => {
        console.log(res)
        this.chatCollection.clear()
      })
    } else {
      const dialogRef = this.dialog.open(MobQuestionComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          dialog_title: "Delete chat(s)",
          title: `Do you really want to delete ${this.chatCollection.selected.length} chat(s) ?`,
          msg1: "All files, messages will be deleted if you proceed.",
          msg2: "This can`t be undone.",
          btn_yes: "Yes. Delete chat(s).",
          btn_no: "No. I want te keep chat(s).",
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().pipe(
          switchMap(result => {
            if (!!result && result.action == 'yes') {
              return forkJoin(this.chatCollection.selected.map(x => this.chatService.deleteChat(x.id, this.company_id).pipe(
                tap(el => {
                  this.chats.splice(this.chats.indexOf(this.chats.find(u => u.id == x.id)), 1)
                  this.taskBarService.removeItems$.next({
                    task_id: x.task_id,
                    task_operation_id: x.task_operation_id,
                    discussion_id: x.id,
                    company_id: x.company_id
                  })
                })
              ))).pipe(map(() => 'yes'))
            } else {
              return of('no')
            }
          })
        ).subscribe(res => {
          console.log(res)
          this.chatCollection.clear()
        })
      )
    }
  }

  multiEditJobsType() {

    let uniqueJobChats = []
    this.chatCollection.selected.forEach(chat => {
      if (uniqueJobChats.filter(x => x.id == chat.taskOperation.id).length == 0) {
        uniqueJobChats.push(chat.taskOperation)
      }
    })
    const dialogRef = this.dialog.open(MultiEditJobsTypeComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: uniqueJobChats
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.chatCollection.selected.forEach(chat => {
            this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
          })
          this.chatCollection.clear()
        }
      })
    )
  }

  filterValuesChange() {
    this.filter.valueChanges.pipe(
      tap((val) => {
        console.log("filterValuesChange 1", val)
        if (this.chatsDataSub) {
          this.chatsDataSub.unsubscribe();
        }
        if (this.chatsSub) {
          this.chatsSub.unsubscribe();
        }
        if (this.parametersSub) {
          this.parametersSub.unsubscribe();
        }
        this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
        if (!!this.initCompanyId) {
          this.company_id = this.initCompanyId
        }
        console.log("lalal")
        this.sortValue = !!this.filter.get('order_by').value ? this.filter.get('order_by').value : "updated_desc";
      }),
      switchMap((val) => {
        console.log("switchMap", this.company)
        console.log("filterValuesChange 2", val)
        if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.company_id).pipe(
            map(x => x[0]),
            tap(x => {
              console.log("lalal2", x)
              this.company = x;
              this.me = this.company.employees[0];
              this.companyService.company$.next(x);
              console.log("lalal2", 3)
            }),
            map(x => val)
          )
        } else {
          return of(val)
        }
      }),
    ).subscribe(params => {
      console.log("route STREAM", params);
      this.page = 1;
      this.chatCollection = new SelectionModel(
        true,
        []
      )

      this.getChatsCount();
      this.getFileChatsCount();

      if (!!this.filter.get('status_id').value) {
        console.log(this.filter.value.status_id)
        if (this.useOut) {
          this.activeStatus = this.filter.value.status_id
        } else {
          this.activeStatus = this.filter.value.status_id.split(',').map(Number)
        }
      } else {
        this.activeStatus = [0]
      }
    
      this.chatsDataSub = this.chatService.getChatsDataStream().pipe(
        concatMap(tasksInPage => {
          return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
        }),
      ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
      console.log("filterValuesChange 3", params)
      this.getChats(this.page);
    })
  }

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Chats"}).subscribe(res => {
        this.bookmarks = res
        this.bookmarks$.next(this.bookmarks.slice())
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  bookmarksChange(e) {
    this.router.navigate(['/chats'], { queryParams: Object.assign({company_id: this.company_id, preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.chatsDataSub) {
            this.chatsDataSub.unsubscribe();
          }
          if (this.chatsSub) {
            this.chatsSub.unsubscribe()
          }
          if (this.parametersSub) {
            this.parametersSub.unsubscribe();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            count: this.filterCount,
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [],
            discussion_other_employee_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') : 0,
            data_file_id: !!this.activatedRoute.snapshot.queryParamMap.get('data_file_id') ? this.activatedRoute.snapshot.queryParamMap.get('data_file_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : '',
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : '',
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            discussion_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id').split(',').map(Number) : [],
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : '',
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? this.activatedRoute.snapshot.queryParamMap.get('priority').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id').split(',').map(Number) : [],
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : '',
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('client_employee_id').split(',').map(Number) : [],
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : '',
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : '',
            id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id').split(',').map(Number) : [],
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',').map(Number) : [],
            created_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000).utc() : "",
            created_at_to: this.activatedRoute.snapshot.queryParamMap.get('created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000).utc() : ""),
            posted_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_from')*1000).utc() : "",
            posted_at_to: this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_to')*1000).utc() : ""),
            discussion_planned_completed_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from')*1000).utc() : "",
            discussion_planned_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to')*1000).utc() : ""),
            discussion_completed_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from')*1000).utc() : "",
            discussion_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to')*1000).utc() : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc",
            q_name: !!this.activatedRoute.snapshot.queryParamMap.get('q_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_name') : "",
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : "",
            q_text: !!this.activatedRoute.snapshot.queryParamMap.get('q_text') ? this.activatedRoute.snapshot.queryParamMap.get('q_text') : "",
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.chatCollection = new SelectionModel(
          true,
          []
        )
        this.chats = [];
        this.getPlatforms();
        this.getPartners();
        this.getProfiles();
        this.page = 1;
        this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
        this.postsDateRange = new DateRange(this.filter.value['posted_at_from'], this.filter.value['posted_at_to']);
        this.deadlineDateRange = new DateRange(this.filter.value['discussion_planned_completed_at_from'], this.filter.value['discussion_planned_completed_at_to']);
        this.completedDateRange = new DateRange(this.filter.value['discussion_completed_at_from'], this.filter.value['discussion_completed_at_to']);

        console.log("INIT this.createdDateRange", this.createdDateRange)
        console.log("INIT this.postsDateRange", this.postsDateRange)
        console.log("INIT this.deadlineDateRange", this.deadlineDateRange)

        // if (this.activatedRoute.snapshot.queryParamMap.get('add_task')) {
        //   if (this.activatedRoute.snapshot.queryParamMap.get('is_employee') == 'true') {
        //     this.emplCreateTask();
        //   } else {
        //     this.createTask();
        //   }
        //   this.filterReset();
        // }
        
        this.getChatsCount()
        this.getFileChatsCount();
        
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          this.getPartnerGroups()
        }
        
        if (!!this.activatedRoute.snapshot.queryParamMap.get('status_id')) {
          this.activeStatus = this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number)
        } else {
          this.activeStatus = [0]
        }

        this.parametersSub = this.parametersService.getParamsStream().pipe(
          concatMap(params => {
            return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
          }),
        ).subscribe(resp => console.log("-----getParamsStream-----",resp))
      
      
        this.chatsDataSub = this.chatService.getChatsDataStream().pipe(
          concatMap(tasksInPage => {
            return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
          }),
        ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
        this.getBookmarks();
        this.getChats(this.page);
      })
    )
  }

  openNotes(item) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: 'chat',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  rangeChange(date: Moment, key, range): void {
    console.log(date);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    console.log("createdRangeChange", this.filter.value);

    this[range] = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  sort(val) {
    if (!this.useOut) {
      this.filterChats(null, val)
    } else {
      this.filter.patchValue({
        order_by: val,
      })
    }
  }

  refreshPage() {
    this.filterChats()
  }

  selectChange(e) {
    this.filterChats();
  }

  removeValue(val, id = null) {
    if (val == "all") {
      this.activeStatus = [0];
      
      this.filter.patchValue({
        count: '',
        q: '',
        status_id: [],
        discussion_other_employee_status_id: 0,
        group_id: [],
        content_type_id: '',
        data_file_id: '',
        preset_id: '',
        channel_platform_id: '',
        channel_id: [],
        discussion_channel_id: [],
        no_channel_id: [],
        is_content_url: '',
        task_created_at_from: "",
        task_created_at_to: "",
        task_operation_created_at_from: "",
        task_operation_created_at_to: "",
        task_operation_completed_at_from: "",
        task_operation_completed_at_to: "",
        discussion_created_at_from: "",
        discussion_created_at_to: "",
        file_created_at_from: "",
        file_created_at_to: "",
        file_updated_at_from: "",
        file_updated_at_to: "",
        file_meta_creation_time_from: "",
        file_meta_creation_time_to: "",
        task_channel_published_at_from: "",
        task_channel_published_at_to: "",
        priority: [],
        task_parameter_value_id: [],
        task_operation_parameter_value_id: [],
        discussion_parameter_value_id: [],
        file_parameter_value_id: [],
        parameter_value_id: [],
        task_status_id: '',
        content_status_id: '',
        operation_employee_id: [],
        operation_partner_company_id: '',
        client_employee_id: [],
        no_operation_id: '',
        origin_company_id: '',
        task_operation_operation_id: [],
        id: [],
        created_at_from : '',
        created_at_to : '',
        posted_at_from : '',
        posted_at_to : '',
        discussion_planned_completed_at_from : '',
        discussion_planned_completed_at_to : '',
        discussion_completed_at_from : '',
        discussion_completed_at_to : '',
        order_by: 'updated_desc',
      })

      if (!this.useOut) {
        this.filterReset()
      } else {
        this.filter.value.status_id = []
      }
    } else {
      if (id) {
        let w = [...this.filter.value[val]]
        w.splice(this.filter.value[val].indexOf(id),1)
        this.filter.patchValue({
          [val]: w
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }

      this.filterChats()
    }
    
  }

  filterReset() {


    if (!this.useOut) {
      this.filter.patchValue({
        id: '',
        operation_completed_at_from: '',
        operation_completed_at_to: ''
      })
      if (this.activeStatus.includes(0)) {
        this.router.navigate(['/chats'], { queryParams: {company_id: this.company_id, order_by: "updated_desc"}});
      } else {
        this.filter.value.status_id = this.activeStatus;
        this.router.navigate(['/chats'], { queryParams: {company_id: this.company_id, status_id: this.filter.value.status_id.join(","), order_by: "updated_desc"}});
      }
    } else {
      this.filter.patchValue({
        id: [],
        operation_completed_at_from: '',
        operation_completed_at_to: '',
        status_id: this.activeStatus.includes(0) ? [0] : this.filter.value.status_id
      })
    }
    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   this.mobile_filter_wrp.nativeElement.classList.remove("active");
    // }
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles = resp
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (!!event && (event.ctrlKey || event.metaKey)) {
      if (id == 0) {
        this.activeStatus = [0];
      } else if (this.activeStatus.includes(id)) {
        this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
      } else if (this.activeStatus.includes(0) && id != 0) {
        this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
        this.activeStatus.push(id);
      } else {
        this.activeStatus.push(id);
      }
      if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
        this.activeStatus = [0];
      }
    } else {
      if (id == 0) {
        this.activeStatus = [0];
      } else {
        if (typeof id == 'object') {
          this.activeStatus = id;
        } else {
          this.activeStatus = [id];
        }
      }
    }
    this.sm.localStorageSetItem("activeStatus", this.activeStatus);

    console.log("changeActiveStatus activeStatus", this.activeStatus)
    if (this.useOut) {
      this.filter.patchValue({
        status_id: JSON.stringify(this.activeStatus).indexOf('0') != -1 ? [] : this.activeStatus
      })
    } else {
      this.filterChats(this.activeStatus)
    }
  }

  filterChats(op_status_id = null, order_by = null, is_btn = false, counting: boolean = true) {
    if (this.useOut) {
      return
    }
    if (this.chatsSub) {
      this.chatsSub.unsubscribe()
    }
    if (counting) {
      this.filterCount = this.filterCount + 1;
      this.filter.value.count = this.filterCount;
    }
    
    let filterData = this.filter.value;

    if (!!filterData.created_at_from) {
      filterData.created_at_from = moment(filterData.created_at_from._d).format("X");
    }
    if (!!filterData.posted_at_from) {
      filterData.posted_at_from = moment(filterData.posted_at_from._d).format("X");
    }
    if (!!filterData.created_at_to) {
      filterData.created_at_to = moment(filterData.created_at_to._d).format("X");
    }
    if (!!filterData.posted_at_to) {
      filterData.posted_at_to = moment(filterData.posted_at_to._d).format("X");
    }
    if (!!filterData.discussion_planned_completed_at_from) {
      filterData.discussion_planned_completed_at_from = moment(filterData.discussion_planned_completed_at_from._d).format("X");
    }
    if (!!filterData.discussion_planned_completed_at_to) {
      filterData.discussion_planned_completed_at_to = moment(filterData.discussion_planned_completed_at_to._d).format("X");
    }
    if (!!filterData.discussion_completed_at_from) {
      filterData.discussion_completed_at_from = moment(filterData.discussion_completed_at_from._d).format("X");
    }
    if (!!filterData.discussion_completed_at_to) {
      filterData.discussion_completed_at_to = moment(filterData.discussion_completed_at_to._d).format("X");
    }

    if (!!op_status_id && op_status_id.toString() != '0') {
      filterData.status_id = op_status_id
    }
    
    if (op_status_id && op_status_id.toString() === '0') {
      delete filterData.status_id;
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }

    // this.sm.localStorageSetItem("activeStatus", filterData.status_id);
    
    let params = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }

    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if ((element == 'data_file_id') && filterData[element] !== '') {
        params[element] = filterData[element];
      } else if ((element == 'status_id') && !(filterData[element].length == 1 && filterData[element].includes(0))) {
        console.log(element, filterData[element]);
        params[element] = filterData[element].join(',')
      } else if ((element == 'group_id' || element == 'no_channel_id' || element == 'channel_id' || element == 'discussion_channel_id' || element == 'priority' || element == 'operation_employee_id' || element == 'client_employee_id' || element == 'task_operation_operation_id' || element == 'id' || element.indexOf("parameter_value_id") != -1) && filterData[element].length) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });

    if (filterData.created_at_to === 0) {
      params['created_at_to'] = '0';
    }
    if (filterData.posted_at_to === 0) {
      params['posted_at_to'] = '0';
    }
    if (filterData.discussion_planned_completed_at_to === 0) {
      params['discussion_planned_completed_at_to'] = '0';
    }
    if (filterData.discussion_completed_at_to === 0) {
      params['discussion_completed_at_to'] = '0';
    }

    
    this.router.navigate(['/chats'], { queryParams: params});

    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   if (this.mobile_filter_wrp) {
    //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
    //   }
    // }
  }


  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.chatsContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page > 1 && this.page <= this.pagination['pageCount']) {
          this.getChats(this.page);
        }
      })
    )
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  pinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.pinChat({
        discussion_id: chat.id,
        order: 0
      }, this.company.id).subscribe(resp => {
        chat.pin = resp;
      })
    )
  }

  unPinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.deletePinnedChat(chat.pin.id, this.company.id).subscribe(resp => {
        chat.pin = null;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  closeOpenFilter() {
    this.subFilter && this.subFilter.unsubscribe();
    if (this.overlayRefFilter) {
      this.overlayRefFilter.dispose();
      this.overlayRefFilter = null;
    }
  }

  openFilter(el, val) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefFilter = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.subFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenFilter())

  }


  deleteChat(chat) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("chat")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.chatService.deleteChat(chat.id, this.company.id).subscribe(resp => {
            this.taskBarService.removeItems$.next({
              task_id: chat.task_id,
              task_operation_id: chat.task_operation_id,
              discussion_id: chat.id,
              company_id: chat.company_id
            })
            this.chats.splice(this.chats.indexOf(chat), 1);
          })
        )
      }
    });

  }

  copyLink(type) {
    let snackData = {};
    snackData['name'] = marker(type + " link");
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }
  
  editChat(chat) {

    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        company: this.company,
        is_create_chat_component: true,
        chat: chat,
        user: this.user
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
          // this.page = 1;
          // chat.status = this.statuses.find(x => x.id == result.data.status_id);
          // chat.status_id = result.data.status_id;
          // chat.name = result.data.name;
          // chat.priority = result.data.priority;
        }
      })
    )
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }
  
  getChats(page) {
    console.log(" getChats @@@ FILTER", this.filter.value)
    // return
    this.isLoad = true;
    this.chatsSub = (this.useOut ? this.chatService.getChatsExpand(page, this.company_id, this.filter.value, !this.is_mobile ? '40' : '15', this.task_id, this.task_operation_id) : this.chatService.getChatsExpand(page, this.company_id, this.filter.value, !this.is_mobile ? '40' : '15', this.task_id, this.task_operation_id)).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.pagination['currentPage'] == '1') {
          this.chats = [];
        }
      }),
      map(el => el.body),
      tap(chats => {
        chats.forEach(chat => {
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
        })
      }),
      tap(resp => {
        this.chatService.chatData$.next(resp)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("1 getChats @@@ FILTER", resp)
      
      if (this.page == 1) {
        this.chats = resp;
        this.getEmployees();
        this.getGroupsCompany();
      } else {
        this.chats.push(...resp);
      }
      console.log("this.chats "+this.page, this.chats)

      this.page = this.page + 1;
      this.isLoad = false;
      if (+this.pagination['currentPage'] == 1) {
        this.chatsContainer.nativeElement.scrollTop = 0;
      }

      if (+this.pagination['currentPage'] == 1 || +this.pagination['pageCount'] <= 1) {
        this.getParameters()
      }

      // if (this.chats && this.chats.length) {
      //   this.moveChat(this.chats[0])
      // }
      

    },
    error => {
      this.isLoad = false;
    })
    
  }

  closeFilter(e) {
    if (!e) {
      this.filterChats(null, null, false, false);
    }
  }

  createActiveChat() {
    const dialogRef = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        company: this.company,
        task: this.task,
        work: this.job,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'create') {
          this.ChatAdded.emit()
        }
      })
    )
    
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  ifAllStatuses() {
    this.attachSubscriptions(
      this.filter.get('status_id').valueChanges.subscribe(val => {
        if (val.sort().toString() == [1,2,3,4,5,6,97,98,99].toString() || val.sort().toString() == [0,1,2,3,4,5,6,97,98,99].toString()) {
          this.filter.patchValue({
            status_id: [0]
          })
        }
      })
    )
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterChats()
    }
  }

  
  add(e) {
    console.log(e)
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })

    if (!this.useOut) {
      this.filterChats()
    }
  }

  
  closeDateFilter() {
    this.dateFilterSub && this.dateFilterSub.unsubscribe();
    if (this.overlayDateRefFilter) {
      this.overlayDateRefFilter.dispose();
      this.overlayDateRefFilter = null;
    }
  }

  openDateFilter(e, el, val) {
    console.log("openDateFilter", el, e, el.getBoundingClientRect())
    this.closeDateFilter();
    let x = el.getBoundingClientRect().left - 350 + el.getBoundingClientRect().width;
    let y =  el.getBoundingClientRect().top + el.getBoundingClientRect().height;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayDateRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayDateRefFilter.attach(new TemplatePortal(this.chatFilterDateMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.dateFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayDateRefFilter && !this.overlayDateRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('mat-calendar-table').length > 0 && !document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
          let check5 = !clickTarget.classList.contains('mat-calendar-body-cell-content')
          // console.log("check1", check1)
          // console.log("check2", check2)
          // console.log("clickTarget", clickTarget.classList.contains('mat-calendar-body-cell-content'))
          // console.log("document.getElementsByClassName('mat-calendar-table')", document.getElementsByClassName('mat-calendar-table'))
          // console.log("document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget)", document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
//(check1 && check2) || (check1 && check3 && !check4)
          // return false
          return check1 && check5
        }),
        take(1)
      ).subscribe(() => this.closeDateFilter())

  }


  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          this.getActiveValuesForParam(param)
        })
      )
    )

    return forkJoin(arr)
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
      console.log(resp.body)
      console.log(this.parameters)
    })
  }


  getActiveValuesForParam(param) {
    let paramValues;
    if (param.id != 0) {
      paramValues = this.allValues.filter(b => b.parameter_id == param.id);
    } else {
      paramValues = this.allValues.slice();
    }
    param.activeTaskValues = []
    // console.log("paramValues",param.name, paramValues)
    this.filter.value.discussion_parameter_value_id.forEach(x => {
      // console.log("x", x)
      // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
       param.activeTaskValues.push(...paramValues.filter(y => y.id == x))
    }) 
    param.activeValues = []
    this.filter.value.parameter_value_id.forEach(x => {
      param.activeValues.push(...paramValues.filter(y => y.id == x))
    })
  }
  

  // getAllApiParameterValues() {
  //   this.attachSubscriptions(
  //     this.parametersService.getAllApiParameterValues(this.company.id).subscribe(resp => {
  //       this.allValues = resp
  //       console.log("getAllApiParameterValues", this.allValues)
  //     })
  //   )
  // }

  getParameterById(id) {
    if (!this.parameters || this.parameters.length == 0) {
      return null
    }
    return this.parameters.find(x => x.id == id)
  }


  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onRemove(value, param, key) {
    console.log("onRemove", this.filter.value)
    if (param.id == 0) {
      let parameterValues = this.parameters.find(x => x.id == value.parameter_id)[key == 'discussion_parameter_value_id' ? "activeTaskValues" : "activeValues"]
      parameterValues.splice(parameterValues.indexOf(parameterValues.find(b => b.id == value.id)),1)
    }
    let val = this.filter.value[key];
    val.splice(val.indexOf(value.id), 1);
    this.filter.patchValue({
      [key]: val
    })
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    if (!this.useOut) {
      this.filterChats()
    }
  }

  selected(e, param, key) {
    console.log("selected", e)

    
    if (param.id == 0) {
      console.log(this.parameters, e, param, key);
      let parameterValues = this.parameters.find(x => x.id == e.option.value.parameter_id)[key == 'discussion_parameter_value_id' ? "activeTaskValues" : "activeValues"]
      parameterValues.push(e.option.value)
    }

    let val = this.filter.value[key];
    val.push(e.option.value.id);
    this.filter.patchValue({
      [key]: val
    })
    // param.search.patchValue("")
    // if (document.getElementById(`param_input_${param.id}`)) {
    //   (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
    // }
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    if (!this.useOut) {
      this.filterChats()
    }
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company_id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.company_id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parametersService.paramsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
          this.parameters = res
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onScrollParams(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }

  closeParameterFilter() {
    this.parameterFilterSub && this.parameterFilterSub.unsubscribe();
    if (this.overlayParameterRefFilter) {
      this.overlayParameterRefFilter.dispose();
      this.overlayParameterRefFilter = null;
    }
  }

  openParameterFilter(el, val) {
    this.closeParameterFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayParameterRefFilter = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayParameterRefFilter.attach(new TemplatePortal(this.filterParameterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.parameterFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayParameterRefFilter && !this.overlayParameterRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
//(check1 && check2) || (check1 && check3 && !check4)
          return check1
        }),
        take(1)
      ).subscribe(() => this.closeParameterFilter())

  }


  
  closeChatMenu() {
    this.subChatMenu && this.subChatMenu.unsubscribe();
    if (this.overlayRefChatMenu) {
      this.overlayRefChatMenu.dispose();
      this.overlayRefChatMenu = null;
    }
  }

  openChatMenu({ x, y }: MouseEvent, chat) {
    this.closeChatMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefChatMenu = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    this.overlayRefChatMenu.attach(new TemplatePortal(this.chatMenu, this.viewContainerRef, {
      $implicit: {
        chat: chat
      }
    }));
    
    this.subChatMenu = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefChatMenu && !this.overlayRefChatMenu.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeChatMenu())

  }

  ngOnDestroy(): void {
    if (this.chatsSub) {
      this.chatsSub.unsubscribe()
    }
    if (this.chatsDataSub) {
      this.chatsDataSub.unsubscribe()
    }
    if (this.parametersSub) {
      this.parametersSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
