import { Directive, Input, HostListener, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Renderer2, Injector, ApplicationRef, EmbeddedViewRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UserInfoComponent } from '../global_components/user-info/user-info.component';
import { Subscription } from 'rxjs';
import { CompanyService } from '../services/rest/company.service';
import { BaseClass } from '../models/base-class';

@Directive({
  selector: '[appHoverUserInfo]'
})
export class HoverUserInfoDirective extends BaseClass implements OnInit, OnDestroy {
  @Input('appHoverUserInfo') data: { user: any, company_id: any, task_operation_id: any, checkClick?: boolean };
  private showTimeout: any;
  private hideTimeout: any;
  private componentRef: ComponentRef<UserInfoComponent>;
  private closeAllSubscription: Subscription;
  // private isMouseDown: boolean = false;

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private companyService: CompanyService,
    private renderer: Renderer2,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {
    super();
  }

  @HostListener('mouseover', ['$event']) onMouseOver(event: MouseEvent) {
    console.log("onMouseOver", event);
    if (!this.componentRef && (!this.data.checkClick || (this.data.checkClick && event.buttons !== 1))) {
      clearTimeout(this.showTimeout);
      this.showTimeout = setTimeout(() => {
        this.showUserInfo(event);
      }, 1500);
    }
  }

  // @HostListener('mouseenter') onMouseEnter(event) {
  //   // console.log("onMouseEnter", event)
  //   // if (!this.isMouseDown) {
  //   //   this.showTimeout = setTimeout(() => {
  //   //     this.showUserInfo();
  //   //   }, 1500);
  //   // }
  // }

  @HostListener('mouseleave') onMouseLeave() {
    console.log("onMouseLeave")
    clearTimeout(this.showTimeout);
    this.startHideTimeout();
  }

  @HostListener('mousedown') onMouseDown() {
    console.log("onMouseDown")
    // if (this.data.checkClick) {
    //   this.isMouseDown = true;
    // }
    if (this.data.checkClick && this.componentRef) {
      clearTimeout(this.showTimeout);
      this.close();
    }
  }

  // @HostListener('mouseup') onMouseUp() {
  //   console.log("onMouseUp")
  //   // if (this.data.checkClick) {
  //   //   this.isMouseDown = false;
  //   // }
  // }

  @HostListener('click') onClick() {
    console.log("onClick")
    if (this.data.checkClick && this.componentRef) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.attachSubscriptions(
      this.companyService.getCloseAllUserHover().subscribe(() => {
        clearTimeout(this.showTimeout);
        this.close();
      })
    );
  }

  private showUserInfo(event) {
    if (!this.componentRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserInfoComponent);
      this.componentRef = componentFactory.create(this.injector);
      this.componentRef.instance.user = this.data.user;
      this.componentRef.instance.company_id = this.data.company_id;
      this.componentRef.instance.task_operation_id = this.data.task_operation_id;

      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.renderer.appendChild(document.body, domElem);

      this.positionInfoComponent(domElem, event);

      this.componentRef.instance.mouseEnter.subscribe(() => {
        clearTimeout(this.hideTimeout);
      });
      this.componentRef.instance.mouseLeave.subscribe(() => {
        this.startHideTimeout();
      });
      this.componentRef.instance.onClose.subscribe(() => {
        this.close();
      });

      this.companyService.closeAllOpened();
      this.companyService.registerComponent(this.componentRef);
    }
  }

  private startHideTimeout() {
    this.hideTimeout = setTimeout(() => {
      this.close();
    }, 1000);
  }

  private close() {
    if (this.componentRef) {
      this.companyService.unregisterComponent(this.componentRef);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private positionInfoComponent(domElem: HTMLElement, event) {
    const hostElem = this.el.nativeElement;
    const hostRect = hostElem.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const elemWidth = 320;
    const elemHeight = 550;

    let top = (hostRect.top || event.y) + hostRect.height;
    let left = hostRect.left || event.x;

    if (top + elemHeight > windowHeight) {
      domElem.style.bottom = `${windowHeight - hostRect.top}px`;
    } else {
      domElem.style.top = `${top}px`;
    }

    if (left + elemWidth > windowWidth) {
      domElem.style.right = `${windowWidth - hostRect.right}px`;
    } else {
      domElem.style.left = `${left}px`;
    }

    domElem.style.position = 'fixed';
    domElem.style.zIndex = `9999999`;
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this.close();
  }
}
