import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { forkJoin, fromEvent } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent extends BaseClass implements OnInit, OnDestroy {
  public uploadLimit: boolean = false;
  public selectedCheck: any;
  public activeLang: any;
  public host: any = environment.host;
  public imgRoute: any;
  public is_mobile: boolean = false;
  public hasChecklistError: boolean = false;
  public checklist: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CheckListComponent>,
    private layoutService: LayoutService,
    private taskService: TaskService,
    private fileService: FileService,
    private parametersService: ParametersService,
    private uploadService: UploadService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    console.log("CheckListComponent", this.data);
    this.checkIsMobile();
    this.getChecklist();
  }


  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
    this.getLangData();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getChecklist() {
    console.log("getChecklist 0")
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.data.company.id, {task_operation_id: this.data.work.id}).pipe(
        map(x => x.body),
        switchMap(val => {
          let tagsFilter:any = {
            task_id: this.data.work.task_id,
            task_operation_id: this.data.work.id,
          }

          if (this.data.company.id != this.data.work.company_id) {
            tagsFilter.partner_company_id = this.data.work.company_id
          }

          return this.parametersService.getTargetParameters(this.data.company.id, tagsFilter).pipe(
            tap(b => {
              let checklist = val
              let jobParameters = b
              this.data.work.checklist = [];
              checklist.forEach(element => {
                if (element.exceptParameterValues.length > 0) {
                  if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
                    this.data.work.checklist.push(element)
                  }
                } else if (element.toParameterValues.length > 0) {
                  if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
                    this.data.work.checklist.push(element)
                  }
                } else {
                  this.data.work.checklist.push(element)
                }
              });
      
              if (this.data.work.checklist.length == 0 && this.data.work.checklist.filter(b => !!b.status).length == this.data.work.checklist.length) {
                this.data.work.hasChecklistError = true;
              }
            }),
            map(() => val)
          )
        })
      ).subscribe(resp => {
        console.log("getChecklist", resp)
      })
    )
  }

  // getChecklist() {
  //   console.log("getChecklist 0")
  //   this.attachSubscriptions(
  //     this.taskService.getOperationReminder(this.data.company.id, {task_operation_id: this.data.work.id}).pipe(
  //       tap(val => {
  //         let checklist = val.body;
  //         let jobParameters = this.data.task.parameterValuesToTask.filter(x => !!x.task_operation_id && !x.discussion_id && !x.file_id)
  //         this.data.work.checklist = [];
  //         checklist.forEach(element => {
  //           if (element.exceptParameterValues.length > 0) {
  //             if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
  //               this.data.work.checklist.push(element)
  //             }
  //           } else if (element.toParameterValues.length > 0) {
  //             if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
  //               this.data.work.checklist.push(element)
  //             }
  //           } else {
  //             this.data.work.checklist.push(element)
  //           }
  //         });
  
  //         if (this.data.work.checklist.length == 0 && this.data.work.checklist.filter(b => !!b.status).length == this.data.work.checklist.length) {
  //           this.data.work.hasChecklistError = true;
  //         }
  //       })
  //     ).subscribe(resp => {
  //       console.log("getChecklist", resp)
  //     })
  //   )
  // }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }


  getColumnName(id) {
    if (!this.data.operationsValues || !this.data.operationsValues.find(el => el.id == id)) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id) && this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
