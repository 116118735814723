<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "You are invited to work in the company" | translate }}
    </span>
    <button mat-icon-button class="ml-1" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container invite_container form_wrapper">
        <ng-container *ngIf="errorMsg; else elseTemplateError">
            <p>{{ errorMsg | translate }}</p>
        </ng-container>
        <ng-template #elseTemplateError>
            <ng-container *ngIf="inviteData">
                {{inviteData.name}}
            </ng-container>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="!errorMsg && inviteData">
        <button class="ml-1" mat-raised-button color="primary" (click)="acceptInvite()">
            <mat-icon>check</mat-icon>
            {{ "Accept" | translate }}
        </button>
    </ng-container>
  
    <button *ngIf="!!!user?.id" (click)="login()" class="ml-1" mat-raised-button cdkFocusInitial color="primary">
        {{ "Log_in" | translate }}
    </button>
</mat-dialog-actions>
