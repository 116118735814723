import {
    Directive,
    Output,
    EventEmitter,
    HostListener,
    OnInit,
    Input
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
  
@Directive({
    selector: '[selectDir]'
  })
  export class SelectDirective implements OnInit {
    @Input() sdType = '';
    constructor(private select:MatSelect) { }
  
    ngOnInit() {
      // console.log(this.select, this.sdType)
      if (this.sdType) {
        this.select._overlayPanelClass = this.sdType
      } else {
        this.select._overlayPanelClass = "SOME_CLASS"
      }
    }
  }