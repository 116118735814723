import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-primary-tag-for-files',
  templateUrl: './primary-tag-for-files.component.html',
  styleUrls: ['./primary-tag-for-files.component.scss']
})
export class PrimaryTagForFilesComponent extends BaseClass implements OnInit, OnDestroy {
  public parameter_value: any;
  public isSubmit: boolean = false;
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PrimaryTagForFilesComponent>,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private parametersService: ParametersService,
  ) { super() }

  ngOnInit(): void {
    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  log() {
    console.log("parameter_value", this.parameter_value)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    this.isSubmit = true;
    console.log("selectedFiles", this.data.selectedFiles)
    let postData = [];
                  
    this.data.selectedFiles.forEach(file => {
      let value_id = file.parameterValuesToTask.find(x => x.parameter_value_id == this.parameter_value.id) ? file.parameterValuesToTask.find(x => x.parameter_value_id == this.parameter_value.id).id : null
      if (value_id) {
        postData.push({
          "path": `/api/parameter-value-to-task/${value_id}/`,
          "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            is_primary: 1
          }
        })
      } else {
        postData.push({
          "path": `/api/parameter-value-to-task/`,
          "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            company_id: file.company_id,
            parameter_id: this.parameter_value.parameter_id,
            parameter_value_id: this.parameter_value.id,
            task_id: file.task_id,
            task_operation_id: file.task_operation_id,
            discussion_id: file.discussion_id && file.discussion ? file.discussion_id : 0,
            file_id: file.id,
            is_primary: 1
          }
        })
      }
    });

    if (postData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(postData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "create", data: resp})
          if (resp && resp.length) {
            // resp.forEach(element => {    
            //   this.data.selectedFiles.forEach(file => {
            //     if (file.id == element.body.file_id) {
            //       file.parameterValuesToTask.find(x => x.id == element.body.id).is_primary = element.body.is_primary
            //     }
            //   })
            // });
          }
        })
      )
    } else {
      this.isSubmit = false;
    }
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
