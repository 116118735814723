<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal">
            {{ (data.is_edit ? "Edit Access Settings" : "Add Access Settings") | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <ng-container *ngIf="data.is_edit; else elseTemplateEdit">
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_name">{{ "Name" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_description">{{ "Description" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_task_channels">{{ "Card publications" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_files">{{ "Card files" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_documents">{{ "Card notes" | translate }}</mat-checkbox>
                </div>
            </ng-container>
            <ng-template #elseTemplateEdit>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="with_all">{{ "For all without authorization" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="with_registered">{{ "For all with authorization" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="with_company_id">{{ "For all from current workspace" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_name">{{ "Name" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_description">{{ "Description" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_task_channels">{{ "Card publications" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_files">{{ "Card files" | translate }}</mat-checkbox>
                </div>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_documents">{{ "Card notes" | translate }}</mat-checkbox>
                </div>
            </ng-template>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            {{ (data.is_edit ? "Edit" : "Add") | translate }}
        </button>
    </div>
</mat-dialog-actions>