import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from '../../models/base-class';
import { GlobalDataService } from '../../services/common/global-data.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { CompanyService } from '../../services/rest/company.service';
import { TaskService } from '../../services/rest/task.service';
import { InterfaceComponent } from '../file-manager/dialog/interface/interface.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ParametersService } from '../../services/rest/parameters.service';
import { LanguageService } from '../../services/common/language.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { prioritys } from '../../consts/prioritys';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { DateRange, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { MobDefaultFilterComponent } from './mob-filters/mob-default-filter/mob-default-filter.component';
import { MobSearchFilterComponent } from './mob-filters/mob-search-filter/mob-search-filter.component';
import { MobActiveFiltersComponent } from './mob-filters/mob-active-filters/mob-active-filters.component';
import { MobShortcutsComponent } from './mob-filters/mob-shortcuts/mob-shortcuts.component';
import { MobStakeholderFilterComponent } from './mob-filters/mob-stakeholder-filter/mob-stakeholder-filter.component';
import { MobTagFilterComponent } from './mob-filters/mob-tag-filter/mob-tag-filter.component';
import { MobTypeFilterComponent } from './mob-filters/mob-type-filter/mob-type-filter.component';
import { MobPlatformFilterComponent } from './mob-filters/mob-platform-filter/mob-platform-filter.component';
import { MobDateFilterComponent } from './mob-filters/mob-date-filter/mob-date-filter.component';
import { MobAutoupdateFilterComponent } from './mob-filters/mob-autoupdate-filter/mob-autoupdate-filter.component';
import { MobStatusFilterComponent } from './mob-filters/mob-status-filter/mob-status-filter.component';
import { MobSortFilterComponent } from './mob-filters/mob-sort-filter/mob-sort-filter.component';
import { MobUpdateIntervalComponent } from './mob-filters/mob-update-interval/mob-update-interval.component';
import { MobChannelsFilterComponent } from './mob-filters/mob-channels-filter/mob-channels-filter.component';
import { SaveFilterToBookmarkComponent } from './dialogs/save-filter-to-bookmark/save-filter-to-bookmark.component';
import { FileService } from '../../services/rest/file.service';
import { Title } from '@angular/platform-browser';
import { MobScheduledTypesFilterComponent } from './mob-filters/mob-scheduled-types-filter/mob-scheduled-types-filter.component';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class NavigationBarComponent extends BaseClass implements OnInit, OnChanges, OnDestroy {
  @ViewChild("searchType") searchType: ElementRef;
  @ViewChild("searchDefault") searchDefault: ElementRef;
  public filter: FormGroup = this.fb.group({
    count: 1,
    q: '',
    task_operation_operation_id: [],
    parameter_value_id: [[]],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id: [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    group_id: [[]],
    content_type_id: [],
    priority: [],
    content_status_id: [],
    task_status_id: [],
    is_content_url: [],
    channel_platform_id: [],
    channel_id: [[]],
    no_channel_id: [[]],
    operation_employee_id: [],
    client_employee_id: [],
    origin_company_id: [],
    operation_partner_company_id: [],
    task_custom_id: "",
    no_operation_id: "",
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
  });

  public outletKey: string = 'channel_id'
  public statusKey: string = 'content_status_id'


  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  public readyVerArr = [
    {
      id: 0,
      name: "No"
    },
    {
      id: 1,
      name: "Yes"
    }
  ]
  
  public pageTab: string = '';
  public page: string = '';
  public filterCount: number = 1;
  throttle = 300;
  scrollUpDistance = 3.5;
  public paramFilterProps: any = ['parameter_value_id', 'task_parameter_value_id', 'task_operation_parameter_value_id', 'discussion_parameter_value_id', 'file_parameter_value_id']

  public showJobsBtn: boolean = false;
  public selectedPartnerForGroup: any = 0;

  public allContentTypes: any;
  public contentTypes: any[] = [];
  public contentTypesControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public prioritys: any = prioritys;
  public prioritysControl: FormControl = new FormControl();
  public prioritys$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public favoriteBookmarks: any;
  public statuses: any;
  public viewsUpdateIntervalList: any;

  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();

  public contentPage: number = 1;
  public contentPagination: any = {};

  public values: any;
  public parameters: any;
  public allValues: any;
  public activeParams: any = {};
  public sortValue: any;
  public paramDataSub: Subscription;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public devices: any;
  public devices$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public devicesMoreControl: FormControl = new FormControl();

  public mobDevices: any;
  public mobDevices$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public mobDevicesMoreControl: FormControl = new FormControl();

  public checklists: any;
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public isOpenBoard: boolean = false;
  public postsHovered: boolean = false;
  public postsQ: any = '';
  public hideShorts: boolean = false;
  public showOutletBtn: boolean = false;
  public showFilter: boolean = false;
  public switched: boolean = false;
  public host: any = environment.host;
  public imgRoute: any = '';

  public profilesPagination: any;
  public activeLang: any;

  public profilesPage: number = 1;
  public groupedProfiles: any = [];
  public profiles: any;
  public profilesControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public jobTypes: any;
  public jobTypesControl: FormControl = new FormControl();
  public jobTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isLoadGroups: boolean = false;
  public groupsPage: number = 1;
  public groupsPagination: any;
  public groups: any;
  public groupsSub: Subscription;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerGroups: any;
  public partnerGroupsControl: FormControl = new FormControl();
  public partnerGroups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public profileStatuses: any;
  public profileStatusesControl: FormControl = new FormControl();
  public profileStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public taskStatuses: any;
  public taskStatusesControl: FormControl = new FormControl();
  public taskStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employeesControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public clients: any;
  public clientsControl: FormControl = new FormControl();
  public clients$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public scheduleTypes: any = ['not_scheduled', 'user', 'auto', 'semi_auto'];

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public permissions: any;
  public permissionsControl: FormControl = new FormControl();
  public permissions$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public departments: any;
  public departmentsControl: FormControl = new FormControl();
  public departments$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public pos: any;
  public posControl: FormControl = new FormControl();
  public pos$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public stakeholderControl: FormControl = new FormControl(0);
  public stakeholderSubControl: FormControl = new FormControl(0);
  public channelsSubsControl: FormControl = new FormControl(0);

  public statusControl: FormControl = new FormControl(0);
  public tagsControl: FormControl = new FormControl(0);

  public datesControl: FormControl = new FormControl(0);
  public datesModel: any[] = [
    {
      name: "Cards",
      id: 0,
      activeFilter: new FormControl(0),
      filters: [
        {
          id: 0,
          name: 'Added',
          key: 'task_created_at_',
          from: 'task_created_at_from',
          to: 'task_created_at_to',
          range: new DateRange("", "")
        },
      ]
    },
    {
      name: "Jobs",
      id: 1,
      activeFilter: new FormControl(0),
      filters: [
        {
          id: 0,
          name: 'Added',
          key: 'task_operation_created_at_',
          from: 'task_operation_created_at_from',
          to: 'task_operation_created_at_to',
          range: new DateRange("", "")
        },
        {
          id: 1,
          name: 'Finished',
          key: 'task_operation_completed_at_',
          from: 'task_operation_completed_at_from',
          to: 'task_operation_completed_at_to',
          range: new DateRange("", "")
        },
      ]
    },
    {
      name: "Chats",
      id: 2,
      activeFilter: new FormControl(0),
      filters: [
        {
          id: 0,
          name: 'Added',
          key: 'discussion_created_at_',
          from: 'discussion_created_at_from',
          to: 'discussion_created_at_to',
          range: new DateRange("", "")
        }
      ]
    },
    {
      name: "Files",
      id: 3,
      activeFilter: new FormControl(0),
      filters: [
        {
          id: 0,
          name: 'Added',
          key: 'file_created_at_',
          from: 'file_created_at_from',
          to: 'file_created_at_to',
          range: new DateRange("", "")
        },
        {
          id: 1,
          name: 'Updated',
          key: 'file_updated_at_',
          from: 'file_updated_at_from',
          to: 'file_updated_at_to',
          range: new DateRange("", "")
        },
        {
          id: 2,
          name: 'Created',
          key: 'file_meta_creation_time_',
          from: 'file_meta_creation_time_from',
          to: 'file_meta_creation_time_to',
          range: new DateRange("", "")
        }
      ]
    },
    {
      name: "Posts",
      id: 4,
      activeFilter: new FormControl(0),
      filters: [
        {
          id: 0,
          name: 'Published',
          key: 'task_channel_published_at_',
          from: 'task_channel_published_at_from',
          to: 'task_channel_published_at_to',
          range: new DateRange("", "")
        }
      ]
    }
  ]

  public postsControl: FormControl = new FormControl(0)
  // {
  //   icon: '',
  //   id: 0,
  //   name: 'Assigned',
  //   key: 'operation_employee_id',
  //   data: [],
  //   asData: []
  // },
  // {
  //   icon: 'plus',
  //   id: 1,
  //   name: '+ Outlet',
  //   key: 'created_employee_id',
  //   data: [],
  //   asData: []
  // },
  // {
  //   icon: 'plus',
  //   id: 2,
  //   name: '+ URL',
  //   key: 'created_url_employee_id',
  //   data: [],
  //   asData: []
  // },
  // {
  //   icon: 'edit',
  //   id: 3,
  //   name: 'Outlet',
  //   key: 'updated_employee_id',
  //   data: [],
  //   asData: []
  // },
  // {
  //   icon: 'edit',
  //   id: 4,
  //   name: 'URL',
  //   key: 'updated_url_employee_id',
  //   data: [],
  //   asData: []
  // },
  public postsModel: any[] = [
    {
      icon: '',
      name: "Individuals",
      onlyThis: false,
      id: 0,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Individuals',
          key: 'operation_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: false,
      icon: '',
      name: "Clients",
      id: 1,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Clients',
          key: 'client_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        },
        {
          icon: '',
          id: 1,
          name: 'Workspaces',
          key: 'origin_company_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: false,
      icon: '',
      name: "Workspaces",
      id: 2,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Workspaces',
          key: 'operation_partner_company_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: true,
      icon: 'plus',
      name: "Outlet",
      id: 3,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'outlet',
          key: 'created_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: true,
      icon: 'plus',
      name: "URL",
      id: 4,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'url',
          key: 'created_url_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: true,
      icon: 'edit',
      name: "Outlet",
      id: 5,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Outlet',
          key: 'updated_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: true,
      icon: 'edit',
      name: "URL",
      id: 6,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'url',
          key: 'updated_url_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    }
  ]
  public stakeholdersModel: any[] = [
    {
      icon: '',
      name: "Individuals",
      onlyThis: false,
      id: 0,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Individuals',
          key: 'operation_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: false,
      icon: '',
      name: "Clients",
      id: 1,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Clients',
          key: 'client_employee_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        },
        {
          icon: '',
          id: 1,
          name: 'Workspaces',
          key: 'origin_company_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    },
    {
      onlyThis: false,
      icon: '',
      name: "Workspaces",
      id: 2,
      activeFilter: new FormControl(0),
      filters: [
        {
          icon: '',
          id: 0,
          name: 'Workspaces',
          key: 'operation_partner_company_id',
          control: new FormControl(''),
          data: [],
          asData: new ReplaySubject<any>(1)
        }
      ]
    }
  ]

  public isMobHasActiveFilter: boolean = false;

  @Input() barItems: any;
  @Input() company: any;
  @Input() company_id: any;
  @Input() user: any;
  @Input() is_mobile: boolean = false;
  @Output() RemoveItem = new EventEmitter<any>();
  @Output() GoToItem = new EventEmitter<any>();
  @Output() LinkToItem = new EventEmitter<any>();
  @Output() GoToPage = new EventEmitter<any>();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private globalDataService: GlobalDataService,
    private taskService: TaskService,
    private fileService: FileService,
    private titleService: Title,
    private languageService: LanguageService,
    private _dateAdapter: DateAdapter<Moment>,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private activatedRoute: ActivatedRoute,
  ) { super() }

  ngOnInit(): void {
    this.prioritys$.next(this.prioritys.slice());
    this.getProfilesStatus();
    this.selectedPartnerForGroup = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') ? +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') : 0;

    if (this.selectedPartnerForGroup) {
      this.getGroupsPartnerCompany();
    }
    this.groupsPage = 1;
    if (this.groupsSub) {
      this.groupsSub.unsubscribe()
    }
    this.getGroupsCompany();
    this.getTaskStatus();
    this.getPartners();
    this.getEmployees();
    this.onRouteChange();
    this.getPlatforms();
    this.getViewsUpdateIntervalList();
    this.getImgRoute();
    this.getParameters();
    this.getLangData();
    this.getFavoriteBookmarks();
    this.getProfiles(this.profilesPage);
    this.getOperationsStatus();
    
    this.getFileDevices();
    this.getChecklists();
    this.getPermissions();
    this.getDepartmentsDyn();
    this.getPositionsDyn();

    this.postsModel.forEach(mod => {
      mod.filters.forEach(fil => {
        this.attachSubscriptions(
          fil.control.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
            this.onSearchPostsModel(resp, fil)
          })
        )
      });
    })

    this.stakeholdersModel.forEach(mod => {
      mod.filters.forEach(fil => {
        this.attachSubscriptions(
          fil.control.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
            this.onSearchStakeholdersModel(resp, fil)
          })
        )
      });
    })


    if (this.activeSections() == "Settings") {
      this.filter.addControl('id', this.fb.control(''))
      this.filter.addControl('time_start_utc_from', this.fb.control(''))
      this.filter.addControl('time_start_utc_to', this.fb.control(''))
      this.filter.addControl('permission', this.fb.control(''))
      this.filter.addControl('department_id', this.fb.control([]))
      this.filter.addControl('department_position_id', this.fb.control([]))
      if (this.datesModel.filter(x => x.name == 'Statements').length == 0) {
        this.datesModel.push({
          name: "Statements",
          id: 6,
          activeFilter: new FormControl(0),
          onlyThis: true,
          filters: [{
            id: 0,
            name: 'Biling At',
            key: 'time_start_utc_',
            from: 'time_start_utc_from',
            to: 'time_start_utc_to',
            range: new DateRange("", ""),
            onlyThis: true
          }]
        })
      }
    } else {
      if (this.activeSections() != "Cards" && this.activeSections() != "Projects" && this.activeSections() != "Analytic") {
        this.filter.removeControl('id');
      }
      this.filter.removeControl('time_start_utc_from');
      this.filter.removeControl('time_start_utc_to');
      this.filter.removeControl('permission');
      this.filter.removeControl('department_id');
      this.filter.removeControl('department_position_id');
      if (this.datesModel.filter(x => x.name == 'Statements').length != 0) {
        this.datesModel.splice(this.datesModel.findIndex(u => u.name == 'Statements'), 1)
        this.datesControl.patchValue(0)
      }
    }

    if (this.activeSections() == "Analytic") {
      this.filter.addControl('id', this.fb.control(''))
    } else {
      if (this.activeSections() != "Cards" && this.activeSections() != "Projects" && this.activeSections() != "Settings") {
        this.filter.removeControl('id');
      }
    }

    if (this.activeSections() == "Projects") {
      this.filter.addControl('id', this.fb.control(''))
      this.filter.addControl('task_id', this.fb.control(''))
      this.filter.addControl('task_operation_id', this.fb.control(''))
      this.filter.addControl('discussion_id', this.fb.control(''))
    } else {
      if (this.activeSections() != "Cards" && this.activeSections() != "Settings" && this.activeSections() != "Analytic") {
        this.filter.removeControl('id')
      }
      if (this.activeSections() != "Settings") {
        this.filter.removeControl('permission')
        this.filter.removeControl('department_id')
        this.filter.removeControl('department_position_id')
      }
      
      if (this.activeSections() != "Cards") {
        this.filter.removeControl('discussion_id')
        this.filter.removeControl('task_operation_id')
      }
      this.filter.removeControl('task_id')
    }


    if (this.activeSections() == "Posts") {
      this.filter.addControl('is_content_views_count_update', this.fb.control(''))
      this.filter.addControl('scheduled_published_time_by', this.fb.control(''))
      this.filter.addControl('external_status', this.fb.control(''))
      this.filter.addControl('is_bravourl_content_views_count_update', this.fb.control(''))
      this.filter.addControl('views_update_interval', this.fb.control([]))
      this.filter.addControl('created_at_from', this.fb.control(''))
      this.filter.addControl('created_at_to', this.fb.control(''))
      this.filter.addControl('analytics_views_count_from', this.fb.control(''))
      this.filter.addControl('analytics_views_count_to', this.fb.control(''))
      this.filter.addControl('created_employee_id', this.fb.control([]))
      this.filter.addControl('updated_employee_id', this.fb.control([]))
      this.filter.addControl('created_url_employee_id', this.fb.control([]))
      this.filter.addControl('updated_url_employee_id', this.fb.control([]))
      if (this.datesModel[4].filters.filter(x => x.name == 'Created').length == 0) {
        this.datesModel[4].filters.push({
          id: this.datesModel[4].filters.length,
          name: 'Created',
          key: 'created_at_',
          from: 'created_at_from',
          to: 'created_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
      if (this.datesModel[4].filters.filter(x => x.name == 'Views').length == 0) {
        this.datesModel[4].filters.push({
          id: this.datesModel[4].filters.length,
          name: 'Views',
          key: 'analytics_views_count_',
          from: 'analytics_views_count_from',
          to: 'analytics_views_count_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }


    } else {
      this.filter.removeControl('views_update_interval')
      this.filter.removeControl('is_content_views_count_update')
      this.filter.removeControl('scheduled_published_time_by')
      this.filter.removeControl('external_status')
      this.filter.removeControl('is_bravourl_content_views_count_update')
      this.filter.removeControl('analytics_views_count_from')
      this.filter.removeControl('analytics_views_count_to')
      this.filter.removeControl('created_at_from')
      this.filter.removeControl('created_at_to')
      this.filter.removeControl('created_employee_id')
      this.filter.removeControl('updated_employee_id')
      this.filter.removeControl('created_url_employee_id')
      this.filter.removeControl('updated_url_employee_id')
      if (this.datesModel[4].filters.filter(x => x.name == 'Views').length > 0) {
        this.datesModel[4].filters.splice(this.datesModel[4].filters.indexOf(this.datesModel[4].filters.find(x => x.name == 'Views')), 1)
      }
      if (this.datesModel[4].filters.filter(x => x.name == 'Created').length > 0) {
        this.datesModel[4].filters.splice(this.datesModel[4].filters.indexOf(this.datesModel[4].filters.find(x => x.name == 'Created')), 1)
      }
    }

    if (this.activeSections() == "Files") {
      this.filter.addControl('location_all', this.fb.control('/'))
      this.filter.addControl('file_id', this.fb.control(''))
      this.filter.addControl('task_channel_file_content_status_id', this.fb.control(''))
      this.filter.addControl('content_type', this.fb.control(''))
      this.filter.addControl('group_by', this.fb.control('original_file_id'))
      this.filter.addControl('meta_device', this.fb.control([]))
      this.filter.addControl('part_of_count', this.fb.control([]))
      this.filter.addControl('consist_of_count', this.fb.control([]))
      this.filter.addControl('related_count', this.fb.control([]))
      this.filter.addControl('operation_reminder_id', this.fb.control([]))
      this.filter.addControl('is_ready_if_approved', this.fb.control([]))
    } else {
      this.filter.removeControl('file_id')
      this.filter.removeControl('task_channel_file_content_status_id')
      this.filter.removeControl('content_type')
      this.filter.removeControl('location_all')
      this.filter.removeControl('group_by')
      this.filter.removeControl('meta_device')
      this.filter.removeControl('part_of_count')
      this.filter.removeControl('consist_of_count')
      this.filter.removeControl('related_count')
      this.filter.removeControl('operation_reminder_id')
      this.filter.removeControl('is_ready_if_approved')
    }

    if (this.activeSections() == "Board by Order") {
      this.filter.addControl('status_id', this.fb.control([[1,2,5]]))
      this.filter.addControl('doneStatus', this.fb.control([[3,4,6,97,98,99]]))
    } else {
      if (this.activeSections() != "Board by Date") {
        this.filter.removeControl('doneStatus')
        if (this.activeSections() != "Chats" && this.activeSections() != "Jobs") {
          this.filter.removeControl('status_id')
        }
      }
    }

    if (this.activeSections() == "Board by Date") {
      this.filter.addControl('status_id', this.fb.control([[1,2,5]]))
      this.filter.addControl('doneStatus', this.fb.control([[3,4,6,97,98,99]]))
      this.filter.addControl('start_at_from', this.fb.control(''))
      this.filter.addControl('start_at_to', this.fb.control(''))
      if (this.datesModel.filter(x => x.name == 'Board').length == 0) {
        this.datesModel.push({
          name: "Board",
          id: 5,
          activeFilter: new FormControl(0),
          onlyThis: true,
          filters: [{
            id: 0,
            name: 'Start At',
            key: 'start_at_',
            from: 'start_at_from',
            to: 'start_at_to',
            range: new DateRange("", ""),
            onlyThis: true
          }]
        })
      }
    } else {
      if (this.activeSections() != "Board by Order") {
        this.filter.removeControl('doneStatus')
        if (this.activeSections() != "Chats" && this.activeSections() != "Jobs") {
          this.filter.removeControl('status_id')
        }
      }
      this.filter.removeControl('start_at_from')
      this.filter.removeControl('start_at_to')
      if (this.datesModel.filter(x => x.name == 'Board').length != 0) {
        this.datesModel.splice(this.datesModel.findIndex(u => u.name == 'Board'), 1)
        this.datesControl.patchValue(0)
      }
    }

    if (this.activeSections() == "Jobs") {
      this.filter.addControl('status_id', this.fb.control(''))
      this.filter.addControl('updated_at_from', this.fb.control(''))
      this.filter.addControl('updated_at_to', this.fb.control(''))
      if (this.datesModel[1].filters.filter(x => x.name == 'Updated').length == 0) {
        this.datesModel[1].filters.push({
          id: this.datesModel[1].filters.length,
          name: 'Updated',
          key: 'updated_at_',
          from: 'updated_at_from',
          to: 'updated_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
      this.filter.addControl('task_operation_planned_completed_at_from', this.fb.control(''))
      this.filter.addControl('task_operation_planned_completed_at_to', this.fb.control(''))
      if (this.datesModel[1].filters.filter(x => x.name == 'Deadline').length == 0) {
        this.datesModel[1].filters.push({
          id: this.datesModel[1].filters.length,
          name: 'Deadline',
          key: 'task_operation_planned_completed_at_',
          from: 'task_operation_planned_completed_at_from',
          to: 'task_operation_planned_completed_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
    } else {
      if (this.activeSections() != "Board by Order" && this.activeSections() != "Board by Date" && this.activeSections() != "Chats") {
        this.filter.removeControl('status_id')
      }
      this.filter.removeControl('updated_at_from')
      this.filter.removeControl('updated_at_to')
      if (this.datesModel[1].filters.filter(x => x.name == 'Updated').length > 0) {
        this.datesModel[1].filters.splice(this.datesModel[1].filters.indexOf(this.datesModel[1].filters.find(x => x.name == 'Updated')), 1)
      }
      this.filter.removeControl('task_operation_planned_completed_at_from')
      this.filter.removeControl('task_operation_planned_completed_at_to')
      if (this.datesModel[1].filters.filter(x => x.name == 'Deadline').length > 0) {
        this.datesModel[1].filters.splice(this.datesModel[1].filters.indexOf(this.datesModel[1].filters.find(x => x.name == 'Deadline')), 1)
      }
    }

    if (this.activeSections() == "Chats") {
      this.filter.addControl('status_id', this.fb.control([]))
      this.filter.addControl('q_name', this.fb.control(''))
      this.filter.addControl('discussion_other_employee_status_id', this.fb.control(0))
      this.filter.addControl('q_text', this.fb.control(''))
      this.filter.addControl('discussion_channel_id', this.fb.control([]))
      this.filter.addControl('posted_at_from', this.fb.control(''))
      this.filter.addControl('posted_at_to', this.fb.control(''))
      this.filter.addControl('discussion_completed_at_from', this.fb.control(''))
      this.filter.addControl('discussion_completed_at_to', this.fb.control(''))
      this.filter.addControl('discussion_planned_completed_at_from', this.fb.control(''))
      this.filter.addControl('discussion_planned_completed_at_to', this.fb.control(''))
      if (this.datesModel[2].filters.filter(x => x.name == 'Completed').length == 0) {
        this.datesModel[2].filters.push({
          id: this.datesModel[2].filters.length,
          name: 'Completed',
          key: 'discussion_completed_at_',
          from: 'discussion_completed_at_from',
          to: 'discussion_completed_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
      if (this.datesModel[2].filters.filter(x => x.name == 'Post Added').length == 0) {
        this.datesModel[2].filters.push({
          id: this.datesModel[2].filters.length,
          name: 'Post Added',
          key: 'posted_at_',
          from: 'posted_at_from',
          to: 'posted_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
      if (this.datesModel[2].filters.filter(x => x.name == 'Deadline').length == 0) {
        this.datesModel[2].filters.push({
          id: this.datesModel[2].filters.length,
          name: 'Deadline',
          key: 'discussion_planned_completed_at_',
          from: 'discussion_planned_completed_at_from',
          to: 'discussion_planned_completed_at_to',
          range: new DateRange("", ""),
          onlyThis: true
        })
      }
    } else {
      if (this.activeSections() != "Board by Order" && this.activeSections() != "Board by Date"  && this.activeSections() != "Jobs") {
        this.filter.removeControl('status_id')
      }
      this.filter.removeControl('q_name')
      this.filter.removeControl('discussion_channel_id')
      this.filter.removeControl('discussion_other_employee_status_id')
      this.filter.removeControl('q_text')
      this.filter.removeControl('posted_at_from')
      this.filter.removeControl('posted_at_to')
      this.filter.removeControl('discussion_completed_at_from')
      this.filter.removeControl('discussion_completed_at_to')
      this.filter.removeControl('discussion_planned_completed_at_from')
      this.filter.removeControl('discussion_planned_completed_at_to')
      if (this.datesModel[2].filters.filter(x => x.name == 'Completed').length > 0) {
        this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Completed')), 1)
      }
      if (this.datesModel[2].filters.filter(x => x.name == 'Post Added').length > 0) {
        this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Post Added')), 1)
      }
      if (this.datesModel[2].filters.filter(x => x.name == 'Deadline').length > 0) {
        this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Deadline')), 1)
      }
    }

    if (this.activeSections() == "Cards") {
      this.filter.addControl('operation_status_id', this.fb.control([]))
      this.filter.addControl('task_content_status_id', this.fb.control([]))
      this.filter.addControl('id', this.fb.control(''))
      this.filter.addControl('internal_id', this.fb.control(''))
      this.filter.addControl('task_company_id', this.fb.control(''))
      this.filter.addControl('discussion_id', this.fb.control(''))
      this.filter.addControl('task_operation_id', this.fb.control(''))
      this.filter.addControl('q_task_channel_content_filename', this.fb.control(''))
      this.filter.addControl('q_task_name', this.fb.control(''))
      this.filter.addControl('q_task_comment', this.fb.control(''))
      this.filter.addControl('q_task_private_comment', this.fb.control(''))
      this.filter.addControl('q_task_operation_comment', this.fb.control(''))
      this.filter.addControl('q_task_operation_employee_comment', this.fb.control(''))
      this.filter.addControl('q_task_operation_private_comment', this.fb.control(''))
    } else {
      this.filter.removeControl('operation_status_id')
      this.filter.removeControl('task_content_status_id')
      if (this.activeSections() != "Projects" && this.activeSections() != "Settings" && this.activeSections() != "Analytic") {
        this.filter.removeControl('id')
      }
      if (this.activeSections() != "Settings") {
        this.filter.removeControl('permission');
        this.filter.removeControl('department_id');
        this.filter.removeControl('department_position_id');
      }
      if (this.activeSections() != "Projects") {
        this.filter.removeControl('discussion_id')
        this.filter.removeControl('task_operation_id')
      }
      this.filter.removeControl('internal_id')
      this.filter.removeControl('task_company_id')
      this.filter.removeControl('q_task_channel_content_filename')
      this.filter.removeControl('q_task_comment')
      this.filter.removeControl('q_task_private_comment')
      this.filter.removeControl('q_task_operation_comment')
      this.filter.removeControl('q_task_operation_employee_comment')
      this.filter.removeControl('q_task_operation_private_comment')
    }
    
    this.filter.updateValueAndValidity()

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )
    this.attachSubscriptions(
      this.contentTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )
    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.clientsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchClients(resp))
    )
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.attachSubscriptions(
      this.prioritysControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPrioritys(resp))
    )
    this.attachSubscriptions(
      this.profilesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
    this.attachSubscriptions(
      this.profileStatusesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfileStatuses(resp))
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
        this.groupsPage = 1;
        if (this.groupsSub) {
          this.groupsSub.unsubscribe()
        }
        this.getGroupsCompany(resp)
      })
    )
    this.attachSubscriptions(
      this.partnerGroupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartnerGroups(resp))
    )
    this.attachSubscriptions(
      this.taskStatusesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTaskStatuses(resp))
    )
    this.attachSubscriptions(
      this.jobTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobTypes(resp))
    )
    this.attachSubscriptions(
      this.departmentsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDepartments(resp))
    )
    this.attachSubscriptions(
      this.posControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPos(resp))
    )

    this.attachSubscriptions(
      this.devicesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDevices(resp))
    )

    this.attachSubscriptions(
      this.mobDevicesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchMobDevices(resp))
    )

    this.attachSubscriptions(
      this.checklistsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecklists(resp))
    )

    this.paramDataSub = this.parametersService.getGlobalParamsStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getGlobalParamsStream-----",resp));
  }

  sendData() {
    this.globalDataService.transferData$.next({
      allContentTypes: this.allContentTypes,
      employees: this.employees,
      clients: this.clients,
      viewsUpdateIntervalList: this.viewsUpdateIntervalList,
      partners: this.partners,
      allValues: this.allValues,
      statuses: this.statuses,
      partnerGroups: this.partnerGroups,
      checklists: this.checklists,
      taskStatuses: this.taskStatuses,
      jobTypes: this.jobTypes, 
      groups: this.groups, 
      prioritys: this.prioritys,
      platforms: this.platforms,
      profiles: this.profiles,
      profileStatuses: this.profileStatuses, 
      contentUrl: this.contentUrl
    })
  }

  getPermissions() {
    this.attachSubscriptions(
      this.taskService.getRules().subscribe(resp => {
        this.permissions = resp;
        this.permissions$.next(this.permissions.slice());
        console.log("getPermissions", this.permissions);
      })
    )
  }

  getStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }


  logFils() {
    console.log("logFils", this.filter)
  }

  getPostStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "Draft";
        break;
      case 3:
        return "Scheduled";
        break;
      case 4:
        return "Published";
        break;
      case 5:
        return "Canceled";
        break;
      case 10:
        return "Upload setup";
        break;
      case 11:
        return "Upload ready";
        break;
      case 6:
        return "To upload";
        break;
      case 7:
        return "Uploading";
        break;
      case 8:
        return "Upload error";
        break;
      case 9:
        return "Uploaded, processing";
        break;
      case 12:
        return "Uploaded, ready to publish";
        break;
      case 13:
        return "Planned";
        break;
      case 14:
        return "Live streaming";
        break;
    }
  }

  selectPartnerGroups(val) {
    this.selectedPartnerForGroup = val;

    this.filter.patchValue({
      group_id: []
    });
    this.partnerGroups = undefined;

    if (this.selectedPartnerForGroup) {
      this.getGroupsPartnerCompany();
    }
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("NavigationBarComponent changes", changes)
    if (changes.company_id && !!changes.company_id.previousValue && changes.company_id.previousValue != changes.company_id.currentValue) {
      this.postsModel.forEach(mod => {
        mod.filters.forEach(fil => {
            fil.data = [];
            fil.asData = new ReplaySubject<any>(1);
        });
      })
      this.stakeholdersModel.forEach(mod => {
        mod.filters.forEach(fil => {
            fil.data = [];
            fil.asData = new ReplaySubject<any>(1);
        });
      })
      this.GoToPage.emit(this.getParams())

      this.selectedPartnerForGroup = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') ? +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') : 0;

      if (this.selectedPartnerForGroup) {
        this.getGroupsPartnerCompany();
      }
      this.groupsPage = 1;
      if (this.groupsSub) {
        this.groupsSub.unsubscribe()
      }
      this.getGroupsCompany();
      this.getLangData();
      this.getProfilesStatus();
      this.getTaskStatus();
      this.getPlatforms();
      this.getViewsUpdateIntervalList();
      this.getImgRoute();
      this.getOperationsStatus();
      this.getFileDevices();
      this.getChecklists();
      this.getDepartmentsDyn();
      this.getPositionsDyn();
      this.getPartners();
      this.getEmployees();
      this.profilesPage = 1;
      this.contentPage = 1;
      this.getProfiles(this.profilesPage);
      this.getFavoriteBookmarks();

      if (this.paramDataSub) {
        this.paramDataSub.unsubscribe();
      }
      this.paramDataSub = this.parametersService.getGlobalParamsStream().pipe(
        concatMap(params => {
          return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
        }),
      ).subscribe(resp => console.log("-----getGlobalParamsStream-----",resp));

      this.getParameters();
    }
  }

  onSearchDevices(resp) {
    if (!this.devices) {
      return;
    }

    if (!resp) {
      this.devices$.next(this.devices.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.devices$.next(
      this.devices.filter(z => z.toLowerCase().indexOf(resp) > -1)
    );
  }

  mouseEnterNavPar(e) {
    console.log("mouseEnterNavPar", e);
    console.log("mouseEnterNavPar input", e.target.querySelector('.search_inp'));
    if (e && e.target && e.target.querySelector('.search_inp')) {
      // setTimeout(() => {
      // },0)
      (e.target.querySelector('.search_inp') as HTMLInputElement).focus()
    }
  }

  mouseEnterNav(el) {
    console.log("mouseEnterNav", el)
    if (!!el && !!el.nativeElement) {
      el.nativeElement.focus()
    }
  }

  setStatusKey(val) {
    this.statusKey = val
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }

  setOutletKey(val) {
    this.outletKey = val
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }

  onSearchMobDevices(resp) {
    if (!this.mobDevices) {
      return;
    }

    if (!resp) {
      this.mobDevices$.next(this.mobDevices.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.mobDevices$.next(
      this.mobDevices.filter(z => z.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchChecklists(resp) {
    if (!this.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.checklists.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.checklists$.next(
      this.checklists.filter(b => b.text.toLowerCase().indexOf(resp) > -1)
    );
  }

  goToBookmark(e, bookmark) {
    if (e) {
      e.stopPropagation();
    }
    this.hideShorts = true;
    setTimeout(() => { this.hideShorts = false }, 100)
    let page;
    
    let activeSections = bookmark.section;

    switch (activeSections) {
      case "Jobs":
        page = '/jobs';
        break;
      case "Chats":
        page = '/chats';
        break;
      case "Board by Date":
        page = '/board';
        break;
      case "Projects":
        page = '/projects';
        break;
      case "Board by Order":
        page = '/board-list';
        break;
      case 'Post List':
        page = '/urls';
        break;
      case 'Post Dashboards':
        page = '/urls-dashboard';
        break;
      case 'Outlet Dashboards':
        page = '/outlets-dashboard';
        break;
      case 'Regular Publications':
        return '/regular-publications'
        break;
      case 'Files':
        page = '/files';
        break;
      default:
        page = '/tasks';
        break;
    }
    console.log('page', page);
    this.router.navigate([page], { queryParams: page.indexOf('dashboard') != -1 ? {company_id: this.company_id, count: 1} : Object.assign(bookmark.settings, {company_id: this.company_id, preset_id: bookmark.id}) });
  }

  // removeBookmarkItem(e, bookmark, k) {
  //   this.attachSubscriptions(
  //     this.companyService.editBookmarkEmpl(boo)
  //   )
  //   this.favoriteBookmarks.splice(k, 1)
  // }

  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id).pipe(
        map(res => res.body),
        tap(arr => {
          arr.map(x => x.name = x.text)
        }),
      ).subscribe(resp => {
        this.checklists = resp;
        this.checklists$.next(this.checklists.slice());
        this.sendData();

        console.log("getChecklists", this.checklists)
      })
    )
  }

  getFileDevices() {
    this.attachSubscriptions(
      this.fileService.getFileDevices(this.company_id).subscribe(resp => {
        console.log("getFileDevices", resp)
        this.devices = resp;
        this.devices$.next(this.devices.slice())

        let mobData = [];
        resp.slice().forEach(x => {
          mobData.push({id: x, name: x})
        })
        console.log("mobData", mobData)
        this.mobDevices = mobData;
        this.mobDevices$.next(this.mobDevices.slice())
      })
    )
  }

  getFavoriteBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, is_shortcut: 1}).subscribe(resp => {
        this.favoriteBookmarks = resp.slice();
        console.log("this.favoriteBookmarks", this.favoriteBookmarks)
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
        this.sendData();
        console.log("this.statuses", this.statuses)
      })
    )
  }

  openDefaultFilter(type) {
    console.log("openDefaultFilter", type)
    let dData:any
    switch (type) {
      case 'card':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.taskStatuses, 
          asArray: this.taskStatuses$, 
          search: this.taskStatusesControl, 
          control: 'task_status_id',
          title: "Card type",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'job':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.jobTypes, 
          asArray: this.jobTypes$, 
          search: this.jobTypesControl, 
          control: 'task_operation_operation_id',
          title: "Job type",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'group':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.groups, 
          asArray: this.groups$, 
          search: this.groupsControl, 
          control: 'group_id',
          title: "Group",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'priority':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.prioritys, 
          asArray: this.prioritys$, 
          search: this.prioritysControl, 
          control: 'priority',
          title: "Priority",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'outlet':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.profiles, 
          asArray: this.profiles$, 
          search: this.profilesControl, 
          control: 'channel_id',
          title: "Outlet",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'status':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.profileStatuses, 
          asArray: this.profileStatuses$, 
          search: this.profileStatusesControl, 
          control: this.statusKey,
          title: "Publication Status",
          showTabs: this.activeSections() == "Cards",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'task_content_status_id':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.profileStatuses, 
          asArray: this.profileStatuses$, 
          search: this.profileStatusesControl, 
          control: 'task_content_status_id',
          title: "Card Posts Status",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'url_presented':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.contentUrl, 
          asArray: undefined, 
          search: undefined, 
          control: 'is_content_url',
          title: "URL presented",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'checklist':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.checklists, 
          asArray: this.checklists$, 
          search: undefined, 
          control: 'operation_reminder_id',
          title: "Checklist",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'device':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.mobDevices, 
          asArray: this.mobDevices$, 
          search: this.mobDevicesMoreControl, 
          control: 'meta_device',
          title: "Device",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'readyVersion':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.readyVerArr, 
          asArray: undefined, 
          search: undefined, 
          control: 'is_content_url',
          title: "Ready Version",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'part_of_count':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: [
            {
              id: 'isset',
              name: "Is part of file"
            },
            {
              id: '0',
              name: "Isn`t part of file"
            }
          ], 
          asArray: undefined, 
          search: undefined, 
          control: 'part_of_count',
          title: "Part of",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'consist_of_count':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: [
            {
              id: 'isset',
              name: "Has parts"
            },
            {
              id: '0',
              name: "Hasn't parts"
            }
          ], 
          asArray: undefined, 
          search: undefined, 
          control: 'consist_of_count',
          title: "Consist of",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      case 'related_count':
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: [
            {
              id: 'isset',
              name: "Is related"
            },
            {
              id: '0',
              name: "Isn`t related"
            }
          ], 
          asArray: undefined, 
          search: undefined, 
          control: 'related_count',
          title: "Related",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
      default:
        dData = {
          user: this.user,
          company: this.company,
          filter: this.filter,
          activeLang: this.activeLang,
          array: this.taskStatuses, 
          asArray: this.taskStatuses$, 
          search: this.taskStatusesControl, 
          control: 'task_status_id',
          title: "Card type",
          onDown: ($event, control) => this.onDown($event, control)
        }
        break;
    }
    const dialogRef = this.dialog.open(MobDefaultFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: dData
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  noChannelMulty(e, id) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.filter.get('no_channel_id').value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.filter.patchValue({
      no_channel_id: arr,
    })
    this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  setChannelMulty(e, id, key) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.filter.get(key).value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.filter.patchValue({
      [key]: arr,
    })
    this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  noChannel(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.filter.patchValue({
      no_channel_id: id,
    })
    this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  setOperation(fil) {
    if (this.filter.value.no_operation_id == fil.id) {
      this.filter.patchValue({
        no_operation_id: ''
      })
    }
  }

  // selectChange(e, no_search?) {
  //   console.log(e);
  //   console.log(this.filter.value);
  //   if (!no_search) {
  //     this.searchFilterSubmit();
  //   }
  // }

  selectChange(e, no_search?, control?) {
    console.log(e);
    console.log(this.filter.value);
    console.log("test0 control", control);
    console.log("test0 control == 'task_operation_operation_id'", control == 'task_operation_operation_id');
    if (control && control == 'task_operation_operation_id') {
      console.log("test1 this.filter.value.task_operation_operation_id", this.filter.value.task_operation_operation_id)
      console.log("test1 e.option.value", e.option.value)
      if (this.filter.value.no_operation_id == e.option.value) {
        this.filter.patchValue({
          no_operation_id: ''
        })
      }
    }
    console.log("test2 !no_search", !no_search)
    if (!no_search) {
      this.searchFilterSubmit();
    }
  }

  setOperMulty(e, id, key) {
    console.log('setOperMulty', e, id, key)
    e.preventDefault();
    e.stopPropagation();
    let arr = this.filter.get(key).value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    if (arr.includes(this.filter.value.no_operation_id)) {
      this.filter.patchValue({
        no_operation_id: ''
      })
    }

    this.filter.patchValue({
      [key]: arr,
    })

    this.showJobsBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  noOperation(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.filter.patchValue({
      no_operation_id: id,
    })

    if (this.activeSections() == "Jobs") {
      if (this.filter.value.task_operation_operation_id.includes(id)) {
        let arr = this.filter.value.task_operation_operation_id;
        arr.splice(arr.findIndex(k => k == id), 1);
        this.filter.patchValue({
          task_operation_operation_id: arr
        })
      }
    } else {
      if (this.filter.value.task_operation_operation_id == id) {
        this.filter.patchValue({
          task_operation_operation_id: ''
        })
      }
    }

    this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  openMobChannelsFilter() {
    const dialogRef = this.dialog.open(MobChannelsFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        title: "Outlet",
        channelsSubsControl: this.channelsSubsControl, 
        groupedProfiles: this.groupedProfiles,
        platforms: this.platforms,
        showTabs: this.activeSections() == "Chats",
        onDown: ($event, control) => this.onDown($event, control),
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobPlatformFilter() {
    const dialogRef = this.dialog.open(MobPlatformFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        platformsMoreControl: this.platformsMoreControl, 
        asArray: this.platforms$, 
        platforms: this.platforms, 
        control: 'task_status_id',
        onDown: ($event, control) => this.onDown($event, control)
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobAutoUpdateFilter(control) {
    const dialogRef = this.dialog.open(MobAutoupdateFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        control: control,
        activeLang: this.activeLang,
        onDown: ($event, control) => this.onDown($event, control)
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobPostingMethodFilter() {
    const dialogRef = this.dialog.open(MobScheduledTypesFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        title: 'Posting method',
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        scheduleTypes: this.scheduleTypes
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobUpdateIntervalFilter() {
    const dialogRef = this.dialog.open(MobUpdateIntervalComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        viewsUpdateIntervalList: this.viewsUpdateIntervalList
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobTypeFilter() {
    const dialogRef = this.dialog.open(MobTypeFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        allContentTypes: this.allContentTypes, 
        contentTypes: this.contentTypes$, 
        contentTypesControl: this.contentTypesControl, 
        asArray: this.taskStatuses$, 
        search: this.taskStatusesControl, 
        platforms: this.platforms, 
        control: 'task_status_id',
        onDown: ($event, control) => this.onDown($event, control)
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobSort() {
    const dialogRef = this.dialog.open(MobSortFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply' && !!result.data) {
            this.searchFilterSubmit(result.data)
          }
        }
      })
    )
  }

  openStatusFilter() {
    const dialogRef = this.dialog.open(MobStatusFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        statuses: this.statuses,
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobDateFilter() {
    const dialogRef = this.dialog.open(MobDateFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        datesControl: this.datesControl,
        datesModel: this.datesModel
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobTagFilter() {
    const dialogRef = this.dialog.open(MobTagFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        tagsControl: this.tagsControl,
        parameters: this.parameters,
        allValues: this.allValues,
        paramFilterProps: this.paramFilterProps,
        onScrollParams: (param) => this.onScrollParams(param),
        selected: ($event, param, key) => this.selected($event, param, key),
        onRemove: (value, param, key) => this.onRemove(value, param, key),
        add: ($event) => this.add($event),
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobStakeholder() {
    const dialogRef = this.dialog.open(MobStakeholderFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        postsModel: this.postsModel,
        postsControl: this.postsControl,
        stakeholdersModel: this.stakeholdersModel,
        stakeholderControl: this.stakeholderControl,
        subControl: this.stakeholderControl, 
        subs: ['Individuals', 'Clients', 'Workspaces'], 
        controls: ['operation_employee_id', 'client_employee_id', 'operation_partner_company_id'],
        arrays: [this.employees, this.clients, this.partners], 
        asArrays: [this.employees$, this.clients$, this.partners$], 
        searchs: [this.employeesControl, this.clientsControl, this.partnersControl], 
        onDown: ($event, control) => this.onDown($event, control)
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  openMobActiveFilters() {
    const dialogRef = this.dialog.open(MobActiveFiltersComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        filter: this.filter,
        activeLang: this.activeLang,
        params: this.getParams(),
        allContentTypes: this.allContentTypes,
        employees: this.employees,
        clients: this.clients,
        viewsUpdateIntervalList: this.viewsUpdateIntervalList,
        partners: this.partners,
        allValues: this.allValues,
        statuses: this.statuses,
        checklists: this.checklists,
        taskStatuses: this.taskStatuses,
        jobTypes: this.jobTypes, 
        groups: this.groups, 
        prioritys: this.prioritys,
        platforms: this.platforms,
        profiles: this.profiles,
        profileStatuses: this.profileStatuses, 
        contentUrl: this.contentUrl
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            if (!!result.data) {
              this.searchFilterSubmit({order_by: ''})
            } else {
              this.searchFilterSubmit()
            }
          }
        }
      })
    )
  }

  openMobShortcuts() {
    const dialogRef = this.dialog.open(MobShortcutsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang,
        favoriteBookmarks: this.favoriteBookmarks,
        groupedBarItems: this.groupedBarItems(this.barItems)
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'go') {
            this.GoToItem.emit(result.data)
          } else if (result.event == 'remove') {
            this.RemoveItem.emit(result.data)
          } else if (result.event == 'bookmark') {
            this.goToBookmark(null, result.data)
          }
        }
      })
    )
  }

  openMobSearch() {
    const dialogRef = this.dialog.open(MobSearchFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            this.searchFilterSubmit()
          }
        }
      })
    )
  }

  onSearchPostsModel(resp, fil) {
    if (!fil.data) {
      return;
    }

    if (!resp) {
      fil.asData.next(fil.data.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    fil.asData.next(
      fil.data.filter(z => fil.key.indexOf('partner') != -1 ? z.partnerCompany.name.toLowerCase().indexOf(resp) > -1 : z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchStakeholdersModel(resp, fil) {
    if (!fil.data) {
      return;
    }

    if (!resp) {
      fil.asData.next(fil.data.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    fil.asData.next(
      fil.data.filter(z => (fil.key.indexOf('partner') != -1 || fil.key.indexOf('origin') != -1) ? z.partnerCompany.name.toLowerCase().indexOf(resp) > -1 : z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchClients(resp) {
    if (!this.clients) {
      return;
    }

    if (!resp) {
      this.clients$.next(this.clients.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.clients$.next(
      this.clients.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  chosenYearHandler(normalizedYear: Moment) {
    // const ctrlValue = this.filter.value;
    // ctrlValue.year(normalizedYear.year());
    // this.filter.setValue(ctrlValue);
  }
  

  chosenMonthHandler(normalizedMonth: Moment) {
    console.log("normalizedMonth", normalizedMonth)
    console.log("normalizedMonth month", normalizedMonth.month())
    console.log("normalizedMonth year", normalizedMonth.year())
    console.log("normalizedMonth utcOffset", normalizedMonth.utcOffset())
    console.log("normalizedMonth.clone().add(normalizedMonth.utcOffset(),'m')", normalizedMonth.clone().add(normalizedMonth.utcOffset(),'m'))

    let startDate;
    if (normalizedMonth.utcOffset() > 0) {
      startDate = normalizedMonth.clone().add(normalizedMonth.utcOffset(),'m')
    } else {
      startDate = normalizedMonth.clone().subtract(normalizedMonth.utcOffset(),'m')
    }

    let endDate;
    if (normalizedMonth.utcOffset() > 0) {
      endDate = normalizedMonth.clone().endOf('month').add(normalizedMonth.utcOffset(),'m')
    } else {
      endDate = normalizedMonth.clone().endOf('month').subtract(normalizedMonth.utcOffset(),'m')
    }

    this.filter.patchValue({
      time_start_utc_from: startDate,
      time_start_utc_to: endDate
    })

    this.searchFilterSubmit();
    this.switched = true;
    setTimeout(() => {
      this.switched = false
    },0)
    
    console.log(this.filter.value)
  }
  
  rangeChange(date: Moment, key): void {
    console.log(date);
    console.log(key);
    // console.log(range);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    
    this.dateByControl(this.datesControl.value).filters[this.dateByControl(this.datesControl.value).activeFilter.value].range = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
    console.log("rangeChange", this.filter.value, this.datesModel);
  }

  clearDate(key) {
    this.filter.patchValue({
      [key+'from']: '',
      [key+'to']: ''
    })
    this.dateByControl(this.datesControl.value).filters[this.dateByControl(this.datesControl.value).activeFilter.value].range = new DateRange("", "");
  }
  onSearchPrioritys(resp) {
    if (!this.prioritys) {
      return;
    }

    if (!resp) {
      this.prioritys$.next(this.prioritys.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.prioritys$.next(
      this.prioritys.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroupedChannels(resp, groupedChannels) {
    if (!groupedChannels.channels) {
      return;
    }

    if (!resp) {
      groupedChannels.asChannels.next(groupedChannels.channels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    groupedChannels.asChannels.next(
      groupedChannels.channels.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  noOper(e, id) {
    console.log('noOper', e, id)
  }

  onDown(e, control) {
    console.log(e?.target?.scrollTop)
    if (control == 'channel_id') {
      if (this.profilesPage <= this.profilesPagination['pageCount']) {
        console.log("#########onDown")
        this.getProfiles(this.profilesPage);
      }
    }
    if (control == 'group_id') {
      if (this.groupsPage <= this.groupsPagination['pageCount']) {
        console.log("#########onDown")
        this.getGroupsCompany();
      }
    }
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._dateAdapter.setLocale(resp.active);
        }),
        switchMap(() => this.taskService.getOperations(this.company_id, this.activeLang).pipe(
          tap(x => {
            this.jobTypes = x;
            this.jobTypes$.next(this.jobTypes.slice());
            this.sendData();
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onSearchPartnerGroups(resp) {
    if (!this.partnerGroups) {
      return;
    }

    if (!resp) {
      this.partnerGroups$.next(this.partnerGroups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.partnerGroups$.next(
      this.partnerGroups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onMouseEnter(e) {
    this.postsHovered = true;
    console.log("q_posts e", e)
    let a = e.target.closest('.q_posts_out');
    setTimeout(() => {
      console.log("q_posts a", a)
      console.log("q_posts querySelectorAll", a.querySelectorAll('.q_posts'))
      if (a.querySelectorAll('.q_posts') && a.querySelectorAll('.q_posts')[0]) {
        (a.querySelectorAll('.q_posts')[0] as HTMLInputElement).focus()
      }
    }, 0)
  }

  onMouseLeave(e) {
    this.postsHovered = false;
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchDepartments(resp) {
    if (!this.departments) {
      return;
    }

    if (!resp) {
      this.departments$.next(this.departments.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.departments$.next(
      this.departments.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPos(resp) {
    if (!this.pos) {
      return;
    }

    if (!resp) {
      this.pos$.next(this.pos.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.pos$.next(
      this.pos.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  onSearchJobTypes(resp) {
    if (!this.jobTypes) {
      return;
    }

    if (!resp) {
      this.jobTypes$.next(this.jobTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.jobTypes$.next(
      this.jobTypes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  removeItem(e, item) {
    e.stopPropagation();
    console.log(e, item);
    this.RemoveItem.emit(item)
  }

  goToItem(e, item) {
    e.stopPropagation();
    this.hideShorts = true;

    this.closeAllDialogs();

    setTimeout(() => { this.hideShorts = false },100)
    this.GoToItem.emit(item)
  }

  linkToItem(e, item) {
    console.log("linkToItem")
    e.stopPropagation();
    e.preventDefault();
    this.hideShorts = true;

    setTimeout(() => { this.hideShorts = false },100)
    this.LinkToItem.emit(item)
  }

  getDepartmentsDyn() {
    this.attachSubscriptions(
      this.companyService.getDepartmentsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getDepartmentsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.departments = conValues;
              this.departments$.next(this.departments.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles departments", this.departments);
      })
    )
  }

  getPositionsDyn() {
    this.attachSubscriptions(
      this.companyService.getPositionsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getPositionsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.pos = conValues;
              this.pos$.next(this.pos.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles positions", this.pos);
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
              this.clients = conValues;
              this.clients$.next(this.clients.slice());

              this.postsModel.forEach(mod => {
                mod.filters.forEach(fil => {
                  if (fil.key.indexOf('partner') == -1 && fil.key.indexOf('origin') == -1) {
                    fil.data = conValues;
                    fil.asData.next(fil.data.slice())
                  }
                });
              })
              this.stakeholdersModel.forEach(mod => {
                mod.filters.forEach(fil => {
                  if (fil.key.indexOf('partner') == -1 && fil.key.indexOf('origin') == -1) {
                    fil.data = conValues;
                    fil.asData.next(fil.data.slice())
                  }
                });
              })
              this.sendData();
            }),
          )
        }),
      ).subscribe(resp => {

        let title = this.titleService.getTitle()
        let paramsTitle = JSON.parse(JSON.stringify(this.getParams()))
        if (title.indexOf('operation_employee_id') != -1 && this.getEmployeeById(paramsTitle.operation_employee_id) && this.getEmployeeById(paramsTitle.operation_employee_id).name) {
          title = title.replace('operation_employee_id', this.getEmployeeById(paramsTitle.operation_employee_id).name)
          this.titleService.setTitle(title);
        }
        if (title.indexOf('client_employee_id') != -1 && this.getEmployeeById(paramsTitle.client_employee_id) && this.getEmployeeById(paramsTitle.client_employee_id).name) {
          title = title.replace('client_employee_id', this.getEmployeeById(paramsTitle.client_employee_id).name)
          this.titleService.setTitle(title);
        }
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())

        let title = this.titleService.getTitle()
        let paramsTitle = JSON.parse(JSON.stringify(this.getParams()))
        if (title.indexOf('operation_partner_company_id') != -1 && this.getPartnerById(paramsTitle.operation_partner_company_id) && this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany && this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany.name) {
          title = title.replace('operation_partner_company_id', this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany.name)
          this.titleService.setTitle(title);
        }
        console.log("this.partners", this.partners)

        this.postsModel.forEach(mod => {
          mod.filters.forEach(fil => {
            if (fil.key.indexOf('partner') != -1 || fil.key.indexOf('origin') != -1) {
              fil.data = resp.filter(x => x.partner_company_id != 0);
              fil.asData.next(fil.data.slice())
            }
          });
        })
        this.stakeholdersModel.forEach(mod => {
          mod.filters.forEach(fil => {
            if (fil.key.indexOf('partner') != -1 || fil.key.indexOf('origin') != -1) {
              fil.data = resp.filter(x => x.partner_company_id != 0);
              fil.asData.next(fil.data.slice())
            }
          });
        })
        this.sendData();

        console.log("this.postsModel", this.postsModel)
        console.log("this.stakeholdersModel", this.stakeholdersModel)
      })
    )
  }

  getGroupsCompany(res?) {
    this.isLoadGroups = true;
    if (this.groupsPage == 1) {
      this.groups = [];
    }

    this.groupsSub = this.companyService.getGroupsDyn(this.company_id, this.groupsPage, '20', {q: res || this.groupsControl.value}).pipe(
      tap(el => {
        this.groupsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(x => x.body),
      switchMap(arr => {
        if (this.groupsPage == 1 && (this.filter.value.group_id && this.filter.value.group_id.length != 0)) {
          let ids = this.filter.value.group_id.join(',');
          return this.companyService.getGroupsDyn(this.company_id, '1', '200', {id: ids}).pipe(
            tap(selectedGroups => {
              selectedGroups.body.forEach(element => {
                if (this.groups.filter(x => x.id == element.id).length == 0) {
                  this.groups.unshift(element)
                }
              });
            }),
            map(() => arr)
          )
        } else {
          return of(arr)
        }
        // !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
      })
    ).subscribe(res => {
      console.log("w8less test", res)
      res.forEach(element => {
        if (this.groups.filter(x => x.id == element.id).length == 0) {
          this.groups.push(element)
        }
      });
      
      this.groups$.next(this.groups.slice());
      this.groupsPage++;
      this.isLoadGroups = false;
      this.sendData();
    }, error => {
      this.isLoadGroups = false;
    })
    
  }

  onOpenedChange(isOpen: boolean): void {
    this.isOpenBoard = isOpen;
    console.log('Select is open:', this.isOpenBoard);
  }

  getGroupsPartnerCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.selectedPartnerForGroup, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.selectedPartnerForGroup, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerGroups = [].concat(...res);
        this.partnerGroups$.next(this.partnerGroups.slice());
        console.log("getGroupsPartnerCompany", this.partnerGroups);
      })
    )
  }

  groupedBarItems(arr) {

    let data = {
      tasks: [],
      works: [],
      chats: [],
      files: []
    }

    if (arr) {
      arr.forEach(element => {
        if (element.type == 'file') {
          data.files.push(element)
        } else if (element.type == "chat") {
          data.chats.push(element)
        } else if (element.type == "job") {
          data.works.push(element)
        } else {
          data.tasks.push(element)
        }
      });
    }


    return data;
  }

  check(val) {
    console.log("groupedBarItems(barItems)", this.groupedBarItems(val))
  }

  logJobType() {
    console.log('logJobType filter', this.filter.value)
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.sendData();
          this.postsQ = '';
          console.log("activatedRoute.queryParams", val);
          this.showJobsBtn = false;
          this.showOutletBtn = false;
          this.outletKey = 'channel_id';
          if (this.activeSections() != "Cards") {
            this.statusKey = 'content_status_id';
          }
          this.pageTab = !!this.activatedRoute.snapshot.queryParamMap.get('tab') ? this.activatedRoute.snapshot.queryParamMap.get('tab') : "";
          this.page = !!this.activatedRoute.snapshot.queryParamMap.get('page') ? this.activatedRoute.snapshot.queryParamMap.get('page') : "";
          this.filter.patchValue({
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? +this.activatedRoute.snapshot.queryParamMap.get('count') : '',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? [+this.activatedRoute.snapshot.queryParamMap.get('is_content_url')] : [],
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id')] : [],
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('task_status_id')] : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : '',
            task_operation_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',')[0]] : [],
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('content_status_id')] : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('content_type_id')] : [],
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? [+this.activatedRoute.snapshot.queryParamMap.get('priority')] : [],
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id')] : [],
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('client_employee_id')] : [],
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : '',
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('origin_company_id')] : [],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id')] : [],
            q_name: this.activatedRoute.snapshot.queryParamMap.get('q_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_name') : '',
            q_text: this.activatedRoute.snapshot.queryParamMap.get('q_text') ? this.activatedRoute.snapshot.queryParamMap.get('q_text') : '',
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })

          if (this.activeSections() == "Posts") {
            if (!!this.filter.get('created_at_from')) {
              this.filter.patchValue({
                created_at_from: this.activatedRoute.snapshot.queryParamMap.get('created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000) : ""),
                created_at_to: this.activatedRoute.snapshot.queryParamMap.get('created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000) : ""),
                analytics_views_count_from: this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from')*1000) : ""),
                analytics_views_count_to: this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to')*1000) : ""),
                views_update_interval: this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') ? [+this.activatedRoute.snapshot.queryParamMap.get('views_update_interval')] : [],
                is_content_views_count_update: this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') : '',
                scheduled_published_time_by: this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') ? this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') : '',
                external_status: this.activatedRoute.snapshot.queryParamMap.get('external_status') ? this.activatedRoute.snapshot.queryParamMap.get('external_status') : '',
                is_bravourl_content_views_count_update: this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') ? +this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') : '',
                created_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_employee_id')] : [],
                updated_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id')] : [],
                created_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id')] : [],
                updated_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id')] : [],
              })
            } else {
              this.filter.addControl('views_update_interval', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') ? [+this.activatedRoute.snapshot.queryParamMap.get('views_update_interval')] : []))
              this.filter.addControl('is_content_views_count_update', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') : ''))
              this.filter.addControl('scheduled_published_time_by', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') ? this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') : ''))
              this.filter.addControl('external_status', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('external_status') ? this.activatedRoute.snapshot.queryParamMap.get('external_status') : ''))
              this.filter.addControl('is_bravourl_content_views_count_update', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') ? +this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') : ''))
              this.filter.addControl('created_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000) : "")))
              this.filter.addControl('created_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000) : "")))
              this.filter.addControl('analytics_views_count_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from')*1000) : "")))
              this.filter.addControl('analytics_views_count_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to')*1000) : "")))
              this.filter.addControl('created_employee_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_employee_id')] : []))
              this.filter.addControl('updated_employee_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id')] : []))
              this.filter.addControl('created_url_employee_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id')] : []))
              this.filter.addControl('updated_url_employee_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id')] : []))
            }
            if (this.datesModel[4].filters.filter(x => x.name == 'Created').length == 0) {
              this.datesModel[4].filters.push({
                id: this.datesModel[4].filters.length,
                name: 'Created',
                key: 'created_at_',
                from: 'created_at_from',
                to: 'created_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
            if (this.datesModel[4].filters.filter(x => x.name == 'Views').length == 0) {
              this.datesModel[4].filters.push({
                id: this.datesModel[4].filters.length,
                name: 'Views',
                key: 'analytics_views_count_',
                from: 'analytics_views_count_from',
                to: 'analytics_views_count_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
          } else {
            this.filter.removeControl('views_update_interval')
            this.filter.removeControl('is_content_views_count_update')
            this.filter.removeControl('scheduled_published_time_by')
            this.filter.removeControl('external_status')
            this.filter.removeControl('is_bravourl_content_views_count_update')
            this.filter.removeControl('analytics_views_count_from')
            this.filter.removeControl('analytics_views_count_to')
            this.filter.removeControl('created_at_from')
            this.filter.removeControl('created_at_to')
            this.filter.removeControl('created_employee_id')
            this.filter.removeControl('updated_employee_id')
            this.filter.removeControl('created_url_employee_id')
            this.filter.removeControl('updated_url_employee_id')
            if (this.datesModel[4].filters.filter(x => x.name == 'Views').length > 0) {
              this.datesModel[4].filters.splice(this.datesModel[4].filters.indexOf(this.datesModel[4].filters.find(x => x.name == 'Views')), 1)
            }
            if (this.datesModel[4].filters.filter(x => x.name == 'Created').length > 0) {
              this.datesModel[4].filters.splice(this.datesModel[4].filters.indexOf(this.datesModel[4].filters.find(x => x.name == 'Created')), 1)
            }
          }

          if (this.activeSections() == "Settings") {
            if (!!this.filter.get('id')) {
              this.filter.patchValue({
                id: this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : '',
                time_start_utc_from: this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from')*1000).utc() : '',
                time_start_utc_to: this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to') ?  moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to')*1000).utc() : '',
                permission: this.activatedRoute.snapshot.queryParamMap.get('permission') ? this.activatedRoute.snapshot.queryParamMap.get('permission') : '',
                department_id: this.activatedRoute.snapshot.queryParamMap.get('department_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('department_id')] : [],
                department_position_id: this.activatedRoute.snapshot.queryParamMap.get('department_position_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('department_position_id')] : [],
              })
            } else {
              this.filter.addControl('id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : ''))
              this.filter.addControl('time_start_utc_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from') ?  moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from')*1000).utc() : ''))
              this.filter.addControl('time_start_utc_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to') ?  moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to')*1000).utc() : ''))
              this.filter.addControl('permission', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('permission') ? this.activatedRoute.snapshot.queryParamMap.get('permission') : ''))
              this.filter.addControl('department_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('department_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('department_id')] : []))
              this.filter.addControl('department_position_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('department_position_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('department_position_id')] : []))
            }
            if (this.datesModel.filter(x => x.name == 'Statements').length == 0) {
              this.datesModel.push({
                name: "Statements",
                id: 6,
                activeFilter: new FormControl(0),
                onlyThis: true,
                filters: [{
                  id: 0,
                  name: 'Biling At',
                  key: 'time_start_utc_',
                  from: 'time_start_utc_from',
                  to: 'time_start_utc_to',
                  range: new DateRange("", ""),
                  onlyThis: true
                }]
              })
            }
          } else {
            if (this.datesModel.filter(x => x.name == 'Statements').length != 0) {
              this.datesModel.splice(this.datesModel.findIndex(u => u.name == 'Statements'), 1)
              this.datesControl.patchValue(0)
            }
            if (this.activeSections() != "Cards" && this.activeSections() != "Projects" && this.activeSections() != "Analytic") {
              this.filter.removeControl('id')
            }
            this.filter.removeControl('time_start_utc_from');
            this.filter.removeControl('time_start_utc_to');
            this.filter.removeControl('permission');
            this.filter.removeControl('department_id');
            this.filter.removeControl('department_position_id');
          }

          if (this.activeSections() == "Analytic") {
            if (!!this.filter.get('id')) {
              this.filter.patchValue({
                id: this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : ''
              })
            } else {
              this.filter.addControl('id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : ''))
            }
          } else {

            if (this.activeSections() != "Cards" && this.activeSections() != "Projects" && this.activeSections() != "Settings") {
              this.filter.removeControl('id')
            }
          }

          if (this.activeSections() == "Projects") {
            if (!!this.filter.get('task_id')) {
              this.filter.patchValue({
                id: this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : '',
                task_id: this.activatedRoute.snapshot.queryParamMap.get('task_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_id') : '',
                task_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
                discussion_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
              })
            } else {
              this.filter.addControl('id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : ''))
              this.filter.addControl('task_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_id') : ''))
              this.filter.addControl('task_operation_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : ''))
              this.filter.addControl('discussion_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : ''))
            }
          } else {
            if (this.activeSections() != "Cards" && this.activeSections() != "Settings" && this.activeSections() != "Analytic") {
              this.filter.removeControl('id')
            }
            if (this.activeSections() != "Cards") {
              this.filter.removeControl('discussion_id')
              this.filter.removeControl('task_operation_id')
            }
            this.filter.removeControl('task_id')
          }
          
          if (this.activeSections() == "Cards") {
            if (!!this.filter.get('internal_id')) {
              this.filter.patchValue({
                operation_status_id: this.activatedRoute.snapshot.queryParamMap.get('operation_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_status_id')] : [],
                task_content_status_id: this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id')] : [],
                id: this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : '',
                internal_id: this.activatedRoute.snapshot.queryParamMap.get('internal_id') ? +this.activatedRoute.snapshot.queryParamMap.get('internal_id') : '',
                task_company_id: this.activatedRoute.snapshot.queryParamMap.get('task_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_company_id') : '',
                discussion_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
                task_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
                q_task_channel_content_filename: this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') : '',
                q_task_name: this.activatedRoute.snapshot.queryParamMap.get('q_task_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_name') : '',
                q_task_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') : '',
                q_task_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') : '',
                q_task_operation_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') : '',
                q_task_operation_employee_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') : '',
                q_task_operation_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') : '',
              })
            } else {
              this.filter.addControl('operation_status_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('operation_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_status_id')] : []))
              this.filter.addControl('internal_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('internal_id') ? +this.activatedRoute.snapshot.queryParamMap.get('internal_id') : ''))
              this.filter.addControl('task_content_status_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id')] : []))
              this.filter.addControl('id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : ''))
              this.filter.addControl('task_company_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_company_id') : ''))
              this.filter.addControl('discussion_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : ''))
              this.filter.addControl('task_operation_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : ''))
              this.filter.addControl('q_task_channel_content_filename', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') : ''))
              this.filter.addControl('q_task_name', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_name') : ''))
              this.filter.addControl('q_task_comment', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') : ''))
              this.filter.addControl('q_task_private_comment', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') : ''))
              this.filter.addControl('q_task_operation_comment', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') : ''))
              this.filter.addControl('q_task_operation_employee_comment', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') : ''))
              this.filter.addControl('q_task_operation_private_comment', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') : ''))
            }
          } else {
            this.filter.removeControl('operation_status_id')
            if (this.activeSections() != "Projects" && this.activeSections() != "Settings" && this.activeSections() != "Analytic") {
              this.filter.removeControl('id')
            }
            if (this.activeSections() != "Projects") {
              this.filter.removeControl('discussion_id')
              this.filter.removeControl('task_operation_id')
            }
            this.filter.removeControl('task_content_status_id')
            this.filter.removeControl('internal_id')
            this.filter.removeControl('task_company_id')
            this.filter.removeControl('q_task_channel_content_filename')
            this.filter.removeControl('q_task_name')
            this.filter.removeControl('q_task_comment')
            this.filter.removeControl('q_task_private_comment')
            this.filter.removeControl('q_task_operation_comment')
            this.filter.removeControl('q_task_operation_employee_comment')
            this.filter.removeControl('q_task_operation_private_comment')
          }

          if (this.activeSections() == "Chats") {
            if (!!this.filter.get('posted_at_from')) {
              this.filter.patchValue({
                status_id: this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [],
                q_name: this.activatedRoute.snapshot.queryParamMap.get('q_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_name') : '',
                discussion_channel_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id').split(',').map(Number) : [],
                discussion_other_employee_status_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') : 0,
                q_text: this.activatedRoute.snapshot.queryParamMap.get('q_text') ? this.activatedRoute.snapshot.queryParamMap.get('q_text') : '',
                posted_at_from: this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_from')*1000) : ""),
                posted_at_to: this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_to')*1000) : ""),
                discussion_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from')*1000) : ""),
                discussion_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to')*1000) : ""),
                discussion_planned_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from')*1000) : ""),
                discussion_planned_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to')*1000) : "")
              })
            } else {
              this.filter.addControl('status_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : []));
              this.filter.addControl('q_name', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_name') : ''));
              this.filter.addControl('discussion_other_employee_status_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_other_employee_status_id') : 0));
              this.filter.addControl('discussion_channel_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_channel_id').split(',').map(Number) : []));
              this.filter.addControl('q_text', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('q_text') ? this.activatedRoute.snapshot.queryParamMap.get('q_text') : ''));
              this.filter.addControl('posted_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_from')*1000) : "")));
              this.filter.addControl('posted_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_to')*1000) : "")));
              this.filter.addControl('discussion_completed_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_from')*1000) : "")));
              this.filter.addControl('discussion_completed_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_completed_at_to')*1000) : "")));
              this.filter.addControl('discussion_planned_completed_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_from')*1000) : "")));
              this.filter.addControl('discussion_planned_completed_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_planned_completed_at_to')*1000) : "")));
            }
            if (this.datesModel[2].filters.filter(x => x.name == 'Completed').length == 0) {
              this.datesModel[2].filters.push({
                id: this.datesModel[2].filters.length,
                name: 'Completed',
                key: 'discussion_completed_at_',
                from: 'discussion_completed_at_from',
                to: 'discussion_completed_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
            if (this.datesModel[2].filters.filter(x => x.name == 'Post Added').length == 0) {
              this.datesModel[2].filters.push({
                id: this.datesModel[2].filters.length,
                name: 'Post Added',
                key: 'posted_at_',
                from: 'posted_at_from',
                to: 'posted_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
            if (this.datesModel[2].filters.filter(x => x.name == 'Deadline').length == 0) {
              this.datesModel[2].filters.push({
                id: this.datesModel[2].filters.length,
                name: 'Deadline',
                key: 'discussion_planned_completed_at_',
                from: 'discussion_planned_completed_at_from',
                to: 'discussion_planned_completed_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
          } else {
            if (this.activeSections() != "Board by Date" && this.activeSections() != "Board by Order" && this.activeSections() != "Jobs") {
              this.filter.removeControl('status_id')
            }
            this.filter.removeControl('q_name')
            this.filter.removeControl('q_text')
            this.filter.removeControl('posted_at_from')
            this.filter.removeControl('posted_at_to')
            this.filter.removeControl('discussion_completed_at_from')
            this.filter.removeControl('discussion_completed_at_to')
            this.filter.removeControl('discussion_planned_completed_at_from')
            this.filter.removeControl('discussion_planned_completed_at_to')
            if (this.datesModel[2].filters.filter(x => x.name == 'Completed').length > 0) {
              this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Completed')), 1)
            }
            if (this.datesModel[2].filters.filter(x => x.name == 'Post Added').length > 0) {
              this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Post Added')), 1)
            }
            if (this.datesModel[2].filters.filter(x => x.name == 'Deadline').length > 0) {
              this.datesModel[2].filters.splice(this.datesModel[2].filters.indexOf(this.datesModel[2].filters.find(x => x.name == 'Deadline')), 1)
            }
          }

          if (this.activeSections() == "Jobs") {
            this.filter.patchValue({
              task_operation_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',').map(Number) : []
            })
            
            if (!!this.filter.get('updated_at_from')) {
              this.filter.patchValue({
                status_id: this.activatedRoute.snapshot.queryParamMap.get('status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('status_id') : "",
                updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_from')*1000) : ""),
                updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_to')*1000) : ""),
                task_operation_planned_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from')*1000) : ""),
                task_operation_planned_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to')*1000) : "")
              })
            } else {
              this.filter.addControl('status_id', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('status_id') : ""))
              this.filter.addControl('updated_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_from')*1000) : "")))
              this.filter.addControl('updated_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_to')*1000) : "")))
              this.filter.addControl('task_operation_planned_completed_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from')*1000) : "")))
              this.filter.addControl('task_operation_planned_completed_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to')*1000) : "")))
            }
            if (this.datesModel[1].filters.filter(x => x.name == 'Updated').length == 0) {
              this.datesModel[1].filters.push({
                id: this.datesModel[1].filters.length,
                name: 'Updated',
                key: 'updated_at_',
                from: 'updated_at_from',
                to: 'updated_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
            if (this.datesModel[1].filters.filter(x => x.name == 'Deadline').length == 0) {
              this.datesModel[1].filters.push({
                id: this.datesModel[1].filters.length,
                name: 'Deadline',
                key: 'task_operation_planned_completed_at_',
                from: 'task_operation_planned_completed_at_from',
                to: 'task_operation_planned_completed_at_to',
                range: new DateRange("", ""),
                onlyThis: true
              })
            }
          } else {
            if (this.activeSections() != "Board by Date" && this.activeSections() != "Board by Order" && this.activeSections() != "Chats") {
              this.filter.removeControl('status_id')
            }
            this.filter.removeControl('updated_at_from')
            this.filter.removeControl('updated_at_to')
            if (this.datesModel[1].filters.filter(x => x.name == 'Updated').length > 0) {
              this.datesModel[1].filters.splice(this.datesModel[1].filters.indexOf(this.datesModel[1].filters.find(x => x.name == 'Updated')), 1)
            }
            this.filter.removeControl('task_operation_planned_completed_at_from')
            this.filter.removeControl('task_operation_planned_completed_at_to')
            if (this.datesModel[1].filters.filter(x => x.name == 'Deadline').length > 0) {
              this.datesModel[1].filters.splice(this.datesModel[1].filters.indexOf(this.datesModel[1].filters.find(x => x.name == 'Deadline')), 1)
            }
          }

          if (this.activeSections() == "Files") {
            if (!!this.filter.get('meta_device')) {
              this.filter.patchValue({
                group_by: !!this.activatedRoute.snapshot.queryParamMap.get('group_by') ? this.activatedRoute.snapshot.queryParamMap.get('group_by') : "original_file_id",
                location_all: !!this.activatedRoute.snapshot.queryParamMap.get('location_all') ? this.activatedRoute.snapshot.queryParamMap.get('location_all') : "/",
                task_channel_file_content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') : "",
                file_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_id') ? +this.activatedRoute.snapshot.queryParamMap.get('file_id') : "",
                content_type: !!this.activatedRoute.snapshot.queryParamMap.get('content_type') ? this.activatedRoute.snapshot.queryParamMap.get('content_type') : "",
                meta_device: !!this.activatedRoute.snapshot.queryParamMap.get('meta_device') ? [this.activatedRoute.snapshot.queryParamMap.get('meta_device')] : [],
                part_of_count: !!this.activatedRoute.snapshot.queryParamMap.get('part_of_count') ? [this.activatedRoute.snapshot.queryParamMap.get('part_of_count')] : [],
                consist_of_count: !!this.activatedRoute.snapshot.queryParamMap.get('consist_of_count') ? [this.activatedRoute.snapshot.queryParamMap.get('consist_of_count')] : [],
                related_count: !!this.activatedRoute.snapshot.queryParamMap.get('related_count') ? [this.activatedRoute.snapshot.queryParamMap.get('related_count')] : [],
                operation_reminder_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id')] : [],
                is_ready_if_approved: !!this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved') ? [this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved')] : [],
              })
            } else {
              this.filter.addControl('group_by', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('group_by') ? this.activatedRoute.snapshot.queryParamMap.get('group_by') : "original_file_id"))
              this.filter.addControl('location_all', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('location_all') ? this.activatedRoute.snapshot.queryParamMap.get('location_all') : "/"))
              this.filter.addControl('file_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('file_id') ? +this.activatedRoute.snapshot.queryParamMap.get('file_id') : ""))
              this.filter.addControl('task_channel_file_content_status_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') : ""))
              this.filter.addControl('content_type', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('content_type') ? this.activatedRoute.snapshot.queryParamMap.get('content_type') : ""))
              this.filter.addControl('meta_device', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('meta_device') ? [this.activatedRoute.snapshot.queryParamMap.get('meta_device')] : []))
              this.filter.addControl('part_of_count', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('part_of_count') ? [this.activatedRoute.snapshot.queryParamMap.get('part_of_count')] : []))
              this.filter.addControl('consist_of_count', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('consist_of_count') ? [this.activatedRoute.snapshot.queryParamMap.get('consist_of_count')] : []))
              this.filter.addControl('related_count', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('related_count') ? [this.activatedRoute.snapshot.queryParamMap.get('related_count')] : []))
              this.filter.addControl('operation_reminder_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id')] : []))
              this.filter.addControl('is_ready_if_approved', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved') ? [this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved')] : []))
            }
          } else {
            this.filter.removeControl('group_by')
            this.filter.removeControl('location_all')
            this.filter.removeControl('file_id')
            this.filter.removeControl('task_channel_file_content_status_id')
            this.filter.removeControl('content_type')
            this.filter.removeControl('meta_device')
            this.filter.removeControl('part_of_count')
            this.filter.removeControl('consist_of_count')
            this.filter.removeControl('related_count')
            this.filter.removeControl('operation_reminder_id')
            this.filter.removeControl('is_ready_if_approved')
          }

          if (this.activeSections() == "Board by Order") {
            if (!!this.filter.get('doneStatus')) {
              this.filter.patchValue({
                status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [1,2,5],
                doneStatus: !!this.activatedRoute.snapshot.queryParamMap.get('doneStatus') ? this.activatedRoute.snapshot.queryParamMap.get('doneStatus').split(',').map(Number) : [3,4,6,97,98,99]
              })
            } else {
              this.filter.addControl('status_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [1,2,5]))
              this.filter.addControl('doneStatus', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('doneStatus') ? this.activatedRoute.snapshot.queryParamMap.get('doneStatus').split(',').map(Number) : [3,4,6,97,98,99]))
            }
          } else {
            if (this.activeSections() != "Board by Date") {
              this.filter.removeControl('doneStatus')
              if (this.activeSections() != "Chats" && this.activeSections() != "Jobs") {
                this.filter.removeControl('status_id')
              }
            }
          }

          if (this.activeSections() == "Board by Date") {
            if (!!this.filter.get('start_at_from')) {
              this.filter.patchValue({
                status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [1,2,5],
                doneStatus: !!this.activatedRoute.snapshot.queryParamMap.get('doneStatus') ? this.activatedRoute.snapshot.queryParamMap.get('doneStatus').split(',').map(Number) : [3,4,6,97,98,99],
                start_at_from: this.activatedRoute.snapshot.queryParamMap.get('start_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('start_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_from')*1000) : ""),
                start_at_to: this.activatedRoute.snapshot.queryParamMap.get('start_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('start_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_to')*1000) : "")
              })
            } else {
              this.filter.addControl('status_id', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [1,2,5]))
              this.filter.addControl('doneStatus', this.fb.control(!!this.activatedRoute.snapshot.queryParamMap.get('doneStatus') ? this.activatedRoute.snapshot.queryParamMap.get('doneStatus').split(',').map(Number) : [3,4,6,97,98,99]))
              this.filter.addControl('start_at_from', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('start_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('start_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_from')*1000) : "")))
              this.filter.addControl('start_at_to', this.fb.control(this.activatedRoute.snapshot.queryParamMap.get('start_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('start_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_at_to')*1000) : "")))
            }
            if (this.datesModel.filter(x => x.name == 'Board').length == 0) {
              this.datesModel.push({
                name: "Board",
                id: 5,
                activeFilter: new FormControl(0),
                onlyThis: true,
                filters: [{
                  id: 0,
                  name: 'Start At',
                  key: 'start_at_',
                  from: 'start_at_from',
                  to: 'start_at_to',
                  range: new DateRange("", ""),
                  onlyThis: true
                }]
              })
            }
          } else {
            if (this.activeSections() != "Board by Order") {
              this.filter.removeControl('doneStatus')
              if (this.activeSections() != "Chats" && this.activeSections() != "Jobs") {
                this.filter.removeControl('status_id')
              }
            }
            this.filter.removeControl('start_at_from')
            this.filter.removeControl('start_at_to')
            if (this.datesModel.filter(x => x.name == 'Board').length != 0) {
              this.datesModel.splice(this.datesModel.findIndex(u => u.name == 'Board'), 1)
              this.datesControl.patchValue(0)
            }
          }

          this.filter.updateValueAndValidity()


          this.datesModel.forEach(tab => {
            tab.filters.forEach(fil => {
              fil.range = new DateRange(this.filter.value[fil.from], this.filter.value[fil.to]);
            });
          })

          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes("", this.contentPage);

          console.log("NavigationBarComponent FILTER", this.filter.value)
          console.log("NavigationBarComponent DATES", this.datesModel)
        }),
      ).subscribe(params => {
        console.log("route", params);

        // if (this.activeSections() == )

        this.activeParams = JSON.parse(JSON.stringify(this.getParams()))
        delete this.activeParams.company_id;
        delete this.activeParams.count;
    
        switch (this.activeSections()) {
          case 'Cards':
            this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
            break;
          case 'Jobs':
            this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
            break;
          case 'Chats':
            this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
            break;
          case 'Posts':
            this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'content_published_at_desc'
            break;
        
          default:
            break;
        }
        if (!!this.sortValue && !['updated_desc', 'content_published_at_desc'].includes(this.sortValue)) {
          this.activeParams.order_by = this.sortValue
        }
       
    
        if (['Board by Date', 'Board by Order'].includes(this.activeSections())) {
          if (!this.activeParams.status_id || this.activeParams.status_id.split(',').sort().toString() == [1,2,5].toString()) {
            delete this.activeParams.status_id;
          }
          if (!this.activeParams.doneStatus || this.activeParams.doneStatus.split(',').sort().toString() == [3,4,6,97,98,99].toString()) {
            delete this.activeParams.doneStatus;
          }
        } else if (['Jobs', 'Chats'].includes(this.activeSections())) {
          if (!this.activeParams.status_id) {
            delete this.activeParams.status_id;
          }
        } else {
          delete this.activeParams.status_id;
          delete this.activeParams.doneStatus;
        }
    
        console.log("this.activeParams NAVIGATION", this.activeParams)

        this.isMobHasActiveFilter = Object.keys(this.activeParams).length > 0 ? true : false

        
        let paramsTitle = JSON.parse(JSON.stringify(this.getParams()))
        delete paramsTitle.company_id;
        delete paramsTitle.count;
        console.log("this.getParams()", paramsTitle)
        
        if (this.activeSections() != window.location.pathname.replace('/', '')) {
          let title = this.activeSections();

          if (paramsTitle.hasOwnProperty('status_id')) {
            if (typeof paramsTitle.status_id === 'number' || paramsTitle.status_id.indexOf(',') == -1) {
              title += " - " + this.getStatusName(paramsTitle.status_id)
            }
          }

          if (paramsTitle.hasOwnProperty('operation_status_id')) {
            if (typeof paramsTitle.operation_status_id === 'number' || paramsTitle.operation_status_id.indexOf(',') == -1) {
              title += " - " + this.getStatusName(paramsTitle.operation_status_id)
            }
          }

          if (paramsTitle.hasOwnProperty('operation_employee_id') && !!paramsTitle.operation_employee_id) {
            if (typeof paramsTitle.operation_employee_id === 'number' || paramsTitle.operation_employee_id.indexOf(',') == -1) {
              if (this.getEmployeeById(paramsTitle.operation_employee_id)) {
                title += " - " + this.getEmployeeById(paramsTitle.operation_employee_id).name
              } else {
                title += " - " + `operation_employee_id`
              }
            }
          }

          if (paramsTitle.hasOwnProperty('operation_partner_company_id') && !!paramsTitle.operation_partner_company_id) {
            if (typeof paramsTitle.operation_partner_company_id === 'number' || paramsTitle.operation_partner_company_id.indexOf(',') == -1) {
              if (this.getPartnerById(paramsTitle.operation_partner_company_id) && this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany && this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany.name) {
                title += " - " + this.getPartnerById(paramsTitle.operation_partner_company_id).partnerCompany.name
              } else {
                title += " - " + `operation_partner_company_id`
              }
            }
          }

          if (paramsTitle.hasOwnProperty('client_employee_id') && !!paramsTitle.client_employee_id) {
            if (typeof paramsTitle.client_employee_id === 'number' || paramsTitle.client_employee_id.indexOf(',') == -1) {
              if (this.getEmployeeById(paramsTitle.client_employee_id)) {
                title += " - " + this.getEmployeeById(paramsTitle.client_employee_id).name
              } else {
                title += " - " + `client_employee_id`
              }
            }
          }

          if (paramsTitle.hasOwnProperty('content_status_id')) {
            if (typeof paramsTitle.content_status_id === 'number' || paramsTitle.content_status_id.indexOf(',') == -1) {
              title += " - " + this.getPostStatusName(paramsTitle.content_status_id)
            }
          }
          
          if (paramsTitle.hasOwnProperty('task_status_id') && !!paramsTitle.task_status_id) {
            if (typeof paramsTitle.task_status_id === 'number' || paramsTitle.task_status_id.indexOf(',') == -1) {
              if (this.getTaskStatusById(paramsTitle.task_status_id)) {
                title += " - " + this.getTaskStatusById(paramsTitle.task_status_id).name
              } else {
                title += " - " + `task_status_id`
              }
            }
          }

          this.titleService.setTitle(title)
        } else {
          this.titleService.setTitle("Reports")
        }

        this.GoToPage.emit(this.getParams())

        if (this.parameters && this.parameters.length > 0) {
          this.parameters.forEach(par => {
            this.getActiveValuesForParam(par)
          })
        }
      })
    )
  }

  logDates() {
    console.log("datesModel", this.datesModel)
  }

  getPartnerById(id) {
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }
  
  getEmployeeById(id) {
    if (!this.employees || this.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        let arr = [];

        resp.forEach(el => {
          if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
            arr.push({
              channel_platform_id: el.channel_platform_id,
              types: [el]
            })
          } else {
            arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
          }
        })

        this.contentTypes.push(...arr);
        this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        } else {
          this.sendData();
        }
      })
    )
  }

  onSearchProfileStatuses(resp) {
    if (!this.profileStatuses) {
      return;
    }

    if (!resp) {
      this.profileStatuses$.next(this.profileStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profileStatuses$.next(
      this.profileStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchTaskStatuses(resp) {
    if (!this.taskStatuses) {
      return;
    }

    if (!resp) {
      this.taskStatuses$.next(this.taskStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.taskStatuses$.next(
      this.taskStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  setFilValue(e, key, val) {
    // console.log('e', e)
    // console.log('e.target', e.target.nodeName == 'MAT-PSEUDO-CHECKBOX')
    // console.log('key', key)
    // console.log('val', val)
    if (e.target.nodeName != 'MAT-PSEUDO-CHECKBOX') {
      e.preventDefault();
      e.stopPropagation();
      this.filter.patchValue({
        [key]: [val]
      })
      this.searchFilterSubmit();
    }
  }

  selectChangeWithOut(e, key) {
    if (e.option._value === 0) {
      this.filter.patchValue({
        [key]: []
      })
    }
    this.selectChange(e);
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        console.log("this.taskStatuses", resp)
        this.taskStatuses = resp.slice()
        this.taskStatuses$.next(this.taskStatuses.slice());
        this.sendData();
        let title = this.titleService.getTitle()
        let paramsTitle = JSON.parse(JSON.stringify(this.getParams()))
        if (title.indexOf('task_status_id') != -1 && this.getTaskStatusById(paramsTitle.task_status_id) && this.getTaskStatusById(paramsTitle.task_status_id).name) {
          title = title.replace('task_status_id', this.getTaskStatusById(paramsTitle.task_status_id).name)
          this.titleService.setTitle(title);
        }
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        this.profileStatuses$.next(this.profileStatuses.slice());
        this.sendData();
      })
    )
  }

  isLinkActive(link) {
    const url = this.router.url;
    return this.router.url.indexOf(link) != -1;
  }

  dateByControl(id) {
    if (this.datesModel.find(x => x.id == id)) {
      return this.datesModel.find(x => x.id == id)
    } else {
      return undefined
    }
  }

  patchControl(control:FormControl, val) {
    this.switched = true;
    control.patchValue(val)
    setTimeout(() => {
      this.switched = false
    },0)
  }

  patchTab(ctrl, val) {
    console.log(ctrl, val);
    this.switched = true;
    ctrl = val
    setTimeout(() => {
      this.switched = false
    },0)
  }

  closeAllDialogs(e?) {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    this.dialog.closeAll()
  }

  goToFilterPage(e, comp, page) {
    console.log("goToFilterPage ", e, comp)

    this.filter.patchValue({
      operation_employee_id: [e.value]
    })

    this.goToPage(page)
  }

  searchFilterSubmit(addData?) {
    let activeSections = this.activeSections();

    switch (activeSections) {
      case "Jobs":
        this.goToPage('/jobs', addData)
        break;
      case "Chats":
        this.goToPage('/chats', addData)
        break;
      case "Board by Date":
        this.goToPage('/board', addData)
        break;
      case "Board by Order":
        this.goToPage('/board-list', addData)
        break;
      case 'Posts':
        this.goToPage('/urls', addData)
        break;
      case 'Posts Start':
        this.goToPage('/urls', addData)
        break;
      case 'Projects':
        this.goToPage('/projects', addData)
        break;
      case 'Post Dashboards':
        this.goToPage('/urls-dashboard', addData)
        break;
      case 'Outlet Dashboards':
        this.goToPage('/outlets-dashboard', addData)
        break;
      case 'Regular Publications':
        this.goToPage('/regular-publications', addData)
        break;
      case 'Files':
        this.goToPage('/files', addData)
        break;
      case 'Analytic':
        if (['progress'].includes(this.activatedRoute.snapshot.queryParamMap.get('page'))) {
          this.goToPage('/urls-analytic', addData)
        } else {
          this.goToPage('/tasks', addData)
        }
        break;
      case 'Settings':
        if (['company', 'individuals', 'schedules', 'profiles', 'automation', 'finances', 'checklists', 'manage-auth'].includes(this.activatedRoute.snapshot.queryParamMap.get('page'))) {
          this.goToPage('/settings', addData)
        } else {
          this.goToPage('/tasks', addData)
        }
        break;
      default:
        this.goToPage('/tasks', addData)
        break;
    }
  }

  
  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (!!param.is_file_type) {
            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -2,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -2,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "RePost Docs"
            })

            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -3,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -3,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "ClaRe docs"
            })
            param.values.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -1,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -1,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "Re docs"
            })

            if (this.user.is_root) {
              param.values.unshift({
                company_id: this.company_id,
                created_at: 0,
                created_user_id: 0,
                id: -4,
                is_not_automatically_assigned: 0,
                order: 0,
                original_company_id: this.company_id,
                original_id: -4,
                original_user_id: 0,
                parameter: null,
                parameter_id: 0,
                parent_value_id: 0,
                system_value_id: 0,
                task_acm: "",
                task_id: 0,
                tips_count: 0,
                updated_at: 0,
                updated_user_id: 0,
                value: "Live stream"
              })
            }
          }
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          this.getActiveValuesForParam(param)
        })
      )
    )

    return forkJoin(arr)
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
      if (!!param.is_file_type || param.id == 0) {
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
     
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      }
      if (this.user.is_root) {
        param.values.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      }
      console.log(resp.body)
      console.log(this.parameters)
    })
  }

  add(e) {
    console.log(e)
  }

  getActiveValuesForParam(param) {
    param.activeTaskValues = []
    param.activeJobValues = []
    param.activeDiscussionValues = []
    param.activeFileValues = []
    let paramValues;
    if (param.id != 0) {
      paramValues = this.allValues.filter(b => b.parameter_id == param.id);
    } else {
      paramValues = this.allValues.slice();
    }
    // console.log("paramValues",param.name, paramValues)
    this.filter.value.task_parameter_value_id.forEach(x => {
      // console.log("x", x)
      // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
      if (x == -1 && !!param.is_file_type) {
        param.activeTaskValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      } else if (x == -3 && !!param.is_file_type) {
        param.activeTaskValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeTaskValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeTaskValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeTaskValues.push(...paramValues.filter(y => y.id == x))
      }
    }) 
    this.filter.value.file_parameter_value_id.forEach(x => {
      // console.log("x", x)
      // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
      if (x == -1 && !!param.is_file_type) {
        param.activeFileValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      }  else if (x == -3 && !!param.is_file_type) {
        param.activeFileValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeFileValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeFileValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeFileValues.push(...paramValues.filter(y => y.id == x))
      }
    }) 
    this.filter.value.task_operation_parameter_value_id.forEach(x => {
      // console.log("x", x)
      // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
      if (x == -1 && !!param.is_file_type) {
        param.activeJobValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      } else if (x == -3 && !!param.is_file_type) {
        param.activeJobValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeJobValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeJobValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeJobValues.push(...paramValues.filter(y => y.id == x))
      }
    }) 
    this.filter.value.discussion_parameter_value_id.forEach(x => {
      // console.log("x", x)
      // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
      if (x == -1 && !!param.is_file_type) {
        param.activeDiscussionValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      } else if (x == -3 && !!param.is_file_type) {
        param.activeDiscussionValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeDiscussionValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeDiscussionValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeDiscussionValues.push(...paramValues.filter(y => y.id == x))
      }
    }) 

    param.activeValues = []
    this.filter.value.parameter_value_id.forEach(x => {
      if (x == -1 && !!param.is_file_type) {
        param.activeValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -1,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -1,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Re docs"
        })
      }  else if (x == -3 && !!param.is_file_type) {
        param.activeValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -3,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -3,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "ClaRe Docs"
        })
      } else if (x == -2 && !!param.is_file_type) {
        param.activeValues.push({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -2,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -2,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "RePost Docs"
        })
      } else if (x == -4 && !!param.is_file_type) {
        param.activeValues.unshift({
          company_id: this.company_id,
          created_at: 0,
          created_user_id: 0,
          id: -4,
          is_not_automatically_assigned: 0,
          order: 0,
          original_company_id: this.company_id,
          original_id: -4,
          original_user_id: 0,
          parameter: null,
          parameter_id: 0,
          parent_value_id: 0,
          system_value_id: 0,
          task_acm: "",
          task_id: 0,
          tips_count: 0,
          updated_at: 0,
          updated_user_id: 0,
          value: "Live stream"
        })
      } else {
        param.activeValues.push(...paramValues.filter(y => y.id == x))
      }
    })
  }
  

  // getAllApiParameterValues() {
  //   this.attachSubscriptions(
  //     this.parametersService.getAllApiParameterValues(this.company_id).subscribe(resp => {
  //       this.allValues = resp
  //       console.log("getAllApiParameterValues", this.allValues)
  //     })
  //   )
  // }


  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getParameterById(id) {
    if (!this.parameters || this.parameters.length == 0) {
      return null
    }
    return this.parameters.find(x => x.id == id)
  }

  onOpened(el) {
    console.log("onOpened", el);
    this.showFilter = true;

    // el.panel.nativeElement.parentElement.
    // document.getElementsByClassName('cdk-overlay-container')[0].nativeElement.style.zIndex = '999999999999999';
    window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = "999999999999999"
  }

  onClosed(el) {
    console.log("onClosed", el)
    this.showFilter = false
    window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = "10000"
  }

  paramSwitcher(key, param) {
    switch (key) {
      case 'task_parameter_value_id':
        return param["activeTaskValues"]
        break;
      case 'task_operation_parameter_value_id':
        return param["activeJobValues"]
        break;
      case 'discussion_parameter_value_id':
        return param["activeDiscussionValues"]
        break;
      case 'discussion_parameter_value_id':
        return param["activeDiscussionValues"]
        break;
      case 'file_parameter_value_id':
        return param["activeFileValues"]
        break;
      case 'parameter_value_id':
        return param["activeValues"]
        break;
    }
  }

  keySwitcher(key) {
    switch (key) {
      case 'task_parameter_value_id':
        return "activeTaskValues"
        break;
      case 'task_operation_parameter_value_id':
        return "activeJobValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'file_parameter_value_id':
        return "activeFileValues"
        break;
      case 'parameter_value_id':
        return "activeValues"
        break;
    }
  }

  onRemove(value, param, key) {
    console.log("onRemove", this.filter.value)
    if (param.id == 0) {
      let parameterValues = this.parameters.find(x => x.id == value.parameter_id)[this.keySwitcher(key)]
      parameterValues.splice(parameterValues.indexOf(parameterValues.find(b => b.id == value.id)),1)
    }
    let val = this.filter.value[key];
    val.splice(val.indexOf(value.id), 1);
    this.filter.patchValue({
      [key]: val
    })
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    this.searchFilterSubmit()
  }

  selected(e, param, key) {
    console.log("selected", e)

    
    if (param.id == 0) {
      console.log(this.parameters, e, param, key);
      let parameterValues = this.parameters.find(x => x.id == e.option.value.parameter_id)[this.keySwitcher(key)]
      parameterValues.push(e.option.value)
    }

    let val = this.filter.value[key];
    val.push(e.option.value.id);
    this.filter.patchValue({
      [key]: val
    })
    param.search.patchValue("")
    if (document.getElementById(`param_nav_input_${param.id}`)) {
      (document.getElementById(`param_nav_input_${param.id}`) as HTMLInputElement).value = ''
    }
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    this.searchFilterSubmit()
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company_id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values);
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -2,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -2,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "RePost Docs"
                  })
                  
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -3,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -3,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "ClaRe Docs"
                  })
      
                  this.allValues.unshift({
                    company_id: this.company_id,
                    created_at: 0,
                    created_user_id: 0,
                    id: -1,
                    is_not_automatically_assigned: 0,
                    order: 0,
                    original_company_id: this.company_id,
                    original_id: -1,
                    original_user_id: 0,
                    parameter: null,
                    parameter_id: 0,
                    parent_value_id: 0,
                    system_value_id: 0,
                    task_acm: "",
                    task_id: 0,
                    tips_count: 0,
                    updated_at: 0,
                    updated_user_id: 0,
                    value: "Re docs"
                  })

                  if (this.user.is_root) {
                    this.allValues.unshift({
                      company_id: this.company_id,
                      created_at: 0,
                      created_user_id: 0,
                      id: -4,
                      is_not_automatically_assigned: 0,
                      order: 0,
                      original_company_id: this.company_id,
                      original_id: -4,
                      original_user_id: 0,
                      parameter: null,
                      parameter_id: 0,
                      parent_value_id: 0,
                      system_value_id: 0,
                      task_acm: "",
                      task_id: 0,
                      tips_count: 0,
                      updated_at: 0,
                      updated_user_id: 0,
                      value: "Live stream"
                    })
                  }
                  console.log("getAllApiParameterValues", this.allValues)
                  this.sendData();
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.company_id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: marker("All Tags"),
            original_id: 0,
          })
          this.parametersService.globalParamsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
          this.parameters = res
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onScrollParams(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }

  
  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getViewsUpdateIntervalList() {
    this.attachSubscriptions(
      this.companyService.getViewsUpdateIntervalList().pipe(
        map(res => {
          let arr = []
          Object.keys(res).forEach(key => {
            arr.push({
              name: key,
              value: res[key]
            })
          })
          return arr
        })
      ).subscribe(resp => {
        console.log("getViewsUpdateIntervalList", resp)
        this.viewsUpdateIntervalList = resp;
        this.sendData();
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
        this.platforms$.next(this.platforms.slice());
        this.sendData();
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getProfiles(page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id, page).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        // public profilesControl: FormControl = new FormControl();
        // public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
        if (page == 1) {
          this.groupedProfiles = [];
          this.channelsSubsControl.patchValue(0)
        }
        resp.forEach(channel => {
          if (this.groupedProfiles.filter(x => x.platform_id == channel.platform_id).length == 0) {
            this.groupedProfiles.push({
              platform_id: channel.platform_id,
              channels: [channel],
              channelsControl: new FormControl(),
              asChannels: new ReplaySubject<any>(1)
            })
            this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
          } else {
            if (this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.filter(b => b.id == channel.id).length == 0) {
              this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.push(channel);
              this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
            }
          }
        })

        console.log("this.groupedProfiles", this.groupedProfiles)

        if (page == 1) {
          this.profiles = resp
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes("", this.contentPage);
        } else {
          resp.forEach(profile => {
            if (this.profiles.filter(x => x.id == profile.id).length == 0) {
              this.profiles.push(...resp);
            }
          })
        }
        this.sendData();

        if (this.groupedProfiles.filter(x => x.platform_id == 0).length == 0) {
          this.groupedProfiles.unshift({
            platform_id: 0,
            channels: [...this.profiles],
            channelsControl: new FormControl(),
            asChannels: new ReplaySubject<any>(1)
          })
          this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
        } else {
          this.profiles.forEach((channel,ind) => {
            if (this.groupedProfiles.find(x => x.platform_id == 0).channels.filter(b => b.id == channel.id).length == 0) {
              this.groupedProfiles.find(x => x.platform_id == 0).channels.push(channel);
            }
            if (ind == this.profiles.length - 1) {
              this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
            }
          });
        }

        this.groupedProfiles.forEach(el => {
          el.channelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {console.log(resp); this.onSearchGroupedChannels(resp, el);})
        })
        this.profiles$.next(this.profiles.slice());
        this.profilesPage = this.profilesPage + 1;
      })
    )
  }

  closeNav(el) {
    console.log(el);
    el.classList.add('hide_nav')
    setTimeout(() => {
      el.classList.remove('hide_nav')
    },100)
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case "/projects":
        return 'Projects';
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-analytic':
        return 'Analytic'
        break;
      case '/urls-start':
        return 'Posts Start'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/regular-publications':
        return 'Regular Publications'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  activePage() {
    return this.activatedRoute.snapshot.queryParamMap.get('page') 
  }

  saveFilterToBookmarks() {
    const dialogRef = this.dialog.open(SaveFilterToBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        filter: this.filter,
        activeLang: this.activeLang,
        params: this.getParams(),
        allContentTypes: this.allContentTypes,
        employees: this.employees,
        clients: this.clients,
        viewsUpdateIntervalList: this.viewsUpdateIntervalList,
        partners: this.partners,
        allValues: this.allValues,
        statuses: this.statuses,
        taskStatuses: this.taskStatuses,
        jobTypes: this.jobTypes, 
        groups: this.groups, 
        prioritys: this.prioritys,
        platforms: this.platforms,
        profiles: this.profiles,
        profileStatuses: this.profileStatuses, 
        contentUrl: this.contentUrl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          console.log("result", result)
        }
      })
    )
  }

  
  showFilesInterface() {
    setTimeout(() => {
      const dialogRef = this.dialog.open(InterfaceComponent, {
        backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
        panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
        hasBackdrop: true,
        data: {
          company: this.company,
          user: this.user,
          loc: '',
          is_global: true
        }
      });
    },0)
  }

  getParams(page?) {
    let params:any = {
      company_id: this.company_id
    };
    
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })

    if (page) {
      if (page != '/files') {
        delete params.location_all
      } else {
        params.location_all = '/'
        delete params.operation_status_id
      }
    } else {
      delete params.location_all
    }

 
    if (this.activatedRoute.snapshot.queryParamMap.get('page')) {
      params.page = this.activatedRoute.snapshot.queryParamMap.get('page')
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      params.tab = this.activatedRoute.snapshot.queryParamMap.get('tab')
    }
    // if (page == '/settings') {
    //   params.page = 'finances_dashboard'
    // }
    let filterData = {...this.filter.value};

    if (this.activatedRoute.snapshot.queryParamMap.get('expand')) {
      filterData.expand = this.activatedRoute.snapshot.queryParamMap.get('expand')
    }
    // console.log("PARAMS TEST filter", filterData)

    Object.keys(filterData).forEach(element => {
      if ((element.indexOf('_from') != -1 || element.indexOf('_to') != -1) && !!filterData[element]) {
        params[element] = (filterData[element] as Moment).format("X")
      } else if (!!filterData[element] && typeof filterData[element] === 'object') {
        if (filterData[element].length > 0) {
          params[element] = filterData[element].join(",")
        }
      } else {
        if (!!filterData[element] && filterData[element].toString() != '') {
          params[element] = filterData[element]
        }
      }
    });
    

    if (this.selectedPartnerForGroup) {
      params.groups_partner_id = this.selectedPartnerForGroup
    }

    if ((page == '/urls' || page == '/urls-start') && !!this.postsQ) {
      params.q = this.postsQ;
      console.log("PARAMS TEST", params)
    }
    return params
  }

  haveActiveFilters() {
    let qParams = this.getParams() || {};

    return Object.keys(qParams).filter(x => !['count', 'company_id', 'order_by', 'expand'].includes(x)).length
  }

  urlParams() {
    let params = this.getParams();
    if (!!this.postsQ) {
      params.q = this.postsQ;
    }


    return params
  }

  goToPage(page, addData?) {
    this.closeAllDialogs();

    let params:any = {
      company_id: this.company_id
    };

    if (!!addData) {
      if (addData.order_by != "") {
        params = Object.assign(params, addData)
      }
    } else if (window.location.pathname == page && !!this.activatedRoute.snapshot.queryParamMap.get('order_by')) {
      params = Object.assign(params, {order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by')})
    }

    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })

    if (page) {
      if (page != '/files') {
        delete params.location_all
      } else {
        if (!params.hasOwnProperty('location_all')) {
          params.location_all = '/'
        }
        delete params.operation_status_id
      }
    } else {
      delete params.location_all
    }


    let filterData = {...this.filter.value};

    console.log("filterData", filterData)

    // if (page == '/settings') {
    //   params.page = 'finances_dashboard'
    // }
    if (['company', 'individuals', 'schedules', 'profiles', 'automation', 'finances', 'checklists', 'manage-auth', 'progress'].includes(this.activatedRoute.snapshot.queryParamMap.get('page'))) {
      params.page = this.activatedRoute.snapshot.queryParamMap.get('page')

      if (!!this.activatedRoute.snapshot.queryParamMap.get('type')) {
        params.type = this.activatedRoute.snapshot.queryParamMap.get('type')
      }
      if (this.activatedRoute.snapshot.queryParamMap.get('page') == 'finances') {
        params.tab = this.activatedRoute.snapshot.queryParamMap.get('tab')
      }
      if (this.activatedRoute.snapshot.queryParamMap.get('page') == 'automation') {
        params.tab = this.activatedRoute.snapshot.queryParamMap.get('tab')
      }
    }

    Object.keys(filterData).forEach(element => {
      if ((element.indexOf('_from') != -1 || element.indexOf('_to') != -1) && filterData[element] != "") {
        params[element] = (filterData[element] as Moment).format("X")
      } else if (!!filterData[element] && typeof filterData[element] === 'object') {
        if (filterData[element].length > 0) {
          params[element] = filterData[element].join(",")
        }
      } else {
        if (!!filterData[element] && filterData[element].toString() != '') {
          params[element] = filterData[element]
        }
      }
    });

    if (this.selectedPartnerForGroup) {
      params.groups_partner_id = this.selectedPartnerForGroup
    }

    if ((page == '/urls' || page == '/urls-start') && !!this.postsQ) {
      page = '/urls'
      params.q = this.postsQ;
      console.log("PARAMS TEST", params)
    }

    this.router.navigate([page], { queryParams: params });
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    if (this.groupsSub) {
      this.groupsSub.unsubscribe()
    }
    this.clearSubscriptions();
  }
}
