<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal" (click)="log(); logCli();">
            {{ "New job" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="small_chips" *ngIf="operations?.length > 0">
                <ng-container *ngIf="!form.get('operation_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Job type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                    <ng-container *ngFor="let operation of operations; let i = index">
                        <mat-chip class="small_chip" (click)="selectType(operation)" [value]="operation?.id">
                            {{ operation.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #operationIdContainer formControlName="operation_id" (selectionChange)="selectType($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                        {{ operation.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Title' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <textarea formControlName="comment" matInput placeholder="{{ 'Drief description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Hidden notes (available only to owners, admins and managers)' | translate }}</mat-label>
                <textarea formControlName="private_comment" matInput placeholder="{{ 'Hidden notes (available only to owners, admins and managers)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
        </form>
        <form [formGroup]="workEmpl" class="form_with_pads form_with_valids">

            <div class="users_select" [ngClass]="{'req_value': !workEmpl.get('employee_id').valid}">
                <label>{{ "Choose an employee" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="employee_id">
                    <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="toggleChip({id: 0})" [value]="0">
                        <mat-icon style="opacity: .7;">close</mat-icon>
                    </mat-chip>
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <form [formGroup]="partnerForm" class="form_with_pads form_with_valids">
            <div class="users_select" *ngIf="partners?.length > 0">
                <label>{{ "Choose a partner" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="partner_company_id">
                    <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="togglePartnerChip({partner_company_id: 0})" [value]="0">
                        <mat-icon style="opacity: .7;">close</mat-icon>
                    </mat-chip>
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="togglePartnerChip(partner)" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #partnerContainer formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}</mat-label>
                <textarea formControlName="employee_comment" matInput placeholder="{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of operationStatuses">
                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'nine': status.id == '97',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <div class="priority_chips">
                <label>{{ "Priority" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="priority">
                    <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                        'Urgent': priority.id == '1000',
                        'High': priority.id == '900',
                        'Middle': priority.id == '800',
                        'Low': priority.id == '700',
                        'No': priority.id == '0'
                    }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                        {{ priority.name | translate }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </form>

        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date the job was performed" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
            
            <div class="date_and_time_label">{{ "Deadline" | translate }}</div>
            <div class="date_and_time_c_w" formGroupName="date">
                <mat-form-field appearance="standard" class="date_picker_cst">
                    <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                    <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                    <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDay></mat-datepicker>
                </mat-form-field>
                
                <div class="ngx-timepicker-field-example">
                    <div class="prefix">{{ "Time" | translate }}</div>
                    <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" formControlName="time"></ngx-timepicker-field>
                </div>
            </div>

            <div class="small_chips" *ngIf="tabs?.length > 0">
                <label>{{ "Choose a tab" | translate }}: </label>
                <div class="chips_spacer" *ngIf="!!tabsSwitch"></div>
                <mat-chip-list [multiple]="true" selectable formControlName="create_parameter_values_to_task" *ngIf="!tabsSwitch">
                    <ng-container *ngFor="let tab of tabs; let i = index">
                        <mat-chip class="small_chip" (click)="toggleTabChip(tab)" [value]="tab.id">
                            {{tab.value}}
                        </mat-chip>
                        <mat-chip *ngIf="i == tabs.length - 1 && i > 6" class="small_chip more" (click)="tabsCont.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #tabsCont [multiple]="true" formControlName="create_parameter_values_to_task" (selectionChange)="toggleTabChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tabs found' | translate }}"
                        [formControl]="tabsMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let tab of tabs$ | async" [value]="tab?.id">
                        {{tab.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="file_tags_out" style="padding-bottom: 18px;">
                <label>{{ "Job Tags" | translate }}: </label>
                <div class="file_tags">
                    <ng-container *ngIf="parameters && parameters.length && parameters[0] && parameters[0].activeValues && parameters[0].activeValues.length">
                        <div class="file_tag" *ngFor="let tag of parameters[0].activeValues; let t = index;">
                            <mat-icon class="verified" (click)="togglePrimary(tag)" [ngClass]="{'cst_primary': !!tag.is_primary}">verified</mat-icon>
                            {{tag.value}}
                            <div class="remove_out">
                                <div class="remove" (click)="onRemoveJobTag(tag, t)">
                                    <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="add_tag cp" (click)="openTargetValues()">
                        <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                        </svg>
                        {{ "Tag" | translate }}
                    </div>
                </div>
            </div>
        </form>


        <form [formGroup]="workEmpl" class="form_with_pads form_with_valids">
            <mat-form-field *ngIf="workEmpl.get('rate')" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points value for job performance' | translate }}</mat-label>
                <input formControlName="rate" matInput type="number" placeholder="{{ 'Points value for job performance' | translate }}">
                <div class="cur_suffix" matSuffix>PTS</div>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="workEmpl.get('price')">
                <mat-label>{{ 'Price to be charged by executor' | translate }}</mat-label>
                <input formControlName="price" matInput type="number" placeholder="{{ 'Price to be charged by executor' | translate }}">
                <div class="cur_suffix" *ngIf="workEmpl && !!workEmpl.get('employee_id').value && !!getEmployeeById(workEmpl.get('employee_id').value)?.currency" matSuffix>{{ getEmployeeById(workEmpl.get('employee_id').value)?.currency?.iso_name }}</div>
            </mat-form-field>
        </form>

        <form [formGroup]="additional" class="form_with_pads form_with_valids">
            <div class="users_select">
                <label>{{ "Choose an additional employee" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="employee_id">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="workEmpl.get('employee_id').value == employee.id ? null : toggleAddChip(employee)" [value]="employee.id" [disabled]="!!partnerForm.get('partner_company_id').value || workEmpl.get('employee_id').value == employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="addEmployeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #addEmployeeIdContainer formControlName="employee_id" multiple (selectionChange)="toggleAddChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                        [formControl]="addEmployeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of addEmployees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': workEmpl.get('employee_id').value == employee.id}" [disabled]="!!partnerForm.get('partner_company_id').value || workEmpl.get('employee_id').value == employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <form [formGroup]="templateForm" class="form_with_pads form_with_valids">
            <div class="users_select">
                <label>{{ "Choose a scenario" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="template_id">
                    <ng-container *ngFor="let template of templates; let i = index">
                        <mat-chip style="padding-left: 12px !important;" class="chip_user" (click)="toggleChipTemplate(template)" [value]="template.id" [selected]="templateForm.value.template_id && templateForm.value.template_id.includes(template.id)">
                            {{ template.name }}
                        </mat-chip>
                        <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <!-- (optionSelected)=“function($event)” try this -->
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select [multiple]="true" #templatesIdContainer formControlName="template_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                        [formControl]="templatesMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                        {{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div class="block_w8 form_with_pads" style="margin: 20px 40px;">
            <div class="label_w8" style="margin: 0;">{{"Job Clients" | translate}}</div>
            <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                <label>{{ "Individuals" | translate }}: </label>
                <div class="chips_spacer" *ngIf="!!cliSwitch"></div>
                <mat-chip-list [multiple]="true" selectable *ngIf="!cliSwitch">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleClientChip(employee)" [selected]="includesCli(employee.id)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            <mat-icon *ngIf="!includesCli(employee.id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                            <ng-container *ngIf="includesCli(employee.id)">
                                <mat-icon style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="setCli($event, employee.id, 0)" 
                                    *ngIf="!!getCli(employee.id)?.is_principal">thumb_up</mat-icon>
                                <mat-icon 
                                    style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="setCli($event, employee.id, 1)" 
                                    *ngIf="!getCli(employee.id)?.is_principal">thumb_up_off_alt</mat-icon>
                            </ng-container>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="cliCardEmployeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #cliCardEmployeeIdContainer multiple [(ngModel)]="clients_ids" (selectionChange)="toggleSelectClientChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                <label>{{ "Partners" | translate }}: </label>
                <div class="chips_spacer" *ngIf="!!pCliSwitch"></div>
                <mat-chip-list [multiple]="true" selectable *ngIf="!pCliSwitch">
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="toggleClientPartnerChip(partner)" [selected]="includesCliPartn(partner.partner_company_id)" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            <mat-icon *ngIf="!includesCliPartn(partner.partner_company_id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                            <ng-container *ngIf="includesCliPartn(partner.partner_company_id)">
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 0)" 
                                *ngIf="!!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up</mat-icon>
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve not granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 1)" 
                                *ngIf="!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up_off_alt</mat-icon>
                            </ng-container>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="cliCardPartnersIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #cliCardPartnersIdContainer multiple [(ngModel)]="partnerClients_ids" (selectionChange)="toggleSelectClientPartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>