import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { take } from 'rxjs/operators';
import { StorageManagerService } from '../../services/common/storage-manager.service';

@Component({
  selector: 'app-back-job-to-prev-status',
  templateUrl: './back-job-to-prev-status.component.html',
  styleUrls: ['./back-job-to-prev-status.component.scss']
})
export class BackJobToPrevStatusComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton;
  public imgRoute: any;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BackJobToPrevStatusComponent>,
    private sm: StorageManagerService
  ) { }

  ngOnInit(): void {
    console.log("BackJobToPrevStatusComponent", this.data)
    this.getImgRoute();
  }

  getImgRoute() {
    this.sm.getImgPath().pipe(take(1)).subscribe(el => {
      this.imgRoute = el;
    })
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  no(): void {
    this.bottomSheetRef.dismiss({
      message: 'no',
    });
  }

  yes() {
    this.bottomSheetRef.dismiss({
      message: 'yes',
    });
  }

}
