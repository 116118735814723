import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link-with',
  templateUrl: './link-with.component.html',
  styleUrls: ['./link-with.component.scss']
})
export class LinkWithComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    channel_id: ['', Validators.required],
  });
  public isFormChange: boolean = false;
  public host: any = environment.host;
  
  public profiles: any[] = [];
  
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LinkWithComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("LinkWithComponent", this.data)

    // if (this.data.profile.channelAccount) {
    //   this.selectedChan = this.data.profile.channelAccount;
    //   this.form.patchValue({
    //     channel_account_id: this.selectedChan.id
    //   })
    //   if (this.channels.filter(x => x.id == this.selectedChan.id).length == 0) {
    //     this.channels.unshift(this.selectedChan)
    //   }
    // }



    // switch (this.data.profile.platform_id) {
    //   case 1:
    //     this.type = "youtube"
    //     break;
    //   case 2:
    //     this.type = "facebook"
    //     break;
    //   case 3:
    //     this.type = "tiktok"
    //     break;
    //   case 4:
    //     this.type = "facebook"
    //     break;
    //   case 5:
    //     this.type = "snapchat"
    //     break;
    //   default:
    //     this.type = "youtube"
    //     break;
    // }

    // this.attachSubscriptions(
    //   this.expChannelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchExpChannels(resp))
    // )

    // if (!!this.data.profile.channel_account_id) {
    //   this.getChannelAccountsChannels(this.data.profile.channel_account_id);
    //   if (this.type == 'facebook') {
    //     this.form.addControl('external_id', this.fb.control(!!this.data.profile.external_id ? [this.data.profile.external_id] : []))
    //   } else {
    //     this.form.addControl('external_id', this.fb.control(!!this.data.profile.external_id ? this.data.profile.external_id : ''))
    //   }
    // } else {
    //   if (!!this.form.get('external_id')) {
    //     this.form.removeControl('external_id')
    //   }
    // }

    // if (!!this.form.get('external_id')) {
    //   this.form.get('external_id').updateValueAndValidity({onlySelf: true})
    // }


    // this.form.get('channel_account_id').valueChanges.subscribe(res => {
    //   this.expChannels = []
    //   this.groupedProfiles.forEach(group => {
    //     group.channels = [];
    //     group.asChannels.next(group.channels.slice())
    //   })
    //   console.log("channel_account_id", res);
    //   if (res) {
    //     if (!!this.form.get('external_id')) {
    //       this.form.patchValue({
    //         external_id: ''
    //       })
    //     } else {
    //       this.form.addControl('external_id', this.fb.control(''))
    //     }
    //     this.getChannelAccountsChannels(res);
    //   } else { 
    //     if (!!this.form.get('external_id')) {
    //       this.form.removeControl('external_id')
    //     }
    //   }
    //   this.form.get('external_id').updateValueAndValidity({onlySelf: true})
    // })

    // this.attachSubscriptions(
    //   this.chanControl.valueChanges.pipe(debounceTime(600),distinctUntilChanged()).subscribe((resp) => {
    //     console.log("chanControl", resp)
    //     this.onSearchChans(resp)
    //   })
    // )

    this.getProfiles();
    // this.getChannels(this.page, {q: this.chanControl.value});

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((res) => {
        console.log("this.form.valueChanges", res)

        // if (!!res.channel_account_id) {
        //   this.getChan(res.channel_account_id);
        // } else {
        //   this.selectedChan = undefined;
        // }

        // if (this.selectedTask && !!res.task_channel_id) {
        //   this.selectedChannel = 
        // } else {
          
        // }
        this.isFormChange = true
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  
  
  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company_id, '1', {platform_id: this.data.account.platform_id}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company_id, '200', {platform_id: this.data.account.platform_id}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getChannelAccountAuthUrl() {
    // this.attachSubscriptions(
    //   this.connectedAppsService.getChannelAccountAuthUrl(this.type, window.location.href + `&open_id=${this.data.profile.id}`, !!this.fromCompany ? this.data.company_id : null).subscribe(resp => {
    //     console.log(resp);
    //     console.log(this.host + resp);
    //     window.open(this.host + resp, "_self")
    //   })
    // )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    let external_id = 0;
    if (this.data.exChan) {
      external_id = this.data.exChan.external_id
    } else {
      if (this.data.account.exChannels && this.data.account.exChannels.length) {
        external_id = this.data.account.exChannels[0].external_id
      } 
    }

    if (!external_id) {
      this.layoutService.showSnackBar({name: ''}, marker("This auth outlet didn`t have channels"), SnackBarItem)
      return
    }

    let formData = {
      company_id: this.data.company_id,
      channel_account_id: this.data.account.id,
      external_id: external_id
    }
    
    this.attachSubscriptions(
      this.companyService.editCompanyGroupProfile(this.form.value.channel_id, formData).subscribe(resp => {
        this.dialogRef.close({event: "edit"});
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
