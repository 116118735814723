import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-work-completed-at-edit',
  templateUrl: './work-completed-at-edit.component.html',
  styleUrls: ['./work-completed-at-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class WorkCompletedAtEditComponent extends BaseClass implements OnInit, OnDestroy {
  public operations: any;
  public isFormChange: boolean = false;
  public form: FormGroup;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkCompletedAtEditComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private _adapter: DateAdapter<any>,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    this.getOperations();
    
    this.form = this.fb.group({
      completed_at: this.data.work.completed_at != 0 ? moment(this.data.work.completed_at*1000) : '',
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getLangData();

  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      this.form.value.completed_at = '0';
    }
    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, this.form.value, this.data.company.id).pipe(
        switchMap(val => {
          if (!!this.data?.work?.employee?.id) {
            return this.membersService.editMember(this.data.work.employee.id, this.form.value, this.data.company.id).pipe(map(() => val ))
          } else {
            return of(val)
          }
        })
      ).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
