<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title">
            <div class="d-f-c">
                <div class="icon_back" (click)="close()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Notifications" | translate }}
                </span>
            </div>
            <form [formGroup]="filter" class="hidden_filter_wrp_out">
                <div class="hidden_filter_wrp" (click)="comapnyes.open()">
                    <div class="active_filter">
                        <span class="text_one_line">
                            {{ getCompanyById(filter.get('company_id').value)?.name }}
                        </span>
                        <svg style="width: 10px; height: 10px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3582_51300head)">
                            <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="#686868"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3582_51300head">
                            <rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 0)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <mat-form-field appearance="legacy" class="hidden_filter_element">
                        <mat-label>{{ "Workspace" | translate }}</mat-label>
                        <mat-select #comapnyes [disableOptionCentering]="true" selectDir formControlName="company_id">
                            <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching Workspace found' | translate }}"
                                    [formControl]="companyesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="">{{ "All Workspaces" | translate }}</mat-option>
                            <mat-option *ngFor="let comp of companyes$ | async" [value]="comp.id">
                                    {{comp.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="not_center mobile_scrollable_int_big" style="background: #fff;" 
    infinite-scroll
    [infiniteScrollDisabled]="isLoad"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDown($event)">
    <div class="mob_chat" (click)="openNot(not)" *ngFor="let not of notifications" [ngClass]="{'highlight_chat': not.is_active == 1}">

        <div class="chat_photo">
            <ng-container *ngIf="not?.imageFile && not?.imageFile.thumbnail; else elseTemplatehasIMG">
                <img [src]="data?.host + not?.imageFile.thumbnail + '?company_id=' + not?.company_id" alt="">
            </ng-container>
            <ng-template #elseTemplatehasIMG>
                <mat-icon>chat</mat-icon>
            </ng-template>
        </div>
        <div class="chat_data">
            <div class="chat_head">
                <div class="d-f-c">
                    <div class="inside_job" [ngClass]="{
                        'first': not?.discussion?.status_id == '1',
                        'second': not?.discussion?.status_id == '2',
                        'third': not?.discussion?.status_id == '3',
                        'fours': not?.discussion?.status_id == '4',
                        'fives': not?.discussion?.status_id == '5',
                        'sixs': not?.discussion?.status_id == '6',
                        'nine': not?.discussion?.status_id == '97',
                        'seven': not?.discussion?.status_id == '98',
                        'eight': not?.discussion?.status_id == '99'}">
                        <span class="text_one_line">{{ getOperationStatusName(not?.discussion?.status_id) | translate }}</span>  
                    </div>

                    <div class="date">
                        {{ not?.discussion?.updated_at | reportsDate:data.activeLang }} 
                    </div>
                </div>
            </div>
            <div class="chat_name text_one_line">
                {{ not?.discussion?.name }}
            </div>

            <div class="chat_last_post">                    
                <div class="text_one_line" [innerHTML]='not?.discussionPost?.text ? (not?.discussionPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div>
            </div>

            <div class="chat_footer">
                <div class="foot_line">
                <div class="job_type">
                    
                </div>
                <div class="job_desc text_one_line" *ngIf="not?.discussion?.taskOperation?.name">
                    {{ not?.discussion?.taskOperation?.name }}
                </div>
                </div>
                <div class="foot_line">
                <div class="group">
                    <span class="text_one_line" *ngIf="not?.discussion?.task?.group_id">
                        {{ getGroup(not?.discussion?.task?.group_id).name }}
                    </span>
                </div>
                <div class="task text_one_line">
                    {{ not?.discussion?.task?.name }}
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</mat-dialog-content>
