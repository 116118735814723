import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-create-root-type',
  templateUrl: './create-root-type.component.html',
  styleUrls: ['./create-root-type.component.scss']
})
export class CreateRootTypeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private rootService: RootService,
    public dialogRef: MatDialogRef<CreateRootTypeComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.form = this.fb.group({
      name: ['', Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    if (this.data.is_card) {
      this.attachSubscriptions(
        this.rootService.addRootTaskType(Object.assign(this.form.value, {company_id: 0})).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.addRootJobType(Object.assign(this.form.value, {company_id: 0})).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
