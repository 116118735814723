import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { GroupManagersComponent } from '../../../../workspace-pages/cases/dialogs/managers/group-managers/group-managers.component';
import { ChannelGroupToEmployeeComponent } from '../../../members/employees/dialogs/channel-group-to-employee/channel-group-to-employee.component';
import { GroupCreateComponent } from '../group-create/group-create.component';
import { GroupEditComponent } from '../group-edit/group-edit.component';
import { ChannelsToGroupComponent } from '../channels-to-group/channels-to-group.component';
import { GroupToEmployeeComponent } from '../../../members/employees/dialogs/group-to-employee/group-to-employee.component';
import { ReplaySubject, Subscription, concat, forkJoin, fromEvent, of } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class CompanyComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company: any;
  public operations: any;
  public activeLang: any;
  public company_id: any;

  public filter: FormGroup = this.fb.group({
    order_by: 'id_desc',
    count: '',
    q: '',
    content_type_id: '',
    channel_id: [[]],
    parameter_value_id: [[]],
    id: "",
  });

  public allValues: any;
  public allContentTypes: any;
  public profiles: any;
  public groupsPagination: any;
  public groupsPage: number = 1;
  public groups: any[] = [];
  public groupsMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public groupsDataSub: Subscription;
  
  overlayRefPublisher: OverlayRef | null;
  @ViewChild('publisherMenu') publisherMenu: TemplateRef<any>;
  
  public isTableExpanded: boolean = false;
  public displayedColumns: string[] = ["Expand", "Id", "Date", "Name", "Channels", "btns"];

  public publisherSub: Subscription;
  
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private membersService: MembersService,
    private router: Router,
    private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private fb: FormBuilder,
    private parametersService: ParametersService,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet
  ) {
    super()
   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getCompany();
    this.getOperations();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles();
    this.getContentTypes();
    this.getLangData();
    this.getValues();
    this.onRouteChange();

    this.groupsDataSub = this.companyService.getGroupsDataStream().pipe(
      concatMap(itemsInPage => {
        return concat(...itemsInPage.map(itemInPage => this.neededData(itemInPage))).pipe(last(),map(x => itemsInPage))
      }),
    ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
  }
  
  getValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  openTargetValues(group) {
    let initData:any = {
      company: this.company,
      group: group
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        // this.getChanTags(profile)
      })
    )
  }

  neededData(group) {
    let arr = [
      this.taskService.getOperationReminder(this.company_id, {'channel_group_id': group.id}).pipe(
        map(el => el.body),
        tap(resp => {
          group.checklists = [];
          this.operations.forEach(op => {
            group.checklists.push({
              type: op.name,
              count: resp.filter(x => x.operation_id == op.id).length,
              items: resp.filter(x => x.operation_id == op.id),
              id: op.id
            })
          });
          group.isGetChecklists = true;
        }),
        catchError(() => {
          return of(null)
        })
      ),
      this.membersService.getChannelGroupToEmployee(this.company_id, {channel_group_id: group.id}).pipe(
        tap(resp => {
          group.canSee = resp;
          group.isGetCanSee = true;
        }),
        catchError(() => {
          return of(null)
        })
      ),
      this.taskService.getGroupManagers({group_id: group.id}).pipe(
        tap(resp => {
          group.managers = resp;
          group.isGetGroups = true;
        }),
        catchError(() => {
          return of(null)
        })
      ),
      this.parametersService.getTargetGroupTags(this.company_id, {group_id : group.id}).pipe(
        tap(resp => {
          group.tags = resp;
          group.isGetTags = true;
        }),
        catchError(() => {
          return of(null)
        })
      )
    ]
    
    return forkJoin(arr)
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getProfiles();
            // this.getAllValues();
            // this.getGroupsCompany();
            // this.getProfiles();
            // this.getTaskStatus();
            // this.getProfilesStatus();
            // this.getEmployees();
            // this.getChecklists();
            // this.getPlatforms();
            // this.getContentTypes();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'id_desc',
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? this.activatedRoute.snapshot.queryParamMap.get('count') : '/',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : '',
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '',
          })

          console.log("FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("Files route STREAM", params);
        this.groupsPage = 1;
        this.groups = [];
        this.getGroups(this.groupsPage);
        // if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
        //   this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
        //   this.getPartnerGroups()
        // }
        // this.getBookmarks();
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterSettins()
  }

  filterSettins() {
    let params = {
      company_id: this.company_id,
      page: this.activatedRoute.snapshot.queryParamMap.get('page')
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "parameter_value_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }
    
    console.log("proj params", params)

    this.router.navigate(['/settings'], {queryParams: params})
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  openChannelGroupToEmployee(group) {
    const dialogRef = this.dialog.open(ChannelGroupToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        group: group,
        fromEmployees: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.groupsPage = 1;
        this.getGroups(this.groupsPage)
      })
    )
  }
  openGroupToEmployee(group) {
    const dialogRef = this.dialog.open(GroupToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        group: group,
        fromEmployees: false
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.groupsPage = 1;
    //     this.getGroups(this.groupsPage)
    //   })
    // )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0]
        this.companyService.company$.next(resp[0])
      })
    )
  }

  getGroups(page) {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsWithFilterCompany(this.company_id, page, '200', this.filter.value).pipe(
        tap(el => {
          this.groupsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        tap(res => {
          if (res.length) {
            this.companyService.groupsData$.next(res)
          }
        })
      ).subscribe(resp => {
        console.log("getgroups", resp)
        if (page == 1) {
          this.groups = resp
        } else {
          // resp.forEach(group => {
          //   if (this.groups.filter(x => x.id == group.id).length == 0) {
          //     this.groups.push(...resp);
          //   }
          // })
          this.groups.push(...resp);
        }
        this.groups$.next(this.groups.slice());
        this.groupsPage = this.groupsPage + 1;
      })
    )
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.groupsPage <= this.groupsPagination['pageCount']) {
            this.getGroups(this.groupsPage);
          }
        }
      )
    )
  }

  rowClick(element) {
    console.log(element);
    if (element.isExpanded && !element.hasOwnProperty('checklists')) {
      element.checklists = [];
      this.taskService.getOperationReminder(this.company_id, {'channel_group_id': element.id}).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        element.checklists = []
        this.operations.forEach(op => {
          element.checklists.push({
            type: op.name,
            count: resp.filter(x => x.operation_id == op.id).length,
            id: op.id
          })
        });
        console.log('getOperationReminder', element)
      })
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  channelsToGroup(group) {
    const dialogRef = this.dialog.open(ChannelsToGroupComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        group: group,
        user: this.user,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.groupsPage = 1;
        this.getGroups(this.groupsPage)
      })
    )
  }
  
  groupManagers(group) {
    const dialogRef = this.dialog.open(GroupManagersComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        groups: this.groups,
        user: this.user,
        group: group
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.groupsPage = 1;
        this.getGroups(this.groupsPage)
      })
    )
  }

  deleteGroupCompany(id) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteGroupCompany(id).subscribe(resp => {
            console.log(resp)
            let group = this.groups.find(el => el.id == id);
            this.groups.splice(this.groups.indexOf(group), 1);
          })
        )
      }
    });

  }

  goToChecklists(e, group) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'checklists', group_id: group.id} });
  }

  log() {
    console.log("this.groups", this.groups)
  }

  
  openPublisherMenu({ x, y }: MouseEvent, group, check, i) {
    this.closePublisherMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefPublisher = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefPublisher.attach(new TemplatePortal(this.publisherMenu, this.viewContainerRef, {
      $implicit: { group, check, i }
    }));
    
    this.publisherSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefPublisher && !this.overlayRefPublisher.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closePublisherMenu())

  }

  closePublisherMenu() {
    this.publisherSub && this.publisherSub.unsubscribe();
    if (this.overlayRefPublisher) {
      this.overlayRefPublisher.dispose();
      this.overlayRefPublisher = null;
    }
  }

  goToCreateChecklists(e, group, checklist) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'checklists', group_id: group.id, operation_id: checklist.id} });
  }

  goToEditChecklists(group, checklist) {
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'checklists', group_id: group.id, operation_id: checklist.id} });
  }

  addGroup(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(GroupCreateComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.groupsPage = 1;
          this.getGroups(this.groupsPage)
        }
      })
    )
  }

  editGroup(e, element) {
    e.preventDefault();
    const dialogRef = this.dialog.open(GroupEditComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        group: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.groupsPage = 1;
          this.getGroups(this.groupsPage)
        }
      })
    )
  }

  goToTasks(e) {
    e.preventDefault();
    this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, order_by: "updated_desc"}});
  }

  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.groups.forEach((row: any) => {
      if (this.isTableExpanded && !row.hasOwnProperty('checklists')) {
        row.checklists = [];
        this.taskService.getOperationReminder(this.company_id, {'channel_group_id': row.id}).pipe(
          map(el => el.body)
        ).subscribe(resp => {
          this.operations.forEach(op => {
            row.checklists.push({
              type: op.name,
              count: resp.filter(x => x.operation_id == op.id).length,
              id: op.id
            })
          });
          row.isExpanded = this.isTableExpanded;
        })
      } else {
        row.isExpanded = this.isTableExpanded;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.groupsDataSub) {
      this.groupsDataSub.unsubscribe()
    }
    this.clearSubscriptions();
  }
}
