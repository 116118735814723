<div class="container centred_page">



    <div class="step">
        <div *ngIf="!!inviteData && activatedRoute.snapshot.queryParamMap.get('code')" class="invite_block">
            <div>
                <div class="user_div">
                    <ng-container *ngIf="!!user?.image; else elseTemplate">
                        <div class="user_photo" [ngStyle]="{'background': 'url(' + user?.image + ') no-repeat center / cover'}"></div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                    <span>{{user.display_name}} ({{user.email}})</span>
                </div>
                <div>
                    {{ "You are invited to work in the company" | translate }}
                </div>
            </div>
            <div class="btns_line">
                <button class="ml-1" mat-raised-button color="primary" (click)="acceptInvite()">
                    <mat-icon>check</mat-icon>
                    {{ "Accept" | translate }}
                </button>
            </div>
        </div>
        
        <div class="table_wrp" style="overflow-x: hidden; overflow-y: auto;" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <table mat-table [dataSource]="companyes" class="main_table">    
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ "Name" | translate }} </th>
                    <td mat-cell *matCellDef="let element"  > 
                        <a href="/dashboard?company_id={{element?.id}}" class="td_with_icon" style="cursor: pointer;" (click)="goToWorkspace($event, element?.id)">
                            <ng-container *ngIf="!!element?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + element?.avatarFile?.thumbnail" alt="">
                            </ng-container>
                            <ng-template #elseTemplatePartnerAvatar>
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                            </ng-template>
                            <span style="position: relative;">
                                {{element.name}}
                                <mat-icon class="verified_icon" style="color: #1877F2 !important;" *ngIf="element.is_verified" #tooltip="matTooltip"
                                matTooltip="{{companyVerificationText(element)?.verification_text}}"
                                [matTooltipPosition]="'right'">verified</mat-icon>
                            </span>
                        </a> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="Pages">
                    <th mat-header-cell *matHeaderCellDef> {{ "Pages" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="main_workspace_pages_out">
                            <div class="main_workspace_pages" [ngClass]="{'mobile_main_workspace_pages': is_mobile}">
                                <a [routerLink]="['/tasks']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Cards' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                [queryParams]="{company_id: element?.id, order_by: 'updated_desc', count: '1'}" 
                                class="link-to-page" 
                                (click)='goToCompany(element?.id, $event)'>
                                    <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                    </svg>
                                </a>

                                <a [routerLink]="['/jobs']" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Jobs' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, order_by: 'updated_desc', count: '1'}" 
                                class="link-to-page" 
                                (click)='goToJobs(element?.id, $event)'>
                                    <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a [routerLink]="['/chats']" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Chats' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, order_by: 'updated_desc', count: '1'}" 
                                class="link-to-page" 
                                (click)='goToChats($event, element?.id)'>
                                    <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.6667 0.666664H2.33333C1.41667 0.666664 0.666666 1.41666 0.666666 2.33333V17.3333L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3333V2.33333C17.3333 1.41666 16.5833 0.666664 15.6667 0.666664Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a 
                                [ngClass]="{'hide_btn': !(element | perm:'see_task_channels')}" 
                                [routerLink]="['/urls-start']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Posts' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                [queryParams]="{company_id: element?.id, count: '1', order_by: 'content_published_at_desc'}" 
                                class="link-to-page" 
                                (click)='goToUrlsStart($event, element?.id)'>
                                    <svg style="width: 18px; height: 9px" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a 
                                [ngClass]="{'hide_btn': !(element | perm:'see_task_channels')}" 
                                [routerLink]="['/urls-dashboard']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Post Dashboards' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                class="link-to-page" 
                                (click)="goToUrlsDash($event, element?.id)">   
                                    <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a 
                                [ngClass]="{'hide_btn': !(element | perm:'see_task_channels')}" 
                                [routerLink]="['/outlets-dashboard']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Outlet Dashboards' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                class="link-to-page" 
                                (click)="closeNav(workspacesSelect); goToOutletsDash($event, element?.id)">
                                    <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a (contextmenu)="openContext($event, element, '/board-list'); $event.preventDefault(); $event.stopPropagation();"
                                [routerLink]="['/board-list']" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Board by Order' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                class="link-to-page" 
                                (click)='goToBoardList($event, element?.id)'>
                                    <svg style="width: 18px; height: 14px;" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                                    </svg>
                                </a>
                                <a 
                                [routerLink]="['/board']" 
                                [routerLinkActiveOptions]="{ exact: true }"
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Board by Date' | translate}}"
                                [matTooltipPosition]="'above'"  
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                class="link-to-page board_desk_ico" 
                                (click)='goToBoard($event, element?.id)'>
                                    <svg style="width: 20px; height: 20px;" class="board" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33333C4.22876 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66667 2.5V5.83333M13.3333 2.5V5.83333" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
                                        <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="#686868"/>
                                    </svg>
                                </a>
                                <a 
                                [routerLink]="['/files']" 
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Files' | translate}}"
                                [matTooltipPosition]="'above'" 
                                class="link-to-page" 
                                (click)="goToMobPage('/files', element?.id, $event)">
                                    <svg style="width: 20px; height: 20px;" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.75 21.75V8.25C3.75 7.14543 4.64543 6.25 5.75 6.25H12.0858C12.351 6.25 12.6054 6.35536 12.7929 6.54289L15 8.75H24.25C25.3546 8.75 26.25 9.64543 26.25 10.75V21.75C26.25 22.8546 25.3546 23.75 24.25 23.75H5.75C4.64543 23.75 3.75 22.8546 3.75 21.75Z" stroke="#686868" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </a>
                                <a 
                                [routerLink]="['/projects']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Projects' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [queryParams]="{company_id: element?.id, count: '1'}" 
                                class="link-to-page" 
                                (click)="goToMobPage('/projects', element?.id, $event)">
                                    <mat-icon>movie_filter</mat-icon>
                                </a>
                                <a 
                                class="link-to-page" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Shortcuts' | translate}}"
                                [matTooltipPosition]="'above'" 
                                (click)="openMobShortcuts(element, $event)">
                                    <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"></path></svg>
                                </a>
                                <a 
                                [routerLink]="['/settings']" 
                                #tooltip="matTooltip"
                                matTooltip="{{ 'Settings' | translate}}"
                                [matTooltipPosition]="'above'" 
                                [routerLinkActiveOptions]="{ exact: true }" 
                                [queryParams]="{company_id: element?.id, count: '1', page: 'finances_dashboard'}" 
                                class="link-to-page" 
                                (click)='goToFinances($event, element?.id)'>
                                    <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3672_54608ps)">
                                        <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_3672_54608ps">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> {{ "Actions" | translate }} </th>
                    <td mat-cell *matCellDef="let element"> 
                      <button mat-icon-button [matMenuTriggerFor]="menuActions" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      
                      <mat-menu #menuActions="matMenu">
                          
                        <ng-container *ngIf="element.permissions.includes('owner')">
                            <a mat-menu-item href="'/edit/{{element.id}}" [routerLink]="['/edit', element.id]">
                              <mat-icon>edit</mat-icon> 
                              <span>{{ "Edit" | translate }}</span>
                            </a>
                            <button mat-menu-item (click)="deleteCompany(element.id, element)">
                                <mat-icon>delete</mat-icon>
                                <span>{{ "Delete" | translate }}</span>
                            </button>
                            <mat-divider></mat-divider>
                        </ng-container>
                        

                        <a [matMenuTriggerFor]="finances" href="/settings?company_id={{element?.id}}&count=1&page=finances_dashboard" mat-menu-item class="mat-menu-item has_sub_menu d-f-c" (click)='goToFinances($event, element?.id)'>
                            <mat-icon>account_balance</mat-icon>
                            <span class="underline">
                                {{ "Finances" | translate }}
                            </span>
                        </a>

                        <a class="mat-menu-item has_sub_menu d-f-c" *ngIf="element?.permissions.includes('owner') || element?.permissions.includes('admin') || element?.permissions.includes('manager')" href="/settings?company_id={{element?.id}}&page=automation" mat-menu-item [matMenuTriggerFor]="automation" (click)='goToScenarios($event, element?.id)'>
                            <mat-icon>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="black"/>
                                </svg>
                            </mat-icon>
                            <span class="underline">
                                <span>{{ "Automation" | translate }}</span> 
                            </span>
                        </a>

                        <a [matMenuTriggerFor]="members" class="mat-menu-item has_sub_menu d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=workspace_members" (click)='goToMembers($event, element?.id)'>
                            <mat-icon>badge</mat-icon>
                            <span class="underline">
                                {{ 'Workspace Members' | translate }}
                            </span>
                        </a>

                        <a [matMenuTriggerFor]="categorization" class="mat-menu-item has_sub_menu d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=categorization" (click)='goToCategorization($event, element?.id)'>
                            <mat-icon>category</mat-icon>
                            <span class="underline">
                                {{ 'Categorization' | translate }}
                            </span>
                        </a>
                        
                        <a [matMenuTriggerFor]="profiles" class="mat-menu-item has_sub_menu d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=profiles" (click)='goToProfiles($event, element?.id)'>
                            <mat-icon>public</mat-icon>
                            <span class="underline">
                                {{ 'Outlets' | translate }}
                            </span>
                        </a>
                        <a [matMenuTriggerFor]="usage" class="mat-menu-item has_sub_menu d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=usage" (click)='goToUsage($event, element?.id)'>
                            <mat-icon>data_usage</mat-icon>
                            <span class="underline">
                                {{ 'Usage' | translate }}
                            </span>
                        </a>

                        <ng-container *ngIf="!element.permissions.includes('owner')">
                            <mat-divider></mat-divider>
    
                            <button mat-menu-item (click)="leaveCompany(element)">
                                <mat-icon>domain_disabled</mat-icon>
                                <span>{{ "Leave the company" | translate }}</span>
                            </button>
                        </ng-container>
                    </mat-menu>

                    <mat-menu #profiles="matMenu" xPosition="before">
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=sftp" mat-menu-item (click)='goToSftp($event, element?.id)'>
                            <mat-icon>outbox</mat-icon>
                            <span class="underline">
                                <span>{{ "Youtube Partner CMS" | translate }}</span> 
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=manage-auth" mat-menu-item (click)='goToManageAuth($event, element?.id)'>
                            <mat-icon>manage_accounts</mat-icon>
                            <span class="underline">
                                <span>{{ "Authorizations" | translate }}</span> 
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=import-data" mat-menu-item (click)='goToImportData($event, element?.id)'>
                            <mat-icon>publish</mat-icon>
                            <span class="underline">
                                <span>{{ "Import Data" | translate }}</span> 
                            </span>
                        </a>
                    </mat-menu>

                    <mat-menu #finances="matMenu" xPosition="before">
                        <a *ngIf="element?.permissions.includes('owner') || element?.permissions.includes('admin')" class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=finances&tab=current_activity" mat-menu-item (click)='goToCurrentActivity($event, element?.id)'>
                            <mat-icon>analytics</mat-icon>
                            <span class="underline">
                                <span>{{ "Current Activity" | translate }}</span> 
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=finances&tab=statements" mat-menu-item (click)='goToStatements($event, element?.id)'>
                            <mat-icon>show_chart</mat-icon>
                            <span class="underline">
                                <span>{{ "Statements" | translate }}</span> 
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=finances&tab=payouts" mat-menu-item (click)='goToPayouts($event, element?.id)'>
                            <mat-icon>payments</mat-icon>
                            <span class="underline">
                                <span>{{ "Payouts" | translate }}</span> 
                            </span>
                        </a>
                    </mat-menu>
                    
                    <mat-menu #usage="matMenu" xPosition="before">
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=vpu_usage" mat-menu-item (click)='goToVpuUsage($event, element?.id)'>
                            <mat-icon>movie_filter</mat-icon>
                            <span class="underline">
                                {{ 'Video Processing Units (VPU)' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=total_usage" mat-menu-item (click)='goToTotalUsage($event, element?.id)'>
                            <mat-icon>perm_media</mat-icon>
                            <span class="underline">
                                {{ 'Storage usage' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=dubbing_usage" mat-menu-item (click)='goToDubbingUsage($event, element?.id)'>
                            <mat-icon>settings_voice</mat-icon>
                            <span class="underline">
                                {{ 'Dubbing' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=speech_to_text" mat-menu-item (click)='goToStuUsage($event, element?.id)'>
                            <mat-icon>record_voice_over</mat-icon>
                            <span class="underline">
                                {{ 'Speech to Text' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=text_to_speech" mat-menu-item (click)='goToTsuUsage($event, element?.id)'>
                            <mat-icon>keyboard_voice</mat-icon>
                            <span class="underline">
                                {{ 'Text to Speech' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=ai_usage" mat-menu-item (click)='goToAiUsage($event, element?.id)'>
                            <mat-icon>assistant</mat-icon>
                            <span class="underline">
                                {{ 'AI Usage' | translate }}
                            </span>
                        </a>
                    </mat-menu>

                    <mat-menu #automation="matMenu" xPosition="before">
                        <a class="mat-menu-item d-f-c" *ngIf="element?.permissions.includes('owner') || element?.permissions.includes('admin') || element?.permissions.includes('manager')" href="/settings?company_id={{element?.id}}&page=checklists" mat-menu-item (click)='goToChecklists($event, element?.id)'>
                            <mat-icon>checklist</mat-icon>
                            <span class="underline">
                                <span>{{ "Checklists" | translate }}</span> 
                            </span>
                        </a>
                    </mat-menu>

                    <mat-menu #members="matMenu" xPosition="before">

                        <a class="mat-menu-item d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=individuals" (click)='goToEmployees($event, element?.id)'>
                            <mat-icon>badge</mat-icon>
                            <span class="underline">
                                {{ 'Individuals' | translate }}
                            </span>
                        </a>

                        <a class="mat-menu-item d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=partner_workspaces" title="{{ 'Partner Workspaces' | translate }}" (click)="goToPartners($event, element?.id)">
                            <mat-icon>supervised_user_circle</mat-icon>
                            <span class="underline">{{ "Partner Workspaces" | translate }}</span>
                        </a>
                        
                        <ng-container *ngIf="element?.permissions.includes('owner') || element?.permissions.includes('admin') || element?.permissions.includes('hr'); else templateNameApplication">
                            <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=questionnaires" mat-menu-item (click)='goToQuestionnaires($event, element?.id)'>
                                <mat-icon>feed</mat-icon>
                                <span class="underline">
                                    <span>{{ "Onboarding forms" | translate }}</span> 
                                </span>
                            </a>
                            <a *ngIf="element?.permissions.includes('admin') || element?.permissions.includes('hr')" class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=form" mat-menu-item (click)='goToForm($event, element?.id)'>
                                <mat-icon>feed</mat-icon>
                                <span class="underline">
                                    <span>{{ "Form" | translate }}</span> 
                                </span>
                            </a>
                        </ng-container>
                        <ng-template #templateNameApplication>
                            <a class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=form" mat-menu-item (click)='goToForm($event, element?.id)'>
                                <mat-icon>feed</mat-icon>
                                <span class="underline">
                                    <span>{{ "Form" | translate }}</span> 
                                </span>
                            </a>
                        </ng-template>

                        <a class="nav_link mat-menu-item d-f-c users" mat-menu-item href="/settings?company_id={{element?.id}}&page=users" title="{{ 'Users' | translate }}" (click)='goToUsers($event, element?.id)'>
                            <mat-icon>people</mat-icon>
                            <span class="underline">
                                <span>{{ "Users" | translate }}</span> 
                            </span>
                        </a>
                    </mat-menu>

                    <mat-menu #categorization="matMenu" xPosition="before">
                        <a mat-menu-item class="mat-menu-item d-f-c" href="/settings?company_id={{element?.id}}&page=structure" (click)='goToStructure($event, element?.id)'>
                            <mat-icon>engineering</mat-icon>
                            <span class="underline">
                                {{ 'Company structure' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=task_types" (click)='goToTaskTypes($event, element?.id)'>
                            <mat-icon>checklist_rtl</mat-icon>
                            <span class="underline">
                                {{ 'Card Types' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=job_types" (click)='goToJobTypes($event, element?.id)'>
                            <mat-icon>checklist_rtl</mat-icon>
                            <span class="underline">
                                {{ 'Job types' | translate }}
                            </span>
                        </a>
                        <a class="mat-menu-item d-f-c" mat-menu-item href="/settings?company_id={{element?.id}}&page=company" (click)='goToGroups($event, element?.id)'>
                            <mat-icon>groups</mat-icon>
                            <span class="underline">
                                {{ 'Groups' | translate }}
                            </span>
                        </a>
                        <a href="/settings?company_id={{element?.id}}&page=bookmarks" mat-menu-item class="mat-menu-item d-f-c" (click)='goToBookmarks($event, element?.id)'>
                            <mat-icon>bookmarks</mat-icon>
                            <span class="underline">
                                {{ "Presets" | translate }}
                            </span>
                        </a>
                        <a href="/settings?company_id={{element?.id}}&page=parameters" *ngIf="element?.permissions.includes('owner') || element?.permissions.includes('admin') || element?.permissions.includes('manager')" mat-menu-item class="mat-menu-item d-f-c" (click)='goToParameters($event, element?.id)'>
                            <mat-icon>settings_suggest</mat-icon>
                            <span class="underline">
                                {{ "Tags" | translate }}
                            </span>
                        </a>
                    </mat-menu>
                    </td>
                </ng-container>
              
                <ng-container matColumnDef="groupHeader">
                    <td colspan="999" class="group_out" mat-cell *matCellDef="let groupBy"><div class="group_name">{{groupBy.initial}}</div></td>
                </ng-container>
                <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <!-- Group header -->
                <tr mat-row class="group_out_row" *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></tr>
            </table>
        </div>
        <div class="btns_line">
            <!-- <a href="/create" [routerLink]="['/create']" routerLinkActive="router-link-active" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Create_company" | translate }}
            </a> -->
            <a href="/set{{promo ? '?promo='+ promo : ''}}" class="ml-1" [routerLink]="['/set']" 
            [queryParams]="promo ? {'promo': promo} : {}" 
            routerLinkActive="router-link-active" mat-raised-button color="link">
                <mat-icon>add</mat-icon>
                {{ "Set up new Workspace" | translate }}
            </a>
        </div>
    </div>
    
</div>

<ng-template #contextMenu let-ctx>
    <section class="card-menu">
        <ng-container *ngIf="ctx.company && ctx.company.selEmployees; else elseTemplateUserIsGetting">
            <mat-form-field appearance="legacy">
                <mat-label>{{"User" | translate}}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir (selectionChange)="goToFilterPage($event, ctx.company, ctx.page)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="ctx.company.employeesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="item.id" *ngFor="let item of ctx.company.employees$ | async">
                        <app-user [class]="'user_div'" [employee]="item" [imgRoute]="imgRoute" [host]="host" [company]="ctx.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="menu_divider"></div>
        </ng-container>
        <ng-template #elseTemplateUserIsGetting>
            <div class="d-f-c" style="height: 60px;">
                <mat-spinner class="spin_16 mr-1"></mat-spinner>
                <span>{{"Getting users" | translate}}...</span>
            </div>
        </ng-template>
    </section>
</ng-template>