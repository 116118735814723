<mat-dialog-title class="dialog_title profile_dialog_title">   
    <div class="d-f-c"> 
        <span class="info" (click)="log()">
            {{ "Edit Platform Content" | translate }}
        </span>
        <div class="for_space users_select">
            <span>{{ "Outlet" | translate }}:</span>
            <mat-chip-list [multiple]="false" [selected]="false">
                <mat-chip class="chip_user" style="padding-left: 0 !important;">
                    <div class="user_div">
                        <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                        <span>{{data.profile?.channel?.name}}</span>
                    </div>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-title>
  
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">

        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="white_block">
       
                <div class="preview_block">
                    <div class="preview">
                        <div class="outlet">
                            <ng-container *ngIf="data?.profile?.channel.avatarFile; else elseTemplateNoPhoto">
                                <div class="channel_avatar">
                                    <img [src]="host + data?.profile?.channel.avatarFile.original + '?company_id=' + data?.company_id" alt="">
                                    <div class="channel_avatar_platform">
                                        <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoPhoto>      
                                <div class="platform_icon">
                                    <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                                </div>
                            </ng-template>
                            <div class="outlet_lines">
                                <div>{{data?.profile?.channel?.name}}</div>
                                <div>{{getContentById(data?.profile?.content_type_id)?.name}} - {{data?.profile?.file?.meta_duration | timeFormat}}</div>
                            </div>
                        </div>
                        <ng-container *ngIf="understandFileType(data?.profile?.file?.content_type) == 'video' || data?.profile?.file?.preview1080; else elseTemplateVidOrImg">              
                            <video width="100%" class="player" controls #player>
                                <source [src]="host + (data?.profile?.file?.preview1080 ? data?.profile?.file?.preview1080 : data?.profile?.file?.original) + '?company_id=' + data?.company_id" [type]="data?.profile?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.profile?.file?.content_type"/>
                                Your browser doesn't support HTML5 video tag.
                            </video>
                        </ng-container>
                        <ng-template #elseTemplateVidOrImg>
                            <img style="max-width: 100%; max-height: 100%; object-fit: cover; width: 100%; height: 100%;" [src]="host + (data?.profile?.file.thumbnail ? data?.profile?.file.thumbnail : data?.profile?.file?.original) + '?company_id=' + data?.company_id">
                        </ng-template>
                        
                        <div class="desc" *ngIf="data?.profile?.channel?.platform_id != 3" [innerHTML]="form?.get('content_description')?.value.replaceAll('\n','<br>') || ('Description preview' | translate)"></div>
                    </div>

                    <ng-container *ngIf="data?.profile?.channel?.platform_id != 3; else elseTemplateIsTikTok">
                        <div class="desc_and_thumb">
                            <ng-container *ngIf="[404, 402, 202, 201, 206].includes(data.profile.content_type_id); else elseTemplateHasName">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ [404, 202].includes(data.profile.content_type_id) ? 'Reel description' : 'Description' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="9" formControlName="content_description" matInput placeholder="{{ [404, 202].includes(data.profile.content_type_id) ? 'Reel description' : 'Description' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('content_description').value" [ngClass]="{'red': form.get('content_description').value.length > 999999999}" align="end">{{form.get('content_description').value.length}}</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #elseTemplateHasName>
                                <mat-form-field appearance="standard" class="full_width_form" >
                                    <mat-label>{{ 'Title' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="2" formControlName="content_name" matInput placeholder="{{ 'Title' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('content_name').value" [ngClass]="{'red': form.get('content_name').value.length > 999999999}" align="end">{{form.get('content_name').value.length}}</mat-hint>
                                </mat-form-field>
            
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Description' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="4" formControlName="content_description" matInput placeholder="{{ 'Description' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('content_description').value" [ngClass]="{'red': form.get('content_description').value.length > 999999999}" align="end">{{form.get('content_description').value.length}}</mat-hint>
                                </mat-form-field>
                            </ng-template>
    
    
                            <ng-container *ngIf="data?.profile?.channel?.platform_id == 1 && form.get('publishing_params') && form.get('publishing_params').get('privacy') && form.get('publishing_params').get('made_for_kids')">
                                <ng-container formGroupName="publishing_params">
                                    <div class="d-f-c" style="margin-top: 15px; align-items: flex-end !important;">
                                        <div class="tik_tok_wrp">
                                            <div class="tik_title">
                                                {{"Privacy status" | translate}}
                                            </div>
                                            <mat-button-toggle-group class="tik_tok_btn_group" formControlName="privacy">
                                              <mat-button-toggle [value]="'public'">{{"Public" | translate}}</mat-button-toggle>
                                              <mat-button-toggle [value]="'private'">{{"Private" | translate}}</mat-button-toggle>
                                              <mat-button-toggle [value]="'unlisted'">{{"Unlisted" | translate}}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>

                                        <div class="box_wrp ml-1" style="margin-bottom: 5px;">
                                            <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="data?.profile?.channel?.platform_id == 4 || [201, 202, 206].includes(data.profile.content_type_id)">
                                <form class="example-form">
                                    <mat-form-field class="full_width_form">
                                        <mat-label>{{"Location" | translate}}</mat-label>
                                        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                        <mat-icon matSuffix>place</mat-icon>
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
                                          <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                                            {{option.name}}
                                          </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint>{{"In case no matches enter code of location. Code you can find" | translate}} <a target="_blank" href="https://www.facebook.com/places/">{{"here" | translate}}.</a></mat-hint>
                                    </mat-form-field>
                                </form>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateIsTikTok>
                        
                        <div *ngIf="form.get('publishing_params')" class="tik_tok_out">

                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Name' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="9" formControlName="content_name" matInput placeholder="{{ 'Name' | translate }}"></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="form.get('content_name').value" [ngClass]="{'red': form.get('content_name').value.length > 999999999}" align="end">{{form.get('content_name').value.length}}</mat-hint>
                            </mat-form-field>

                            <ng-container formGroupName="publishing_params">
                                <div class="tik_tok_wrp" *ngIf="tikTok">
                                    <div class="tik_title">
                                        {{"Who can view this video" | translate}}
                                    </div>
                                    <mat-button-toggle-group class="tik_tok_btn_group" formControlName="privacy_level" aria-label="Font Style">
                                      <mat-button-toggle #tooltip="matTooltip" 
                                      matTooltip="{{ (item + '_desk') | translate }}" 
                                      [matTooltipPosition]="'above'" *ngFor="let item of tikTok.data.privacy_level_options" [value]="item">{{item | translate}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
        
                                <div class="allow_users">
                                    <div class="tik_title">
                                        {{"Allow users to" | translate}}
                                    </div>
    
                                    <div class="d-f-c">
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_duet">{{ "disable_duet" | translate }}</mat-checkbox>
                                        </p>
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_stitch">{{ "disable_stitch" | translate }}</mat-checkbox>
                                        </p>
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_comment">{{ "disable_comment" | translate }}</mat-checkbox>
                                        </p>
                                    </div>
                                </div>

                                <div class="tt_title">
                                  <span>{{ "Disclose video content" | translate }}</span>
                                  <mat-slide-toggle [hideIcon]="true" color="primary" (change)="onChangeDisc($event)"></mat-slide-toggle>
                                </div>
                                <ng-container *ngIf="isChecked">
                                    <div class="tt_p">{{ "Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both." | translate }}</div>
                                    <div class="tt_title">
                                        <span>{{ "Your brand" | translate }}</span>
                                        <mat-checkbox color="primary" formControlName="brand_organic_toggle"></mat-checkbox>
                                    </div>
                                    <div class="tt_p">{{ "You are promoting yourself or your own business. This video will be classified as Brand Organic" | translate }}</div>
                                    <div class="tt_title">
                                        <span>{{ "Branded content" | translate }}</span>
                                        <mat-checkbox color="primary" formControlName="brand_content_toggle"></mat-checkbox>
                                    </div>
                                    <div class="tt_p">{{ "You are promoting another brand or a third party. This video will be classified as Branded Content. By posting, you agree to" | translate }} <a target="_blank" href="https://www.tiktok.com/legal/page/global/bc-policy/en">{{ "TikTok's Branded Content Policy" | translate }}</a></div>
                                    <div class="tt_p">{{ "By posting, you agree to our" | translate }} <a target="_blank" href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">{{ "Music Usage Confirmation" | translate }}</a>.</div>
                                </ng-container>
                                

                            </ng-container>
                            
                        </div>
                    </ng-template>


                    
                </div>
    
            </div>  

            <div class="white_block" formGroupName="publishing_params" *ngIf="data?.profile?.content_type_id == '106'">
                <div class="white_block_title">
                    {{ "Live settings" | translate }}
                </div>
                <div class="d-f-c">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Live stream url' | translate }}</mat-label>
                        <input formControlName="live_stream_url" matInput placeholder="{{ 'Live stream url' | translate }}">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="ml-1 full_width_form">
                        <mat-label>{{ 'Live stream key' | translate }}</mat-label>
                        <input formControlName="live_stream_key" matInput placeholder="{{ 'Live stream key' | translate }}">
                    </mat-form-field>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_loop">{{ "Loop" | translate }}</mat-checkbox>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_random">{{ "Random" | translate }}</mat-checkbox>
                </div>
                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('publishing_params').get('is_random').value">
                    <mat-label>{{ 'Start streaming when files are ready (pcs)' | translate }}</mat-label>
                    <input formControlName="random_start_count" type="number" matInput placeholder="{{ 'Start streaming when files are ready (pcs)' | translate }}">
                </mat-form-field>

                <mat-divider style="margin: 15px 0;"></mat-divider>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_backup">{{ "Additional stream from backup server" | translate }}</mat-checkbox>
                </div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Live stream backup url' | translate }}</mat-label>
                    <input formControlName="live_stream_url_backup" matInput placeholder="{{ 'Live stream backup url' | translate }}">
                </mat-form-field>

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="is_api_set_up_new">{{ "Create stream in YouTube via Reports" | translate }}</mat-checkbox>
                </div>
                <div class="block_w8" *ngIf="!!form?.get('publishing_params')?.get('is_api_set_up_new')?.value">

                    <div class="label_w8">{{"Stream settings" | translate}}</div>

                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="made_for_kids">{{ "Made for kids" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="enable_auto_start">{{ "Enable auto start" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="enable_auto_stop">{{ "Enable auto stop" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="enable_dvr">{{ "Enable dvr" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="record_from_start">{{ "Enable recording" | translate }}</mat-checkbox>
                    </div>
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Files Tag(s)" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="files_parameter_values_ids">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('publishing_params').get('files_parameter_values_ids').value && form.get('publishing_params').get('files_parameter_values_ids').value.length">
                                <ng-container *ngFor="let id of form.get('publishing_params').get('files_parameter_values_ids').value">
                                    <span>{{ getValuesById(id)?.value }}</span>
                                </ng-container>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="allValuesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                            <div class="d-f-c" style="justify-content: space-between;">
                                <span>{{ item.value }}</span>
                                <span>{{ item?.parameter?.name }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>

    </div>
</mat-dialog-content>
  
<mat-dialog-actions align="end">
<div class="f_w_p_btns">
    <div class="d-f-c">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>

    <div class="d-f-c">
        <div class="content_filename" *ngIf="form && form.value && form.value.content_filename">
            {{form.value.content_filename}}
        </div>

        <div class="post_id ml-1 cp" *ngIf="data.profile" ngxClipboard [cbContent]="data.profile.id" (cbOnSuccess)="copyLink('Post ID')">
            {{data.profile.id}} 
        </div>
    </div>
</div>
</mat-dialog-actions>