import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Moment } from 'moment';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { AskTextComponent } from 'src/app/shared/global_components/ask-text/ask-text.component';
import { MY_NEW_FORMATS, PostsCleanerComponent } from 'src/app/shared/global_components/cleaners/posts-cleaner/posts-cleaner.component';
import { environment } from 'src/environments/environment';
import { ManagePostsComponent } from '../../cases/dialogs/open-task/dialogs/manage-posts/manage-posts.component';
import { AddAndEditScheduledPostComponent } from 'src/app/components/workspace-settings/add-and-edit-scheduled-post/add-and-edit-scheduled-post.component';
import { SaveFilterToBookmarkComponent } from 'src/app/shared/global_components/navigation-bar/dialogs/save-filter-to-bookmark/save-filter-to-bookmark.component';
import { StreamRestartAssistantComponent } from '../../cases/dialogs/open-task/dialogs/stream-restart-assistant/stream-restart-assistant.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { DateRange, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MobChannelsFilterComponent } from 'src/app/shared/global_components/navigation-bar/mob-filters/mob-channels-filter/mob-channels-filter.component';
@Component({
  selector: 'app-urls-start',
  templateUrl: './urls-start.component.html',
  styleUrls: ['./urls-start.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class UrlsStartComponent extends BaseClass implements OnInit, OnDestroy {

  public company_id: any;
  public dateTab: number = 0;
  public company: any;
  public activeLang: any;
  public operationsValues: any;
  public is_mobile: boolean = false;
  public profileStatuses: any;
  public imgRoute: any = '';
  public user: any = {};
  public filterCount: number = 1;
  public sortValue: string = 'content_published_at_desc';
  public host: any = environment.host;

  public platforms: any;
  public isLoadPlatforms: boolean = true;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();
  
  public groups: any;
  public groupsPagination: any;
  public groupsPage: number = 1;
  public isLoadGroups: boolean = true;
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsControl: FormControl = new FormControl();
  public groupsSub: Subscription;
  
  public bookmarks: any;
  public isLoadBooks: boolean = true;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public allContentTypes: any;
  public isLoadContentTypes: boolean = true;
  public contentTypes: any[] = [];
  public contentTypesControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public contentPage: number = 1;
  public contentPagination: any = {};

  public publishDateRange = new DateRange("", "");
  public createdDateRange = new DateRange("", "");
  public viewsDateRange = new DateRange("", "");

  public futureDateSchemes = [
    {
      name: 'Tomorrow',
      date_from: moment().add(1, 'days').startOf('day'),
      date_to: moment().add(1, 'days').endOf('day')
    },
    {
      name: 'Day After Tomorrow',
      date_from: moment().add(2, 'days').startOf('day'),
      date_to: moment().add(2, 'days').endOf('day')
    },
    {
      name: 'Next Week',
      date_from: moment().add(1, 'week').startOf('week'),
      date_to: moment().add(1, 'week').endOf('week')
    },
    {
      name: 'Next Month',
      date_from: moment().add(1, 'month').startOf('month'),
      date_to: moment().add(1, 'month').endOf('month')
    },
    {
      name: 'Next Year',
      date_from: moment().add(1, 'year').startOf('year'),
      date_to: moment().add(1, 'year').endOf('year')
    }
  ];

  public dateSchemes = [
    {
      name: 'Today',
      date_from: moment().startOf('day'),
      date_to: moment().endOf('day')
    },
    {
      name: 'Yesterday',
      date_from: moment().subtract(1, 'days').startOf('day'),
      date_to: moment().subtract(1, 'days').endOf('day')
    },
    {
      name: 'Current Week',
      date_from: moment().startOf('week'),
      date_to: moment().endOf('week')
    },
    {
      name: 'Last Week',
      date_from: moment().subtract(1, 'week').startOf('week'),
      date_to: moment().subtract(1, 'week').endOf('week')
    },
    {
      name: 'Current Month',
      date_from: moment().startOf('month'),
      date_to: moment().endOf('month')
    },
    {
      name: 'August',
      date_from: moment().subtract(1, 'month').startOf('month'),
      date_to: moment().subtract(1, 'month').endOf('month')
    },
    {
      name: 'Current Quarter',
      date_from: moment().startOf('quarter'),
      date_to: moment().endOf('quarter')
    },
    {
      name: 'Current Year',
      date_from: moment().startOf('year'),
      date_to: moment().endOf('year')
    },
    {
      name: 'Previous Year',
      date_from: moment().subtract(1, 'year').startOf('year'),
      date_to: moment().subtract(1, 'year').endOf('year')
    }
  ];

  public isLoadOutlets: boolean = true;
  public outletsSubsControl: FormControl = new FormControl('')
  public outletsPage: number = 1;
  public outletsPagination: any;
  public outlets: any;
  public outlets$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public outletsSub: Subscription;
  public searchControl: FormControl = new FormControl('')
  throttle = 100;
  scrollUpDistance = 3.5;

  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    content_status_id: [],
    no_operation_id: '',
    no_channel_id: [[]],
    views_update_interval: '',
    preset_id: '',
    scheduled_published_time_by: '',
    created_employee_id: [[]],
    updated_employee_id: [[]],
    created_url_employee_id: [[]],
    updated_url_employee_id : [[]],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: '',
    is_content_url: '',
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
    operation_partner_company_id: '',
    client_employee_id: '',
    origin_company_id: '',
    task_operation_operation_id: '',
    task_custom_id: '',
    task_status_id: '',
    external_status: '',
    content_type_id: '',
    is_content_views_count_update: '',
    is_live_stream_backup: '',
    is_bravourl_content_views_count_update: '',
    channel_id: [[]],
    group_id: [[]],
    channel_platform_id: '',
    created_at_from : '',
    created_at_to : '',
    summary_views_count_from : '',
    analytics_views_count_from : '',
    analytics_views_count_to : '',
    content_published_at_from : '',
    content_published_at_to : '',
    order_by: 'content_published_at_desc',
  });
  constructor(
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private uploadService: UploadService,
    private refreshService: RefreshService,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private _dateAdapter: DateAdapter<Moment>,
    public ls: LoadingService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.checkIsMobile();
    this.getLangData(this.company_id);
    this.getProfilesStatus();
    this.getPlatforms();
    this.getImgRoute();
    this.getUser();

    this.groupsPage = 1;
    if (this.groupsSub) {
      this.groupsSub.unsubscribe()
    }
    this.getGroupsCompany();

    this.getProfiles(this.outletsPage);

    this.contentPage = 1;
    this.contentTypes = [];
    this.contentTypes$.next(this.contentTypes.slice());
    this.getContentTypes("", this.contentPage);

    this.onRouteChange();

    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Posts") {
          this.getBookmarks()
        }
      })
    )

    this.attachSubscriptions(
      this.contentTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )
    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onFilterBySearch(resp))
    )

    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
        this.outletsPage = 1;
        if (this.outletsSub) {
          this.outletsSub.unsubscribe()
        }
        this.getProfiles(this.outletsPage, resp)
      })
    )

    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )
    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
        this.groupsPage = 1;
        if (this.groupsSub) {
          this.groupsSub.unsubscribe()
        }
        this.getGroupsCompany(resp)
      })
    )
    
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  getProfileById(id) {
    return this.outlets.find(x => x.id == id)
  }

  onDownGroups(e) {
    console.log(e?.target?.scrollTop)
    if (this.groupsPage <= this.groupsPagination['pageCount']) {
      console.log("#########onDown")
      this.getGroupsCompany();
    }
  }

  onDown(e) {
    console.log("onDown", e?.target?.scrollTop)
    if (this.outletsPagination && this.outletsPage <= this.outletsPagination['pageCount']) {
      console.log("#########onDown")
      this.getProfiles(this.outletsPage);
    }
  }

  getProfiles(page, q: string = '', platform_id:any = '1,2,3,4') {
    this.isLoadOutlets = true;

    if (this.outletsPage == 1) {
      this.outlets = [];
    }
    this.attachSubscriptions(
      this.companyService.getFilteredProfilesDyn(this.company_id, page, {q, platform_id}).pipe(
        tap(el => {
          if (page == 1) {
            this.outletsPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(el => el.body),
        switchMap(arr => {
          if (this.outletsPage == 1 && (this.filter.value.channel_id && this.filter.value.channel_id.length != 0)) {
            let ids = this.filter.value.channel_id.join(',');
            console.log("ids", ids);
            return this.companyService.getFilteredProfilesDyn(this.company_id, '1', {id: ids}).pipe(
              tap(selectedOutlets => {
                console.log("selectedOutlets", selectedOutlets)
                selectedOutlets.body.forEach(element => {
                  if (this.outlets.filter(x => x.id == element.id).length == 0) {
                    this.outlets.unshift(element)
                  }
                });
              }),
              map(() => arr)
            )
          } else {
            return of(arr)
          }
        })
      ).subscribe(resp => {
        console.log("getProfiles", resp)


        resp.forEach(element => {
          if (this.outlets.filter(x => x.id == element.id).length == 0) {
            this.outlets.push(element)
          }
        });
        
        this.outlets$.next(this.outlets.slice());

        this.isLoadOutlets = false;
        this.outletsPage = this.outletsPage + 1;
      })
    )
  }

  goToProgress() {
    this.router.navigate(['/urls-analytic'], { queryParams: {company_id: this.company_id, page: 'progress'}});
  }

  onFilterBySearch(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, resp)
  }

  onFilterByPlatforms(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, this.searchControl.value)
  }

  patchControl(control:FormControl, val) {
    control.patchValue(val)
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  setChannelMulty(e, id, key) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.filter.get(key).value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.filter.patchValue({
      [key]: arr,
    })
    // if (!this.needCst) {
    //   this.savedForm = JSON.parse(JSON.stringify(this.form.value[key]));
    // }
    // if (this.multiple) {
    //   this.savedForm = JSON.parse(JSON.stringify(this.cstForm.value[this.outletKey]));
    // }
    // this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  noChannelMulty(e, id) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.filter.get('no_channel_id').value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.filter.patchValue({
      no_channel_id: arr,
    })
    
    // if (!this.needCst) {
    //   this.noSavedForm = JSON.parse(JSON.stringify(this.form.value.no_channel_id));
    // }
    // this.showOutletBtn = true;
    // this.searchFilterSubmit();
    // jobTypeSelect.close();
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  noChannel(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.filter.patchValue({
      no_channel_id: [id],
    })
  }

  getContentTypes(platform_id, contentPage) {
    this.isLoadContentTypes = true;
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        let arr = [];

        resp.forEach(el => {
          if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
            arr.push({
              channel_platform_id: el.channel_platform_id,
              types: [el]
            })
          } else {
            arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
          }
        })

        this.contentTypes.push(...arr);
        this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        } else {
          this.isLoadContentTypes = false;
        }
      })
    )
  }
  
  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks() {
    this.isLoadBooks = true;
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Post List"}).subscribe(res => {
        this.bookmarks = res
        this.bookmarks$.next(this.bookmarks.slice())
        this.isLoadBooks = false;
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
            this.getLangData(this.company_id);
            this.groups = [];
            this.groupsPage = 1;
            this.getGroupsCompany();

            this.outletsPage = 1;
            this.getProfiles(this.outletsPage);
          }
          
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "content_published_at_desc";
          this.filter.patchValue({
            count: this.filterCount,
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('content_status_id')] : [],
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            views_update_interval: !!this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') ? +this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') : "",
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : "",
            scheduled_published_time_by: !!this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') ? this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') : "",
            created_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_employee_id')] : [],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : '',
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : '',
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : '',
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : '',
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : '',
            is_live_stream_backup: this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') == '1' || this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') == '0' ? +this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') : '',
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            external_status: !!this.activatedRoute.snapshot.queryParamMap.get('external_status') ? this.activatedRoute.snapshot.queryParamMap.get('external_status') : '',
            updated_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id')] : [],
            created_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id')] : [],
            updated_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id')] : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            is_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') : "",
            is_bravourl_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') : "",
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : "",
            created_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000) : "",
            created_at_to: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000) : "",
            summary_views_count_from: !!this.activatedRoute.snapshot.queryParamMap.get('summary_views_count_from') ? +this.activatedRoute.snapshot.queryParamMap.get('summary_views_count_from') : "",
            analytics_views_count_from: !!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from')*1000) : "",
            analytics_views_count_to: this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to')*1000) : ""),
            content_published_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from')*1000) : "",
            content_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to')*1000) : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "content_published_at_desc",
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })

          this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
          this.publishDateRange = new DateRange(this.filter.value['content_published_at_from'], this.filter.value['content_published_at_to']);
          this.viewsDateRange = new DateRange(this.filter.value['analytics_views_count_from'], this.filter.value['analytics_views_count_to']);

          console.log("INIT this.createdDateRange", this.createdDateRange);
          console.log("INIT this.publishDateRange", this.publishDateRange);
          console.log("INIT this.viewsDateRange", this.viewsDateRange);
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.companyService.company$.next(x);
                this.getBookmarks();
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }            
              }),
              map(() => val)
            )
          } else {
            return of(val)
          }
        })
      ).subscribe(resp => {
      })
    )
  }

  
  postsCleaner() {
    const dialogRef = this.dialog.open(PostsCleanerComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  onMouseEnter(e) {
    console.log("e", e)
    setTimeout(() => {
      const targetElement = e.target as HTMLElement;
      const inputElement = targetElement.querySelector('.search_inp') as HTMLInputElement;
  
      if (inputElement) {
        inputElement.focus();
      }
    },0)
  }

  goToRegularPublications(e) {
    e.preventDefault();
    this.router.navigate(['/regular-publications'], { queryParams: {company_id: this.company_id}});
  }

  addScheduledPost(val?) {
    const dialogRef = this.dialog.open(AddAndEditScheduledPostComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang,
        val: val
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addScheduledPost", result)
      })
    )
  }

  streamRestartAassistant() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(StreamRestartAssistantComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
    
      })
    )
  }
  
  managePosts() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(ManagePostsComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
    
      })
    )
  }
  
  setFilValue(e, key, val) {
    // console.log('e', e)
    // console.log('e.target', e.target.nodeName == 'MAT-PSEUDO-CHECKBOX')
    // console.log('key', key)
    // console.log('val', val)
    if (e.target.nodeName != 'MAT-PSEUDO-CHECKBOX') {
      e.preventDefault();
      e.stopPropagation();
      this.filter.patchValue({
        [key]: [val]
      })
    }
  }

  selectChange(e) {
    console.log("selectChange", e)
  }

  goToBook(book) {
    this.router.navigate(['/urls'], { queryParams: Object.assign({company_id: this.company_id, preset_id: book.id}, book.settings)});
  }
  
  changeTab(val) {
    this.dateTab = val;
  }

  toNoPublishUrls() {
    this.filter.patchValue({
      content_published_at_from: '',
      content_published_at_to: 0
    })
  }

  rangeChange(date: Moment, key, range): void {
    console.log(date);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    console.log("createdRangeChange", this.filter.value);

    this[range] = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
  }

  goToPage(page, addData?) {

    let params:any = {
      company_id: this.company_id
    };

    if (!!addData) {
      if (addData.order_by != "") {
        params = Object.assign(params, addData)
      }
    } else if (window.location.pathname == page && !!this.activatedRoute.snapshot.queryParamMap.get('order_by')) {
      params = Object.assign(params, {order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by')})
    }

    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })

    if (page) {
      if (page != '/files') {
        delete params.location_all
      } else {
        if (!params.hasOwnProperty('location_all')) {
          params.location_all = '/'
        }
        delete params.operation_status_id
      }
    } else {
      delete params.location_all
    }


    let filterData = {...this.filter.value};

    console.log("filterData", filterData)


    Object.keys(filterData).forEach(element => {
      if ((element.indexOf('_from') != -1 || element.indexOf('_to') != -1) && filterData[element] != "") {
        params[element] = (filterData[element] as Moment).format("X")
      } else if (!!filterData[element] && typeof filterData[element] === 'object') {
        if (filterData[element].length > 0) {
          params[element] = filterData[element].join(",")
        }
      } else {
        if (!!filterData[element] && filterData[element].toString() != '') {
          params[element] = filterData[element]
        }
      }
    });

    this.router.navigate([page], { queryParams: params });
  }

  log() {
    console.log('filter', this.filter.value)
    console.log('platforms', this.platforms)
    console.log('groups', this.groups)
    console.log('user', this.user)
    console.log('profileStatuses', this.profileStatuses)
    console.log('imgRoute', this.imgRoute)
  }
  
  useScheme(item) {
    console.log('useScheme dateTab', this.dateTab)
    console.log('useScheme item', item)
    if (this.dateTab == 0) {
      this.filter.patchValue({
        content_published_at_from: item.date_from,
        content_published_at_to: item.date_to
      })
      this.publishDateRange = new DateRange(this.filter.value['content_published_at_from'], this.filter.value['content_published_at_to']);
    } else if (this.dateTab = 1) {
      this.filter.patchValue({
        created_at_from: item.date_from,
        created_at_to: item.date_to
      })
      this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
    } else if (this.dateTab = 2) {
      this.filter.patchValue({
        analytics_views_count_from: item.date_from,
        analytics_views_count_to: item.date_to
      })
      this.viewsDateRange = new DateRange(this.filter.value['analytics_views_count_from'], this.filter.value['analytics_views_count_to']);
    }
  }

  getFilterDateKey() {
    if (this.dateTab == 0) {
      return 'content_published_at_'
    } else if (this.dateTab = 1) {
      return 'created_at_'
    } else if (this.dateTab = 2) {
      return 'analytics_views_count_'
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getGroupsCompany(res?) {
    this.isLoadGroups = true;
    if (this.groupsPage == 1) {
      this.groups = [];
    }

    this.groupsSub = this.companyService.getGroupsDyn(this.company_id, this.groupsPage, '20', {q: res || this.groupsControl.value}).pipe(
      tap(el => {
        this.groupsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(x => x.body),
      switchMap(arr => {
        if (this.groupsPage == 1 && (this.filter.value.group_id && this.filter.value.group_id.length != 0)) {
          let ids = this.filter.value.group_id.join(',');
          return this.companyService.getGroupsDyn(this.company_id, '1', '200', {id: ids}).pipe(
            tap(selectedGroups => {
              selectedGroups.body.forEach(element => {
                if (this.groups.filter(x => x.id == element.id).length == 0) {
                  this.groups.unshift(element)
                }
              });
            }),
            map(() => arr)
          )
        } else {
          return of(arr)
        }
        // !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
      })
    ).subscribe(res => {
      console.log("w8less test", res)
      res.forEach(element => {
        if (this.groups.filter(x => x.id == element.id).length == 0) {
          this.groups.push(element)
        }
      });
      
      console.log("w8less this.groups", this.groupsPage, this.groups)
      this.groups$.next(this.groups.slice());
      this.groupsPage++;
      this.isLoadGroups = false;
    }, error => {
      this.isLoadGroups = false;
    })
    
  }

  getPlatforms() {
    this.isLoadPlatforms = true;
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        // resp.unshift({
        //   id:	0,
        //   name: "Without platform",
        //   url: '',
        //   created_at:	0,
        //   updated_at:	0,
        // })
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
        this.isLoadPlatforms = false;
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })
  }

  getParams(page?) {
    let params:any = {
      company_id: this.company_id
    };
    
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })

    if (page) {
      if (page != '/files') {
        delete params.location_all
      } else {
        params.location_all = '/'
        delete params.operation_status_id
      }
    } else {
      delete params.location_all
    }

 
    if (this.activatedRoute.snapshot.queryParamMap.get('page')) {
      params.page = this.activatedRoute.snapshot.queryParamMap.get('page')
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      params.tab = this.activatedRoute.snapshot.queryParamMap.get('tab')
    }
    // if (page == '/settings') {
    //   params.page = 'finances_dashboard'
    // }
    let filterData = {...this.filter.value};

    if (this.activatedRoute.snapshot.queryParamMap.get('expand')) {
      filterData.expand = this.activatedRoute.snapshot.queryParamMap.get('expand')
    }
    // console.log("PARAMS TEST filter", filterData)

    Object.keys(filterData).forEach(element => {
      if ((element.indexOf('_from') != -1 || element.indexOf('_to') != -1) && !!filterData[element]) {
        params[element] = (filterData[element] as Moment).format("X")
      } else if (!!filterData[element] && typeof filterData[element] === 'object') {
        if (filterData[element].length > 0) {
          params[element] = filterData[element].join(",")
        }
      } else {
        if (!!filterData[element] && filterData[element].toString() != '') {
          params[element] = filterData[element]
        }
      }
    });
    

    // if (this.selectedPartnerForGroup) {
    //   params.groups_partner_id = this.selectedPartnerForGroup
    // }

    // if ((page == '/urls' || page == '/urls-start') && !!this.postsQ) {
    //   params.q = this.postsQ;
    //   console.log("PARAMS TEST", params)
    // }
    return params
  }

  

  saveFilterToBookmarks() {
    const dialogRef = this.dialog.open(SaveFilterToBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        filter: this.filter,
        activeLang: this.activeLang,
        params: this.getParams(),
        profileStatuses: this.profileStatuses,
        allContentTypes: this.allContentTypes,
        groups: this.groups, 
        profiles: this.outlets, 
        platforms: this.platforms, 
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          console.log("result", result)
        }
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      this.filter.patchValue({
        count: '',
        q: '',
        content_status_id: [],
        no_operation_id: '',
        no_channel_id: [],
        views_update_interval: '',
        preset_id: '',
        scheduled_published_time_by: '',
        created_employee_id: [],
        task_parameter_value_id: [],
        task_operation_parameter_value_id : [],
        discussion_parameter_value_id: [],
        file_parameter_value_id : [],
        parameter_value_id: [],
        operation_employee_id: '',
        is_content_url: '',
        task_created_at_from: "",
        task_created_at_to: "",
        task_operation_created_at_from: "",
        task_operation_created_at_to: "",
        task_operation_completed_at_from: "",
        task_operation_completed_at_to: "",
        discussion_created_at_from: "",
        discussion_created_at_to: "",
        file_created_at_from: "",
        file_created_at_to: "",
        file_updated_at_from: "",
        file_updated_at_to: "",
        file_meta_creation_time_from: "",
        file_meta_creation_time_to: "",
        task_channel_published_at_from: "",
        task_channel_published_at_to: "",
        operation_partner_company_id: '',
        is_live_stream_backup: '',
        client_employee_id: '',
        origin_company_id: '',
        task_operation_operation_id: '',
        task_custom_id: '',
        task_status_id: '',
        external_status: '',
        updated_employee_id: [],
        created_url_employee_id: [],
        updated_url_employee_id : [],
        channel_id: [],
        content_type_id: '',
        is_content_views_count_update: '',
        is_bravourl_content_views_count_update: '',
        group_id: [],
        channel_platform_id: '',
        created_at_from : '',
        created_at_to : '',
        summary_views_count_from: '',
        analytics_views_count_from : '',
        analytics_views_count_to : '',
        content_published_at_from : '',
        content_published_at_to : '',
        order_by: 'content_published_at_desc'
      })
    } else {
      if (val.indexOf('employee_id') != -1) {
        this.filter.patchValue({
          [val]: []
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }
    }

    this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
    this.publishDateRange = new DateRange(this.filter.value['content_published_at_from'], this.filter.value['content_published_at_to']);
    this.viewsDateRange = new DateRange(this.filter.value['analytics_views_count_from'], this.filter.value['analytics_views_count_to']);
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  // mob
  
  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case "/projects":
        return 'Projects';
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-analytic':
        return 'Analytic'
        break;
      case '/urls-start':
        return 'Posts Start'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/regular-publications':
        return 'Regular Publications'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }
  
  openMobChannelsFilter() {
    const dialogRef = this.dialog.open(MobChannelsFilterComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: this.company,
        filter: this.filter,
        activeLang: this.activeLang,
        title: "Outlet",
        // channelsSubsControl: this.channelsSubsControl, 
        // groupedProfiles: this.groupedProfiles,
        platforms: this.platforms,
        showTabs: this.activeSections() == "Chats",
        // onDown: ($event, control) => this.onDown($event, control),
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'apply') {
            // this.searchFilterSubmit()
          }
        }
      })
    )
  }

  trigerFil(key) {
    this.companyService.triggerMobFilter$.next(key)
  }


  ngOnDestroy(): void {
    if (this.groupsSub) {
      this.groupsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
