import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { CompanyService } from '../../services/rest/company.service';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { FileService } from '../../services/rest/file.service';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { AddEditAiSchemeComponent } from '../add-edit-ai-scheme/add-edit-ai-scheme.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ReplaySubject } from 'rxjs';
import { AiService } from '../../services/rest/ai.service';
import { ShowAnswerComponent } from '../add-edit-ai-scheme/show-answer/show-answer.component';
import { LanguageService } from '../../services/common/language.service';

@Component({
  selector: 'app-add-edit-note',
  templateUrl: './add-edit-note.component.html',
  styleUrls: ['./add-edit-note.component.scss']
})
export class AddEditNoteComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public is_scheme_editing: boolean = false;
  public no_acssess: boolean = true;
  public selectedText: any;
  public bookmarks: any;
  public languages: any;
  public company: any;
  public company_id: any;
  public target_company_id: any = this.data.target_company_id || (this.data.item && this.data.item.company_id ? this.data.item.company_id : this.data.company_id);
  @Input() initData: any;

  public ai_model: FormControl = new FormControl('gpt-4o-mini');
  public aiModelControl: FormControl = new FormControl();
  public aiAction: FormControl = new FormControl();
  public aiLang: FormControl = new FormControl();
  public aiActionName: FormControl = new FormControl();
  public aiLangName: FormControl = new FormControl();
  public models$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public models = [
    {
      name: 'gpt-3.5-turbo',
      only_root: 0,
    },
    {
      name: 'gpt-3.5-turbo-0125',
      only_root: 0,
    },
    {
      name: 'gpt-3.5-turbo-1106',
      only_root: 0,
    },
    {
      name: 'gpt-4o',
      only_root: 0,
    },
    {
      name: 'gpt-4o-mini',
      only_root: 0,
    },
    {
      name: 'gpt-4o-2024-08-06',
      only_root: 0,
    },
    {
      name: 'chatgpt-4o-latest',
      only_root: 0,
    },
    {
      name: 'gpt-4',
      only_root: 1,
    },
    {
      name: 'gpt-4-0613',
      only_root: 1,
    },
    {
      name: 'gpt-4-1106-vision-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo-2024-04-09',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-0125-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-1106-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-vision-preview',
      only_root: 1,
    },
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private aiService: AiService,
    private fileService: FileService,
    private languageService: LanguageService,
    private taskService: TaskService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditNoteComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("full_width_dialog")
    if (this.data.file && this.data.file.company_id) {
      this.company_id = this.data.file.company_id
    }
    if (this.data.company_id) {
      this.company_id = this.data.company_id
    }
    if (this.data.company && this.data.company.id) {
      this.company_id = this.data.company.id
    }

    if (this.data.item && this.data.item.company_id) {
      this.target_company_id = this.data.item.company_id
    } else {
      this.target_company_id = this.company_id
    }
    if (this.data.target_company_id) {
      this.target_company_id = this.data.target_company_id
    }

    this.models$.next(this.models.slice());

    this.attachSubscriptions(
      this.aiModelControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAiModels(resp))
    )

    this.getLangData();

    if (this.initData) {
      this.data = this.initData
    }
    
    console.log("AddEditNoteComponent", this.data);
    this.form = this.fb.group({
      company_id: this.target_company_id,
      task_id: 0,
      task_operation_id: 0,
      discussion_id: 0,
      file_id: 0,
      name: ['', Validators.required],
      text: ['', Validators.required],
    })

    if (this.data.is_edit) {
      this.form.patchValue({
        name: this.data.note.name,
        text: this.data.note.text
      })
    }

    if (!this.data.is_shared) {
      this.getCompany()
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap((resp:any) => {
          this.aiLang.patchValue(resp.active);
          this.aiLangName.patchValue(resp.active);
          this.languages = resp.languages;
        })
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  logAi() {
    console.log("form", this.form.value)
    console.log("ai_model", this.ai_model.value)
    console.log("aiLang", this.aiLang.value)
    console.log("aiAction", this.aiAction.value)
    console.log("aiLangName", this.aiLangName.value)
    console.log("aiActionName", this.aiActionName.value)
  }

  selectAiAction(control, item) {
    console.log('selectAiAction', control, item)
    control.patchValue(item)
    console.log('selectAiAction', control)
  }

  onSearchAiModels(resp) {
    if (!this.models) {
      return;
    }

    if (!resp) {
      this.models$.next(this.models.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.models$.next(
      this.models.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company ? this.data.company.id : this.company_id).subscribe(resp => {
        console.log("getCompany",resp)
        this.company = resp[0];
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager') || (!!this.data.user && this.data.note && !!this.data.note.created_user_id && this.data.user.id == this.data.note.created_user_id)) {
          this.no_acssess = false
        }
        this.getBookmarks();
      })
    )
  }

  selectAiRequest(key, actionControl, langControl) {
    this.logAi();
    console.log("selectAiRequest", this.getSchemeContent(actionControl.value, langControl.value, key))

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model.value
    }

    let content = `${this.getSchemeContent(actionControl.value, langControl.value, key)}`
    
    x.messages.push({
      role: 'user',
      content: content
    })

    console.log("x", x)

    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).subscribe(resp => {
        console.log("resp")

        const dialogRef = this.dialog.open(ShowAnswerComponent, {
          backdropClass: ['backdrop_under_header'],
          panelClass: ['show_header'],
          hasBackdrop: true,
          data: {
            company: this.company,
            company_id: this.company_id,
            answer: resp,
            request: content
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == 'accept') {
            this.form.patchValue({
              [key]: resp.replaceAll('/n', '<br>').replaceAll('"', '').replaceAll("'", "")
            })
          } else if (!!result && result.event == 'new') {
            this.form.patchValue({
              [key]: resp.replaceAll('/n', '<br>').replaceAll('"', '').replaceAll("'", "")
            })
            if (this.data.is_file) {
              this.submitFile(false)
            } else {
              this.submitForm(false)
            }
          }
        })
      })
    )
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: (this.company || this.data.company).employees[0].id, section: "Document", type: "AI"}).subscribe(res => {
        this.bookmarks = res;
        console.log("add-edit-note bookmarks", this.bookmarks);
      })
    )
  }
  
  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    const dialogRef = this.dialog.open(AddEditAiSchemeComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: ['show_header'],
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        scheme: scheme
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deleteScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Document AI scheme")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }

  createNewAiScheme(e) {
    e.preventDefault();

    console.log("createNewAiScheme")

    const dialogRef = this.dialog.open(AddEditAiSchemeComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: ['show_header'],
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }

  checkIfIsTranslate(action) {
    if (action.name && action.name.toLowerCase().indexOf('translate') != -1) {
      return true
    } else {
      return false
    }
  }

  getSchemeContent(action, lang, key) {
    if (action.settings && action.settings.devScheme) {
      let string = ``;

      action.settings.devScheme.forEach((el,i) => {
        if (el.name == 'Text') {
          if (!el.not_use) {
            string += `${el.value} `
          }
        } else if (el.name == 'Document') {
          if (this.form.value[key]) {
            string += `'${this.form.value[key]}' `
          }
        }
      });

      if (this.checkIfIsTranslate(action)) {
        string += `to '${lang}' language `
      }
      
      return string
    }
  }


  formatText(key) {
    if (key == 'createLink') {

      const from = this.selectedText.state.linkNode;
      console.log(this.selectedText);
      console.log("!!from", from);
      if (!!from) {
        let sel = window.getSelection();
        const range = document.createRange();
        var linkURL = prompt('Enter a URL:', this.selectedText.state.link ? this.selectedText.state.link : '');
        // document.getSelection().removeAllRanges();
        from.childNodes.forEach(element => {
          console.log("childNodes", element)
          // range.selectNode(element);
        });
        sel.removeAllRanges();
        range.selectNodeContents(from)
        
        sel.addRange(range);
        var sText = sel.toString();
        
        console.log('sText', sText);
        console.log('linkURL', linkURL);
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        } else {
          // let sel2 = window.getSelection();
          // let range2 = document.createRange();
          // sel2.removeAllRanges();
          // range2.selectNodeContents(from)
          // sel2.addRange(range2);
          // // sel2.removeAllRanges();
          document.execCommand('unlink', false, null);
          sel.removeAllRanges();
        }
        console.log("!!from", from);
      } else {
        var linkURL = prompt('Enter a URL:', '');
        var sText = document.getSelection().toString();
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        }
      }
      this.mouseUp()
    } else {
      document.execCommand(key, false, null);
      this.mouseUp()
    }
  }

  onPaste(e) {
    if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
      document.execCommand('insertHTML', false, text);
    }
    this.mouseUp()
  }

  // onCopy(event) {
  //   const selection = document.getSelection();
  //   if (selection) {
  //     const range = selection.getRangeAt(0);
  //     const clonedContent = range.cloneContents();
  
  //     const wrapperDiv = document.createElement('div');
  //     wrapperDiv.classList.add('on_reports_copy'); // Замените 'your-class' на нужный вам класс
  
  //     wrapperDiv.appendChild(clonedContent);
  
  //     event.clipboardData?.setData('text/html', wrapperDiv.outerHTML);
  //     event.clipboardData?.setData('text/plain', wrapperDiv.textContent || '');
      
  //     event.preventDefault();
  //   }
  // }

  onCopy(event) {
    const selection = document.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  // onPaste(event) {
  //   event.preventDefault(); // Отменить стандартное действие вставки
  
  //   const clipboardData = event.clipboardData;
  //   const pastedText = clipboardData.getData('text/plain').replaceAll("\n","<br>");
  //   let pastedHtml;
  //   if (clipboardData.getData('text/html').indexOf('<!--StartFragment-->') != -1) {
  //     pastedHtml = clipboardData.getData('text/html').split('<!--StartFragment-->')[1].split('<!--EndFragment-->')[0].replaceAll("\n","<br>");
  //   } else {
  //     pastedHtml = clipboardData.getData('text/html').replaceAll("\n","<br>");
  //   }

  //   console.log("pastedText", pastedText)
  //   console.log("pastedHtml", pastedHtml)
  
  //   if (pastedHtml) {
  //     // Создать временный контейнер для разбора HTML
  //     const tempContainer = document.createElement('div');
  //     tempContainer.innerHTML = pastedHtml;
  
  //     // Фильтровать и обработать каждый узел
  //     Array.from(tempContainer.childNodes).forEach(node => {
  //       if (node instanceof Element) {
  //         // Фильтровать разрешенные теги
  //         const allowedTags = ['ul', 'ol', 'u', 'a', 'b'];
  
  //         if (allowedTags.includes(node.tagName.toLowerCase())) {
  //           // Вставить отфильтрованный HTML
  //           // console.log("node.outerHTML", node.outerHTML)
  //           document.execCommand('insertHTML', false, node.outerHTML);
  //         } else {
  //           // Вставить как текст
  //           document.execCommand('insertText', false, node.textContent || '');
  //         }
  //       } else {
  //         // Вставить как текст
  //         document.execCommand('insertText', false, node.textContent || '');
  //       }
  //     });
  //   } else {
  //     // Если нет HTML, вставить текст как plain text
  //     document.execCommand('insertText', false, pastedText);
  //   }
  // }

  mouseUp(e?) {
    let selection = document.getSelection();
    let range = selection.getRangeAt(0);
    
    let clientRects = range.getClientRects();

    let posArr = [];
    Object.keys(clientRects).forEach(key => {
      posArr.push({x: clientRects[key].x + clientRects[key].width/2 , y: clientRects[key].y})
    });

    let pos = {
      x: Math.round(posArr.map(n => n.x).reduce((a, b) => +a + +b, 0) / posArr.length),
      y: Math.round(posArr.map(n => n.y).reduce((a, b) => +a + +b, 0) / posArr.length),
    }


    this.selectedText = {
      is_selected: selection.toString().trim().length > 0,
      position: pos,
      state: {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        insertOrderedList: document.queryCommandState('insertOrderedList'),
        insertUnorderedList: document.queryCommandState('insertUnorderedList'),
        createLink: this.isLink(),
        link: this.isLink() ? this.getLink() : "",
        linkNode: this.isLink() ? this.getLinkNode() : null
      }
    }
  }

  getLink() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.getAttribute('href')
    const endA = selection.focusNode.parentElement.getAttribute('href')
    
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA['href'] || altEndA['href']
    }
    return startA || endA
  }

  getLinkNode() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement
    const endA = selection.focusNode.parentElement
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA || altEndA
    }
    return startA || endA
  }


  // isLink () {
  //   const selection = window.getSelection();
  //   if (selection && selection.rangeCount > 0) {
  //     const range = selection.getRangeAt(0);
  //     const anchorTag = document.createElement('a');
  
  //     if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
  //       anchorTag.appendChild(range.commonAncestorContainer.parentNode?.cloneNode(true));
  //     } else {
  //       anchorTag.appendChild(range.commonAncestorContainer.cloneNode(true));
  //     }
  
  //     const isSelectionInLink = range.intersectsNode(anchorTag);
  
  //     if (isSelectionInLink) {
  //       console.log('Selection is part of an <a> tag');
  //       return true
  //     } else {
  //       console.log('Selection is not part of an <a> tag');
  //       return false
  //     }
  //   }
  // }

  isLink () {
    const selection = document.getSelection()
    const parentStartA = selection.anchorNode.parentElement.parentElement.tagName === 'A'
    const parentEndA = selection.focusNode.parentElement.parentElement.tagName === 'A'
    const startA = selection.anchorNode.parentElement.tagName === 'A'
    const endA = selection.focusNode.parentElement.tagName === 'A'
    const altStartA = selection.anchorNode.nodeName === 'A'
    const altEndA = selection.anchorNode.nodeName === 'A'
    

    console.log("isLink selection", selection);
    console.log("isLink test", parentStartA || parentEndA || startA || endA || altStartA || altEndA);
    // console.log("isLink startA", selection.anchorNode.parentElement);
    // console.log("isLink endA", selection.focusNode.parentElement);
    return parentStartA || parentEndA || startA || endA || altStartA || altEndA
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  closeBtn() {
    if (this.isFormChange) {
      this.layoutService.openBottomSheet(this.dialogRef);
    } else {
      this.close();
    }
  }

  submitFile(closeAfter:boolean = true) {
    if (!this.form.valid) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    let target:any = {};
    if (this.data.type == 'task') {
      target.task_id = this.data.item.id
      target.task_operation_id = 0
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "job") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.id;
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "chat") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.id;
      target.file_id = 0
    } else if (this.data.type == "file") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.discussion_id || 0;
      target.file_id = this.data.item.id;
    } else {
      target.task_id = this.data.task_id
      target.task_operation_id = this.data.work_id
      target.discussion_id = 0;
      target.file_id = 0;
    }

    console.log('target submitFile', target)

    if (this.data.item && this.data.item.company_id && this.data.item.company_id != this.data.company_id) {
      target.partner_company_id = this.data.item.company_id
    }

    if (this.data.is_edit && !!closeAfter) {
      this.isSubmit = true;
      let editData:any = {
        text: this.form.value.text,
        name: this.form.value.name
      }
      this.attachSubscriptions(
        this.companyService.editNote(this.data.file.document.id, editData, this.company_id, target).subscribe(resp => {
          console.log(resp);
          this.data.file.document.name = resp.name;
          this.data.file.document.text = resp.text;
          this.isFormChange = false;
          this.isSubmit = false;
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to edit'}, error, SnackBarItem)
          this.isSubmit = false;
        })
      )
    } else { 
      this.isSubmit = true;
      this.attachSubscriptions(
        this.fileService.uploadFile({
          company_id: this.target_company_id,
          task_id: this.data.task_id,
          task_operation_id: this.data.work_id,
          filename: this.form.value.name,
          location: this.data.location,
          is_document: 1
        }, this.data.company.id).pipe(
          switchMap(uplFile => {
            console.log("uplFile",uplFile)
            let fil = JSON.parse(JSON.stringify(target))
            delete fil.file_id
            delete fil.discussion_id
            return this.fileService.getFilesProgress(this.company_id, [uplFile.id], fil)
            // return this.fileService.getFilesPreview(this.company_id, [uplFile.id])
          }),
          switchMap(files => {
            console.log("SWITCH FILE", files)
            if (files && files[0]) {
              target.file_id = files[0].id
            }
            return this.companyService.editNote(files[0].document.id, {text: this.form.value.text}, this.company_id, target).pipe(tap(x => {files[0].document.text = x.text}), map(() => files[0]))
          })
        ).subscribe(resp => {
          console.log("RESP", resp)
          if (this.data.files) {{
            this.data.files.push(resp)
          }}
          if (this.data.playlist) {{
            this.data.playlist.push(resp)
          }}
          this.layoutService.showSnackBar({name: resp.document.name}, marker("Created"), SnackBarItem)
          if (closeAfter) {
            this.dialogRef.close({event: "create", data: resp})
          }
          this.isSubmit = false;
        }, error => {
          console.log("CreateDocumentComponent error", error)
          this.isSubmit = false;
          if (error && error[0] && error[0].message && error[0].message == 'The directory or file exists' && error[1]) {
            this.form.patchValue({
              filename: error[1].message
            })
            this.submitFile(closeAfter);
          }
        })
      )
    }
  }

  submitForm(closeAfter:boolean = true) {
    if (!this.form.valid ) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    let target:any = {};
    if (this.data.type == 'task') {
      target.task_id = this.data.item.id
      target.task_operation_id = 0
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "job") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.id;
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "chat") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.id;
      target.file_id = 0
    } else if (this.data.type == "file") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.discussion_id || 0;
      target.file_id = this.data.item.id;
    }

    if (this.data.item && this.data.item.company_id && this.data.item.company_id != this.data.company_id) {
      target.partner_company_id = this.data.item.company_id
    }

    if (this.data.is_edit && !!closeAfter) {
      let editData:any = {
        text: this.form.value.text,
        name: this.form.value.name
      }
      this.attachSubscriptions(
        this.companyService.editNote(this.data.note.id, editData, this.company_id, target).subscribe(resp => {
          // this.dialogRef.close({event: "edit", data: resp})
          this.data.note.name = resp.name;
          this.data.note.text = resp.text;
          this.isFormChange = false;
          this.layoutService.showSnackBar({name: 'Editing successful'}, '', SnackBarItem)
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to edit'}, error, SnackBarItem)
          // this.dialogRef.close({event: "error"})
        })
      )
    } else {
      let addData = {...this.form.value}
      if (this.data.type == "task") {
        addData.task_id = this.data.item.id;
        delete addData.task_operation_id
        delete addData.discussion_id
        delete addData.file_id
      } else if (this.data.type == "job") {
        addData.task_id = this.data.item.task_id;
        addData.task_operation_id = this.data.item.id;
        delete addData.discussion_id
        delete addData.file_id
      } else if (this.data.type == "chat") {
        addData.task_id = this.data.item.task_id;
        addData.task_operation_id = this.data.item.task_operation_id;
        addData.discussion_id = this.data.item.id;
        delete addData.file_id
      } else if (this.data.type == "file") {
        addData.task_id = this.data.item.task_id;
        addData.task_operation_id = this.data.item.task_operation_id;
        addData.discussion_id = this.data.item.discussion_id;
        addData.file_id = this.data.item.id;
      }
  
      this.attachSubscriptions(
        this.companyService.addNote(this.data.company_id, addData, target).subscribe(resp => {
          this.layoutService.showSnackBar({name: resp.name}, marker("Created"), SnackBarItem)
          if (closeAfter) {
            this.dialogRef.close({event: "add", data: resp});
          }
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to add'}, error, SnackBarItem)
          // this.dialogRef.close({event: "error"});
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
