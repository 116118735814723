<div class="file_info container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
    <div class="line">
        <div class="block">
            <div class="file">
                <div class="file_img cp" (click)="fileClick(data.file)">
                    <ng-container [ngSwitch]="understandFileType(data.file.content_type)">
                        <ng-container *ngSwitchCase="'video'">
                            <ng-container *ngIf="data.file.thumbnail; else elseTemplateThumbnail">
                                <img (error)="onImgError($event)" [src]="host + data.file.thumbnail + '?company_id=' + data?.company_id">
                            </ng-container>
                            <ng-template #elseTemplateThumbnail>
                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                    movie
                                </mat-icon>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'audio'">
                            <ng-container *ngIf="data.file.thumbnail; else elseTemplateThumbnailAudio">
                                <img (error)="onImgError($event)" [src]="host + data.file.thumbnail + '?company_id=' + data?.company_id">
                            </ng-container>
                            <ng-template #elseTemplateThumbnailAudio>
                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                    music_note
                                </mat-icon>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'image'">
                            <img (error)="onImgError($event)" [src]="host + (data.file.thumbnail ? data.file.thumbnail : data.file.original) + '?company_id=' + data?.company_id">
                        </ng-container>
                        <ng-container *ngSwitchCase="'pdf'">
                            <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                viewBox="0 0 303.188 303.188" xml:space="preserve">
                                <g>
                                    <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                    <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                        c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                        c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                        c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                        c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                        c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                        c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                        c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                        c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                        c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                        c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                        C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                        c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                        c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                        c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                        c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                        c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                        c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                        c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                        c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                        M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                        c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                        c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                    <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                    <g>
                                        <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                            v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                            M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                            h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                        <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                            h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                            c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                            C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                        <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                            L196.579,273.871L196.579,273.871z"/>
                                    </g>
                                    <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0"/>
                                </g>
                            </svg>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                <img (error)="onImgError($event)" [src]="host + data.file.thumbnail + '?company_id=' + data?.company_id">
                            </ng-container>
                            <ng-template #elseTemplateHasThumb>
                                <ng-container *ngIf="data.file.content_type == 'reports-json-auto-post'; else elseTemplateForPost">
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        file_present
                                    </mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateForPost>      
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        insert_drive_file
                                    </mat-icon>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="ml-1 cp name text_one_line" ngxClipboard [cbContent]="data?.file?.filename" (click)="$event.preventDefault(); $event.stopPropagation();" (cbOnSuccess)="copyData('name')">{{data?.file.filename}}</div>
            </div>

            <div class="title_and_val">
                <div class="title">{{ "File ID" | translate }}</div>
                <div class="val">
                    <span class="cp" ngxClipboard [cbContent]="data?.file?.id" (click)="$event.preventDefault(); $event.stopPropagation();" (cbOnSuccess)="copyData('id')">{{data?.file.id}}</span>
                    <span class="circle_point"></span>
                    <span class="cp" style="color: #888888 !important;" ngxClipboard [cbContent]="data?.file?.original_file_id" (click)="$event.preventDefault(); $event.stopPropagation();" (cbOnSuccess)="copyData('original id')">{{data?.file?.original_file_id}}</span>
                </div>
            </div>
            <div class="hor_split"></div>
            
            <div class="vals">
                <div class="title_and_val">
                    <div class="title">{{ "Size" | translate }}</div>
                    <div class="val">
                        {{data?.file.filesize | filesize}}
                    </div>
                </div>
                <div class="title_and_val" *ngIf="['image', 'video'].includes(understandFileType(data?.file?.content_type)) || ['image', 'video'].includes(mimeTypes.getType(data?.file.name))">
                    <div class="title">{{ "Resolution" | translate }}</div>
                    <div class="val">
                        {{data?.file?.meta_width + "px &#10005; " + data?.file?.meta_height + "px"}}
                    </div>
                </div>
            </div>

            <div class="hor_split"></div>
            
            <div class="vals">
                <div class="title_and_val" *ngIf="['video', 'audio'].includes(understandFileType(data?.file?.content_type)) || ['video', 'audio'].includes(mimeTypes.getType(data?.file.name))">
                    <div class="title">{{ "Duration" | translate }}</div>
                    <div class="val">
                        {{data?.file?.meta_duration | timeFormat}}
                    </div>
                </div>
                <div class="title_and_val" *ngIf="parsedMeta && parsedMeta.frame_rate">
                    <div class="title">{{ "Frame Rate" | translate }}</div>
                    <div class="val">
                        {{parsedMeta.frame_rate}} fps
                    </div>
                </div>
                <div class="title_and_val" *ngIf="parsedMeta && parsedMeta.frame_rate">
                    <div class="title">{{ "Bit Rate" | translate }}</div>
                    <div class="val">
                        {{(parsedMeta.bit_rate/1000000) | number:'1.0-0'}} Mbs
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <div class="block_title">{{"Uploader Info" | translate}}</div>
            <ng-container *ngIf="data?.file?.userIp">
                <div class="title_and_val">
                    <div class="title">{{ "IP address" | translate }}</div>
                    <div class="val">
                        {{ data?.file?.userIp }}
                    </div>
                </div>
                <div class="hor_split"></div>
            </ng-container>
            
            <div class="vals">
                <div class="title_and_val" *ngIf="data?.file?.user_geo_country">
                    <div class="title">{{ "User geo country" | translate }}</div>
                    <div class="val d-f-c">
                        <img 
                        class="flag_cdn" 
                        src="https://flagcdn.com/w40/{{ data?.file?.user_geo_country | lowercase }}.png" 
                        [alt]="data?.file?.user_geo_country + ' Flag'" 
                        width="24">
                        {{ data?.file?.user_geo_country }}
                    </div>
                </div>
                <div class="title_and_val" *ngIf="data?.file?.user_upload_speed_mbit">
                    <div class="title">{{ "Upload speed" | translate }}</div>
                    <div class="val">
                        {{ data?.file?.user_upload_speed_mbit }}MB
                    </div>
                </div>
            </div>
            <ng-container *ngIf="data?.file?.createdEmployee">
                <div class="hor_split"></div>
                <div class="title_and_val" >
                    <div class="title">{{ "User profile" | translate }}</div>
                    <div class="val">
                        <div class="file_creator d-f-c">
                            <div class="file_creator_img">
                                <ng-container *ngIf="data?.file?.createdEmployee.image; else elseTemplateUserAvatar">
                                    <img [src]="data?.file?.createdEmployee?.image" alt="">
                                </ng-container>
                                <ng-template #elseTemplateUserAvatar>
                                    <mat-icon>account_circle</mat-icon>
                                </ng-template>
                            </div>
                            <div class="file_creator_name ml-1 text_one_line">{{data?.file?.createdEmployee.name}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
     
        </div>
        <div class="block">
            <div class="block_title">{{"Metadata" | translate}}</div>
            <div class="title_and_val" *ngIf="data?.file?.user_geo_country">
                <div class="title">{{ "Type" | translate }}</div>
                <div class="val">
                    <div class="d-f-c">
                        <div class="file_img">
                            <ng-container [ngSwitch]="understandFileType(data.file.content_type)">
                                <ng-container *ngSwitchCase="'video'">
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        movie
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'audio'">
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        music_note
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'image'">
                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                        image
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'pdf'">
                                    <div class="pdf">
                                        <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                            viewBox="0 0 303.188 303.188" xml:space="preserve">
                                            <g>
                                                <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                    c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                    c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                    c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                    c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                    c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                    c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                    c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                    c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                    c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                    c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                    C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                    c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                    c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                    c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                    c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                    c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                    c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                    c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                    c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                    c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                    c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                <g>
                                                    <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                        v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                        M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                        h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                    <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                        h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                        c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                        C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                    <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                        L196.579,273.871L196.579,273.871z"/>
                                                </g>
                                                <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0"/>
                                            </g>
                                        </svg>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <ng-container *ngIf="data.file.content_type == 'reports-json-auto-post'; else elseTemplateForPost">
                                        <mat-icon class="file_img_icon" style="color: #3f51b5">
                                            file_present
                                        </mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateForPost>   
                                        <ng-container *ngIf="!data.file.content_type; else elseTemplateFolderType">
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                folder
                                            </mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateFolderType>      
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                insert_drive_file
                                            </mat-icon>
                                        </ng-template>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </div>
                        <span class="ml-1">{{data?.file.content_type ? data?.file.content_type : 'Folder'}}</span>
                    </div>
                    
                </div>
            </div>
            <div class="hor_split"></div>
            <div class="title_and_val flex" *ngIf="data?.file.meta_device">
                <div class="title">{{ "Device" | translate }}</div>
                <div class="val">
                    {{ data?.file.meta_device }}
                </div>
            </div>
            <div class="title_and_val flex" *ngIf="data?.file.meta_device">
                <div class="title">{{ "Coordinates" | translate }}</div>
                <div class="val small">
                    {{ data?.file.meta_latitude + '° N, ' + data?.file.meta_longitude + '° E'}}

                    <a class="ml-1" target="_blank" [href]="'https://www.google.com/maps/search/?api=1&query=' + data?.file.meta_latitude + ',' + data?.file.meta_longitude">
                        {{'Open on map' | translate}}
                    </a>
                </div>
            </div>
            <div class="title_and_val flex" *ngIf="data?.file.created_at">
                <div class="title">{{ "Added at" | translate }}</div>
                <div class="val">
                    {{ data?.file?.created_at*1000 | date:'MMM d y, hh:mm a':'':activeLang}}
                </div>
            </div>
            <div class="title_and_val flex" *ngIf="data?.file.updated_at">
                <div class="title">{{ "Updated at" | translate }}</div>
                <div class="val">
                    {{ data?.file?.updated_at*1000 | date:'MMM d y, hh:mm a':'':activeLang}}
                </div>
            </div>
            <div class="title_and_val flex" style="align-items: flex-start;" *ngIf="data?.file?.meta_creation_time">
                <div class="title">{{ "Created" | translate }}</div>
                <div class="val">
                    <div>
                        {{ data?.file?.meta_creation_time*1000 | date:'MMM d y, hh:mm a':'':activeLang}}
                    </div>

                    <div class="val small abs">
                        {{ data?.file?.meta_creation_time*1000 | date:'MMM d y, hh:mm a':convertSecondstoTime(data?.file?.meta_creation_time_offset):activeLang}} 
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 0.5C3.97471 0.5 3.45457 0.603463 2.96927 0.804482C2.48396 1.0055 2.04301 1.30014 1.67157 1.67157C0.921427 2.42172 0.5 3.43913 0.5 4.5C0.5 5.56087 0.921427 6.57828 1.67157 7.32843C2.04301 7.69986 2.48396 7.9945 2.96927 8.19552C3.45457 8.39654 3.97471 8.5 4.5 8.5C5.56087 8.5 6.57828 8.07857 7.32843 7.32843C8.07857 6.57828 8.5 5.56087 8.5 4.5C8.5 3.97471 8.39654 3.45457 8.19552 2.96927C7.9945 2.48396 7.69986 2.04301 7.32843 1.67157C6.95699 1.30014 6.51604 1.0055 6.03073 0.804482C5.54543 0.603463 5.02529 0.5 4.5 0.5ZM6.18 6.18L4.1 4.9V2.5H4.7V4.58L6.5 5.66L6.18 6.18Z" fill="#ADB2B6"/>
                        </svg>
                        <span style="color: #75787B;">
                            UTC                            
                        </span>
                    </div>
                </div>
            </div>
            <div class="title_and_val flex" style="align-items: flex-start;" *ngIf="data?.file?.meta_creation_time">
                <div class="title">{{ "Uploaded" | translate }}</div>
                <div class="val">
                    <div>
                        {{ data?.file.created_at*1000 | date:'MMM d y, hh:mm a':'':activeLang }}
                    </div>

                    <div class="val small abs">
                        <span class="mr-1">{{"Updated" | translate}}</span>
                        {{ data?.file.updated_at*1000 | date:'MMM d y, hh:mm a':'':activeLang }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="block" style="margin-top: 20px;">
        <div class="block_title big d-f-c" style="justify-content: space-between;">
            {{"File Location" | translate}}
            <div class="copies" (click)="openSameFiles()" *ngIf="data?.file?.countSameFiles > 0">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9.5C17 13.6423 13.6423 17 9.5 17C5.35775 17 2 13.6423 2 9.5C2 5.35775 5.35775 2 9.5 2C13.6423 2 17 5.35775 17 9.5ZM9.5 15.5C11.0913 15.5 12.6174 14.8679 13.7426 13.7426C14.8679 12.6174 15.5 11.0913 15.5 9.5C15.5 7.9087 14.8679 6.38258 13.7426 5.25736C12.6174 4.13214 11.0913 3.5 9.5 3.5C7.9087 3.5 6.38258 4.13214 5.25736 5.25736C4.13214 6.38258 3.5 7.9087 3.5 9.5C3.5 11.0913 4.13214 12.6174 5.25736 13.7426C6.38258 14.8679 7.9087 15.5 9.5 15.5Z" fill="#848F9A" stroke="white" stroke-width="0.3"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 11C9.30109 11 9.11032 10.921 8.96967 10.7803C8.82902 10.6397 8.75 10.4489 8.75 10.25V6.5C8.75 6.30109 8.82902 6.11032 8.96967 5.96967C9.11032 5.82902 9.30109 5.75 9.5 5.75C9.69891 5.75 9.88968 5.82902 10.0303 5.96967C10.171 6.11032 10.25 6.30109 10.25 6.5V10.25C10.25 10.4489 10.171 10.6397 10.0303 10.7803C9.88968 10.921 9.69891 11 9.5 11Z" fill="#848F9A" stroke="white" stroke-width="0.3"/>
                    <path d="M8.75 12.5C8.75 12.3011 8.82902 12.1103 8.96967 11.9697C9.11032 11.829 9.30109 11.75 9.5 11.75C9.69891 11.75 9.88968 11.829 10.0303 11.9697C10.171 12.1103 10.25 12.3011 10.25 12.5C10.25 12.6989 10.171 12.8897 10.0303 13.0303C9.88968 13.171 9.69891 13.25 9.5 13.25C9.30109 13.25 9.11032 13.171 8.96967 13.0303C8.82902 12.8897 8.75 12.6989 8.75 12.5Z" fill="#848F9A" stroke="white" stroke-width="0.3"/>
                </svg>

                {{ "This file has" | translate }} {{data?.file?.countSameFiles}} {{ "copies" | translate }}
            </div>
        </div>

        <div class="locations">
            <div class="location">
                <div class="group" *ngIf="data?.task?.group">{{data?.task?.group?.name}}</div>
                <div class="name">{{data?.task?.name}}</div>
            </div>
            <div class="location" *ngIf="findWork(data?.file.task_operation_id)">
                <svg style="width: 15px; height: 15px" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.527344 0.0351562V13.0352C0.527344 13.5874 0.975059 14.0352 1.52734 14.0352H14.5273" stroke="#D1D6DB"/>
                </svg>
                <div class="type">{{getColumnName(findWork(data?.file.task_operation_id)?.operation_id)}}</div>
                <div class="tag" *ngIf="getPrimaryTag(findWork(data?.file.task_operation_id))">{{getPrimaryTag(findWork(data?.file.task_operation_id))?.parameterValue?.value}}</div>
                <div class="job_name">{{findWork(data?.file.task_operation_id).name}}</div>
            </div>
            <div class="location" [ngStyle]="{'padding-left': findWork(data?.file.task_operation_id) ? '20px' : '0'}">
                <svg style="width: 16px; height: 18px" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.8809 14.3887C15.0762 14.1934 15.0762 13.8769 14.8809 13.6816L11.6989 10.4996C11.5037 10.3044 11.1871 10.3044 10.9918 10.4996C10.7965 10.6949 10.7965 11.0115 10.9918 11.2067L13.8202 14.0352L10.9918 16.8636C10.7965 17.0588 10.7965 17.3754 10.9918 17.5707C11.1871 17.766 11.5037 17.766 11.6989 17.5707L14.8809 14.3887ZM0.0273438 0.0351562V13.0352H1.02734V0.0351562H0.0273438ZM1.52734 14.5352H14.5273V13.5352H1.52734V14.5352ZM0.0273438 13.0352C0.0273438 13.8636 0.698916 14.5352 1.52734 14.5352V13.5352C1.2512 13.5352 1.02734 13.3113 1.02734 13.0352H0.0273438Z" fill="#D1D6DB"/>
                </svg>
                <div class="path">{{data?.file.location}}</div>
            </div>
            <!-- <div style="margin-left: 15px;" class="d-f-c cp" (click)="openTask(data.file)">
                <span class="title">
                    {{ "Task" | translate }}:
                </span>
                <span class="ml-1">                            
                    <div class="task_ids_wrp"> 
                        {{ data?.task?.name.length > 25 ? (data?.task?.name | slice:0:25) + " ..." : data?.task?.name }}
                        <div class="task_ids">
                            {{data?.task?.custom_id}} 
                            <span>
                                <div>{{data?.task?.internal_id}}</div>
                                <div>{{data?.task?.id}}</div>
                            </span>
                        </div>
                    </div>
                </span>
            </div>
            <div style="margin-left: 15px;" *ngIf="data?.file.task_operation_id" class="d-f-c cp" (click)="openJob(data.file)">
                <span class="title">
                    {{ "Work" | translate }}:
                </span>
                <span class="ml-1 job">
                    <ng-container *ngIf="!!findWork(data?.file.task_operation_id)?.employee?.image; else elseTemplate">
                        <img [src]="findWork(data?.file.task_operation_id)?.employee?.image" alt="">
                    </ng-container>
                    <ng-template #elseTemplate>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                    {{ getColumnName(findWork(data?.file.task_operation_id)?.operation_id) }}
                    <span class="ml-1" [ngClass]="{
                        'first': findWork(data?.file.task_operation_id)?.status_id == '1',
                        'second': findWork(data?.file.task_operation_id)?.status_id == '2',
                        'third': findWork(data?.file.task_operation_id)?.status_id == '3',
                        'fours': findWork(data?.file.task_operation_id)?.status_id == '4',
                        'fives': findWork(data?.file.task_operation_id)?.status_id == '5',
                        'sixs': findWork(data?.file.task_operation_id)?.status_id == '6'}">
                    {{ getOperationStatusName(findWork(data?.file.task_operation_id)?.status_id) | translate }}
                    </span>
                </span>
            </div> -->
        </div>



    </div>

    <div class="published_line">
        <!-- <div class="block">
            <div class="block_title">{{"Published" | translate}}</div>
            <div class="posts" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <ng-container *ngFor="let item of data?.file?.fileOriginalData?.allChannels?.task_channels">
                    <ng-container *ngIf="!item.taskChannel; else elseTemplateLoadPost">
                        <p-skeleton height="66px" borderRadius="6px"></p-skeleton>
                    </ng-container>
                    <ng-template #elseTemplateLoadPost>
                        <div class="post" >
                             <div class="d-f-c">
                                 <div class="post_img">
                                    <img [src]="item?.taskChannel?.content_image" alt="">
                                 </div>
                                 <div class="post_info">
                                    <div class="d-f-c">
                                        <div class="post_name text_one_line" style="width: calc(100% - 24px);">{{item?.taskChannel?.content_name}}</div>
                                        <a *ngIf="item?.taskChannel?.content_url" class="ico" [href]="item?.taskChannel?.content_url" target="_blank"><mat-icon style="width:18px; height:18px;font-size:18px">open_in_new</mat-icon></a> 
                                    </div>
                                    <div class="d-f-c">
                                        <div class="el">{{getContentTypeById(item?.taskChannel?.content_type_id)?.name}}</div>
                                        <div class="el outlet_in">
                                            <div class="channel" [ngClass]="{
                                                'blue_name': !!item?.taskChannel?.content_url,
                                                'silver_name': !item?.taskChannel?.content_url}">
                                                <app-platform [platform_id]="item?.taskChannel?.channel?.platform_id"></app-platform>
                                            </div>
                                            
                                            <div class="profile_name">
                                                {{item?.taskChannel?.channel?.name}}
                                            </div>
                                        </div>
                                        <div class="el">
                                            <span class="stat" #tooltip="matTooltip" matTooltip="{{ item?.taskChannel?.content_status_id ? item?.taskChannel?.publishing_error : '' }}" [matTooltipPosition]="'above'" [ngClass]="{
                                                'stat_1': item?.taskChannel?.content_status_id == 1,
                                                'stat_2': item?.taskChannel?.content_status_id == 2,
                                                'stat_3': item?.taskChannel?.content_status_id == 3,
                                                'stat_4': item?.taskChannel?.content_status_id == 4,
                                                'stat_5': item?.taskChannel?.content_status_id == 5
                                            }">
                                            {{ getStatusById(item?.taskChannel?.content_status_id)?.name+'_profile' | translate }}
                                            <mat-icon *ngIf="item?.taskChannel?.content_status_id == 8" class="upload_error_icon">warning</mat-icon>
                                            </span>
                            
                                            <ng-container *ngIf="item?.taskChannel?.content_published_at > today && item?.taskChannel?.content_published_at < todayEnd; else publishedAtTempl">
                                              <span class="date" #tooltip="matTooltip"
                                                matTooltip="{{ item?.taskChannel?.content_published_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                                [matTooltipPosition]="'above'">
                                                {{ item?.taskChannel?.content_published_at*1000 | date:'hh:mm a':'':activeLang }}
                                              </span>
                                            </ng-container>
                                            <ng-template #publishedAtTempl>
                                              <span class="date" *ngIf="item?.taskChannel?.content_published_at && item?.taskChannel?.content_published_at > 30000" #tooltip="matTooltip"
                                                matTooltip="{{ item?.taskChannel?.content_published_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                                [matTooltipPosition]="'above'">
                                                {{ item?.taskChannel?.content_published_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                              </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                 </div>
                             </div>
                             <div class="post_stat">
                                <div class="views" *ngIf="item?.taskChannel?.content_views_count && item?.taskChannel?.content_views_count > 0">
                                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                    </svg>
                                    {{item?.taskChannel?.content_views_count | shortNumber }}
                                </div>
                             </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div> -->

        
        <div class="block">
            <div class="block_title">{{"Posts" | translate}}</div>
            <app-platforms-file *ngIf="data.company | perm:'see_task_channels'" [addClass]="'big_platforms'" [company]="data.company" [profileStatuses]="profileStatuses" [company_id]="data.company_id" [file]="data.file"></app-platforms-file>
        </div>
    

        <div class="block" style="display: flex; flex-direction: column;">
            <div class="block_title" (click)="log()">{{"Views Chart" | translate}}</div>

            <div class="chart_out" *ngIf="isDoneChart">
                <ngx-charts-pie-chart
                    [view]="view"
                    [scheme]="platformsColors"
                    [results]="platformsChart"
                    [animations]="true"
                    [gradient]="false"
                    [legend]="false"
                    [labels]="true"
                    [doughnut]="true"
                    >
                    <!-- [labelFormatting]="customLabelFormatting" -->
                </ngx-charts-pie-chart>
                <div class="total_chart">
                    <div class="total_views">{{totalViews | shortNumber}}</div>
                    <div>{{"Total Views" | translate}}</div>
                </div>
            </div>
            
        </div>

        <div class="connections block" *ngIf="(part_of && part_of.length) || (related && related.length) || (consist_of && consist_of.length)">
            <div *ngIf="part_of && part_of.length" class="connection">
                <div class="title">
                    <div class="count">
                        {{part_of.length}}
                    </div>
                    {{ "Part Of" | translate }}
                </div>
                <div class="items_wrp">
                    <div class="item" *ngFor="let item of part_of">
                        <div class="item_img" (click)="fileClick(item.file)">
                            <img [src]="host + item?.file?.thumbnail + '?company_id=' + data?.company_id">
                        </div>
                        <div class="item_info">
                            <div class="item_name text_one_line" (click)="fileClick(item.file)">{{item?.file?.filename}}</div>
                            <div class="item_location text_one_line" (click)="openFolder(item.file)">{{item?.file?.location}}</div>
                        </div>
                        <ng-container *ngIf="item?.file">
                            <app-platforms-file *ngIf="data.company | perm:'see_task_channels'" [company]="data.company" [profileStatuses]="profileStatuses" [company_id]="data.company_id" [file]="item?.file"></app-platforms-file>
                        </ng-container>
                    </div>
                </div>
            </div>
            
            <div *ngIf="related && related.length" class="connection">
                <div class="title" (click)="relatedOpen = !relatedOpen">
                    <div class="count">
                        {{related.length}}
                    </div>
                    {{ "Related To" | translate }}
                    <div class="expander">
                        <mat-icon *ngIf="!relatedOpen">expand_more</mat-icon>
                        <mat-icon *ngIf="!!relatedOpen">expand_less</mat-icon>
                    </div>
                </div>
                <div class="items_wrp" *ngIf="relatedOpen">
                    <div class="item" *ngFor="let item of related">
                        <div class="item_img" (click)="fileClick(item.relatedFile)">
                            <img [src]="host + item?.relatedFile?.thumbnail + '?company_id=' + data?.company_id">
                        </div>
                        <div class="item_info">
                            <div class="item_name text_one_line" (click)="fileClick(item.relatedFile)">{{item?.relatedFile?.filename}}</div>
                            <div class="item_location text_one_line" (click)="openFolder(item.relatedFile)">{{item?.relatedFile?.location}}</div>
                        </div>
                        <ng-container *ngIf="item?.relatedFile">
                            <app-platforms-file *ngIf="data.company | perm:'see_task_channels'" [company]="data.company" [profileStatuses]="profileStatuses" [company_id]="data.company_id" [file]="item?.relatedFile"></app-platforms-file>
                        </ng-container>
                    </div>
                </div>
            </div>
    
            <div *ngIf="consist_of && consist_of.length" class="connection">
                <div class="title" (click)="consistOpen = !consistOpen">
                    <div class="count">
                        {{consist_of.length}}
                    </div>
                    {{ "Consist Of" | translate }}
                    <div class="expander">
                        <mat-icon *ngIf="!consistOpen">expand_more</mat-icon>
                        <mat-icon *ngIf="!!consistOpen">expand_less</mat-icon>
                    </div>
                </div>
                <div class="items_wrp" *ngIf="consistOpen">
                    <div class="item" *ngFor="let item of consist_of">
                        <div class="item_img" (click)="fileClick(item.consistOfFile)">
                            <img [src]="host + item?.consistOfFile?.thumbnail + '?company_id=' + data?.company_id">
                        </div>
                        <div class="item_info">
                            <div class="item_name text_one_line" (click)="fileClick(item.consistOfFile)">{{item?.consistOfFile?.filename}}</div>
                            <div class="item_location text_one_line" (click)="openFolder(item.consistOfFile)">{{item?.consistOfFile?.location}}</div>
                        </div>
                        <ng-container *ngIf="item?.consistOfFile">
                            <app-platforms-file *ngIf="data.company | perm:'see_task_channels'" [company]="data.company" [profileStatuses]="profileStatuses" [company_id]="data.company_id" [file]="item?.consistOfFile"></app-platforms-file>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="block visual">
            <!-- <ng-container *ngIf="data?.file.is_dir == 0 && understandFileType(data?.file.content_type) == 'video' && (!data?.file.thumbnail || !data?.file.preview1080)">
            </ng-container> -->
            <div class="block">
                <div class="block_title">{{"File Processing info" | translate}}</div>
                
                <ng-container *ngIf="data?.file?.fileMetaUpdateQueue; else elseTemplateHesMetaQue">
                    <ng-container *ngIf="data?.file.fileMetaUpdateQueue.is_error == 1; else elseTemplateStatus">
                        <div class="title_and_val">
                            <div class="title">{{ "Status" | translate }}</div>
                            <div class="val" #tooltip="matTooltip" matTooltip="{{ data?.file.fileMetaUpdateQueue.processing_error }}" [matTooltipPosition]="'above'">
                                {{"Error" | translate}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateStatus>
                        <div class="title_and_val">
                            <div class="title">{{ "Status" | translate }}</div>
                            <div class="val">
                                {{ (!!data?.file?.fileMetaUpdateQueue?.is_done ? "Ready" : "Processing") | translate}}
                            </div>
                        </div>
                    </ng-template>
    
                    <div class="title_and_val">
                        <div class="title">{{ "Priority" | translate }}</div>
                        <div class="val">
                            <ng-container [ngSwitch]="data?.file?.fileMetaUpdateQueue?.waiting_time_limit">
                                <ng-container *ngSwitchCase="1">
                                    {{ "Now" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="300">
                                    {{ "Immediately" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="1200">
                                    {{ "Urgent" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="14400">
                                    {{ "High" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="43200">
                                    {{ "Middle" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="86400">
                                    {{ "Low" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="172800">
                                    {{ "No priority" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ data?.file?.fileMetaUpdateQueue?.waiting_time_limit }}
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
    
                    <div class="title_and_val">
                        <div class="title">{{ "Processing Created" | translate }}</div>
                        <div class="val">
                            {{ data?.file?.fileMetaUpdateQueue?.created_at*1000 | date:'MMM d y, hh:mm a':'':activeLang }}
                        </div>
                    </div>
    
                    <div class="title_and_val">
                        <div class="title">{{ "Processing Updated" | translate }}</div>
                        <div class="val">
                            {{ data?.file?.fileMetaUpdateQueue?.updated_at*1000 | date:'MMM d y, hh:mm a':'':activeLang }}
                        </div>
                    </div>
        
                    <div class="d-f-c" style="margin-top: 10px;">
                        <div class="process_btn cp" (click)="startProcessing(data?.file)">
                            <mat-icon class="mr-1">fast_forward</mat-icon>
                            {{ 'Change processing priority' | translate }}
                        </div>
        
                        <div class="cut_value ml-1">
                            <div (click)="priority.open()">
                                <span>
                                    {{ "Priority" | translate }}:
                                </span>
                                <b class="ml-1">
                                    <ng-container [ngSwitch]="waiting_time_limit.value">
                                        <ng-container *ngSwitchCase="1">
                                            {{ "Now" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="300">
                                            {{ "Immediately" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="1200">
                                            {{ "Urgent" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="14400">
                                            {{ "High" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="43200">
                                            {{ "Middle" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="86400">
                                            {{ "Low" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="172800">
                                            {{ "No priority" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{ waiting_time_limit.value }}
                                        </ng-container>
                                    </ng-container>
                                </b>
                            </div>
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select disableOptionCentering #priority [formControl]="waiting_time_limit">
                                    <mat-option value="1" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1}">
                                        {{ "Now" | translate }}
                                    </mat-option>
                                    <mat-option value="300" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 300}">
                                        {{ "Immediately" | translate }}
                                    </mat-option>
                                    <mat-option value="1200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1200}">
                                        {{ "Urgent" | translate }}
                                    </mat-option>
                                    <mat-option value="14400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 14400}">
                                        {{ "High" | translate }}
                                    </mat-option>
                                    <mat-option value="43200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 43200}">
                                        {{ "Middle" | translate }}
                                    </mat-option>
                                    <mat-option value="86400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 86400}">
                                        {{ "Low" | translate }}
                                    </mat-option>
                                    <mat-option value="172800" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 172800}">
                                        {{ "No priority" | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <!-- <pre>{{data?.file.fileMetaUpdateQueue|json}}</pre> -->
                </ng-container>
                <ng-template #elseTemplateHesMetaQue>
                    <div class="d-f-c">
                        <div class="process_btn cp" (click)="startProcessing(data?.file)">
                            <mat-icon class="mr-1">fast_forward</mat-icon>
                            {{ 'Start processing file now' | translate }}
                        </div>
        
                        <div class="cut_value ml-1">
                            <div (click)="priority.open()">
                                <span>
                                    {{ "Priority" | translate }}:
                                </span>
                                <b class="ml-1">
                                    <ng-container [ngSwitch]="waiting_time_limit.value">
                                        <ng-container *ngSwitchCase="1">
                                            {{ "Now" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="300">
                                            {{ "Immediately" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="1200">
                                            {{ "Urgent" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="14400">
                                            {{ "High" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="43200">
                                            {{ "Middle" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="86400">
                                            {{ "Low" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="172800">
                                            {{ "No priority" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{ waiting_time_limit.value }}
                                        </ng-container>
                                    </ng-container>
                                </b>
                            </div>
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select disableOptionCentering #priority [formControl]="waiting_time_limit">
                                    <mat-option value="1" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1}">
                                        {{ "Now" | translate }}
                                    </mat-option>
                                    <mat-option value="300" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 300}">
                                        {{ "Immediately" | translate }}
                                    </mat-option>
                                    <mat-option value="1200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1200}">
                                        {{ "Urgent" | translate }}
                                    </mat-option>
                                    <mat-option value="14400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 14400}">
                                        {{ "High" | translate }}
                                    </mat-option>
                                    <mat-option value="43200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 43200}">
                                        {{ "Middle" | translate }}
                                    </mat-option>
                                    <mat-option value="86400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 86400}">
                                        {{ "Low" | translate }}
                                    </mat-option>
                                    <mat-option value="172800" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 172800}">
                                        {{ "No priority" | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-template>
            </div>
            
            <div class="block project" *ngIf="project">
                <span class="block_title d-f-c" style="justify-content: space-between;">
                    <div>
                        {{ "Project ID" | translate }}: <span class="ml-5 cp" style="font-weight: 300;" ngxClipboard [cbContent]="project?.id" (click)="$event.preventDefault(); $event.stopPropagation();" (cbOnSuccess)="copyData('project id')">{{project?.id}}</span>
                    </div>
    
                    <div class="d-f-c">
                        <button style="color: #A3CB38;" *ngIf="!!project.history_count" mat-icon-button [matMenuTriggerFor]="menuAsNew" aria-label="Example icon-button with a menu" #menuTrigger="matMenuTrigger">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <mat-menu #menuAsNew="matMenu">
                            <button  mat-menu-item (click)="V5VideoEditor(project, i, true)">
                                <mat-icon class="mr-1">movie</mat-icon>
                                {{ 'V5' | translate }}
                            </button>
                            <button  mat-menu-item (click)="videoEditorV6(project, i, true)">
                                <mat-icon class="mr-1">movie</mat-icon>
                                {{ 'V5.1' | translate }}
                            </button>
                            <button mat-menu-item (click)="videoEditorVA(project, i, true)">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V. A' | translate }}
                                    <div class="other_job" (click)="$event.preventDefault(); $event.stopPropagation(); duplicateToOtherJob(project, i, true, menuTrigger, 'A');" #tooltip="matTooltip" matTooltip="{{ 'Duplicate to other job' | translate }}" [matTooltipPosition]="'above'">
                                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"></path></svg>
                                    </div>
                                </div>
                            </button>
                            <button mat-menu-item (click)="videoEditorAA(project, i, true)">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V. AA' | translate }}
                                    <div class="other_job" (click)="$event.preventDefault(); $event.stopPropagation(); duplicateToOtherJob(project, i, true, menuTrigger, 'AA');" #tooltip="matTooltip" matTooltip="{{ 'Duplicate to other job' | translate }}" [matTooltipPosition]="'above'">
                                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"></path></svg>
                                    </div>
                                </div>
                            </button>
                        </mat-menu>
                        <button style="color: #4263c6;" *ngIf="!!project.history_count" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>movie_edit</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button  mat-menu-item (click)="V5VideoEditor(project, i)">
                                <mat-icon class="mr-1">movie</mat-icon>
                                {{ 'V5' | translate }}
                            </button>
                            <button  mat-menu-item (click)="videoEditorV6(project, i)">
                                <mat-icon class="mr-1">movie</mat-icon>
                                {{ 'V5.1' | translate }}
                            </button>
                            <button mat-menu-item (click)="videoEditorVA(project, i)">
                                <mat-icon class="mr-1">movie_edit</mat-icon>
                                {{ 'V. A' | translate }}
                            </button>
                            <button mat-menu-item (click)="videoEditorAA(project, i)">
                                <mat-icon class="mr-1">movie_edit</mat-icon>
                                {{ 'V. AA' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </span>
                <div class="project_name">{{project?.name}}</div>
                <div class="project_location">{{project?.output_location}}</div>
            </div>

            <div class="block project" *ngIf="data?.file?.tags">
                <!-- <span class="block_title">
                    <div>
                        {{ "Tags" | translate }}
                    </div>
                </span> -->

                <div class="file_tags">
                    <ng-container *ngIf="data?.file?.tags">
                        <ng-container *ngFor="let tag of data.file.tags; let t = index;">
                            <div class="file_tag">
                                <span class="text_one_line">{{tag?.parameterValue?.value}}</span>
                                <div class="primary_out" [ngClass]="{'primary_tag': !!tag.is_primary}" (click)="togglePrimary(tag)">
                                    <svg style="width: 13px; height: 12px;" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.275 4.05273C2.53359 4.05273 2.78158 3.95001 2.96443 3.76716C3.14728 3.58432 3.25 3.33632 3.25 3.07773C3.25 2.81915 3.14728 2.57115 2.96443 2.38831C2.78158 2.20546 2.53359 2.10273 2.275 2.10273C2.01641 2.10273 1.76842 2.20546 1.58557 2.38831C1.40272 2.57115 1.3 2.81915 1.3 3.07773C1.3 3.33632 1.40272 3.58432 1.58557 3.76716C1.76842 3.95001 2.01641 4.05273 2.275 4.05273ZM10.0165 5.72973C10.2505 5.96373 10.4 6.28873 10.4 6.65273C10.4 7.01023 10.257 7.33523 10.0165 7.56923L6.7665 10.8192C6.64594 10.9404 6.50262 11.0366 6.34478 11.1022C6.18694 11.1678 6.01769 11.2016 5.84675 11.2016C5.67581 11.2016 5.50656 11.1678 5.34872 11.1022C5.19088 11.0366 5.04756 10.9404 4.927 10.8192L0.3835 6.27573C0.143 6.03523 0 5.71023 0 5.35273V2.10273C0 1.38123 0.5785 0.802734 1.3 0.802734H4.55C4.9075 0.802734 5.2325 0.945734 5.4665 1.17973L10.0165 5.72973ZM7.501 1.91423L8.151 1.26423L12.6165 5.72973C12.857 5.96373 13 6.29523 13 6.65273C13 7.01023 12.857 7.33523 12.623 7.56923L9.126 11.0662L8.476 10.4162L12.1875 6.65273L7.501 1.91423Z" fill="#79B857"/>
                                    </svg>
                                </div>
                                    
                                <div class="remove" (click)="onRemove(data.file, tag, t)">
                                    <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="add_tag cp" (click)="openTargetValues([data?.file?.task_id, data?.file?.task_operation_id ? data?.file?.task_operation_id : 0, data?.file?.discussion_id ? data?.file?.discussion_id : 0, data?.file?.id], data?.file?.task || data?.task)">
                        <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#3F7DB8"/>
                        </svg>
                        {{ "Tag" | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="block connections" style="margin-top: 20px;" *ngIf="data?.user?.is_root && data.postFile && data.postFile.liveStreamOriginalFile">
        <div *ngIf="data.postFile.liveStreamOriginalFile" class="connection">
            <div class="block_title">
                {{ "Live Stream Original File" | translate }}
            </div>
            <div class="items_wrp">
                <div class="item">
                    <div class="name" (click)="fileClick(data.postFile.liveStreamOriginalFile)">{{data?.postFile?.liveStreamOriginalFile?.filename}}</div>
                    <div class="location" (click)="openFolder(data.postFile.liveStreamOriginalFile)">{{data?.postFile?.liveStreamOriginalFile?.location}}</div>
                    <div class="icon-i" (click)="openInfo(data.postFile.liveStreamOriginalFile)">
                        <mat-icon>info</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="block connections" style="margin-top: 20px;" *ngIf="data?.user?.is_root && data.postFile && data.postFile.originalFile">
        <div *ngIf="data.postFile.originalFile" class="connection">
            <div class="block_title">
                {{ "Original File" | translate }}:
            </div>
            <div class="items_wrp">
                <div class="item">
                    <div class="name" (click)="fileClick(data.postFile.originalFile)">{{data.postFile.originalFile?.filename}}</div>
                    <div class="location" (click)="openFolder(data.postFile.originalFile)">{{data.postFile.originalFile?.location}}</div>
                    <div class="icon-i" (click)="openInfo(data.postFile.originalFile)">
                        <mat-icon>info</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="data?.file?.is_dir == 0 && data?.user?.is_root">
        <div class="block" style="margin-top: 20px;">
            <span class="block_title">
                {{ "File Migrations" | translate }}
            </span>
    
            <pre>
                {{migrations|json}}
            </pre>
              
            <div class="d-f-c">
                <div class="process_btn cp" (click)="addMigration()">
                    <mat-icon class="mr-1">move_up</mat-icon>
                    {{ 'Migrate' | translate }}
                </div>
    
                <div class="cut_value ml-1">
                    <div (click)="storageSelect.open()">
                        <span>
                            {{ "Storage" | translate }}:
                        </span>
                        <b class="ml-1">
                            <ng-container *ngIf="storage.value">
                                {{getStorageByCode(storage.value)?.name}}
                            </ng-container>
                        </b>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select disableOptionCentering #storageSelect [formControl]="storage">
                            <mat-option [value]="item.storage_code" [ngClass]="{'cst_active_select_option': storage.value == item.storage_code}" *ngFor="let item of storages">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
         </div>
     </ng-container>





</div>