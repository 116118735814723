import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseClass } from '../../models/base-class';
import { LanguageService } from '../../services/common/language.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';

@Component({
  selector: 'app-connections-modal',
  templateUrl: './connections-modal.component.html',
  styleUrls: ['./connections-modal.component.scss']
})
export class ConnectionsModalComponent extends BaseClass implements OnInit, OnDestroy {
  public expandConsist: boolean = false;
  public expandPart: boolean = false;
  public expandRelated: boolean = false;
  public activeLang: any;
  public timeZone: any;
  public host: any = environment.host;
  public origin: any = window.location.origin;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConnectionsModalComponent>,
    public languageService: LanguageService,
    private router: Router,
    private sm: StorageManagerService,
    private alldialogRef: MatDialog
  ) { super() }
 
  ngOnInit(): void {
    console.log("ConnectionsModalComponent", this.data)
    // if (this.data.task.consist_of_count == '0' || this.data.task.part_of_count == '0') {
    //   this.panelOpenState = true;
    // }
    this.timeZone = new Date().getTimezoneOffset();
    if (this.data.task.consist_of_count != '0') {
      this.expandConsist = true;
    }
    if (this.data.task.part_of_count != '0' && !this.expandConsist && !this.expandRelated) {
      this.expandPart = true;
    }
    if (this.data.task.related_count != '0' && !this.expandConsist && !this.expandPart) {
      this.expandRelated = true;
    }

    this.getLangData();
  }


  goToTask(e,task) {
    e.preventDefault();
    this.alldialogRef.closeAll();
    this.router.navigate([`/task/${task.acm}`]);
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
