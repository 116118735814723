<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal" (click)="log()">
            {{ "Adding Youtube Sftp Dropbox Channel" | translate }}
        </div>

        <form [formGroup]="form" class="form_with_valids form_with_pads">
            <div class="silver_wrp">
                <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                    <mat-label>{{ "Select publication outlet" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="channel_id" required [multiple]="true">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('channel_id').value && form.get('channel_id').value.length">
                                <ng-container *ngFor="let id of form.get('channel_id').value">
                                    <span class="multi_item"><app-outlet-line [channel]="getProfile(id)" [company_id]="data.company_id"></app-outlet-line></span>
                                </ng-container>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                            [formControl]="profilesMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o" [disabled]="ifSftpHasChannel(profile.id)">
                            <app-outlet-line [channel]="profile" [company_id]="company_id"></app-outlet-line>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button [disabled]="isSubmit || !form.valid">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
