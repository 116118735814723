import { MAT_STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-to-approve-stepper',
  templateUrl: './to-approve-stepper.component.html',
  styleUrls: ['./to-approve-stepper.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false, showError: true }}
  ],
})
export class ToApproveStepperComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  public form: FormGroup;
  public filesControl: FormControl;
  public completedForm: FormGroup;
  public costsForm: FormGroup = this.fb.group({});
  public selectedIndex: number = 0;
  public selectedEmpls: any;
  public currencyes: any;
  public selectedEmpl: any;
  public tabIndex: number = 3;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public hasCostsStep:boolean = false;
  public isSaveCompletedAt:boolean = false;
  public isSaveCosts:boolean = false;
  
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ToApproveStepperComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private globalDataService: GlobalDataService,
    private membersService: MembersService,
    private companyService: CompanyService,
    private fileService: FileService,
    private refreshService: RefreshService
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    this.getCurrencyList();
    this.form = this.fb.group({
      content_url: [this.data.job.content_url, Validators.required]
    })
    
    console.log("ToApproveStepperComponent", this.data)
    if (!!this.data.chat) {
      this.selectedEmpls = this.data.job.empl_status;
      if (this.selectedEmpls.filter(x => x.partner_company_id == this.data.company.id).length > 0) {
        this.selectedEmpl = this.selectedEmpls.find(x => x.partner_company_id == this.data.company.id)
      } else {
        this.selectedEmpl = this.selectedEmpls[0]
      }
    } else {
      this.selectedEmpls = this.data.job.empl_status.filter(x => x.selected);
      if (this.selectedEmpls.filter(x => x.employee_id == this.data.company.employees[0].id).length > 0) {
        this.selectedEmpl = this.selectedEmpls.find(x => x.employee_id == this.data.company.employees[0].id)
      } else {
        this.selectedEmpl = this.selectedEmpls[0]
      } 
    }
    
    console.log("this.selectedEmpl", this.selectedEmpl);
    console.log("this.selectedEmpls", this.selectedEmpls);
    
    
    this.completedForm = this.fb.group({
      completed_at: [this.selectedEmpl.completed_at ? moment(this.selectedEmpl.completed_at*1000) : '', Validators.required]
    })
    this.filesControl = this.fb.control('')
    if (this.data.empl_status) {
      this.costsForm.addControl("rate", this.fb.control(this.selectedEmpl.rate, Validators.pattern(this.numberRegEx)))
      this.costsForm.addControl("price", this.fb.control(this.selectedEmpl.price, Validators.pattern(this.numberRegEx)))
    } else {
      if (this.selectedEmpl.employee.rate_target != '0.0000') {
        this.costsForm.addControl("rate", this.fb.control(this.selectedEmpl.rate, Validators.pattern(this.numberRegEx)))
      } else {
        this.costsForm.addControl("price", this.fb.control(this.selectedEmpl.price, Validators.pattern(this.numberRegEx)))
      }
    }
   
    this.costsForm.updateValueAndValidity();

    this.selectedEmpls.splice(this.selectedEmpls.indexOf(this.selectedEmpl), 1)

    this.checkIfHasCostsStep()
  }
 
  checkIfHasCostsStep() {
    if (this.selectedEmpl.employee_id == this.data.company.employees[0].id || (this.selectedEmpl.partner_employee_id && this.selectedEmpl.partner_employee_id == this.data.company.employees[0].id) || this.data.empl_status) {
      this.hasCostsStep = true;
    }
    if (this.data.job.employees.filter(x => x.employee_id == this.data.company.employees[0].id || (x.partner_employee_id && x.partner_employee_id == this.data.company.employees[0].id)).length > 0 && this.data.job.employees.find(x => x.employee_id == this.data.company.employees[0].id || (x.partner_employee_id && x.partner_employee_id == this.data.company.employees[0].id)).is_price_manager == 1) {
      this.hasCostsStep = true;
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyes = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyes = [...resp];
    //   })
    // )
  }

  
  getCurrency(id) {
    if (!!this.currencyes && this.currencyes.find(el => el.id == id)) {
      return this.currencyes.find(el => el.id == id)
    } else {
      return ''
    }
  }

  changeTab(val) {
    console.log(val);
    // if (val == 1) {
    //   this.form.controls.content_url.setValidators(Validators.required);
    // } else {
    //   this.form.controls.content_url.clearValidators();
    // }
    // this.form.get('content_url').updateValueAndValidity();
    this.tabIndex = val;
    if (val == 0) {
      this.filesControl = this.fb.control('', Validators.required)
      this.filesControl.updateValueAndValidity()
    } else {
      this.filesControl.clearValidators()
      this.filesControl.updateValueAndValidity()
    }
    if (val == 2) {
      this.stepper.next();
    }
  }

  saveFiles() {
    this.attachSubscriptions(
      this.fileService.detectFiles("/to_approve", "0", this.data.company.id, this.data.job.id).subscribe(resp => {
        if (resp.length) {
          this.filesControl.patchValue('1')
          this.stepper.next()
        } else {
          this.filesControl.patchValue('')
          this.layoutService.showSnackBar({name: ''}, marker("Folder 'To approve' is empty! You must upload files to approve."), SnackBarItem);
        }
      })
    )
    
  }

  saveUrl() {
    if (!this.form.valid) {
      return
    }
    this.attachSubscriptions(
      this.taskService.editWork(this.data.job.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.data.job.content_url = resp.content_url;
        this.stepper.next();
      })
    )
  }

  saveCompletedAt() {
    if (!this.completedForm.valid) {
      return;
    }
    let submData = {...this.completedForm.value};

    this.isSaveCompletedAt = true;

    if (!!this.completedForm.value.completed_at && !!this.completedForm.value.completed_at._d) {
      submData.completed_at = submData.completed_at.unix();
    } else {
      submData.completed_at = '0';
    }
    
    
    this.attachSubscriptions(
      (!!this.selectedEmpl.is_partner ? this.membersService.editTaskPartner(this.selectedEmpl.id, submData, this.data.company.id) : (!!this.selectedEmpl.is_partner_employee ? this.membersService.editTaskPartnerEmployee(this.selectedEmpl.id, submData, this.data.company.id) : this.membersService.editMember(this.selectedEmpl.id, submData, this.data.company.id))).pipe(
        switchMap(val => {
          return (!!this.selectedEmpl.is_partner ? this.membersService.editPartnerStatus(this.selectedEmpl.partnerCompanyStatus.id, {status_id: this.data.status.id}, this.data.company.id) : (!!this.selectedEmpl.is_partner_employee ? this.membersService.editPartnerEmployeeStatus(this.selectedEmpl.employeeStatus.id, {status_id: this.data.status.id}, this.data.company.id) : this.membersService.editTaskEmployeeStatus(this.selectedEmpl.employeeStatus.id, {status_id: this.data.status.id}, this.data.company.id)).pipe(
            tap(x => {
              this.selectedEmpl.selected = false
            })
          ))
        })
      ).subscribe(resp => {
        this.isSaveCompletedAt = false;
        if (!this.hasCostsStep) {
          if (this.selectedEmpls.length > 0) {
            this.openNextExecutor()
            this.dialogRef.close({event: "update", data: resp})
          } else {
            this.data.getTask()
            this.dialogRef.close({event: "update", data: resp})
          }
        } else {
          this.stepper.next()
        }
      },
      error => {
        this.showError();
        this.isSaveCompletedAt = false;
      })
    )
  }

  openNextExecutor() {
    const dialogRef = this.dialog.open(ToApproveStepperComponent, {
      panelClass: 'to_appove_dialog',
      data: {
        company: this.data.company,
        user: this.data.user,
        task: this.data.task,
        activeLang: this.data.activeLang,
        job: this.data.job,
        status: this.data.status,
        statuses: this.data.statuses,
        imgRoute: this.data.imgRoute,
        is_mobile: this.data.is_mobile,
        host: this.data.host,
        empl_status: this.data.empl_status,
        operationsValues: this.data.operationsValues,
        getTask: () => {this.data.getTask(true)}
      }
    });

    this.data.getTask();
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute + '/assets/img/partner.png'
  }

  saveCosts() {
    if (String(this.costsForm.value['rate']).indexOf(",") != -1) {
      this.costsForm.value['rate'] = this.costsForm.value['rate'].replace(",", ".");
    }
    if (String(this.costsForm.value['price']).indexOf(",") != -1) {
      this.costsForm.value['price'] = this.costsForm.value['price'].replace(",", ".");
    }
    this.isSaveCosts = true;

    this.attachSubscriptions(
      (!!this.selectedEmpl.is_partner ? this.membersService.editTaskPartner(this.selectedEmpl.id, this.costsForm.value, this.data.company.id) : (!!this.selectedEmpl.is_partner_employee ? this.membersService.editTaskPartnerEmployee(this.selectedEmpl.id, this.costsForm.value, this.data.company.id) : this.membersService.editMember(this.selectedEmpl.id, this.costsForm.value, this.data.company.id))).subscribe(resp => {
        this.isSaveCosts = false;
        if (this.selectedEmpls.length > 0) {
          this.openNextExecutor()
          this.dialogRef.close({event: "update", data: resp})
        } else {
          this.data.getTask()
          this.dialogRef.close({event: "update", data: resp})
        }
      },
      error => {
        this.showError();
        this.isSaveCosts = false;
      })
    )
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }
  
  selectionChange($event:StepperSelectionEvent):void {
    console.log($event);
    this.selectedIndex = $event.selectedIndex;
  }
  goBack() {
    this.tabIndex = 3;
    this.stepper.previous()
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
