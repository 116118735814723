import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outlet-line',
  templateUrl: './outlet-line.component.html',
  styleUrls: ['./outlet-line.component.scss']
})
export class OutletLineComponent implements OnInit {
  public host: any = environment.host;
  @Input() class: string = '';
  @Input() company_id: any;
  @Input() no_name: boolean = false;
  @Input() channel: any;
  constructor() { }

  ngOnInit(): void {
    if (!this.company_id) {
      this.company_id = this.channel.company_id
    }
  }
}
