<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Stakeholder" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="overflow: hidden; background: #fff;">
        <ng-container *ngIf="activeSections() == 'Posts'; else elseTemplateNotPostList">
            <section class="sub_filter_wrp short_sub_wrp">
                <div class="subs" [appDeviceClass]="'custom-scroll'">
                    <div class="sub" *ngFor="let tab of data.postsModel" [ngClass]="{'active': data.postsControl.value === tab.id}" (click)="patchControl(data.postsControl, tab.id)">
                        <ng-container *ngIf="tab?.icon == 'edit'">
                            <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3980_54429fil)">
                                <path d="M1 5.7501V7.0001H2.25L5.93667 3.31343L4.68667 2.06343L1 5.7501ZM6.90333 2.34677C7.03333 2.21677 7.03333 2.00677 6.90333 1.87677L6.12333 1.09677C5.99333 0.966768 5.78333 0.966768 5.65333 1.09677L5.04333 1.70677L6.29333 2.95677L6.90333 2.34677Z" fill="#686868"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_3980_54429fil">
                                <rect width="8" height="8" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </ng-container>
                        <ng-container *ngIf="tab?.icon == 'plus'">
                            <svg style="left: 10px; width: 12px; height: 12px;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path fill="#686868" d="M381,236H276V131c0-11-9-20-20-20s-20,9-20,20v105H131c-11,0-20,9-20,20s9,20,20,20h105v105c0,11,9,20,20,20  s20-9,20-20V276h105c11,0,20-9,20-20S392,236,381,236z"/>
                            </svg>
                        </ng-container>
                        {{ tab.name | translate }} <ng-container *ngIf="tab?.onlyThis">*</ng-container>
                    </div>
                </div>
                <div class="block">
                    <div class="sub_tabs" *ngIf="data.postsModel[data.postsControl.value].filters.length > 1">
                        <div class="sub_tabs_in">
                            <div class="sub_tab" *ngFor="let subTab of data.postsModel[data.postsControl.value].filters" [ngClass]="{'active': data.postsModel[data.postsControl.value].activeFilter.value === subTab.id}" (click)="data.postsModel[data.postsControl.value].filters.length > 1 ? patchControl(data.postsModel[data.postsControl.value].activeFilter, subTab.id) : null;">
                                {{subTab.name}} <ng-container *ngIf="subTab?.onlyThis">*</ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!switched">
                        <ng-container *ngIf="data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].data.length > 7; else elseTemplateSubL">
                            <div class="filter_exp_search">
                                <mat-form-field appearance="legacy" class="filter_form search">
                                    <input [formControl]="data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].control" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                </div>
                            <form [formGroup]="data.filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'" >
                                <mat-selection-list [multiple]="false" formControlName="{{data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key}}" >
                                    <mat-list-option [value]="0" *ngIf="data.postsControl.value == 0" [ngClass]="{'active': 0 === data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                        <div class="user_div">
                                            <mat-icon style="visibility: none;">user</mat-icon>
                                            <span>
                                                {{ "Stakeholder not set" | translate }}
                                            </span>
                                        </div>
                                    </mat-list-option>
                                    <ng-container *ngFor="let fil of data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].asData | async">
                                        <ng-container *ngIf="data.postsControl.value == 2 || (data.postsControl.value == 1 && data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                            </mat-list-option>
                                        </ng-container>
                                        <ng-template #elseTemplateTypeSub>
                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [employee]="fil"></app-user>
                                            </mat-list-option>
                                        </ng-template>
                                    </ng-container>
                                </mat-selection-list>
                            </form>
                        </ng-container>
                        <ng-template #elseTemplateSubL>
                            <form [formGroup]="data.filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                <mat-selection-list [multiple]="false" formControlName="{{data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key}}" >
                                    <mat-list-option [value]="0" *ngIf="data.postsControl.value == 0" [ngClass]="{'active': 0 === data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                        <div class="user_div">
                                            <mat-icon style="visibility: none;">user</mat-icon>
                                            <span>
                                                {{ "Stakeholder not set" | translate }}
                                            </span>
                                        </div>
                                    </mat-list-option>
                                    <ng-container *ngFor="let fil of data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].data">
                                        <ng-container *ngIf="data.postsControl.value == 2 || (data.postsControl.value == 1 && data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                            </mat-list-option>
                                        </ng-container>
                                        <ng-template #elseTemplateTypeSub>
                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get(data.postsModel[data.postsControl.value].filters[data.postsModel[data.postsControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [employee]="fil"></app-user>
                                            </mat-list-option>
                                        </ng-template>
                                    </ng-container>
                                </mat-selection-list>
                            </form>
                        </ng-template>
                    </ng-container>
                </div>
            </section>
        </ng-container>
        <ng-template #elseTemplateNotPostList>
            <section class="sub_filter_wrp short_sub_wrp">
                <div class="subs" [appDeviceClass]="'custom-scroll'">
                    <div class="sub" *ngFor="let tab of data.stakeholdersModel" [ngClass]="{'active': data.stakeholderControl.value === tab.id}" (click)="patchControl(data.stakeholderControl, tab.id)">
                        <ng-container *ngIf="tab?.icon == 'edit'">
                            <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3980_54429fil)">
                                <path d="M1 5.7501V7.0001H2.25L5.93667 3.31343L4.68667 2.06343L1 5.7501ZM6.90333 2.34677C7.03333 2.21677 7.03333 2.00677 6.90333 1.87677L6.12333 1.09677C5.99333 0.966768 5.78333 0.966768 5.65333 1.09677L5.04333 1.70677L6.29333 2.95677L6.90333 2.34677Z" fill="#686868"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_3980_54429fil">
                                <rect width="8" height="8" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </ng-container>
                        <ng-container *ngIf="tab?.icon == 'plus'">
                            <svg style="left: 10px; width: 12px; height: 12px;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <path fill="#686868" d="M381,236H276V131c0-11-9-20-20-20s-20,9-20,20v105H131c-11,0-20,9-20,20s9,20,20,20h105v105c0,11,9,20,20,20  s20-9,20-20V276h105c11,0,20-9,20-20S392,236,381,236z"/>
                            </svg>
                        </ng-container>
                        {{ tab.name | translate }} <ng-container *ngIf="tab?.onlyThis">*</ng-container>
                    </div>
                </div>
                <div class="block">
                    <div class="sub_tabs" *ngIf="data.stakeholdersModel[data.stakeholderControl.value].filters.length > 1">
                        <div class="sub_tabs_in">
                            <div class="sub_tab" *ngFor="let subTab of data.stakeholdersModel[data.stakeholderControl.value].filters" [ngClass]="{'active': data.stakeholdersModel[data.stakeholderControl.value].activeFilter.value === subTab.id}" (click)="data.stakeholdersModel[data.stakeholderControl.value].filters.length > 1 ? patchControl(data.stakeholdersModel[data.stakeholderControl.value].activeFilter, subTab.id) : null;">
                                {{subTab.name}} <ng-container *ngIf="subTab?.onlyThis">*</ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!switched">
                        <ng-container *ngIf="data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].data.length > 7; else elseTemplateSubL">
                            <div class="filter_exp_search">
                                <mat-form-field appearance="legacy" class="filter_form search">
                                    <input [formControl]="data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].control" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                </div>
                            <form [formGroup]="data.filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'" >
                                <mat-selection-list [multiple]="false" formControlName="{{data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key}}" >
                                    <mat-list-option [value]="0" *ngIf="data.stakeholderControl.value == 0" [ngClass]="{'active': 0 === data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                        <div class="user_div">
                                            <mat-icon style="visibility: none;">user</mat-icon>
                                            <span>
                                                {{ "Stakeholder not set" | translate }}
                                            </span>
                                        </div>
                                    </mat-list-option>
                                    <ng-container *ngFor="let fil of data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].asData | async">
                                        <ng-container *ngIf="data.stakeholderControl.value == 2 || (data.stakeholderControl.value == 1 && data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                            </mat-list-option>
                                        </ng-container>
                                        <ng-template #elseTemplateTypeSub>
                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [employee]="fil"></app-user>
                                            </mat-list-option>
                                        </ng-template>
                                    </ng-container>
                                </mat-selection-list>
                            </form>
                        </ng-container>
                        <ng-template #elseTemplateSubL>
                            <form [formGroup]="data.filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                <mat-selection-list [multiple]="false" formControlName="{{data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key}}" >
                                    <mat-list-option [value]="0" *ngIf="data.stakeholderControl.value == 0" [ngClass]="{'active': 0 === data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                        <div class="user_div">
                                            <mat-icon style="visibility: none;">user</mat-icon>
                                            <span>
                                                {{ "Stakeholder not set" | translate }}
                                            </span>
                                        </div>
                                    </mat-list-option>
                                    <ng-container *ngFor="let fil of data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].data">
                                        <ng-container *ngIf="data.stakeholderControl.value == 2 || (data.stakeholderControl.value == 1 && data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                            </mat-list-option>
                                        </ng-container>
                                        <ng-template #elseTemplateTypeSub>
                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get(data.stakeholdersModel[data.stakeholderControl.value].filters[data.stakeholdersModel[data.stakeholderControl.value].activeFilter['value']].key).value}">
                                                <app-user [class]="'user_div'" [employee]="fil"></app-user>
                                            </mat-list-option>
                                        </ng-template>
                                    </ng-container>
                                </mat-selection-list>
                            </form>
                        </ng-template>
                    </ng-container>
                </div>
            </section>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Apply" | translate }}
        </button>
    </div>
</mat-dialog-actions>