import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { off } from 'process';
import { forkJoin, fromEvent, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { OpenJobComponent } from '../../workspace-pages/cases/dialogs/open-job/open-job.component';

@Component({
  selector: 'app-job-link',
  templateUrl: './job-link.component.html',
  styleUrls: ['./job-link.component.scss']
})
export class JobLinkComponent extends BaseClass implements OnInit, OnDestroy {

  public host: any = environment.host;
  public imgRoute: any = '';
  public link: any;
  public is_mobile: boolean = false;
  public is_load: boolean = false;
  public linkStr: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService
  ) { super() }

  ngOnInit(): void {
    this.linkStr = window.location.href;
    this.getImgRoute();
    this.checkIsMobile();
    this.getInitMeta();
  }

  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  toMainPage() {
    this.router.navigate(['/main'])
  }

  openInCompany(company) {
    this.openJob(this.link.meta.task_id, this.link.meta.id, company)
  }

  getInitMeta() {
    this.is_load = true;
    this.attachSubscriptions(
      this.auth.getInitMetaDev('job', this.router.url.split('/')[2].split('?')[0]).pipe(
        switchMap((res:any) => {
          if (res.meta && res.meta.id && res.meta.acm) {
            return this.companyService.getCompanyesForLink(false, res.meta.id, false, false, res.meta.acm).pipe(
              tap(el => {
                res.companyes = el
              }),
              catchError(error => {
                console.log(error, res)
                res.companyes = []
                return of(res)
              }),
              map(() => res)
            )
          } else {
            res.companyes = []
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.link = resp
        
        console.log("getInitData", resp)
        if (resp.companyes && resp.companyes.length) {
          if (resp.companyes.length == 1) {
            this.openJob(resp.meta.task_id, resp.meta.id, resp.companyes[0])
            setTimeout(() => {
              this.is_load = false
            }, 1000)
          } else {
            this.is_load = false
          }
        } else {
          this.is_load = false
        }
      })
    )
  }
  
  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }


  openJob(task_id, task_operation_id, company) {
    console.log(this.activatedRoute.snapshot.queryParamMap.get('path'))
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          autoFocus: false,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
            company: company,
            initCompanyId: company.id,
            selectedIndex: !this.is_mobile && !this.activatedRoute.snapshot.queryParamMap.get('path') ? 4 : undefined,
            loc: this.activatedRoute.snapshot.queryParamMap.get('path') ? decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('path')) : '',
            is_link: true
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!this.is_mobile) {
            this.toMainPage()
          }
        })
      })
    )
  }

  goToDashboard(company_id) {
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
