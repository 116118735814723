import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-mob-tag-filter',
  templateUrl: './mob-tag-filter.component.html',
  styleUrls: ['./mob-tag-filter.component.scss']
})

export class MobTagFilterComponent extends BaseClass implements OnInit, OnDestroy {
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public switched: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobTagFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobTagFilterComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  patchControl(control:FormControl, val) {
    this.switched = true;
    control.patchValue(val)
    setTimeout(() => {
      this.switched = false
    },0)
  }

  patchTab(ctrl, val) {
    console.log(ctrl, val);
    this.switched = true;
    ctrl = val
    setTimeout(() => {
      this.switched = false
    },0)
  }
  
  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  getValueById(id) {
    if (!this.data.allValues || this.data.allValues.length == 0) {
      return null
    }
    return this.data.allValues.find(x => x.id == id)
  }

  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }

  getParameterById(id) {
    if (!this.data.parameters || this.data.parameters.length == 0) {
      return null
    }
    return this.data.parameters.find(x => x.id == id)
  }

  paramSwitcher(key, param) {
    switch (key) {
      case 'task_parameter_value_id':
        return param["activeTaskValues"]
        break;
      case 'task_operation_parameter_value_id':
        return param["activeJobValues"]
        break;
      case 'discussion_parameter_value_id':
        return param["activeDiscussionValues"]
        break;
      case 'discussion_parameter_value_id':
        return param["activeDiscussionValues"]
        break;
      case 'file_parameter_value_id':
        return param["activeFileValues"]
        break;
      case 'parameter_value_id':
        return param["activeValues"]
        break;
    }
  }

  keySwitcher(key) {
    switch (key) {
      case 'task_parameter_value_id':
        return "activeTaskValues"
        break;
      case 'task_operation_parameter_value_id':
        return "activeJobValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'file_parameter_value_id':
        return "activeFileValues"
        break;
      case 'parameter_value_id':
        return "activeValues"
        break;
    }
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
