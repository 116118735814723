
<h2 mat-dialog-title class="dialog_title">
    <div class="d-f-c channel_info">
        <app-outlet-line [channel]="data?.profile?.channel" [company_id]="data?.company?.id"></app-outlet-line>
        <span class="text_one_line">
            {{data?.profile?.content_name}}
        </span>
        <span>
            {{"comments" | translate}}:
        </span>
    </div>
    <div class="d-f-c" style="margin-left: 30px;">
        <button mat-icon-button style="color: #27ae60;" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="refreshComments(false)">
              <mat-icon>rotate_right</mat-icon>
              <span>{{"Refresh last comments" | translate}}</span>
            </button>
            <button mat-menu-item (click)="refreshComments(true)">
              <mat-icon>refresh</mat-icon>
              <span>{{"Refresh all comments" | translate}}</span>
            </button>
        </mat-menu>
        
        <form [formGroup]="channelForm" class="channelForm" class="ml-1 mr-1">
            <mat-checkbox color="primary" [disabled]="" formControlName="is_comments_update">{{ "Auto-update comments" | translate }}</mat-checkbox>
        </form>
        <button class="icon_close" mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="comments" [appDeviceClass]="'custom-scroll'" *ngIf="comments">
        <div class="comment" *ngFor="let item of comments">
            <div class="comment_in_wrp">
                <div class="comment_in">
                    <div class="from">
                        {{item?.from_name}}
                    </div>
                    <div class="msg">
                        <span class="message_text" [innerHTML]='item?.message ? (item?.message.replaceAll("\n","<BR>") | linky:{newWindow: true}) : ""'></span>
                    </div>
                </div>
                <div class="comment_bottom">

                    <div class="reply show_on_hover" (click)="reply_msg = item">
                        Reply
                    </div>

                    <div *ngIf="item?.replies_count > 0" class="reviews" [ngClass]="{'blue': !!item?.replies_count && !(item?.reviews && item?.reviews.length)}" (click)="(!!item?.replies_count && !(item?.reviews && item?.reviews.length)) ? getReviews(item) : null">
                        Replies: {{item?.replies_count}}
                    </div>

                    <div class="time">
                        <ng-container *ngIf="compareDates(item?.created_at); else updatedAtTempl">
                            <span class="message_date">
                                <span #tooltip="matTooltip"
                                matTooltip="{{ item?.created_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                    {{ item?.created_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}
                                </span>
                            </span>
                        </ng-container>
                        <ng-template #updatedAtTempl>
                            <span class="message_date">
                                <span #tooltip="matTooltip"
                                matTooltip="{{ item?.created_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                    {{ item?.created_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}
                                </span>
                            </span>
                        </ng-template>
                    </div>

                    <div class="likes d-f-c" *ngIf="item?.likes_count > 0">
                        <img height="18" role="presentation" src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='0%25' y2='100%25'%3e%3cstop offset='0%25' stop-color='%2318AFFF'/%3e%3cstop offset='100%25' stop-color='%230062DF'/%3e%3c/linearGradient%3e%3cfilter id='c' width='118.8%25' height='118.8%25' x='-9.4%25' y='-9.4%25' filterUnits='objectBoundingBox'%3e%3cfeGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/%3e%3cfeOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/%3e%3cfeComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/%3e%3cfeColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/%3e%3c/filter%3e%3cpath id='b' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/%3e%3c/defs%3e%3cg fill='none'%3e%3cuse fill='url(%23a)' xlink:href='%23b'/%3e%3cuse fill='black' filter='url(%23c)' xlink:href='%23b'/%3e%3cpath fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/%3e%3c/g%3e%3c/svg%3e" width="18">
                        <span *ngIf="item?.likes_count > 1">{{item?.likes_count}}</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="item?.reviews && item?.reviews.length">
                <ng-container [ngTemplateOutlet]="replyTmpl" [ngTemplateOutletContext]="{ item: item }"></ng-container>
            </ng-container>
            
            
        </div>
        <!-- <div class="more" (click)="getMore(comments)" *ngIf="is_get_more && comments.summary.total_count && comments.paging.cursors.after">more comments</div> -->
    </div>

    <ng-template #replyTmpl let-item="item">
        <div class="comment_out">
            <ng-container *ngFor="let revItem of item?.reviews">
                <div class="comment_in_wrp">
                    <div class="comment_in">
                        <div class="from">
                            {{revItem?.from_name}}
                        </div>
                        <div class="msg">
                            {{revItem?.message}}
                        </div>
                    </div>
                    <div class="comment_bottom">

                        <div class="reply show_on_hover" (click)="reply_msg = revItem">
                            Reply
                        </div>
                        
                        <div *ngIf="revItem?.replies_count > 0" class="reviews" [ngClass]="{'blue': !!revItem?.replies_count && !(revItem?.reviews && revItem?.reviews.length)}" (click)="(!!revItem?.replies_count && !(revItem?.reviews && revItem?.reviews.length)) ? getReviews(revItem) : null">
                            Replies: {{revItem?.replies_count}}
                        </div>

                        <div class="time">
                            <ng-container *ngIf="compareDates(revItem?.created_at); else updatedAtTempl">
                                <span class="message_date">
                                    <span #tooltip="matTooltip"
                                    matTooltip="{{ revItem?.created_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                                    [matTooltipPosition]="'above'">
                                        {{ revItem?.created_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}
                                    </span>
                                </span>
                            </ng-container>
                            <ng-template #updatedAtTempl>
                                <span class="message_date">
                                    <span #tooltip="matTooltip"
                                    matTooltip="{{ revItem?.created_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                                    [matTooltipPosition]="'above'">
                                        {{ revItem?.created_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}
                                    </span>
                                </span>
                            </ng-template>
                        </div>

                        <div class="likes d-f-c" *ngIf="revItem?.likes_count > 0">
                            <img height="18" role="presentation" src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 16 16'%3e%3cdefs%3e%3clinearGradient id='a' x1='50%25' x2='50%25' y1='0%25' y2='100%25'%3e%3cstop offset='0%25' stop-color='%2318AFFF'/%3e%3cstop offset='100%25' stop-color='%230062DF'/%3e%3c/linearGradient%3e%3cfilter id='c' width='118.8%25' height='118.8%25' x='-9.4%25' y='-9.4%25' filterUnits='objectBoundingBox'%3e%3cfeGaussianBlur in='SourceAlpha' result='shadowBlurInner1' stdDeviation='1'/%3e%3cfeOffset dy='-1' in='shadowBlurInner1' result='shadowOffsetInner1'/%3e%3cfeComposite in='shadowOffsetInner1' in2='SourceAlpha' k2='-1' k3='1' operator='arithmetic' result='shadowInnerInner1'/%3e%3cfeColorMatrix in='shadowInnerInner1' values='0 0 0 0 0 0 0 0 0 0.299356041 0 0 0 0 0.681187726 0 0 0 0.3495684 0'/%3e%3c/filter%3e%3cpath id='b' d='M8 0a8 8 0 00-8 8 8 8 0 1016 0 8 8 0 00-8-8z'/%3e%3c/defs%3e%3cg fill='none'%3e%3cuse fill='url(%23a)' xlink:href='%23b'/%3e%3cuse fill='black' filter='url(%23c)' xlink:href='%23b'/%3e%3cpath fill='white' d='M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725a.73.73 0 01.089.546c-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666zM3.6 7h.8a.6.6 0 01.6.6v3.8a.6.6 0 01-.6.6h-.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6z'/%3e%3c/g%3e%3c/svg%3e" width="18">
                            <ng-container *ngIf="revItem?.likes_count > 1">{{revItem?.likes_count}}</ng-container>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="revItem?.reviews && revItem?.reviews.length">
                    <ng-container [ngTemplateOutlet]="replyTmpl" [ngTemplateOutletContext]="{ item: revItem }"></ng-container>
                </ng-container>
            </ng-container>
            
            <!-- <div class="more more_reply" (click)="getMoreReviews(item)" *ngIf="item?.is_get_more">more reply</div> -->
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="reply_msg" *ngIf="reply_msg">
        {{reply_msg.message}}

        <div class="close d-f-c">
            <mat-icon (click)="generateCustomReply()" style="color: red;">quickreply</mat-icon>
            <mat-icon style="margin-left: 10px;" (click)="is_cst_reply = false; generateReply()">quickreply</mat-icon>
            <mat-icon style="margin-left: 10px;" (click)="reply_msg = undefined">close</mat-icon>
        </div>
    </div>
    <div *ngIf="is_cst_reply && reply_msg" class="form_sys_out">
        <form class="form_sys" [formGroup]="aiReq">      
            <div class="sys_str" contenteditable="true" spellcheck="false" formControlName="content"></div>
        </form>
        <button mat-icon-button (click)="getResults()" color="warn"><mat-icon>quickreply</mat-icon></button>
        <!-- <pre class="request_sys" *ngIf="!!getResultsReqObject() && getResultsReqObject().messages && getResultsReqObject().messages[0]">{{getResultsReqObject().messages[0]}}</pre> -->
    </div>
    <form class='comment_form' [formGroup]="form">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Write a comment...' | translate }}</mat-label>
            <textarea 
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="9" formControlName="message" matInput placeholder="{{ 'Write a comment...' | translate }}"></textarea>
        </mat-form-field>
        <button mat-icon-button color="primary" (click)="send(reply_msg ? reply_msg : false)">
            <mat-icon>send</mat-icon>
        </button>
    </form>
</mat-dialog-actions>
