import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { RefreshService } from "src/app/shared/services/rest/refresh.service";
import { TaskService } from "src/app/shared/services/rest/task.service";


@Component({
  selector: 'app-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss']
})
export class EditEmplStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public operationStatuses: any;
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditEmplStatusComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private refreshService: RefreshService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("EditEmplStatusComponent", this.data)
    this.getOperationsStatus();

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (resp.company_id == this.data.company.id && this.data.task.id == resp.task_id) {
          this.close()
        }
      })
    )
    
    this.form = this.fb.group({
      status_id: [!!this.data.employee.employeeStatus ? this.data.employee.employeeStatus.status_id : this.data.work.status_id, Validators.required],
    })

    if (this.data.employee.is_partner) {
      this.form.patchValue({
        status_id: this.data.employee.partnerCompanyStatus ? this.data.employee.partnerCompanyStatus.status_id : this.data.work.status_id
      })
    }


    this.attachSubscriptions(
      this.form.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!!this.data.employee.is_partner) {
      if (!!this.data?.employee?.partnerCompanyStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(this.data.employee.partnerCompanyStatus.id, this.form.value, this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(Object.assign(this.form.value, {
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              partner_company_id: this.data.employee.partner_company_id,
            }), this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      }
    } else if (!!this.data?.employee.is_partner_employee) {
      if (!!this.data?.employee.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(this.data?.employee.employeeStatus.id, this.form.value, this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus({
            status_id: this.form.value.status_id,
            company_id: this.data.company.id,
            task_id: this.data.task_id,
            task_operation_id: this.data.work.id,
            employee_id: this.data?.employee.employee_id,
            partner_company_id: this.data?.employee.partner_company_id,
            partner_employee_id: this.data?.employee.partner_employee_id
          }, this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      }
    } else {
      if (!!this.data?.employee?.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(this.data.employee.employeeStatus.id, this.form.value, this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(Object.assign(this.form.value, {
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              employee_id: this.data.employee.employee_id,
            }), this.data.company.id).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      }
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
