<mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding members to Preset" | translate }}
    </span>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper bookmarks">

        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="white_block">
                <div class="white_block_title">
                    {{ "Save for" | translate }}
                </div>

                <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                    <label>{{ "Choose an employee" | translate }}: </label>
                    <mat-chip-list *ngIf="!switched" [multiple]="true" selectable formControlName="employee_id">
                        <ng-container *ngFor="let employee of data.employees; let i = index">
                            <mat-chip [disabled]="!!employee?.is_save" [ngClass]="{'p-e-none': !!employee?.is_save}" class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                <div class="settings_employee" *ngIf="form.get('employee_id').value.includes(employee.id) && !employee?.is_save">
                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(employee, 'is_active')">
                                        <ng-container *ngIf="employee.is_active == 1; else elseTemplateIsActive">
                                            <mat-icon>visibility</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateIsActive>
                                            <mat-icon>visibility_off</mat-icon>
                                        </ng-template>
                                    </button>
                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(employee, 'is_shortcut')">
                                        <ng-container *ngIf="employee.is_shortcut == 1; else elseTemplateIsShortcut">
                                            <mat-icon>star</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateIsShortcut>
                                            <mat-icon>star_border</mat-icon>
                                        </ng-template>
                                    </button>
                                </div>
                            </mat-chip>
                            <mat-chip *ngIf="i == data.employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
    
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select [multiple]="true" #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                            [formControl]="employeeMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [disabled]="employee?.is_save">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </form>

    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
