<div class="container centred_page">
    <h2>{{ 'Editing a company' | translate }}: {{ form.get('name').value }}</h2>

    <div class="workspace_avatar_wrp">
        <div class="workspace_avatar">
            <div class="edit_avatar" (click)="uploader.click()">
                <mat-icon>edit</mat-icon>
            </div>
            <img (error)="onImgError($event)" [src]="host + (company?.avatarFile?.thumbnail ? company?.avatarFile?.thumbnail : company?.avatarFile?.original)" alt="">
        </div>
    </div>
    <input 
        hidden 
        type="file" 
        #uploader
        (change)="uploadFile($event, company)"
    />

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
        </mat-form-field>
        
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Time zone" | translate }}</mat-label>
            <mat-select disableOptionCentering formControlName="timezone">
                <mat-option>
                    <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                </mat-option>
                <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                [disabled]="zone.disabled">
                    <mat-option *ngFor="let name of zone.names" [value]="name">
                        {{name}}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
            <input [readonly]="form.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
        </mat-form-field>
        
        <div class="btns_line">
            <button mat-raised-button routerLink="[/main]">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1" type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
                {{ "Save" | translate }}
            </button>
        </div>
    </form>
</div>