import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgAudioRecorderService, OutputFormat } from 'ng-audio-recorder';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ClientsService } from 'src/app/shared/services/rest/clients.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { environment } from 'src/environments/environment';
import { PartnersListComponent } from './dialogs/partners/partners-list/partners-list.component';
import { MobShortcutsComponent } from 'src/app/shared/global_components/navigation-bar/mob-filters/mob-shortcuts/mob-shortcuts.component';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<any>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public is_mobile: boolean = false;
  public user_id: number;
  public meta: any;
  public activeLang: any;
  public errorMsg: any;
  public allCompanyes: any;
  public companyes: any;
  public invite: string = ""
  public displayedColumns: string[] = ['Name', 'Pages', 'Actions'];
  public inviteData: any;
  public allClientsInvites: object[];
  overlayRef: OverlayRef | null;
  public contextSub: Subscription;
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private router: Router,
    public taskBarService: TaskBarService,
    public activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private refreshService: RefreshService,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private dialog: MatDialog
  ) {
    super()
   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getUser();
    this.getMeta();
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
    this.companyService.company$.next({});

    this.invite = this.activatedRoute.snapshot.queryParamMap.get('code');

    this.getLangData();

    this.getImgRoute();
    this.getCsrf();

    this.getCompanyes();

    this.attachSubscriptions(
      this.companyService.getInvitedStream().subscribe(res => {
        this.getCompanyes()
      })
    )

    this.getClientsInvites();
  }

  goToFilterPage(e, comp, page) {
    console.log("goToFilterPage ", e, comp)

    if (!e.value) {
      return
    }

    console.log("goToMobPage", page);

    let params:any = {
      company_id: comp.id,
      operation_employee_id: e.value
    }    

    this.router.navigate([page], { queryParams: params });
  }

  getEmployees(comp) {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', comp.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, comp.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              // public employeesControl: FormControl = new FormControl();
              // public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
              comp.employeesSub = new Subscription();
              comp.employees$ = new ReplaySubject<any>(1);
              comp.employeesControl = new FormControl('')
              comp.selEmployees = conValues;
              comp.employees$.next(comp.selEmployees.slice())

              if (comp.employeesSub) {
                comp.employeesSub.unsubscribe()
              }

              comp.employeesSub = comp.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(comp, resp))
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees", comp);
      })
    )
  }

  onSearchEmoloyees(comp, resp) {
    if (!comp.selEmployees) {
      return;
    }

    if (!resp) {
      comp.employees$.next(comp.selEmployees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    comp.employees$.next(
      comp.selEmployees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  openContext({ x, y }: MouseEvent, val, page) {
    this.closeContext();
    this.getEmployees(val);
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: {
        company: val,
        page: page
      }
    }));
    
    this.contextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.contextSub && this.contextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openMobShortcuts(comp, e) {
    e.preventDefault();
    console.log(comp)
    const dialogRef = this.dialog.open(MobShortcutsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: comp,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        needGet: true,
        favoriteBookmarks: [],
        groupedBarItems: []
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'go') {
            this.taskBarService.goToItem$.next(result.data)
          } else if (result.event == 'bookmark') {
            this.goToBookmark(null, result.data, result.company.id)
          }
        }
      })
    )
  }

  goToBookmark(e, bookmark, cID) {
    if (e) {
      e.stopPropagation();
    }
    let page;
    
    let activeSections = bookmark.section;

    switch (activeSections) {
      case "Jobs":
        page = '/jobs';
        break;
      case "Chats":
        page = '/chats';
        break;
      case "Board by Date":
        page = '/board';
        break;
      case "Projects":
        page = '/projects';
        break;
      case "Board by Order":
        page = '/board-list';
        break;
      case 'Post List':
        page = '/urls';
        break;
      case 'Post Dashboards':
        page = '/urls-dashboard';
        break;
      case 'Outlet Dashboards':
        page = '/outlets-dashboard';
        break;
      case 'Regular Publications':
        return '/regular-publications'
        break;
      case 'Files':
        page = '/files';
        break;
      default:
        page = '/tasks';
        break;
    }
    console.log('page', page);
    this.router.navigate([page], { queryParams: page.indexOf('dashboard') != -1 ? {company_id: cID, count: 1} : Object.assign(bookmark.settings, {company_id: cID, preset_id: bookmark.id}) });
  }

  goToMobPage(page, id, e?) {
    if (e) {
      e.preventDefault();
    }

    console.log("goToMobPage", page);

    let params:any = {
      company_id: id
    }


    if (page == '/settings') {
      params.page = 'finances_dashboard'
    }
    

    this.router.navigate([page], { queryParams: params });
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  companyVerificationText(comp) {
    return comp.translations.filter(x => x.language == this.activeLang).length > 0 ? comp.translations.find(x => x.language == this.activeLang) : undefined
  }

  goToCompany(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }
  
  goToUrls(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/urls'], { queryParams: {company_id: company_id, order_by: "content_published_at_desc", count: 1} });
  }

  goToUrlsStart(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/urls-start'], { queryParams: {company_id: company_id, order_by: "content_published_at_desc", count: 1} });
  }

  goToBoardList(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/board-list'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToOutletsDash(e, company_id) {
    e.stopPropagation();
    e.preventDefault();
    this.router.navigate(['/outlets-dashboard'], { queryParams: {company_id: company_id, count: 1} });
  }

  goToUrlsDash(e, company_id) {
    e.stopPropagation();
    e.preventDefault();
    this.router.navigate(['/urls-dashboard'], { queryParams: {company_id: company_id, count: 1} });
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  getMeta() {
    this.attachSubscriptions(
      this.sm.getMeta().subscribe(resp => {
        console.log("getMeta", resp)
        this.meta = resp;
      })
    )
  }

  goToSftp(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'sftp'}});
  }
  goToManageAuth(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'manage-auth'}});
  }
  goToImportData(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'import-data'}});
  }
  goToFinances(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances_dashboard'}});
  }

  goToBookmarks(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'bookmarks'} });
  }
  
  goToParameters(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'parameters'} });
  }
  
  goToChecklists(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'checklists'} });
  }

  goToPartners(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'partner_workspaces'} });
  }
  
  goToQuestionnaires(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'questionnaires'} });
  }

  goToForm(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'form'} });
  }

  goToCompanyStats(e, id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'stats'} });
  }

  goToCompanyStatsCreator(e, id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'stats'} });
  }

  goToGroups(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'company'} });
  }

  goToChats(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/chats'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }
  
  goToJobs(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/jobs'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToBoard(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/board'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToCurrentActivity(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'current_activity'} });
  }

  goToStatements(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'statements'} });
  }

  goToPayouts(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'payouts'} });
  }

  goToProfiles(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'profiles'} });
  }

  goToUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'usage'} });
  }
  
  goToDubbingUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'dubbing_usage'} });
  }
  
  goToTotalUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'total_usage'} });
  }

  goToEmployees(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'individuals'} });
  }

  goToUsers(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'users'} });
  }

  goToJobTypes(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'job_types'} });
  }

  goToTaskTypes(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'task_types'} });
  }

  addClient(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/task/add-client'], { queryParams: {company_id: company_id} });
  }

  goToClients(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/task/clients'], { queryParams: {company_id: company_id} });
  }

  goToScenarios(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'automation'} });
  }

  goToMembers(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'workspace_members'} });
  }

  goToStructure(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'structure'} });
  }
  
  goToCategorization(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'categorization'} });
  }

  deleteCompany(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("company")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.editCompany(id, {is_delete: 1}).subscribe(resp => {
            this.companyes.splice(this.companyes.indexOf(i), 1);
            this.table.renderRows();
            this.refreshService.newWorkSpace$.next(true)
          })
        )
      }
    });
   
  }

  getCompanyes() {
    this.attachSubscriptions(
      this.companyService.getCompanyes().pipe(
        tap(x => {
          this.allCompanyes = JSON.parse(JSON.stringify(x))
        }),
        tap(val => {
          console.log("getCompanyes", val)
          let ownW = []
          let adminW = []
          let execW = []

          let res = [];

          val.forEach(workspace => {
            if (workspace.permissions.includes("owner")) {
              ownW.push(workspace)
            } else if (workspace.permissions.includes("admin") || workspace.permissions.includes("manager")) {
              adminW.push(workspace)
            } else {
              execW.push(workspace)
            }
          });

          if (ownW.length) {
            res.push({
              initial: 'Workspaces I own', 
              isGroupBy: true
            })
            res.push(...ownW)
          }
          if (adminW.length) {
            res.push({
              initial: 'Workspaces I admin / manage', 
              isGroupBy: true
            })
            res.push(...adminW)
          }
          if (execW.length) {
            res.push({
              initial: 'Workspaces I am executor / client', 
              isGroupBy: true
            })
            res.push(...execW)
          }
          // 1. Workspaces I own
          // 2. Workspaces I admin / manage
          // 3. Workspaces I am executor / client

          this.companyes = res;
        }),
        switchMap(x => {
          if (!!this.invite) {
            return this.companyService.getInvite(this.invite).pipe(
              tap(resp => {
                console.log("test 3333", resp);
                if (resp.hasOwnProperty('exception')) {
                  this.errorMsg = resp.exception.message + " Повторите позже."
                } else if (resp.length == 0) {
                  this.errorMsg = "Код приглашения не найден или не действителен."
                } else {
                  if (!this.companyes.find(x => x.id == resp[0].company_id)) {
                    this.inviteData = resp[0];
                  } else {
                    this.errorMsg = "Вы уже состоите в этой компании."
                  }
                }
        
                if (this.errorMsg) {
                  this.layoutService.showSnackBar({name: this.user.email}, marker(this.errorMsg), SnackBarItem)
                }
              })
            )
          } else {
            return of(null)
          }
        })
      ).subscribe(result => {
        if (!this.invite) {
          if (this.allCompanyes.length == 1) {
            this.goToWorkspace(null, this.allCompanyes[0].id)
          }
        }
        console.log("test 4444", result);
        console.log("test this.companyes", this.companyes);
        console.log("test this.allCompanyes", this.allCompanyes);
      })
    )
  }

  isGroup(index, item): boolean{
    return item.isGroupBy;
  }

  goToWorkspace(e, company_id) {
    if (e) {
      e.preventDefault()
    }
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }


  getClientsInvites() {
    this.attachSubscriptions(
      this.clientsService.getInvites().subscribe(resp => {
        this.allClientsInvites = resp;
      })
    )
  }

  acceptInvite() {
    this.attachSubscriptions(
      this.companyService.acceptInvite(this.inviteData.id, this.invite).subscribe(resp => {
        console.log(resp);
        this.invite = '';
        this.inviteData = false;
        this.getCompanyes();
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {}});
        this.refreshService.newWorkSpace$.next(true)
      })
    )
  }

  // rejectInvite() {
  //   this.attachSubscriptions(
  //     this.companyService.rejectInvite(this.inviteData.id).subscribe(resp => {
  //       this.getCompanyes();
  //     })
  //   )
  // }

  leaveCompany(company) {

    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("workspace")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.rejectInvite(company.employees[0].id).subscribe(resp => {
            this.getCompanyes();
          })
        )
      }
    });
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
