import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddAdditionalEmployeesComponent } from '../../additionalEmpl/add-additional-employees/add-additional-employees.component';
import { AdditionalEmployeesComponent } from '../../additionalEmpl/additional-employees/additional-employees.component';
import { AddChatToBoardComponent } from '../../workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { EditOperationChatComponent } from '../edit-operation-chat/edit-operation-chat.component';
import { OperationChatComponent } from '../operation-chat/operation-chat.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';

@Component({
  selector: 'app-task-chats',
  templateUrl: './task-chats.component.html',
  styleUrls: ['./task-chats.component.scss']
})
export class TaskChatsComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("chatsContainer") chatsContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public displayedColumns: string[] = ['Date', 'Creator', 'Name', 'Preview', 'Preview Date', 'Status', 'Actions'];
  public chats: any = [];
  public groups: any;
  throttle = 200;
  scrollUpDistance = 3.5;
  public pagination: any;
  public page: number = 1;
  public activeLang: any;
  public filter: FormGroup;
  public origin = window.location.origin;
  public host: any = environment.host;
  public sortValue: string = "updated_desc";
  public activeStatus: number[] = [0];
  public timeZone: any;
  public isLoad: boolean = false;
  public isInit: boolean = false;
  public is_mobile: boolean = false;
  public big_monitor: boolean = false;
  public chatsDataSub: Subscription;
  public statuses: any = [
    {
      name: "All",
      id: 0,
      hasChats: true
    },
    {
      name: "Waiting",
      id: 1,
      hasChats: false
    },
    {
      name: "In process",
      id: 2,
      hasChats: false
    },
    {
      name: "To approve",
      id: 3,
      hasChats: false
    },
    {
      name: "Approved",
      id: 4,
      hasChats: false
    },
    {
      name: "To correct",
      id: 5,
      hasChats: false
    },
    {
      name: "Sources needed",
      id: 6,
      hasChats: false
    },
    {
      name: "On hold",
      id: 97,
      hasChats: false
    },
    {
      name: "Ready",
      id: 98,
      hasChats: false
    },
    {
      name: "Canceled",
      id: 99,
      hasChats: false
    },
  ];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskChatsComponent>,
    public chatService: ChatService,
    private layoutService: LayoutService,
    public taskBarService: TaskBarService,
    public taskService: TaskService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    public membersService: MembersService,
    public languageService: LanguageService,
    public companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private refreshService: RefreshService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    this.timeZone = new Date().getTimezoneOffset();

    if (!this.data.task.hasOwnProperty('operations') || !this.data.task.operations.length) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpandJobs(this.data.company.id, this.data.task.id).pipe(
          map(x => x.body[0]),
          switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
        ).subscribe(resp => {
          if (resp.operations) {
            this.data.task.operations = resp.operations
          }
          console.log("!this.data.task.hasOwnProperty('operations') || !this.data.task.operations.length", resp)
        })
      )
    }

    console.log(this.data);
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
      if (window.innerHeight >= 1400) {
        this.big_monitor = true;
      }
    }

    this.filter = this.fb.group({
      status_id: [[0], Validators.required],
      order_by: this.sortValue,
      q: ''
    })

    this.attachSubscriptions(
      this.filter.get('q').valueChanges.pipe(debounceTime(300)).subscribe(() => this.onSearchChats())
    )

    this.chatsDataSub = this.chatService.getTaskChatsDataStream().pipe(
      concatMap(tasksInPage => {
        return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
      }),
    ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));

    this.isInit = true;
    this.getChats();
    this.getChatsCount();
    this.getLangData();
    this.onResize();
    this.getGroupsCompany();
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.chatsContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 300),
        debounceTime(300)
      ).subscribe(() => {
        console.log("onScroll", this.page > 1 && this.pagination['pageCount'] >= this.page)
        if (this.pagination['pageCount'] >= this.page) {
          this.getChats();
        }
      })
    )
  }

  onSearchChats() {
    this.page = 1;
    this.chats = [];
    this.getChats(false)
  }


  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.data.company.id).pipe(
        tap(res => {
          chat.employees = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
         
        }),
        catchError(err => of(err))
      ),
      // this.membersService.getTaskPartnerStatuses({task_id: chat.task_id, task_operation_id: chat.task_operation_id}, this.company.id).pipe(
      //   tap(resp => {
      //   console.log("chatPartnerStats GET", resp)
      // }))
    ])
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  sort(val) {
    this.sortValue = val;
    this.filter.patchValue({
      order_by: this.sortValue
    })
    this.page = 1;
    this.getChats()
  }

  getChatsCount() {
    this.statuses.filter(y => y.id).map(t => t.hasChats = false);
    this.attachSubscriptions(
      forkJoin(this.statuses.filter(y => y.id).map(x => this.chatService.getTaskChatsCount(this.data.company.id, this.data.task.id, x.id).pipe(
        tap(z => {
          console.log(x);
          if (z.headers.get('x-pagination-total-count') > 0) {
            x.hasChats = true;
          }
        })
      ))).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (!!event && (event.ctrlKey || event.metaKey)) {
      if (id == 0) {
        this.activeStatus = [0];
      } else if (this.activeStatus.includes(id)) {
        this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
      } else if (this.activeStatus.includes(0) && id != 0) {
        this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
        this.activeStatus.push(id);
      } else {
        this.activeStatus.push(id);
      }
      if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
        this.activeStatus = [0];
      }
    } else {
      if (id == 0) {
        this.activeStatus = [0];
      } else {
        this.activeStatus = [id];
      }
    }

    this.filter.patchValue({
      status_id: this.activeStatus
    })
    this.page = 1;
    this.getChats()
  }

  openNotes(item) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        user: this.data.user,
        type: 'chat',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
            this.big_monitor = false;
          } else {
            this.is_mobile = false;
            if (wWidth >= 1400) {
              this.big_monitor = true;
            } else {
              this.big_monitor = false;
            }
          }
        }
      )
    )
  }


  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("getNextProfiles")
      this.getChats();
    }
  }

  getChats(bool: boolean = true) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.chatService.getCaseChats(this.page, this.data.company.id, this.data.task.id, this.filter.value).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(resp => {
          this.chatService.taskChatsData$.next(resp)
        }),
      ).subscribe(resp => {
        if (this.page == 1) {
          this.chats = resp
        } else {
          this.chats.push(...resp);
        }
        console.log("getChats" + this.page, this.chats);
        this.isLoad = false;
        this.page++;
        
        if (bool && this.isInit && this.data.chat_id && this.chats.find(el => el.id == this.data.chat_id)) {
          this.goToChat(this.chats.find(el => el.id == this.data.chat_id));
          this.isInit = false;
        }
      })
    )
  }

  goToChat(chat) {
    if (!this.data.task.operations || !this.data.task.operations.length) {
      this.layoutService.showSnackBar({name: ''}, marker("Wait we are getting card jobs. Try again later."), SnackBarItem)
      return
    }

    console.log(chat);
    if(chat.is_read === '0') {
      this.data.task.read_discussion_count = this.data.task.read_discussion_count + 1;
    }
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.data.company,
        task: this.data.task,
        tasks: this.data.tasks,
        work: this.data.task.operations.find(x => x.id == chat.task_operation_id),
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: chat,
        dialogType: 'operation_chat',
        chats: this.chats,
        dialogComp: this.dialogRef,
        operationsValues: this.data.operationsValues
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.page = 1;
          this.chats = [];
          this.getChatsCount();
          this.getChats();
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  deleteChat(chat) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("chat")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.chatService.deleteChat(chat.id, this.data.company.id).subscribe(resp => {
            this.taskBarService.removeItems$.next({
              task_id: chat.task_id,
              task_operation_id: chat.task_operation_id,
              discussion_id: chat.id,
              company_id: chat.company_id
            })
            this.chats.splice(this.chats.indexOf(chat), 1);
          })
        )
      }
    });

  }

  dblEditChat(e, chat, status) {
    if (!status) {
      return
    }

    this.editChat(chat);

  }

  addAdditionalEmplToWork(task, work, discussion) {
    const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: task,
        work: work,
        discussion: discussion
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "add") {
          this.additionalEmployees(task, work, discussion);
        }
      })
    )
  }

  additionalEmployees(task, work, discussion) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: task,
        work: work,
        discussion: discussion
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == "update") {
          
    //     }
    //   })
    // )
  }

  neededTaskData(task) {
    let arr = [];
    if (this.data.company.permissions.includes('owner') || this.data.checkIsManager()) {
      arr.push(this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }
    arr.push(this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
      tap(res => {
        task['operations'].map(o_el => {
          o_el['chatsInfo'] = [];
          o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
          o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
        });
        task['chatsInfo'] = [];
        task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
        task['unReadChats'] = res.filter(z => z.is_read != '1').length;
        task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
      })
    ))
    return forkJoin(arr)
  }

  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.data.company.permissions.includes('owner') || this.data.checkIsManager(),
            operationsValues: this.data.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: chat.id});
            this.page = 1;
            this.getChats();
          })
        )
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  editChat(chat) {
    if (!this.data.task.operations || !this.data.task.operations.length) {
      this.layoutService.showSnackBar({name: ''}, marker("Wait we are getting card jobs. Try again later."), SnackBarItem)
      return
    }
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        tasks: this.data.tasks,
        is_create_chat_component: true,
        company: this.data.company,
        task: this.data.task,
        work: this.data.task.operations.find(x => x.id == chat.task_operation_id),
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: chat
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          console.log("result", result);
          
          // if (result.beforeChatStatus != 4 && result.chatStatus == 4) {
          //   this.data.task.unClosedChats = this.data.task.unClosedChats - 1;
          //   this.data.work.unClosedChats = this.data.work.unClosedChats - 1;
          // } else if (result.beforeChatStatus == 4 && result.chatStatus != 4) {
          //   this.data.task.unClosedChats = this.data.task.unClosedChats + 1;
          //   this.data.work.unClosedChats = this.data.work.unClosedChats + 1;
          // }

          // if (result.unClosedChats == 1 && result.chatStatus == 4) {
          //   this.taskService.editWork(this.data.work.id, {status_id: 3}, this.data.company.id).subscribe(response => {
          //     this.data.work.status_id = response.status_id;
          //   })
          // }
          this.page = 1;
          this.getChats();
          this.getChatsCount();
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  getColumnName(id) {
    if (!this.data.operationsValues || this.data.operationsValues?.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues?.find(el => el.id == id)?.translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
