<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ (data.is_loc ? "Destination location" : "Source location") | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <ng-container *ngIf="!data.is_loc; else elseTemplateLoc">

            <h3>{{"From what Cards" | translate}}</h3>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Type of relation" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [formControl]="activeRelation">
                    <mat-option *ngFor="let item of relationTypes" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card type" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_statuses_ids">
                    <mat-option *ngFor="let item of data.taskStatuses" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card group" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_groups_ids">
                    <mat-option *ngFor="let group of data.groups" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card tag" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_parameter_values_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.task_parameter_values_ids && data.source.task_parameter_values_ids.length">
                            <ng-container *ngFor="let id of data.source.task_parameter_values_ids">
                                <span>{{ getValuesById(id)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <h3>{{"What job" | translate}}</h3>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job type" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_operations_ids">
                    <mat-option *ngFor="let item of data.operations" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job status" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_statuses_ids">
                    <mat-option *ngFor="let item of data.operationStatuses" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job executor" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_employees_ids">
                    <mat-option *ngFor="let item of data.employees" [value]="item.id">
                        <app-user [class]="'user_div'" [employee]="item"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job partner" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_partner_companies_ids">
                    <mat-option *ngFor="let item of data.partners" [value]="item.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="item?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="toggle_checks">
                <div (click)="toggleKey(data.source, 'is_skip_task_operation_parameters', 'is_none_task_operation_parameters')" [ngClass]="{'active': !!data.source.is_skip_task_operation_parameters}">
                    <mat-icon *ngIf="!!data.source.is_skip_task_operation_parameters" class="mr-5">check_circle</mat-icon>
                    {{ "Skip this check" | translate }}
                </div>
                <div (click)="toggleKey(data.source, 'is_none_task_operation_parameters', 'is_skip_task_operation_parameters')" [ngClass]="{'active': !!data.source.is_none_task_operation_parameters}">
                    <mat-icon *ngIf="!!data.source.is_none_task_operation_parameters" class="mr-5">check_circle</mat-icon>
                    {{ "Execute if source job has no such tag(s) either" | translate }}
                </div>
            </div>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job has same tag from tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_parameters_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.task_operation_parameters_ids && data.source.task_operation_parameters_ids.length">
                            <ng-container *ngFor="let id of data.source.task_operation_parameters_ids">
                                <span>{{ getParamById(id)?.name }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                        [formControl]="paramControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of parameters$ | async">
                        <mat-option *ngIf="!!item.id" [value]="item.id">
                            <span>{{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job tag(s)" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_parameter_values_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.task_operation_parameter_values_ids && data.source.task_operation_parameter_values_ids.length">
                            <ng-container *ngFor="let id of data.source.task_operation_parameter_values_ids">
                                <span>{{ getValuesById(id)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <h3>{{"What files" | translate}}</h3>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Locate in folder" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.file_directories">
                    <mat-option *ngFor="let item of data.folders" [value]="item.location">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "File tag(s)" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.file_parameter_values_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.file_parameter_values_ids && data.source.file_parameter_values_ids.length">
                            <ng-container *ngFor="let id of data.source.file_parameter_values_ids">
                                <span>{{ getValuesById(id)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "File has one of the tags" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.file_or_parameter_values_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.file_or_parameter_values_ids && data.source.file_or_parameter_values_ids.length">
                            <ng-container *ngFor="let id of data.source.file_or_parameter_values_ids">
                                <span>{{ getValuesById(id)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Check list(s)" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.file_operation_reminders_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.file_operation_reminders_ids && data.source.file_operation_reminders_ids.length">
                            <ng-container *ngFor="let id of data.source.file_operation_reminders_ids">
                                <span>{{ getChecklistsById(id)?.text }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching check lists found' | translate }}"
                        [formControl]="checklistsControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of checklists$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.text }}</span>
                            <span>{{ item?.group?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="toggle_checks">
                <div (click)="toggleKey(data.source, 'is_skip_file_parameters', 'is_none_file_parameters')" [ngClass]="{'active': !!data.source.is_skip_file_parameters}">
                    <mat-icon *ngIf="!!data.source.is_skip_file_parameters" class="mr-5">check_circle</mat-icon>
                    {{ "Skip this check" | translate }}
                </div>
                <div (click)="toggleKey(data.source, 'is_none_file_parameters', 'is_skip_file_parameters')" [ngClass]="{'active': !!data.source.is_none_file_parameters}">
                    <mat-icon *ngIf="!!data.source.is_none_file_parameters" class="mr-5">check_circle</mat-icon>
                    {{ "Execute if source file has no such tag(s) either" | translate }}
                </div>
            </div>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "File has same tag from {tag category} as destination job" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.file_parameters_ids">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="data.source.file_parameters_ids && data.source.file_parameters_ids.length">
                            <ng-container *ngFor="let id of data.source.file_parameters_ids">
                                <span>{{ getParamById(id)?.name }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                        [formControl]="paramControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of parameters$ | async">
                        <mat-option *ngIf="!!item.id" [value]="item.id">
                            <span>{{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            
        </ng-container>
        <ng-template #elseTemplateLoc>
            <h3>{{"Where to locate" | translate}}</h3>
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Locate in folder" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="data.source.destination_directory">
                    <mat-option *ngFor="let item of data.folders" [value]="item.location">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
