<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Choose a status" | translate }}:
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!statusForm.valid ? null : onSubmit()">
    <div class="container">
        <form [formGroup]="statusForm">
            <div class="custom_radio">
                <mat-radio-group required formControlName="status_id">
                  <mat-radio-button color="primary" *ngFor="let status of data.statuses" [disabled]="(data?.company?.permissions.includes('client') && data?.chat?.user_id != data?.user?.id && (status.is_lock == 1 || statusForm.get('status_id').value == 4 ||  statusForm.get('status_id').value == 99)) || (data?.company?.permissions.includes('employee') && !data?.company?.permissions.includes('manager') && (status.is_lock == 1 || statusForm.get('status_id').value == 4 ||  statusForm.get('status_id').value == 99))" [value]="status.id">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!statusForm.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>