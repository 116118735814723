import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-default-filter',
  templateUrl: './mob-default-filter.component.html',
  styleUrls: ['./mob-default-filter.component.scss']
})

export class MobDefaultFilterComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 300;
  scrollUpDistance = 3.5;
  public switched: boolean = false;
  public statusKey: string = 'content_status_id'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobDefaultFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobDefaultFilterComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  setStatusKey(val) {
    this.statusKey = val;
    this.data.control = this.statusKey;
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }

  noOperation(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.data.filter.patchValue({
      no_operation_id: id,
    })
    this.onSubmit();
    // jobTypeSelect.close();
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
