<mat-drawer-container class="settings_out_page" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
        <div class="menu">
            <ng-container *ngFor="let menuItem of menuItems">
                <div class="menu_item" [ngClass]="{'active': menuItem.tab == activeParentTab}" *ngIf="!menuItem?.is_root || !!user?.is_root">
                    <div class="menu_trigger" (click)="goToMenuItem(menuItem)">
                        <ng-container [ngSwitch]="menuItem.tab">
                            <ng-container *ngSwitchCase="'automation'">
                                <div class="icon_wrp">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="black"/>
                                    </svg>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-icon>{{menuItem.icon}}</mat-icon>
                            </ng-container>
                        </ng-container>
                        <h4 *ngIf="isExpanded">{{ menuItem.name | translate }}</h4>
                    </div>
    
    
    
                    <div class="sub_items" *ngIf="menuItem.subItems.length && isExpanded">
                        <ng-container *ngFor="let sub of menuItem.subItems">
                            <div *ngIf="(!sub?.is_root || !!user?.is_root) && ((['Tags'].includes(sub.name) && (company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions?.includes('manager'))) || !['Tags'].includes(sub.name))" class="sub_item" [ngClass]="{'active': sub.tab == 'finances' ? (sub.tab == activeTab && sub.subTab == activeSubTab) : sub.tab == activeTab }" (click)="goToMenuItem(sub)">
                                {{ sub.name | translate }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            
        </div>
        <!-- <mat-nav-list class="menu_list">
            <mat-list-item (click)="goToMenuItem(menuItem)" [ngClass]="{'active_menu': menuItem.tab == activeParentTab}" *ngFor="let menuItem of menuItems">
                <ng-container [ngSwitch]="menuItem.tab">
                    <ng-container *ngSwitchCase="'automation'">
                  
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <mat-icon mat-list-icon>{{menuItem.icon}}</mat-icon>
                    </ng-container>
                </ng-container>

                <h4 mat-line *ngIf="isExpanded">{{ menuItem.name | translate }}</h4>
            </mat-list-item>
        </mat-nav-list> -->
        <div class="menu_btn">
            <button mat-mini-fab (click)="isExpanded = !isExpanded" color="warn" style="margin: 10px;">
            <mat-icon aria-label="Menu">
                <ng-container *ngIf="isExpanded; else elseTemplatemenu_open">
                    menu_open
                </ng-container>
                <ng-template #elseTemplatemenu_open>
                    menu
                </ng-template>
            </mat-icon>
            </button>
        </div>
    </mat-drawer>
  
    <mat-drawer-content>
        <div class="container" [ngClass]="{'full_w': activeTab == 'manage-auth'}">
            <div class="root_content" [ngSwitch]="activeTab">

                <div class="settings_page" *ngSwitchCase="'finances_dashboard'">
                    <app-finances-dashboard></app-finances-dashboard>
                </div>
                
                <div class="settings_page" *ngSwitchCase="'finances'">
                    <app-finances></app-finances>
                </div>

                <div class="settings_page" *ngSwitchCase="'automation'">
                    <app-scenarios></app-scenarios>
                </div>
                <div class="settings_page" *ngSwitchCase="'checklists'">
                    <app-checklist></app-checklist>
                </div>


                <div class="settings_page" *ngSwitchCase="'workspace_members'">
                    <app-members-dashboard></app-members-dashboard>
                </div>
                <div class="settings_page" *ngSwitchCase="'individuals'">
                    <app-employees></app-employees>
                </div>
                <div class="settings_page" *ngSwitchCase="'schedules'">
                    <app-employees-schedules></app-employees-schedules>
                </div>
                <div class="settings_page" *ngSwitchCase="'partner_workspaces'">
                    <app-partner-workspaces></app-partner-workspaces>
                </div>
                <div class="settings_page" *ngSwitchCase="'questionnaires'">
                    <ng-container *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr'); else elseTmplQuests">
                        <app-queasts-list></app-queasts-list>
                    </ng-container>
                    <ng-template #elseTmplQuests>
                        <app-quest></app-quest>
                    </ng-template>
                </div>
                <div class="settings_page" *ngSwitchCase="'form'">
                    <app-quest></app-quest>
                </div>
                <div class="settings_page" *ngSwitchCase="'users'">
                    <app-users></app-users>
                </div>


                <div class="settings_page" *ngSwitchCase="'categorization'">
                    <app-categorization-dashboard></app-categorization-dashboard>
                </div>
                <div class="settings_page" *ngSwitchCase="'structure'">
                    <app-company-structure></app-company-structure>
                </div>
                <div class="settings_page" *ngSwitchCase="'task_types'">
                    <app-task-types></app-task-types>
                </div>
                <div class="settings_page" *ngSwitchCase="'job_types'">
                    <app-job-types></app-job-types>
                </div>
                <div class="settings_page" *ngSwitchCase="'company'">
                    <app-company></app-company>
                </div>
                <div class="settings_page" *ngSwitchCase="'parameters'">
                    <app-workspace-parameters></app-workspace-parameters>
                </div>
                <div class="settings_page" *ngSwitchCase="'bookmarks'">
                    <app-bookmarks [user]="user" [host]="host" [company]="company" [imgRoute]="imgRoute"></app-bookmarks>
                </div>

                <div class="settings_page" *ngSwitchCase="'profiles'">
                    <app-profile></app-profile>
                </div>
                <div class="settings_page" *ngSwitchCase="'sftp'">
                    <app-dropboxes></app-dropboxes>
                </div>
                <div class="settings_page" *ngSwitchCase="'manage-auth'">
                    <app-manage-auth [user]="user" [company_id]="company_id" [company]="company" [imgRoute]="imgRoute"></app-manage-auth>
                </div>
                <div class="settings_page" *ngSwitchCase="'import-data'">
                    <app-import-data></app-import-data>
                </div>
                <div class="settings_page" *ngSwitchCase="'usage'">
                    <app-usage></app-usage>
                </div>
                <div class="settings_page" *ngSwitchCase="'total_usage'">
                    <app-total-usage></app-total-usage>
                </div>
                <div class="settings_page" *ngSwitchCase="'dubbing_usage'">
                    <app-dubbing-usage></app-dubbing-usage>
                </div>

                <div class="settings_page" *ngSwitchCase="'billing'">
                    <app-billing-dashboard></app-billing-dashboard>
                </div>
                <div class="settings_page" *ngSwitchCase="'transactions'">
                    <app-transactions></app-transactions>
                </div>
                <div class="settings_page" *ngSwitchCase="'my_services'">
                    <app-my-services></app-my-services>
                </div>

                <div class="settings_page" *ngSwitchCase="'discontinued'">
                    <app-discontinued-dashboard></app-discontinued-dashboard>
                </div>
                <div class="settings_page" *ngSwitchCase="'stats'">
                    <ng-container *ngIf="company?.permissions.includes('owner'); else elseTmplStats">
                        <app-stat-by-month-creator></app-stat-by-month-creator>
                    </ng-container>
                    <ng-template #elseTmplStats>
                        <app-stat-by-month></app-stat-by-month>
                    </ng-template>
                </div>


            </div>
        </div>
    </mat-drawer-content>
  </mat-drawer-container>
  

<ng-template #elseTemplatePhoto>
    <mat-icon>account_circle</mat-icon>
</ng-template>