<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Get Files settings" | translate }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container task_container form_wrapper" style="padding: 20px; width: calc(100% - 40px);">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Select job" | translate }}</mat-label>
            <!-- (selectionChange)="selectValue($event)" -->
            <mat-select [disableOptionCentering]="true" selectDir #consistContainer [(ngModel)]="data.getFilesSettings.job_id">
                <mat-select-trigger>
                    <div class="job_item" *ngIf="data.getFilesSettings.job_id && getCardJobById(data.getFilesSettings.job_id)">
                        <div class="item_header">
                            <div class="primary_tag">
                                <div class="text_one_line">{{ getCardJobById(data.getFilesSettings.job_id)?.operation?.name }}</div>
                                <span *ngIf="getPrimaryTag(getCardJobById(data.getFilesSettings.job_id))" class="text_one_line val">{{getPrimaryTag(getCardJobById(data.getFilesSettings.job_id))?.parameterValue?.value}}</span>
                            </div>
                            <div class="id">{{ getCardJobById(data.getFilesSettings.job_id)?.id }}</div>
                        </div>
                        <div class="name text_one_line">{{ getCardJobById(data.getFilesSettings.job_id)?.name }}</div>
                    </div>
                    <div class="job_item" *ngIf="data.getFilesSettings.job_id == 0">
                        {{ "From Card" | translate}}
                    </div>
                </mat-select-trigger>

                <mat-option>
                    <ngx-mat-select-search 
                    placeholderLabel="{{ 'Search' | translate }}" 
                    noEntriesFoundLabel="{{ 'no matching card jobs found' | translate }}"
                    [formControl]="cardJobControl"></ngx-mat-select-search>
                </mat-option>

                <mat-option class="job_option" [value]="0">
                    <div class="job_item" style="height: 36px; line-height: 36px;">
                        {{ "From Card" | translate}}
                    </div>
                </mat-option>
                <mat-option *ngFor="let job of cardJobs$ | async" class="job_option" [value]="job.id">
                    <div class="job_item">
                        <div class="item_header">
                            <div class="primary_tag">
                                <div class="text_one_line">{{ job?.operation?.name }}</div>
                                <span *ngIf="getPrimaryTag(job)" class="text_one_line val">{{getPrimaryTag(job)?.parameterValue?.value}}</span>
                            </div>
                            <div class="id">{{ job?.id }}</div>
                        </div>
                        <div class="name text_one_line">{{ job?.name }}</div>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Folder' | translate }}</mat-label>
            <input [(ngModel)]="data.getFilesSettings.folder" matInput placeholder="{{ 'Folder' | translate }}">
        </mat-form-field>
        
        <p>
            <mat-checkbox [(ngModel)]="data.getFilesSettings.is_location_all">{{ "All subfolders" | translate }}</mat-checkbox>
        </p>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="primary" (click)="find()">
        {{ "Find" | translate }}
    </button>
    <!-- <button class="ml-1" mat-raised-button color="warn" (click)="findAlt()">
        {{ "Alternative Find" | translate }}
    </button> -->
</mat-dialog-actions>