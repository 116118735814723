import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleSmoothScrollService } from 'ng2-simple-smooth-scroll';
import { forkJoin, fromEvent, interval, of, Subscription } from 'rxjs';
import { BaseClass } from './shared/models/base-class';
import { LanguageService } from './shared/services/common/language.service';
import { StorageManagerService } from './shared/services/common/storage-manager.service';
import { AuthService } from './shared/services/rest/auth.service';
import { environment } from "src/environments/environment";
import * as moment from 'moment';
import { CompanyService } from './shared/services/rest/company.service';
import { TaskService } from './shared/services/rest/task.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReminderAlertComponent } from './shared/global_components/reminder-alert/reminder-alert.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InviteDialogComponent } from './components/atTasksDialog/invite-dialog/invite-dialog.component';
import { PartnerDialogComponent } from './components/main/dialogs/partners/partner-dialog/partner-dialog.component';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { GlobalDataService } from './shared/services/common/global-data.service';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { RefreshService } from './shared/services/rest/refresh.service';
import { HttpRestService } from './shared/services/common/http-rest.service';
import { ApproveRejectFilesComponent } from './shared/global_components/approve-reject-files/approve-reject-files.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BackJobToPrevStatusComponent } from './shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { ChangeScenarioDataComponent } from './components/workspace-settings/automation/scenarios/dialogs/change-scenario-data/change-scenario-data.component';
import { TaskBarService } from './shared/services/rest/task-bar.service';
import { SelectEmployeeForEditChatStatus } from './shared/global_components/select-employee-for-edit-chat-status/select-employee-for-edit-chat-status.component';
import { FileService } from './shared/services/rest/file.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseClass implements OnInit, OnDestroy {
  public imgRoute: any = "";
  public host: any = environment.host;
  public hasReminder: boolean = false;
  public is_mobile: boolean = false;
  public isGetGlobalData: boolean = false;
  public user: any;
  public user_id: number;
  public languages: string[];
  public activeLang: any;
  public tokenData: any[] = [];
  public company: any;
  public invite: string = '';
  public companyForm: FormGroup = this.fb.group({
    companyID: 0
  })
  public origin = window.location.origin
  private sub: Subscription;
  private miniSub: Subscription;
  public dialogRef: MatDialogRef<ReminderAlertComponent>;
  public is_expanded: boolean = false;
  public is_loader_expanded: boolean = false;

  public isVisible: boolean = true;
  public isInit: boolean = false;
  public VAPID_PUBLIC_KEY = "BF9wWV_MHCWQeSWLESUKYNtsHHr4qTzisV3GSLQmIzKKNzzdTZXHsxeRPKo0HNndeAL9WP9OFc8VqpfQofWo2CQ";
  
  constructor(
    private sm: StorageManagerService, 
    private smooth: SimpleSmoothScrollService,
    private auth: AuthService,
    private http: HttpRestService,
    private refreshService: RefreshService,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private translate: TranslateService,
    private taskBarService: TaskBarService,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet
    ) {
      super();
    }
  ngOnInit(): void {
    window.scroll(0,0);
    this.getToken();

    this.attachSubscriptions(
      interval(30000).pipe(
        tap(() => {
          let audios = Array.from(document.getElementsByTagName('audio'))
          let videos = Array.from(document.getElementsByTagName('video'))

          if (audios.filter(item => !item.paused).length > 0) {
            this.tokenData.push({
              company_id: this.company.id,
              type: 'audio_listen',
              start_time: 0,
              end_time: 0,
              time: 0,
            })
          }

          if (videos.filter(item => !item.paused).length > 0) {
            this.tokenData.push({
              company_id: this.company.id,
              type: 'video_watch',
              start_time: 0,
              end_time: 0,
              time: 0,
            })
          }

          let unixTime = moment().unix();
          this.tokenData.forEach(el => {
            el.start_time = (unixTime - 30);
            el.end_time = unixTime;
            el.time = unixTime;
          })
          // console.log("tokenData audios", audios);
          // console.log("tokenData video", videos);
        }),
        switchMap(x => {
          return this.auth.getToken(this.tokenData.length > 0 ? {telemetry: this.tokenData} : {})
        })
      ).subscribe((resp) => {
        if (this.user.hasOwnProperty("id") && (this.user_id != this.user.id)) {
          this.refresh();
        } else {
          this.auth.$userToken.next(resp)
        }
        this.tokenData = []
      })
    )

    this.attachSubscriptions(
      interval(10000).pipe(
        filter(x => this.isVisible && !!this.sm.localStorageGetItem('download_archives') && this.user && !!this.user.id),
        switchMap(() => {
          let archives = JSON.parse(this.sm.localStorageGetItem('download_archives'))
          if (archives && archives.length && archives.filter(x => x.u_id == this.user.id).length) {
            return forkJoin([...archives.filter(x => x.u_id == this.user.id).map(archive => this.fileService.downloadCreatedArchive(archive.a_id, archive.c_id))])
          } else {
            return of(false)
          }
        })
      ).subscribe((res:any) => {
        if (res) {
          res.forEach(archiveData => {
            if (archiveData.download_url && archiveData.download_url.length) {
              let archivesIn = JSON.parse(this.sm.localStorageGetItem('download_archives'))
              archivesIn.splice(archivesIn.indexOf(archivesIn.find(b => b.a_id == archiveData.id)),1)
              this.sm.localStorageSetItem('download_archives', JSON.stringify(archivesIn))

              async function loop() {
                for (let i = 0; i < archiveData.download_url.length; i++) {
                  await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
                  console.log(archiveData.download_url[i]); // вывод блока
                  if (archiveData.download_url[i]) {
                    let a = document.createElement('a');
                    a.setAttribute('href', archiveData.download_url[i]);
                    a.setAttribute('download','download');
                    // a.target = "_blank"
                    a.click();
                  }
                }
              }
              loop();
            }
          });
        }
      })
    )

    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
    this.getInitData();

    console.log(this.router)
    console.log(window.location.pathname)


    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.onResize();
    this.getActiveLang();


    this.attachSubscriptions(
      this.refreshService.getForceRequest().subscribe(resp => {
        console.log("getForceRequest", resp, resp.err.error.name)
        switch (resp.err.error.name) {
          case 'RemoveEmployeeToDepartmentPosition':
            console.log("RemoveEmployeeToDepartmentPosition", resp);
            let msg = '';
            if (!!resp.err.error.params.department_position_id) {
              msg = 'Deleting a Position requires confirmation '
              if (resp.err.error.params.employees_to_department_count) {
                msg += 'because: it has' + resp.err.error.params.employees_to_department_count + 'assignments for members'
              }
            } else {
              msg = 'Deleting a Department requires confirmation because: '
              if (resp.err.error.params.employees_to_department_count) {
                msg += 'it has' + resp.err.error.params.employees_to_department_count + 'assignments for members'

                if (resp.err.error.params.department_positions_count) {
                  msg += `, it contains ${resp.err.error.params.department_positions_count} positions`
                }
              } else {
                if (resp.err.error.params.department_positions_count) {
                  msg += `it contains ${resp.err.error.params.department_positions_count} positions`
                }
              }
            }

            let posDepAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                msg: msg,
                btn_no: "Cancel",
                btn_yes: "Confirm"
              }
            });

            posDepAlert.afterDismissed().subscribe(data => {
              console.log('posDepAlert data', data)
              if (data && data.message == 'no') {
                console.log("no");
              } else if (data && data.message == 'yes') {
                this.attachSubscriptions(
                  this.http.executeDelete('/api/' + resp.request.url.split("/api/")[1], true, {
                    params: resp.request.params,
                    body: Object.assign({
                      [resp.err.error.message]: {
                        __is_force: 1
                      }
                    }, resp.request.body),
                  }).pipe(map(() => resp)).subscribe(rslt => {
                    if (!!resp.err.error.params.department_position_id) {
                      this.companyService.posDeleted$.next({department_id: resp.err.error.params.department_id, department_position_id: resp.err.error.params.department_position_id})
                    } else {
                      this.companyService.depDeleted$.next({department_id: resp.err.error.params.department_id})
                    }
                  })
                )
              }
            })
            break;
          case 'RemoveChannelToEmployee':
            console.log("RemoveEmployeeToDepartmentPosition", resp);
            let chMsg = '';
            chMsg = 'Deleting a See Task Channels role requires confirmation '
            if (!!resp.err.error.params.channels_to_employee_count) {
              chMsg += 'because: this member has ' + resp.err.error.params.channels_to_employee_count + ' assigned channels'
            }
          
            let remChanToEmpl =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                msg: chMsg,
                btn_no: "Cancel",
                btn_yes: "Confirm"
              }
            });

            remChanToEmpl.afterDismissed().subscribe(data => {
              console.log('remChanToEmpl data', data)
              if (data && data.message == 'no') {
                console.log("no");
              } else if (data && data.message == 'yes') {
                this.attachSubscriptions(
                  this.http.executeDelete('/api/' + resp.request.url.split("/api/")[1], true, {
                    params: resp.request.params,
                    body: Object.assign({
                      [resp.err.error.message]: {
                        __is_force: 1
                      }
                    }, resp.request.body),
                  }).pipe(map(() => resp)).subscribe(rslt => {
                    this.companyService.chToEmDeleted$.next({rule_id: +resp.request.url.split('/').filter(u => !!u)[resp.request.url.split('/').filter(u => !!u).length - 1], employee_id: resp.err.error.params.employee_id})
                  })
                )
              }
            })
            break;
          case 'SortToApproveFiles':
            const dialogRef = this.dialog.open(ApproveRejectFilesComponent, {
              panelClass: 'without_paddings_modal',
              data: {
                target: resp.err.error.params.task_operation,
                val: resp,
                company_id: resp.request.params.get('company_id')
              }
            });
    
            dialogRef.afterClosed().subscribe(result => {
              console.log("SortToApproveFiles res", result)
            })
            break;

          case 'SortDiscussionsStatuses':
            let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                msg: marker(`Do you really want to convert all chats to status: '${this.getOperationStatusName(resp.request.body.status_id || 98)}'?`)
              }
            });
        
            questionAlert.afterDismissed().subscribe(data => {
              if (data && data.message == 'no') {
                console.log("no");
              } else if (data && data.message == 'yes') {
                if (resp.request.method == 'POST') {
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                          __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      this.refreshService.refreshJob$.next({company_id: resp.err.error.params.task_operation.company_id, job_id: resp.err.error.params.task_operation.id})
                      this.refreshService.refreshTask$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id})
                      this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id, task_operation_id: resp.err.error.params.task_operation.id})
                    })
                  )
                } else if (resp.request.method == 'DELETE') {
                  this.attachSubscriptions(
                    this.http.executeDelete('/api/' + resp.request.url.split("/api/")[1], true, {
                      params: resp.request.params,
                      body: Object.assign({
                        [resp.err.error.message]: {
                          __is_force: 1
                        }
                      }, resp.request.body),
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      console.log("SortDiscussionsStatuses", rslt)
                      console.log("SortDiscussionsStatuses", resp)
                      this.refreshService.refreshChecklist$.next({type: 'delete', all: true})
                    })
                  )
                }
              }
            })
            break;

          case 'ConfirmScenarioExecution':
            console.log("ConfirmScenarioExecution", resp);

            let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                msg: marker(`This scenario requires you to confirm.`),
                btn_no: "Change",
                btn_yes: "Confirm"
              }
            });

            confirmAlert.afterDismissed().subscribe(data => {
              if (data && data.message == 'no') {
                console.log("no");
                const changeDialog = this.dialog.open(ChangeScenarioDataComponent, {
                  panelClass: 'without_paddings_modal',
                  data: {
                    val: resp
                  }
                });
        
                changeDialog.afterClosed().subscribe(result => {
                  console.log(result)
                })
              } else if (data && data.message == 'yes') {
                if (resp.request.method == 'POST') {
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                          __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      this.refreshService.refreshJob$.next({company_id: resp.err.error.params.task_operation.company_id, job_id: resp.err.error.params.task_operation.id})
                      this.refreshService.refreshTask$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id})
                      this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id, task_operation_id: resp.err.error.params.task_operation.id})
                    })
                  )
                }
              }
            })
            break
          case 'ConfirmScenarioMultipleEmployees':
            console.log("ConfirmScenarioMultipleEmployees", resp);

            let confirmScenarioMultipleEmployees =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                msg: marker(`This scenario needs to be confirmed as for chats based on channels there are those where 2 or more executors are selected.`),
                btn_no: "Change",
                btn_yes: "Confirm"
              }
            });

            confirmScenarioMultipleEmployees.afterDismissed().subscribe(data => {
              if (data && data.message == 'no') {
                console.log("no");
                const changeDialog = this.dialog.open(ChangeScenarioDataComponent, {
                  panelClass: 'without_paddings_modal',
                  data: {
                    val: resp,
                  }
                });
        
                changeDialog.afterClosed().subscribe(result => {
                  console.log(result)
                })
              } else if (data && data.message == 'yes') {
                if (resp.request.method == 'POST') {
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                          __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      this.refreshService.refreshJob$.next({company_id: resp.err.error.params.task_operation.company_id, job_id: resp.err.error.params.task_operation.id})
                      this.refreshService.refreshTask$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id})
                      this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id, task_operation_id: resp.err.error.params.task_operation.id})
                    })
                  )
                }
              }
            })
            break

          case "DiscussionHasAssignedEmployee":
            console.log("DiscussionHasAssignedEmployee", resp);

            let confirmDiscussinAlert = this.bottomSheet.open(SelectEmployeeForEditChatStatus, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                resp: resp,
                company: this.company,
                btn_no: "Cancel",
                btn_yes: "Change"
              }
            });

            confirmDiscussinAlert.afterDismissed().subscribe(data => {
              if (data && data.message == 'no') {
                console.log("no");
              } else if (data && data.message == 'yes') {
                console.log("yes")
                if (resp.request.method == 'POST') {
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                          __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      this.refreshService.refreshJob$.next({company_id: resp.err.error.params.discussion.company_id, job_id: resp.err.error.params.discussion.task_operation_id})
                      this.refreshService.refreshTask$.next({company_id: resp.err.error.params.discussion.company_id, task_id: resp.err.error.params.discussion.task_id})
                      this.refreshService.refreshChat$.next({company_id: resp.err.error.params.discussion.company_id, chat_id: resp.err.error.params.discussion.id})
                      this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.discussion.company_id, task_id: resp.err.error.params.discussion.task_id, task_operation_id: resp.err.error.params.discussion.task_operation_id})
                    })
                  )
                }
              }
            })

            break;
          case "TaskOperationHasAssignedEmployee":
            console.log("TaskOperationHasAssignedEmployee", resp);

            let TaskOperationHasAssignedEmployee = this.bottomSheet.open(SelectEmployeeForEditChatStatus, {
              hasBackdrop: true,
              backdropClass: 'bottom-sheed-backdrop',
              data: {
                resp: resp,
                btn_no: "Cancel",
                btn_yes: "Change"
              }
            });

            TaskOperationHasAssignedEmployee.afterDismissed().subscribe(data => {
              if (data && data.message == 'no') {
                console.log("no");
              } else if (data && data.message == 'yes') {
                console.log("yes")
                if (resp.request.method == 'POST') {
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                        __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      this.refreshService.refreshJob$.next({company_id: resp.err.error.params.task_operation.company_id, job_id: resp.err.error.params.task_operation.id})
                      this.refreshService.refreshTask$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id})
                      this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id, task_operation_id: resp.err.error.params.task_operation.id})
                    })
                  )
                }
              }
            })

            break;
          case "ConfirmUpdateIsReminder":
            console.log("ConfirmUpdateIsReminder", resp);
            if (resp.err.error.message.indexOf("ConfirmUpdateIsReminder_for_detele_operation_reminder") != -1) {
              let deleteChecklist = this.bottomSheet.open(BackJobToPrevStatusComponent, {
                hasBackdrop: true,
                backdropClass: 'bottom-sheed-backdrop',
                data: {
                  count: marker(`There are`) + " " + resp.err.error.params.task_operations_count + " " + marker(`works`),
                  msg: marker(`have the Approved status and these works have these checklists and after saving the changes, these checklists will disappear from these works, and the works will be transferred to the Ready status.`),
                  btn_no: "Cancel",
                  btn_yes: "Save"
                }
              });
              
              deleteChecklist.afterDismissed().subscribe(data => {
                if (data && data.message == 'no') {
                  console.log("no");
                } else if (data && data.message == 'yes') {
                  console.log("yes")
                  this.attachSubscriptions(
                    this.http.executeDelete('/api/' + resp.request.url.split("/api/")[1], true, {
                      params: resp.request.params,
                      body: Object.assign({
                        [resp.err.error.message]: {
                          __is_force: 1
                        }
                      }, resp.request.body),
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      console.log(rslt)
                      console.log(resp)
                      this.refreshService.refreshChecklist$.next({type: 'delete', data: resp.err.error.params.delete_operation_reminder})
                    })
                  )
                }
              })
            } else if (resp.err.error.message.indexOf("ConfirmUpdateIsReminder_for_insert_operation_reminder_to_parameter_value_") != -1) {
              let setOnlyFor = this.bottomSheet.open(BackJobToPrevStatusComponent, {
                hasBackdrop: true,
                backdropClass: 'bottom-sheed-backdrop',
                data: {
                  msg: marker(`There are works that have the Approved status and these works have these checklists and after saving the changes, these checklists will disappear from these works, and the works will be transferred to the Ready status.`),
                  btn_no: "Cancel",
                  btn_yes: "Save"
                }
              });
              
              setOnlyFor.afterDismissed().subscribe(data => {
                if (data && data.message == 'no') {
                  console.log("no");
                } else if (data && data.message == 'yes') {
                  console.log("yes")
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                        __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).subscribe(rslt => {
                      console.log(rslt)
                      console.log(resp)

                      this.refreshService.refreshOnlyFor$.next({type: 'add', data: resp.err.error.params.insert_operation_reminder_to_parameter_value, result: rslt})
                    })
                  )
                }
              })
            } else if (resp.err.error.message.indexOf("ConfirmUpdateIsReminder_for_insert_operation_reminder_except_parameter_value_") != -1) {
              let setExcept = this.bottomSheet.open(BackJobToPrevStatusComponent, {
                hasBackdrop: true,
                backdropClass: 'bottom-sheed-backdrop',
                data: {
                  msg: marker(`There are works that have the Approved status and these works have these checklists and after saving the changes, these checklists will disappear from these works, and the works will be transferred to the Ready status.`),
                  btn_no: "Cancel",
                  btn_yes: "Save"
                }
              });
              
              setExcept.afterDismissed().subscribe(data => {
                if (data && data.message == 'no') {
                  console.log("no");
                } else if (data && data.message == 'yes') {
                  console.log("yes")
                  this.attachSubscriptions(
                    this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                      [resp.err.error.message]: {
                        __is_force: 1
                      }
                    }, resp.request.body), true, {
                      params: resp.request.params
                    }).subscribe(rslt => {
                      console.log(rslt)
                      console.log(resp)
                      this.refreshService.refreshExcept$.next({type: 'add', data: resp.err.error.params.insert_operation_reminder_except_parameter_value, result: rslt})
                    })
                  )
                }
              })
            } else if (resp.err.error.message.indexOf("ConfirmUpdateIsReminder_for_delete_operation_reminder_to_parameter_value_") != -1) {
              let deleteOnlyFor = this.bottomSheet.open(BackJobToPrevStatusComponent, {
                hasBackdrop: true,
                backdropClass: 'bottom-sheed-backdrop',
                data: {
                  msg: marker(`There are works that have the Approved status and these works have these checklists and after saving the changes, these checklists will disappear from these works, and the works will be transferred to the Ready status.`),
                  btn_no: "Cancel",
                  btn_yes: "Save"
                }
              });
              
              deleteOnlyFor.afterDismissed().subscribe(data => {
                if (data && data.message == 'no') {
                  console.log("no");
                } else if (data && data.message == 'yes') {
                  console.log("yes")
                  // this.attachSubscriptions(
                  //   this.http.executePost('/api/' + resp.request.url.split("/api/")[1], Object.assign({
                  //     [resp.err.error.message]: {
                  //       __is_force: 1
                  //     }
                  //   }, resp.request.body), true, {
                  //     params: resp.request.params
                  //   }).pipe(map(() => resp)).subscribe(rslt => {
                  //     console.log(rslt)
                  //     console.log(resp)
                  //     this.refreshService.refreshOnlyFor$.next({type: 'delete', data: resp.err.error.params.insert_operation_reminder_to_parameter_value})
                  //   })
                  // )
                  this.attachSubscriptions(
                    this.http.executeDelete('/api/' + resp.request.url.split("/api/")[1], true, {
                      params: resp.request.params,
                      body: Object.assign({
                        [resp.err.error.message]: {
                          __is_force: 1
                        }
                      }, resp.request.body),
                    }).pipe(map(() => resp)).subscribe(rslt => {
                      console.log(rslt)
                      console.log(resp)
                      this.refreshService.refreshOnlyFor$.next({type: 'delete', data: resp.err.error.params.delete_operation_reminder_to_parameter_value})
                    })
                  )
                }
              })
            }
            break;
        
          default:
            break;
        }
      })
    )

    this.attachSubscriptions(
      this.companyService.getReminderStream().subscribe(resp => {
        if (+resp.count > 0) {
          this._openDialog(resp.count, resp.company);
        }
      })
    )

    this.attachSubscriptions(
      this.companyForm.valueChanges.subscribe(resp => {

        // console.log("CHANGE COMPANY !!!!", resp)
        // console.log("CHANGE COMPANY !!!!", this.companyForm.value)


        if (this.miniSub) {
          this.miniSub.unsubscribe()
        }

        if (this.companyForm.value.companyID) {
          this.miniSub = this.taskBarService.getBarItems(this.companyForm.value.companyID).pipe(
            tap(barItems => {
              barItems.forEach(barItem => {
                barItem.company = this.company
                barItem.activeLang = this.activeLang
                barItem.user = this.user;
                barItem.isOpen == false;
              });
            })
          ).subscribe(resp => {
            console.log("getBarItems", resp)
            this.taskBarService.barItems$.next(resp)
          })
        } else {
          this.taskBarService.barItems$.next([])
        }


        if (this.sub) {
          this.sub.unsubscribe()
        }

        this.sub = interval(12000000).pipe(
          filter(() => !!this.company && !!Object.keys(this.company).length && !this.company.permissions.includes('owner')),
          switchMap(x => {
              return this.taskService.getReminderTasksCount(this.company.id)
              .pipe(
                tap(resp => {
                  if (+resp > 0 && this.activatedRoute.snapshot.queryParamMap.get('operation_status_id') != '4') {
                    this._openDialog(+resp)
                  }
                })
              )
          }),
        ).subscribe((res) => {
          console.log(res)
        })
      })
    )

    this.attachSubscriptions(
      this.companyService.getCompanyStream().subscribe(resp => {
        console.log(resp)
        this.company = resp;
        console.log("getCompanyStream");
        if (this.companyForm.value.companyID != resp.id) {
          this.companyForm.patchValue({
            companyID: resp.id
          })
        }
      })
    )

    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )
    
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
        if (resp.user_id) {
          this.getGlobalData();
        }
      })
    )
  
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.translate.setDefaultLang(resp.active);
        this.languages = resp.languages;
        this.activeLang = resp.active;
      })
    )

    // setTimeout(() => {
      
    // }, 0);
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        console.log("PARAMS !!!!", params)
        if (!!params.link_to) {
          let target = params.link_to.split("_")[0]
          switch (target) {
            case 't':
              this.router.navigate([`/task/${params.acm}`])
              break;
            case 'w':
              this.router.navigate([`/job/${params.acm}`])
              break;
            case 'c':
              this.router.navigate([`/chat/${params.acm}`])
              break;
            case 'm':
              this.router.navigate([`/chat/${params.acm}`], {queryParams: {post: params.link_to.split("_")[4]}})
              break;
          }
          return
        }

        if (params.hasOwnProperty('fbclid')) {
          console.log("PARAMS this.router.url !!!!", this.router.url)
          this.router.navigate([this.router.url.split("?fbclid=")[0]])
        }

        if (params.hasOwnProperty('code')) {
          this.invite = "?code=" + this.activatedRoute.snapshot.queryParamMap.get('code');

          if (!this.user.id) {
            const dialogInvite = this.dialog.open(InviteDialogComponent, {
              data: {
                invite: this.invite,
                host: this.host,
                user: this.user
              }
            });
          
            this.attachSubscriptions(
              dialogInvite.afterClosed().subscribe(resp => {
                
              })
            )
          }
        }
        
        if (params.hasOwnProperty('invite') && params.hasOwnProperty('id')) {
          this.companyService.checkPartner(this.activatedRoute.snapshot.queryParamMap.get('id'), this.activatedRoute.snapshot.queryParamMap.get('invite')).pipe(
            switchMap(res => {
              if (!!this.user && !!this.user.id) {
                return of(res)
              } else {
                  return this.auth.getInitData().pipe(
                    tap(resp => {
                      console.log("checkPartner getInitData", resp)
                      this.user = resp.user
                      return of(res)
                    }),
                    map(() => res)
                  )
              }
            })
          ).subscribe(resp => {
            console.log("checkPartner", resp)
            if (resp.length == 0) {
              return
            }
            const dialogInvite = this.dialog.open(PartnerDialogComponent, {
              data: {
                invite: this.activatedRoute.snapshot.queryParamMap.get('invite'),
                host: this.host,
                partner: resp[0],
                user: this.user
              }
            });
          
            this.attachSubscriptions(
              dialogInvite.afterClosed().subscribe(resp => {
                
              })
            )

          })
        }
      })
    )

  }

  private _openDialog(task_count, company?) {
    if (this.dialogRef || +task_count == 0 ) {
      return;
    }
    this.dialogRef = this.dialog.open(ReminderAlertComponent, {
      data: {
        company: this.company ? this.company : company,
        task_count: task_count
      },
      width: '375px',
      backdropClass: 'blured_background'
    });
  
    this.attachSubscriptions(
      this.dialogRef.afterClosed().subscribe(resp => {
        this.dialogRef = undefined;
      })
    )
  }

  // getActiveLang() {
  //   this.languageService.languageData$.next({
  //     "active": this.sm.localStorageGetItem("active_lang") || navigator.language.split("-")[0],
  //     "languages": ["en","ru","es","uk"]
  //   });
  // }

  isMainPage() {
    return this.router.url.indexOf("/main") != -1
  }

  checkIfLink() {
    if (window.location.pathname.indexOf("/task/") != -1 || window.location.pathname.indexOf("/job/") != -1 || window.location.pathname.indexOf("/chat/") != -1 || window.location.pathname.indexOf("/file/") != -1 || window.location.pathname.indexOf("/static/privacy") != -1) {
      return true
    } else {
      return false
    }
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getGlobalData() {
    // console.log("getGlobalData", this.isGetGlobalData)
    if (this.isGetGlobalData) {
      return;
    }
    let globalReqData = [
      {
        "path": '/api/content-status/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      },
      {
        "path": '/api/currency/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      },
      {
        "path": '/api/operation-status/',
        "query": {
          'per-page': '200'
        },
        "method": "GET",
        "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
      }
    ];

    this.attachSubscriptions(
      this.taskService.multiRequest(globalReqData).subscribe(resp => {
        this.isGetGlobalData = true;
        // console.log("multiRequest", resp)
        this.globalDataService.contentStatus$.next(resp[0].body.slice())
        this.globalDataService.currencies$.next(resp[1].body.slice())
        this.globalDataService.operationStatus$.next(resp[2].body.slice())
      })
    )
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openPrivacy() {
    window.open(this.origin + '/task/qDvNw-PNvnSjO3zDk', '_self');
    // const dialogRef = this.dialog.open(PrivacyComponent, {
    //   data: {
    //     tmpl: []
    //   }
    // });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {

    //   })
    // )
  }

  getActiveLang() {
    let langData = this.languageService.getLang();
    if (langData != false) {
      this.languageService.languageData$.next(langData);
      this.translate.setDefaultLang(langData.active);
      moment.locale(langData.active)
    } 
  }
  
  hideMenu() {
    this.is_expanded = !this.is_expanded;
  }

  hideLoaders() {
    this.is_loader_expanded = !this.is_loader_expanded;
  }

  getInitData() {
    this.attachSubscriptions(
      this.auth.getInitData().subscribe(resp => {
        console.log("getInitData", resp)
        if (resp.user) {
          this.auth.$user.next(resp.user);
        }
        if (resp['language']) {
          this.languageService.languageData$.next(resp['language']);
          this.translate.setDefaultLang(resp['language'].active);
        }
        this.isInit = true;
      })
    )
  }

  goTop() {
    this.smooth.smoothScrollToTop({ duration: 500, easing: 'linear' });
  }

  refresh(): void {
    console.log("REFRESH")
    this.clearSubscriptions();
    if (this.sub) {
      this.sub.unsubscribe()
    }
    window.location.reload();
  }

  getToken(flag:boolean = false) {
    this.attachSubscriptions(
      this.auth.getToken({}).subscribe(resp => {
        if (flag && this.user.hasOwnProperty("id") && (this.user_id != this.user.id)) {
          this.refresh();
        }
        this.auth.$userToken.next(resp)
      })
    )
  }

  ngOnDestroy(): void {
    console.log("DESTROY");
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.clearSubscriptions();    
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
      this.isVisible = $event.target.visibilityState === 'visible';
      // console.log("&&&&", isVisible)
      if (this.isVisible) {
        this.getToken();
          // tab is visible
      } else {
         // tab is not-visible
      }
  }

  @HostListener('window:click', ['$event']) 
  onClickWindow(event: MouseEvent) {
    // console.log('window click', event, this.company)
    if (this.company && this.company.id && this.tokenData.filter(x => x.company_id == this.company.id && x.type == 'mouse_click').length == 0) {
      this.tokenData.push({
        company_id: this.company.id,
        type: 'mouse_click',
        start_time: 0,
        end_time: 0,
        time: 0,
      })

    }
  } 

  @HostListener('window:keydown', ['$event']) 
  onKeydownWindow(event: KeyboardEvent) {
    // console.log('window keydown', event, this.company)
    if (this.company && this.company.id && this.tokenData.filter(x => x.company_id == this.company.id && x.type == 'key_press').length == 0) {
      this.tokenData.push({
        company_id: this.company.id,
        type: 'key_press',
        start_time: 0,
        end_time: 0,
        time: 0,
      })
    }
  }
}
