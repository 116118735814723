<div [class]="class + ' outlet-line'" *ngIf="!!channel">
    <ng-container *ngIf="channel.avatarFile; else elseTemplateNoPhoto">
        <div class="channel_avatar">
            <img [src]="host + channel.avatarFile.original + '?company_id=' + company_id" alt="">
            <div class="channel_avatar_platform">
                <app-platform [platform_id]="channel?.platform_id"></app-platform>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplateNoPhoto>      
        <div class="platform_icon">
            <app-platform [platform_id]="channel?.platform_id"></app-platform>
        </div>
    </ng-template>
    <div class="outlet_info text_one_line" *ngIf="!no_name">
        {{channel?.name}}
    </div>
</div>