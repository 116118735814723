<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Text to Speech" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container" >
        <form class="speech_to_text" [formGroup]="form">
            <!-- <textarea #text readonly>{{data?.file?.document?.text}}</textarea> -->
            <mat-form-field appearance="outline" class="full_width_form">
                <mat-label>{{ "Voice" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="voice_id">
                    <mat-option [value]="item.voice_id" [ngClass]="{'cst_active_select_option': form.get('voice_id').value == item.voice_id}" *ngFor="let item of voices">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full_width_form">
                <mat-label>{{ "Voice Legacy" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="voice_id">
                    <mat-option [value]="item.voice_id" [ngClass]="{'cst_active_select_option': form.get('voice_id').value == item.voice_id}" *ngFor="let item of voicesLegacy">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full_width_form">
                <mat-label>{{ "Voice Shared" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="voice_id">
                    <mat-option [value]="item.voice_id" [ngClass]="{'cst_active_select_option': form.get('voice_id').value == item.voice_id}" *ngFor="let item of voicesShared">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="example" *ngIf="form.get('voice_id').value && !!getVoiceById(form.get('voice_id').value)">
                <h4>{{"Example" | translate}} - {{getVoiceById(form.get('voice_id').value)?.name}}</h4>
                <audio controls [src]="getVoiceById(form.get('voice_id').value)?.preview_url"></audio>
            </div>

            <ng-container formGroupName="voice_settings">
                <div class="setting_card">
                    <h2>{{"Stability" | translate}}</h2>              
                    <div class="card_info">
                        <label class="left_label">{{"More variable" | translate}}</label>
                        <label class="right_label">{{"More stable" | translate}}</label>
                    </div>
                    <mat-slider
                        class="example-margin"
                        [max]="1"
                        [min]="0"
                        [step]="0.01"
                        formControlName="stability">
                    </mat-slider>
                </div>

                <div class="setting_card">
                    <h2>{{"Similarity" | translate}}</h2>              
                    <div class="card_info">
                        <label class="left_label">{{"Low" | translate}}</label>
                        <label class="right_label">{{"High" | translate}}</label>
                    </div>
                    <mat-slider
                        class="example-margin"
                        [max]="1"
                        [min]="0"
                        [step]="0.01"
                        formControlName="similarity_boost">
                    </mat-slider>
                </div>

                <div class="setting_card">
                    <h2>{{"Style Exaggeration" | translate}}</h2>              
                    <div class="card_info">
                        <label class="left_label">{{"None" | translate}}</label>
                        <label class="right_label">{{"Exaggerated" | translate}}</label>
                    </div>
                    <mat-slider
                        class="example-margin"
                        [max]="1"
                        [min]="0"
                        [step]="0.01"
                        formControlName="style">
                    </mat-slider>
                </div>
    
                <div class="slide_tog_block">
                    <!-- <mat-checkbox color="primary" formControlName="use_speaker_boost">{{ "Speaker boost" | translate }}</mat-checkbox> -->
                    <mat-slide-toggle color="primary" formControlName="use_speaker_boost">{{ "Speaker boost" | translate }}</mat-slide-toggle>
                
                    <div class="reset_btn" (click)="resetForm()">
                        <mat-icon style="transform: rotate(180deg) scaleX(-1);">replay</mat-icon>
                        {{"Reset values" | translate}}
                    </div>
                </div>
            </ng-container>
        </form>

        <ng-container *ngIf="speaches?.length">
            <div class="d-f-c">
                <mat-spinner class="spin_16 mr-1"></mat-spinner>  
                <span>{{"Getting result" | translate}}</span>
            </div>
        </ng-container>
        
        <div class="example" *ngIf="result && result.original">
            <h4>{{"Result" | translate}}</h4>
            <audio controls [src]="host + result?.original + '?company_id=' + data?.company?.id"></audio>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>

    <button 
    style="background: rgb(59, 134, 49)" 
    class="ml-1 d-f-btn" 
    *ngIf="data?.needFileBeforeClose && result && result.original" 
    (click)="log(); use();" 
    mat-raised-button 
    cdkFocusInitial 
    color="primary" 
    [disabled]="isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Use this result for VE" | translate }}
    </button>

    <button class="ml-1 d-f-btn" (click)="start()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Text to Speech" | translate }}
    </button>
</mat-dialog-actions>