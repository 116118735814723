import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { ReplaySubject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-set-empl',
  templateUrl: './set-empl.component.html',
  styleUrls: ['./set-empl.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class SetEmplComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public partnerForm: FormGroup;
  public employees: any;
  public host: any = environment.host;
  public imgRoute: any = '';
  public partners: any;
  public activeIndex = new FormControl(0);
  public operationStatuses: any;

  public emplSwitch: boolean = false;
  public partnerSwitch: boolean = false;

  public isSubmit: boolean = false;
  public submited: boolean = false;

  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<SetEmplComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.dialogRef.addPanelClass("adding_executors_dialog")
    console.log("SetEmplComponent", this.data);
    this.form = this.fb.group({
      company_id: !this.data.selectedJobs ? this.data.work.company_id : "",
      task_id: !this.data.selectedJobs ? this.data.task.id || this.data.work.task_id : "",
      task_operation_id: !this.data.selectedJobs ? this.data.work.id : "",
      discussion_id: 0,
      status_id: !this.data.selectedJobs ? this.data.work.status_id : "",
      is_manager: this.data.is_manager ? this.data.is_manager : 1,
      is_price_manager: 0,
      employee_id: [[], Validators.required],
    })

    this.partnerForm = this.fb.group({
      company_id: !this.data.selectedJobs ? this.data.work.company_id : "",
      task_id: !this.data.selectedJobs ? this.data.task.id || this.data.work.task_id : "",
      task_operation_id: !this.data.selectedJobs ? this.data.work.id : "",
      discussion_id: 0,
      partner_company_id: [[], Validators.required],
      is_manager: 1,
      is_price_manager: 0,
      rate: 0,
      price: 0,
      completed_at: '',
    })

    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    if (this.data.add_partner) {
      this.changeTab(1);
    }

    this.getImgRoute()
    this.getPartners()
    this.getOperationsStatus();
    this.getEmployees();
  }

  getPartnerById(id) {
    return this.partners.find(x => x.id == id)
  }

  toggleChip = (chip: any) => {
    this.form.patchValue({
      employee_id: !!chip.id ? chip.id : chip.value
    })
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  log() {
    console.log(this.form.value)
    console.log(this.partnerForm.value)
  }

  toggleEmplChip = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.value.employee_id && this.form.value.employee_id.includes(chip.id)) {
        let val = [...this.form.value.employee_id]
        val.splice(this.form.value.employee_id.indexOf(chip.id), 1)
        this.form.patchValue({
          employee_id: val
        })
      } else {
        if (!!this.form.value.employee_id) {
          let val = [...this.form.value.employee_id]
          val.push(chip.id)
          this.form.patchValue({
            employee_id: val
          })
        } else {
          this.form.patchValue({
            employee_id: [chip.id]
          })
        }
      }
    }

    if (!chip.id) {
      this.emplSwitch = true;

      setTimeout(() => {
        this.emplSwitch = false;
      }, 0)
    }
  }

  toggleAddPartnerChip = (chip: any) => {
    if (!!chip.partner_company_id) {
      if (!!this.partnerForm.value.partner_company_id && this.partnerForm.value.partner_company_id.includes(chip.partner_company_id)) {
        let val = [...this.partnerForm.value.partner_company_id]
        val.splice(this.partnerForm.value.partner_company_id.indexOf(chip.partner_company_id), 1)
        this.partnerForm.patchValue({
          partner_company_id: val
        })
      } else {
        if (!!this.partnerForm.value.partner_company_id) {
          let val = [...this.partnerForm.value.partner_company_id]
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          val.push(chip.partner_company_id)
          this.partnerForm.patchValue({
            partner_company_id: val
          })
        } else {
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          this.partnerForm.patchValue({
            partner_company_id: [chip.partner_company_id]
          })
        }
      }
    }

    console.log("chip.partner_company_id", chip.partner_company_id, chip)

    if (!chip.partner_company_id) {
      this.partnerSwitch = true;

      setTimeout(() => {
        this.partnerSwitch = false;
      }, 0)
    }
  }

  togglePartnerChip = (chip: any) => {
    if (chip.hasOwnProperty("partner_company_id")) {
      if (this.partnerForm.value.partner_company_id === chip?.partner_company_id) {
        this.partnerForm.patchValue({
          partner_company_id: ''
        })
      } else {
        this.partnerForm.patchValue({
          partner_company_id: chip?.partner_company_id
        })
      }
    } else {
      this.partnerForm.patchValue({
        partner_company_id: chip.value
      })
    }
    console.log(chip);
    console.log(this.partnerForm.value);
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
  //       console.log(1, resp)
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  addExecutor() {
    this.isSubmit = true;

    if (!this.data.selectedJobs) {
      if (this.data.task.company_id == this.data.company.id) {
        let formData = JSON.parse(JSON.stringify(this.form.value))
        delete formData.employee_id
        this.attachSubscriptions(
          forkJoin(this.form.value.employee_id.map(empl_id => this.membersService.addMember(Object.assign({employee_id: empl_id}, formData), this.data.company.id))).subscribe(resp => {
            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: resp});
          })
        )
      } else {
        let formData = JSON.parse(JSON.stringify(this.form.value))
        delete formData.employee_id;

        this.attachSubscriptions(
          forkJoin(this.form.value.employee_id.map(empl_id => this.membersService.addTaskPartnerEmployee(Object.assign({partner_company_id: this.data.company.id, company_id: this.data.task.company_id, partner_employee_id: empl_id}, formData), this.data.company.id))).subscribe(resp => {
            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: resp});
          })
        )
      }
    } else {
      this.multiAddExecutors()
    }
  }

  multiAddExecutors() {
    let postData = [];
    this.data.selectedJobs.forEach(job => {
      this.form.value.employee_id.forEach(empl_id => {
        if (job.employees.filter(x => x.employee_id == empl_id).length == 0) {
          let jobData = JSON.parse(JSON.stringify(this.form.value))
          delete jobData.employee_id
          jobData.company_id = job.company_id;
          jobData.task_operation_id = job.id;
          jobData.task_id = job.task_id;
          jobData.status_id = job.status_id;
          if (job.company_id != this.data.company.id) {
            jobData.partner_company_id = this.data.company.id
            jobData.partner_employee_id = empl_id
          } else {
            jobData.employee_id = empl_id
          }
          postData.push({
            "path": job.company_id == this.data.company.id ? `/api/task-employee/` : `/api/task-partner-employee/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": Object.assign({
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
          }, jobData) 
          })
        }
      })
    });
    this.attachSubscriptions(
      this.taskService.multiRequest(postData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      })
    )
  }

  multiAddPartners() {
    let postData = [];
    this.data.selectedJobs.forEach(job => {
      let jobData = JSON.parse(JSON.stringify(this.partnerForm.value))
      jobData.company_id = job.company_id;
      jobData.task_operation_id = job.id;
      jobData.task_id = job.task_id;
      delete jobData.partner_company_id
      this.partnerForm.value.partner_company_id.forEach(pci => {
        if (job.partnerCompanies.filter(x => x.partner_company_id == pci).length == 0) {
          postData.push({
            "path": `/api/task-partner/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": Object.assign({
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              partner_company_id: pci
          }, jobData) 
          })
        }
      })
    });
    this.attachSubscriptions(
      this.taskService.multiRequest(postData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      })
    )
  }

  addPartner() {
    if (!this.partnerForm.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.partnerForm.value['rate']).indexOf(",") != -1) {
      this.partnerForm.value['rate'] = this.partnerForm.value['rate'].replace(",", ".");
    }
    if (String(this.partnerForm.value['price']).indexOf(",") != -1) {
      this.partnerForm.value['price'] = this.partnerForm.value['price'].replace(",", ".");
    }
    if (!!this.partnerForm.value.completed_at && !!this.partnerForm.value.completed_at._d) {
      this.partnerForm.value.completed_at = moment(this.partnerForm.value.completed_at._d).format("X");
    } else {
      delete this.partnerForm.value.completed_at;
    }

    if (!this.data.selectedJobs) {

      let formData = JSON.parse(JSON.stringify(this.partnerForm.value))
      delete formData.partner_company_id
      this.attachSubscriptions(
        forkJoin(this.partnerForm.value.partner_company_id.map(pci => this.membersService.addTaskPartner(Object.assign({partner_company_id: pci}, formData), this.data.company.id))).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    } else {
      this.multiAddPartners()
    }

  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
