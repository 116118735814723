<div class="container centred_page">

    <div class="step">
        <!-- <form *ngIf="payments?.length && !withoutFilter" [formGroup]="filter" class="filter_wrp d-f-c" style="justify-content: space-between;">
          <h3>{{ 'Payments' | translate }}:</h3>
          <div class="d-f-c">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                        [formControl]="execControl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="">{{ "All" | translate }}</mat-option>
                  <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form ml-1">
              <mat-label>{{ "Choose a partner" | translate }}</mat-label>
              <mat-select [disableOptionCentering]="true" selectDir formControlName="partner_company_id">
                  <mat-option>
                      <ngx-mat-select-search 
                      placeholderLabel="{{ 'Search' | translate }}" 
                      noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                      [formControl]="partnersControl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="">{{ "All" | translate }}</mat-option>
                  <mat-option *ngFor="let parnter of partners$ | async" [value]="parnter.partner_company_id">
                      <app-user [class]="'user_div'" [companyEmployee]="parnter.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form> -->
        <div #tableContainer class="example-container mat-elevation-z8 employees_table">
            <table mat-table [dataSource]="statementTempls" class="centred_cols">

              <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef> {{ "ID" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.id }}
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef> <b>{{ 'Total' | translate }}</b> </td> -->
              </ng-container>
              <ng-container matColumnDef="Created At">
                <th mat-header-cell *matHeaderCellDef> {{ "Created At" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.created_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef> <b>{{ 'Total' | translate }}</b> </td> -->
              </ng-container>

              <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> {{ "Name" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.name }}
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef></td> -->
              </ng-container>
<!-- 
              <ng-container matColumnDef="Employee">
                <th mat-header-cell *matHeaderCellDef> {{ "Employee" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element?.employee; else elseTemplatePartner">
                      <app-user [class]="'user_div'" [employee]="element?.employee"></app-user>
                    </div>
                    <ng-template #elseTemplatePartner>
                      <div>
                        <app-user *ngIf="element?.partnerCompany" [class]="'user_div'" [companyEmployee]="element?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                      </div>
                    </ng-template>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Amount">
                <th mat-header-cell *matHeaderCellDef> {{ "Amount" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                  {{ getCurrency(element?.currency_id)?.short_name }}{{ roundNumber(element.amount) }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  <ng-container *ngFor="let value of currencyes; let i = index;">
                    <span *ngIf="+value.val">
                      <ng-container [ngSwitch]="value.id">
                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                      </ng-container>{{ value.val | reportsNumber }}
                    </span>
                  </ng-container>  
                </td>
              </ng-container>

              <ng-container matColumnDef="Comment">
                <th mat-header-cell *matHeaderCellDef> {{ "Comment" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.comment }}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container> -->

              <ng-container matColumnDef="Activity">
                <th mat-header-cell *matHeaderCellDef> 
                  <div class="d-f-c" style="justify-content: flex-end;">{{ "Activity" | translate }}</div></th>
                <td mat-cell *matCellDef="let element"> 
                  <div class="d-f-c" style="justify-content: flex-end;" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')">
                    <a class="cp ml-5" (click)="addEditTemplate($event, element)">
                        <mat-icon style="color: green;">edit</mat-icon>
                    </a>
                    <mat-icon class="cp ml-5" style="color: red;" (click)="deleteTemplate(element)">delete</mat-icon>
                  </div>
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef> </td> -->
              </ng-container>       
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
              <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr> -->
            </table>
        </div>

        <div class="btns_line">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addEditTemplate($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add template" | translate }}
            </a>
        </div>

    </div>
</div>