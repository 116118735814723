<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Select tag category to import" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Root tag categories" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="id">
                    <mat-option>
                      <ngx-mat-select-search 
                          placeholderLabel="{{ 'Search' | translate }}" 
                          noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                          [formControl]="parametersCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                      {{parameter.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="btns_layer">
        <button mat-raised-button (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        <button class="d-f-btn ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
