
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ScenariosService {

  constructor(
    private http: HttpRestService
  ) { }

  getAutomationTemplatesTypes() {
    return this.http.executeGet<any>(`/api/automation-scenario/task-operation-scenarios/`)
  }

  getCardScenarioTypes() {
    return this.http.executeGet<any>(`/api/automation-scenario/task-scenarios/`)
  }

  getAutomationTemplates(page, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set('per-page', per_page)
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/automation-template/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addAutomationTemplates(data) {
    return this.http.executePost<any>(`/api/automation-template/`, data)
  }

  getAutomationTemplate(id, ci?, pci?) {
    let filter_params = new HttpParams()
    if (ci) {
      filter_params = filter_params.set('company_id', ci);
    }
    if (pci) {
      filter_params = filter_params.set('partner_company_id', pci);
    }

    return this.http.executeGet<any>(`/api/automation-template/${id}/`, true, {
      params: filter_params
    })
  }

  editAutomationTemplates(id, data) {
    return this.http.executePost<any>(`/api/automation-template/${id}/`, data)
  }

  deleteAutomationTemplates(id) {
    return this.http.executeDelete<any>(`/api/automation-template/${id}/`)
  }

  getAutos(page, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set('per-page', per_page).set("expand", "company,automationTemplate,operation,employee,parameterValue,parameterValue.parameter")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/automation-template-to-operation/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addAuto(data) {
    return this.http.executePost<any>(`/api/automation-template-to-operation/`, data)
  }

  getAuto(id) {
    return this.http.executeGet<any>(`/api/automation-template-to-operation/${id}/`)
  }

  editAuto(id, data) {
    return this.http.executePost<any>(`/api/automation-template-to-operation/${id}/`, data)
  }

  deleteAuto(id) {
    return this.http.executeDelete<any>(`/api/automation-template-to-operation/${id}/`)
  }

  getAutoTags(page, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set('per-page', per_page).set("expand", "company,parameterValue,parameterValue.parameter,taskStatus,taskGroup,operation,operationEmployee,taskParameterValue,taskParameterValue.parameter,taskOperationParameterValue,taskOperationParameterValue.parameter")
    // .set("expand", "company,parameterValue,taskStatus,taskGroup,operation,operationEmployee,taskParameterValue,taskParameterValue.parameter,taskOperationParameterValue,taskOperationParameterValue.parameter")
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/automation-parameter-value-to-file/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addAutoTag(data) {
    return this.http.executePost<any>(`/api/automation-parameter-value-to-file/`, data)
  }

  getAutoTag(id) {
    return this.http.executeGet<any>(`/api/automation-parameter-value-to-file/${id}/`)
  }

  editAutoTag(id, data) {
    return this.http.executePost<any>(`/api/automation-parameter-value-to-file/${id}/`, data)
  }

  deleteAutoTag(id) {
    return this.http.executeDelete<any>(`/api/automation-parameter-value-to-file/${id}/`)
  }

  getScenExecutors(filter, company_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', "200").set("expand", "employee,automationTemplate,partnerCompany")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/automation-template-to-employee/`, true, {
      params: filter_params
    })
  }

  addExecScenario(data, company_id) {
    return this.http.executePost<any>(`/api/automation-template-to-employee/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteExecScenario(id) {
    return this.http.executeDelete<any>(`/api/automation-template-to-employee/${id}/`)
  }

  getTaskTemplateExecutors(filter, company_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', "200").set("expand", "employee,taskTemplate")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/task-template-to-employee/`, true, {
      params: filter_params
    })
  }

  getGroupsToScenario(filter, company_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', "200")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/automation-template-to-group/`, true, {
      params: filter_params
    })
  }

  addGroupsToScenario(data, company_id) {
    return this.http.executePost<any>(`/api/automation-template-to-group/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteGroupsToScenario(id) {
    return this.http.executeDelete<any>(`/api/automation-template-to-group/${id}/`)
  }

  addTaskTemplateExecutor(data) {
    return this.http.executePost<any>(`/api/task-template-to-employee/`, data)
  }

  editTaskTemplateExecutor(id, data) {
    return this.http.executePost<any>(`/api/task-template-to-employee/${id}/`, data)
  }

  deleteTaskTemplateExecutor(id) {
    return this.http.executeDelete<any>(`/api/task-template-to-employee/${id}/`)
  }


  getTaskTemplates(page, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set('per-page', per_page);
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/task-template/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addTaskTemplate(data) {
    return this.http.executePost<any>(`/api/task-template/`, data)
  }

  getTaskTemplate(id) {
    return this.http.executeGet<any>(`/api/task-template/${id}/`)
  }

  editTaskTemplate(id, data) {
    return this.http.executePost<any>(`/api/task-template/${id}/`, data)
  }

  deleteTaskTemplate(id) {
    return this.http.executeDelete<any>(`/api/task-template/${id}/`)
  }

  getAutomationScenarios(company_id, filter:any = false) {
    let filter_params = new HttpParams().set("company_id", company_id).set('expand', 'template')
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/automation-scenario/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  tryScenario(id, ci?) {
    let filter_params = new HttpParams()
    if (ci) {
      filter_params = filter_params.set('company_id', ci);
    }
    return this.http.executePost<any>(`/api/automation-scenario/execute/${id}/`, {}, true, {
      params: filter_params
    })
  }

  addAutomationScenario(data, company_id) {
    return this.http.executePost<any>(`/api/automation-scenario/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editAutomationScenario(id, data, company_id) {
    return this.http.executePost<any>(`/api/automation-scenario/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteAutomationScenario(id, ci?) {
    let filter_params = new HttpParams()
    if (ci) {
      filter_params = filter_params.set('company_id', ci);
    }

    return this.http.executeDelete<any>(`/api/automation-scenario/${id}/`, true, {
      params: filter_params
    })
  }
}
