import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, concat, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-move-all-card-jobs',
  templateUrl: './move-all-card-jobs.component.html',
  styleUrls: ['./move-all-card-jobs.component.scss']
})
export class MoveAllCardJobsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isLoad: boolean = false;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public page: number = 1;
  public pagination: any;
  public jobs: any;
  public taskControl: FormControl = new FormControl();
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public tasks: any[] = [];
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MoveAllCardJobsComponent>,
    private fb: FormBuilder,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MoveAllCardJobsComponent", this.data)

    this.form = this.fb.group({
      task_id: '',
    })

    this.getCardJobs();
    this.attachSubscriptions(
      this.taskControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTasks();
  }

  getCardJobs() {
    this.attachSubscriptions(
      this.taskService.getCardJobs(this.data.company.id, {task_id: this.data.task.id}).subscribe(resp => {
        this.jobs = resp
      })
    )
  }

  onSearch(resp) {
    if (!this.tasks) {
      return;
    }

    if (!resp) {
      this.tasks$.next(this.tasks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.tasks$.next(
      this.tasks.filter(z => z.internal_id.toString().indexOf(resp) > -1)
    );
  }

  getTasks() {
    this.isLoad = true;
    this.attachSubscriptions(
      this.taskService.getTasksSelectNoExpDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.taskService.getTasksSelectNoExpDyn(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.isLoad = false;
        this.tasks = [].concat(...res.filter(x => x.id != this.data.task.id));
        if (this.taskControl.value) {
          this.onSearch(this.taskControl.value)
        } else {
          this.tasks$.next(this.tasks.slice())
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.jobs || this.jobs.length == 0) {
      this.layoutService.showSnackBar({name: ''}, marker('This card jobs is empty'), SnackBarItem)
      return
    }
    this.isSubmit = true;

    let sbmtData = [];
    let partsSbmtData = [];

    this.jobs.forEach((job, i) => {
      sbmtData.push({
        "path": `/api/task-operation/move/`,
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, Object.assign(this.form.value, {task_operation_id: job.id}))
      });
    })

    for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
      partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
    }

    let count = 0;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * (count+1)),
          target: "Move card jobs",
          type: 'task',
          refresh: [{company_id: this.data.company.id, task_id: this.data.task.id}, {company_id: this.data.company.id, task_id: this.form.value.task_id}]
        })
        if (count == 0) {
          this.isSubmit = false;
          this.close();
        }
        count++;
      })
    ).subscribe(res => {
      console.log(res);
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
