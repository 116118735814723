<div class="container centred_page">

    <div class="step">
        <h3 *ngIf="partners?.length">{{ 'Partner workspaces' | translate }}:</h3>
        
        <div class="example-container mat-elevation-z8 employees_table">
            <table mat-table [dataSource]="partners" class="centred_cols">
          
              <ng-container matColumnDef="Partner">
                <th mat-header-cell *matHeaderCellDef> {{ "Partner" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <ng-container *ngIf="element?.partnerCompany?.name; else elseTemplatepartnerCompany">
                        <div class="company_name d-f-c">
                            <ng-container *ngIf="!!element?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                <img [appHoverUserInfo]="{ user: element, company_id: company_id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + element?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company_id" alt="">
                            </ng-container>
                            <ng-template #elseTemplatePartnerAvatar>
                                <img [appHoverUserInfo]="{ user: element, company_id: company_id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                            </ng-template>
                            <span class="ml-1">{{element?.partnerCompany?.name}}</span>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplatepartnerCompany>
                        <div class="company_name d-f-c" style="cursor: pointer;" ngxClipboard [cbContent]="origin+'/?invite='+element?.invite+'&id='+element.id" (cbOnSuccess)="copyLink('Partner invite')">
                            <mat-icon class="mr-1">content_copy</mat-icon>
                            {{element?.invite}}
                        </div>  
                    </ng-template>

                </td>
              </ng-container>
    
              <ng-container matColumnDef="Partner Rules">
                <th mat-header-cell *matHeaderCellDef> {{ "Partner Rules" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="element?.partnerCompany?.name" (click)="openChannelToEmployee(element)"><mat-icon>public</mat-icon></button>
                    <button mat-icon-button (click)="rules(element)">
                        <mat-icon>rule</mat-icon>
                    </button>
                    <button mat-icon-button #tooltip="matTooltip"
                    matTooltip="{{'What groups can see to filter and assign to cards' | translate}}"
                    [matTooltipPosition]="'above'" (click)="openChannelGroupToEmployee(element)"><mat-icon>group</mat-icon></button>
                    <button mat-icon-button (click)="autoPrice(element)"><mat-icon>attach_money</mat-icon></button>
                    <button mat-icon-button (click)="posAutos(element)"><mat-icon>settings_accessibility</mat-icon></button>
                    <button mat-icon-button (click)="openFilesSettingsToEmployee(element)"><mat-icon>photo_size_select_large</mat-icon></button>
                </td>
              </ng-container>
    
              <ng-container matColumnDef="Rate">
                <th mat-header-cell *matHeaderCellDef> {{ "Points" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{element?.rate_target | reportsNumber}}
                </td>
              </ng-container>
              
              <ng-container matColumnDef="Salary">
                <th mat-header-cell *matHeaderCellDef> {{ "Salary" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{element?.salary | reportsNumber}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Currency">
                <th mat-header-cell *matHeaderCellDef> {{ "Currency" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ getCurrency(element?.currency_id)?.short_name }}
                </td>
              </ng-container>     

              <!-- <ng-container matColumnDef="Application status">
                <th mat-header-cell *matHeaderCellDef> {{ "Application status" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <a [href]="host + '/settings?company_id=18&page=questionnaires&id=' + element.id" (click)="goToEmplApp($event, element.id)">{{ getStatusName(element?.application_status_id) }}</a>
                </td>
              </ng-container>        -->
              

              <ng-container matColumnDef="Activity">
                <th mat-header-cell *matHeaderCellDef> {{ "Activity" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="company_btns">
                        <button mat-icon-button *ngIf="element?.partnerCompany?.name" (click)="editPartner(element)" style="color: green;"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="deletePartner(element)" style="color: red;"><mat-icon>delete</mat-icon></button>
                    </div>
                </td>
              </ng-container>       
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    
            </table>
        </div>

        <div class="btns_line">
            <a mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addPartner()" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add a partner" | translate }}
            </a>
        </div>

    </div>
</div>