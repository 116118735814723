<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <div class="dash_item" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Current Activity" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>analytics</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=finances&tab=current_activity" (click)='goToCurrentActivity($event)' class="link-to-page page_btn hover_viss">
                    {{ "To page" | translate }}
                </a>
            </div>

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null;" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Statements" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>show_chart</mat-icon>
                    </div>
                </div>
                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=finances&tab=statements" (click)='goToStatements($event)'>
                    {{ "To page" | translate }}
                </a>
            </div>
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null;" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Payouts" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>payments</mat-icon>
                    </div>
                </div>
                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=finances&tab=payouts" (click)='goToPayouts($event)'>
                    {{ "To page" | translate }}
                </a>
            </div>
            
        </div>
    </div>
</div>

