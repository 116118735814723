<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ ((data.is_edit ? "Edit" : "Add") + " Variable") | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.is_edit">
            <mat-label>{{ "Choose a Varibale" | translate }}</mat-label>
            <mat-select [(ngModel)]="data.variable" required>
                <ng-container *ngFor="let variable of variables">
                    <mat-option [value]="variable" *ngIf="variable.is_root == 0 || !!data?.user?.is_root">
                        {{ variable.name | translate }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        
        <ng-container *ngIf="data.variable && !!data.variable.hasOwnProperty('fontSize')">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Font size" | translate }}</mat-label>
                <mat-icon class="cp" [(colorPicker)]="data.variable.color" [style.color]="data.variable.color" matPrefix>palette</mat-icon>
                <input [(ngModel)]="data.variable.fontSize" matInput placeholder="{{ 'Font size' | translate }}">
            </mat-form-field>       
        </ng-container>

        <ng-container *ngIf="data.variable && data.formats && !!data.variable.hasOwnProperty('format')">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Time format" | translate }}</mat-label>
                <mat-select [(ngModel)]="data.variable.format" required>
                    <ng-container *ngFor="let format of data.formats">
                        <mat-option [value]="format.key">
                            {{ format.key }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>     
        </ng-container>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
