<h2 mat-dialog-title class="dialog_title">
    <span class="d-f-c">
        <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33334C4.22877 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66668 2.5V5.83333M13.3333 2.5V5.83333" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="white"/>
        </svg>
            
        {{ "Add chat to planning board" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper add_exec_dialog">
        <div class="pre_title text_one_line">{{ data.chat.name }}</div>
        
        <form [formGroup]="form" *ngIf="form">
            <div class="start_at_wrp">
                <div class="_title">
                    {{ "Executors and their schedule" | translate }}:
        
                    <button *ngIf="data?.company?.permissions.includes('manager') || data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin')" class="add_exec_btn" mat-icon-button [disableRipple]="true" [matMenuTriggerFor]="menuBoard" (click)="$event.stopPropagation()">
                        <mat-icon style="color: #1877F2;">person_add</mat-icon> 
                    </button>
                    <mat-menu #menuBoard="matMenu">
                        <button mat-menu-item (click)="addExecutor()">
                            <mat-icon>work</mat-icon> 
                            <span>{{ "Add executor to job" | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="addAdditionalEmplToWork()">
                            <mat-icon>chat</mat-icon> 
                            <span>{{ "Add executor to chat" | translate }}</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="empl_wrp">
                    <div *ngFor="let empl of data.employees; let ind = index;" class="employee">
                        <div class="empl_name_div">
                            <ng-container *ngIf="empl.is_partner; else elseTemplateOUTPartner">
                                <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePartnerAvatar>
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                </ng-template>
                                <span>{{ empl.partnerCompany.name }}</span>
                                <!-- <a class="on_hover" *ngIf="!!empl?.partnerCompanyStatus && empl?.partnerCompanyStatus.start_at" style="height: 24px; margin-left: 4px;" target="_blank" [href]="data.host + '/board?company_id=' + data?.company?.id + '&status_id=1,2,5&doneStatus=3,4,6,98,99&employee_id=' + empl.employee_id + '&count=1'">
                                    <mat-icon style="color: #1877F2;" class="ic">open_in_new</mat-icon>
                                </a> -->
                            </ng-container>
                            <ng-template #elseTemplateOUTPartner>
                                <ng-container *ngIf="!!empl?.employee?.user_id; else elseEmplTemplate">
                                    <ng-container *ngIf="!!empl?.employee?.image; else elseTemplate">
                                        <img [src]="empl?.employee?.image" alt="">
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <mat-icon>account_circle</mat-icon>
                                    </ng-template>
                                    <span class="text_one_line">{{ empl?.employee?.name }}</span>
                                </ng-container>
                                <ng-template #elseEmplTemplate>
                                    <mat-icon>account_circle</mat-icon>
                                    <span class="text_one_line">{{ empl?.employee?.name }}</span>
                                </ng-template>
                                <a class="on_hover" *ngIf="!!empl?.employeeStatus && empl?.employeeStatus.start_at && empl.employee_id" style="height: 24px; margin-left: 4px;" target="_blank" [href]="origin + (empl?.employeeStatus.start_at > 1 ? '/board?company_id=' : '/board-list?company_id=') + data?.company?.id + '&status_id=1,2,5&doneStatus=3,4,6,97,98,99&operation_employee_id=' + empl.employee_id + '&count=1'">
                                    <mat-icon style="color: #1877F2;" class="ic">open_in_new</mat-icon>
                                </a>
                            </ng-template>
                        </div>
    
                        <div class="start_info" *ngIf="data?.company?.permissions.includes('manager') || data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || (empl?.employee?.user_id == data?.user?.id)">
                            <mat-form-field class="full_width_form" style="visibility: hidden; width: 1px;">
                                <input (dateChange)="changeStartAt($event, empl)" matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="start_at-{{ind}}">
                                <ngx-mat-datetime-picker panelClass="setStartAt" #picker [showSpinners]="true" [locale]="data.activeLang" [touchUi]="false" [color]="'primary'">
                                    <ng-template>
                                        <div class="empl_action_btn_wrp">
                                            <div class="empl_name_div">
                                                <ng-container *ngIf="empl.is_partner; else elseTemplateInPartner">
                                                    <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                        <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplatePartnerAvatar>
                                                        <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                                    </ng-template>
                                                    <span>{{ empl.partnerCompany.name }}</span>
                                                </ng-container>
                                                <ng-template #elseTemplateInPartner>
                                                    <ng-container *ngIf="!!empl?.employee?.user_id; else elseEmplTemplate">
                                                        <ng-container *ngIf="!!empl?.employee?.image; else elseTemplate">
                                                            <img [src]="empl?.employee?.image" alt="">
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <mat-icon>account_circle</mat-icon>
                                                        </ng-template>
                                                        <span class="text_one_line">{{ empl?.employee?.name }}</span>
                                                    </ng-container>
                                                    <ng-template #elseEmplTemplate>
                                                        <mat-icon>account_circle</mat-icon>
                                                        <span class="text_one_line">{{ empl?.employee?.name }}</span>
                                                    </ng-template>
                                                </ng-template>
                                            </div>
                                            <span style="margin-left: 10px">OK</span>
                                        </div>
                                        
                                    </ng-template>
                                   
                                </ngx-mat-datetime-picker>
                            </mat-form-field>


                            <ng-container *ngIf="empl?.is_partner; else elseTemplateTimePartner">
                                <ng-container *ngIf="!empl?.partnerCompanyStatus || !empl?.partnerCompanyStatus.start_at; else elseTemplateHASTIME">
                                    <div class="board_cst_btn blue" (click)="changeStartAt($event, empl, true)">
                                        {{ "Add to board" | translate }}
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateHASTIME>
                                    <ng-container *ngIf="empl?.partnerCompanyStatus && empl?.partnerCompanyStatus.start_at == 1;">
                                        <div class="board_cst_btn white">
                                            {{ "On the board" | translate }}
                                        </div>
                                        <div class="board_cst_btn blue" (click)="openPicker(picker)">
                                            {{ "Add to calendar" | translate }}
                                        </div>
                                    </ng-container>   
                                    <ng-container *ngIf="empl?.partnerCompanyStatus && empl?.partnerCompanyStatus.start_at > 1;">
                                        <div class="board_cst_btn white">
                                            {{ "On the board" | translate }}
                                        </div>
                                        <div class="date">{{ empl?.partnerCompanyStatus.start_at*1000 | date:'MMM dd. Y':'':data.activeLang | yearPipe }}</div>
                                        <div class="time">{{ empl?.partnerCompanyStatus.start_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}</div>
                                        <mat-datepicker-toggle class="on_hover small_edit" matSuffix [for]="picker">
                                            <mat-icon style="color: #1877F2;" matDatepickerToggleIcon>edit</mat-icon>
                                        </mat-datepicker-toggle>
                                    </ng-container>     
                                </ng-template>
                            </ng-container>
                            <ng-template #elseTemplateTimePartner>                                
                                <ng-container *ngIf="!empl?.employeeStatus || !empl?.employeeStatus.start_at; else elseTemplateHASTIME">
                                    <div class="board_cst_btn blue" (click)="changeStartAt($event, empl, true)">
                                        {{ "Add to board" | translate }}
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateHASTIME>
                                    <ng-container *ngIf="empl?.employeeStatus && empl?.employeeStatus.start_at == 1;">
                                        <div class="board_cst_btn white">
                                            {{ "On the board" | translate }}
                                        </div>
                                        <div class="board_cst_btn blue" (click)="openPicker(picker)">
                                            {{ "Add to calendar" | translate }}
                                        </div>
                                    </ng-container>  
                                    <ng-container *ngIf="empl?.employeeStatus && empl?.employeeStatus.start_at > 1;">
                                        <div class="board_cst_btn white">
                                            {{ "On the board" | translate }}
                                        </div>
                                        <div class="date">{{ empl?.employeeStatus.start_at*1000 | date:'MMM dd. Y':'':data.activeLang | yearPipe }}</div>
                                        <div class="time">{{ empl?.employeeStatus.start_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}</div>
                                        <mat-datepicker-toggle class="on_hover small_edit" matSuffix [for]="picker">
                                            <mat-icon style="color: #1877F2;" matDatepickerToggleIcon>edit</mat-icon>
                                        </mat-datepicker-toggle>
                                    </ng-container>
                                </ng-template>
                            </ng-template>
                            
                        </div>
    
                        <div class="delete_btn" *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin')" (click)="deleteStartAt(empl)" class="on_hover" [ngClass]="{'no_block': (empl.employeeStatus && !empl.employeeStatus.start_at) || (empl.partnerCompanyStatus && !empl.partnerCompanyStatus.start_at)}">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 15.8333C5 16.75 5.75 17.5 6.66666 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V5.83333H5V15.8333ZM15.8333 3.33333H12.9167L12.0833 2.5H7.91666L7.08333 3.33333H4.16666V5H15.8333V3.33333Z" fill="#FF6A6A"/>
                            </svg>                        
                        </div>
                    </div>
                </div>
                
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="start_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>
