import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';
import { EditPartnerWorkspaceComponent } from './dialogs/edit-partner-workspace/edit-partner-workspace.component';
import { RulesComponent } from '../employees/dialogs/rules/rules.component';
import { FilesSettingsToEmployeeComponent } from '../employees/dialogs/files-settings-to-employee/files-settings-to-employee.component';
import { ChannelToEmployeeComponent } from '../employees/dialogs/channel-to-employee/channel-to-employee.component';
import { AutoPositionComponent } from '../employees/dialogs/auto-position/auto-position.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { PriceToEmployeeComponent } from '../employees/dialogs/price-to-employee/price-to-employee.component';
import { ChannelGroupToEmployeeComponent } from '../employees/dialogs/channel-group-to-employee/channel-group-to-employee.component';

@Component({
  selector: 'app-partner-workspaces',
  templateUrl: './partner-workspaces.component.html',
  styleUrls: ['./partner-workspaces.component.scss']
})
export class PartnerWorkspacesComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<any>;
  public partners: any[];
  public host: any = environment.host;
  public imgRoute: any = '';
  public origin = window.location.origin;
  public company_id: any;
  public company: any;
  public currencyList: any;
  public activeLang: any;
  public user: any = {};
  public user_id: number;
  public displayedColumns: string[] = ["Partner", "Partner Rules", "Rate", "Salary", "Currency", "Activity"]
  constructor(
    private companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private globalDataService: GlobalDataService,
    private languageService: LanguageService,
    private auth: AuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private sm: StorageManagerService,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getCurrencyList();
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getCompany()
    this.getUser()
    this.getImgRoute();
    this.getPartners();
    this.getLangData();
  }

  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }


  openChannelGroupToEmployee(empl) {
    const dialogRef = this.dialog.open(ChannelGroupToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        fromEmployees: true,
        forPartnerWorkspace: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  
  autoPrice(empl) {
    const dialogRef = this.dialog.open(PriceToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        is_partner: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  rules(empl) {
    const dialogRef = this.dialog.open(RulesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        is_partner: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  
  openFilesSettingsToEmployee(empl) {
    const dialogRef = this.dialog.open(FilesSettingsToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        is_partner: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  posAutos(empl) {
    const dialogRef = this.dialog.open(AutoPositionComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        is_partner: true,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
        host: this.host,
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0];
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  openChannelToEmployee(empl) {
    const dialogRef = this.dialog.open(ChannelToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        employee: empl,
        fromEmployees: true,
        forPartnerWorkspace: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
  }
  
  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  // addEmployee(e) {
  //   e.preventDefault();
  //   const dialogRef = this.dialog.open(EmployeeAddComponent, {
  //     disableClose: true,
  //     data: {
  //       user: this.user,
  //       company: this.company,
  //       company_id: this.company_id,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (!!result && result.event == "update") {
  //         this.getEmployees();
  //       }
  //     })
  //   )
  // }

  
  editPartner(partner) {
    const dialogRef = this.dialog.open(EditPartnerWorkspaceComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        partner: partner,
        host: this.host,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getPartners();
        }
      })
    )
  }
  // addPartner() {
  //   const dialogRef = this.dialog.open(AddPartnerComponent, {
  //     data: {
  //       company: this.data.company,
  //       user: this.data.user,
  //       partners: this.partners,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log(result)
  //       if (result.event == 'update') {
  //         this.getPartners();
  //       }
  //     })
  //   )
  // }

  addPartner() {
    this.attachSubscriptions(
      this.companyService.addPartner({company_id: this.company_id, reset_invite: 1}).subscribe(resp => {
        console.log(resp);
        this.getPartners();
      })
    )
  }

  goBack() {
    history.back()
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        if (this.table.dataSource) {
          this.table.renderRows();
        }
      })
    )
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }
  
  deletePartner(partner) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("company partner")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deletePartner(partner.id).subscribe(resp => {
            let index = this.partners.indexOf(partner)
            this.partners.splice(index,1);
            this.table.renderRows();
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
