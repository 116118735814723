const keyboardMappingENtoRU = {
    'q': 'й',
    'w': 'ц',
    'e': 'у',
    'r': 'к',
    't': 'е',
    'y': 'н',
    'u': 'г',
    'i': 'ш',
    'o': 'щ',
    'p': 'з',
    '[': 'х',
    ']': 'ъ',
    'a': 'ф',
    's': 'ы',
    'd': 'в',
    'f': 'а',
    'g': 'п',
    'h': 'р',
    'j': 'о',
    'k': 'л',
    'l': 'д',
    ';': 'ж',
    "'": 'э',
    'z': 'я',
    'x': 'ч',
    'c': 'с',
    'v': 'м',
    'b': 'и',
    'n': 'т',
    'm': 'ь',
    ',': 'б',
    '.': 'ю',
  };
  
  const keyboardMappingRUtoEN = {
    'й': 'q',
    'ц': 'w',
    'у': 'e',
    'к': 'r',
    'е': 't',
    'н': 'y',
    'г': 'u',
    'ш': 'i',
    'щ': 'o',
    'з': 'p',
    'х': '[',
    'ъ': ']',
    'ф': 'a',
    'ы': 's',
    'в': 'd',
    'а': 'f',
    'п': 'g',
    'р': 'h',
    'о': 'j',
    'л': 'k',
    'д': 'l',
    'ж': ';',
    'э': "'",
    'я': 'z',
    'ч': 'x',
    'с': 'c',
    'м': 'v',
    'и': 'b',
    'т': 'n',
    'ь': 'm',
    'б': ',',
    'ю': '.',
  };
  
  export function convertRussianToEnglish(text) {
    const chars = text.split('');
    const englishChars = chars.map(char => {
      const isUpperCase = char === char.toUpperCase();
      const englishChar = keyboardMappingRUtoEN[char.toLowerCase()] || char;
      return isUpperCase ? englishChar.toUpperCase() : englishChar;
    });
    return englishChars.join('');
  }
  
  // Приклад використання:
  console.log(convertRussianToEnglish('нщгг ешдт')); // 'hello world'

  
  export function convertEnglishToRussian(text) {
    const chars = text.split('');
    const russianChars = chars.map(char => {
      const isUpperCase = char === char.toUpperCase();
      const russianChar = keyboardMappingENtoRU[char.toLowerCase()] || char;
      return isUpperCase ? russianChar.toUpperCase() : russianChar;
    });
    return russianChars.join('');
  }
  
  // Приклад використання:
  console.log(convertEnglishToRussian('hello world')); // 'нщгг ешдт'
  