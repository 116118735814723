import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'snack-bar-item',
    templateUrl: 'snack-bar-item.html',
    styleUrls: ['./snack-bar-item.scss']
  })
  export class SnackBarItem implements OnInit {
    constructor(
      private translate: TranslateService,
      private snackBarRef: MatSnackBarRef<any>,
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
    ) {}

    ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
      console.log("SnackBarItem", this.data)
    }


    closeSnack() {
      this.snackBarRef.dismiss();
    }

  }
  