import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('consistPlaceholder') consistPlaceholder: any;
  @ViewChild('partPlaceholder') partPlaceholder: any;

  @ViewChild('consistDragPlaceholder') consistDragPlaceholder: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;

  @ViewChild('relatedPlaceholder') relatedPlaceholder: any;
  @ViewChild('relatedDragPlaceholder') relatedDragPlaceholder: ElementRef;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public origin = window.location.origin;
  
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public statuses: any;
  public prioritys: any = prioritys;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public pagination: any;
  public customIdValue: number;
  public isGetId: boolean = false;
  public isFormChange: boolean = false;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public relations: boolean = false;
  public showGroups: boolean = false;
  public thumbIsOpen: boolean = false;
  public tasks: any[] = [];
  public savedTasks: any[] = [];
  public task: any;
  public page: number = 1;
  public groups: any;
  public searchValue: any = '';
  public partIds: any[] = [];
  public relativeIds: any[] = [];
  public consistsIds: any[] = [];
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');
  public consistOfControl: FormControl = new FormControl();
  public partOfControl: FormControl = new FormControl();
  public relativeControl: FormControl = new FormControl();

  public groupMoreControl: FormControl = new FormControl();
  public groupOfSearchControl: FormControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskEditComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    window.scroll(0,0);

    if (!!this.data.showRelations) {
      this.relations = true;
    }

    this.company_id = this.data.company_id;
    this.form = this.fb.group({
      company_id: this.company_id,
      status_id: ['', Validators.required],
      custom_id: [0, Validators.required],
      name: ['', Validators.required],
      comment: '',
      group_id: '',
      is_random_avatar: 1,
      custom_avatar_property: 0,
      priority: 0,
      private_comment: '',
      consist_of_task_id: [[]],
      part_of_task_id: [[]],
      related_task_id: [[]]
    })

    this.getGroupsCompany();
    this.attachSubscriptions(
      this.consistOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(resp) ? this.groupOfSearchControl.value : 0})
      })
    )

    this.attachSubscriptions(
      this.groupOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? resp : 0})
      })
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.task_id = this.data.task.id;
    this.getTask();

    this.getImgRoute();
    this.getCsrf();
    this.getCompany();
    this.getUser();

    this.getTaskStatus();

    this.getTasks(this.page);
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  openRelations() {
    this.relations = !this.relations;
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  pasteIdVal() {
    this.form.patchValue({
      custom_id: this.customIdValue
    })
    this.isGetId = false;
  }

  getCustomId(value) {
    this.attachSubscriptions(
      this.taskService.getCustomId(this.data.company_id, value).subscribe(resp => {
        this.customIdValue = +resp;
        this.isGetId = true;
      })
    )
  }

  selectGroup(group) {
    if (!this.data.isMulti) {
      this.getCustomId(!!group.id ? group.id : group.value);
    }

    this.form.patchValue({
      group_id: !!group.id ? group.id : group.value
    })

    this.groupOfSearchControl.patchValue(!!group.id ? group.id : group.value)
  }

  showMoreGroups() {
    this.showGroups = true;
  }

  openThumbnail() {
    this.thumbIsOpen = !this.thumbIsOpen
  }
  
  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  selectType(type) {
    this.form.patchValue({
      status_id: type.id
    })
  }

  onScroll(type) {
    console.log("onScroll", type, this[type].value || '');
    this.onSearchTasks(this[type].value || '', this.typeOfSearchControl.value, true)
  }

  onSearchTasks(resp, query, notDel?) {
    
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchTasks', resp, query, notDel)

    let filter:any = {
      group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? this.groupOfSearchControl.value : 0
    }
    if (!notDel) {
      this.tasks = []
      this.tasks$.next(this.tasks.slice())
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks
    switch (query) {
      case 'Everywhere':
        filter.q = resp;
        break;
      case 'Task Name':
        filter.q_task_name = resp;
        break;
      case 'Custom ID':
        filter.custom_id = resp;
        break;
      case 'Internal ID':
        filter.internal_id = resp;
        break;
      case 'Global ID':
        filter.id = resp;
        break;
    }

    this.getTasks(this.page, filter)

  }

  getTasks(page, filter:any = null) {
    console.log("getTasks filter", filter)
    this.attachSubscriptions(
      this.taskService.getTasksSelect(page, this.data.company_id, filter).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.tasks.push(...resp.body.filter(x => x.id != this.task_id))
        console.log("getTasks res" + this.page, this.tasks)
        this.page = this.page + 1; 
        this.tasks$.next(this.tasks.slice())
      })
    )
  }

  getTask() {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, this.task_id)
      .pipe(
        map(el => casesModel(el.body, [], 'update').arr[0]),
        switchMap(task => {

          console.log("getTaskgetTaskgetTaskgetTaskgetTask", task)
          let arr = [];

          if (task.consist_of_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {part_of_task_id: task.id}, '100').pipe(
                tap(tasksRes => {
                  task.partOfTasks = [...tasksRes];
                })
              )
            )
          } else {
            task.partOfTasks = []
          }
      
          if (task.part_of_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {consist_of_task_id: task.id}, '100').pipe(
                tap(tasksRes => {
                  task.consistOfTasks = [...tasksRes];
                })
              )
            )
          } else {
            task.consistOfTasks = []
          }
          if (task.related_count) {
            arr.push(
              this.taskService.getCardsExpand('1', this.company_id, {related_task_id: task.id}, '100').pipe(
                tap(tasksRes => {
                  task.relatedTasks = [...tasksRes];
                })
              )
            )
          } else {
            task.relatedTasks = []
          }
      
          if (arr.length) {
            return forkJoin([...arr]).pipe(map(() => task))
          } else {
            return of(task)
          }
        })
      ).subscribe(resp => {
        console.log("getTask", resp);

        resp.consistOfTasks.forEach(element => {
          this.consistsIds.push(element.id)
        });
        resp.partOfTasks.forEach(element => {
          this.partIds.push(element.id)
        });
        resp.relatedTasks.forEach(element => {
          this.relativeIds.push(element.id)
        });

        this.savedTasks = [...resp.relatedTasks,...resp.partOfTasks,...resp.consistOfTasks]

        this.form.patchValue({
          name: resp.name,
          custom_id: resp.custom_id,
          status_id: resp.status_id,
          comment: resp.comment,
          is_random_avatar: resp.is_random_avatar,
          custom_avatar_property: resp.custom_avatar_property,
          priority: !!resp.priority ? resp.priority : 0,
          group_id: resp.group_id,
          private_comment: resp.private_comment,
          consist_of_task_id: [...this.consistsIds],
          part_of_task_id: [...this.partIds],
          related_task_id: [...this.relativeIds]
        })

        this.groupOfSearchControl.patchValue(resp.group_id || '')

        this.task = resp;

        this.attachSubscriptions(
          this.form.valueChanges.subscribe(() => this.isFormChange = true)
        )
    
        this.attachSubscriptions(
          this.dialogRef.backdropClick().subscribe(e => {
            e.preventDefault();
            if (this.isFormChange) {
              this.layoutService.openBottomSheet(this.dialogRef);
            } else {
              this.close();
            }
          })
        )
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }


  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.statuses = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  findTask(id) {
    if (this.savedTasks.find(x => x.id == id)) {
      return this.savedTasks.find(x => x.id == id)
    }
  }

  selected(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.form.get(key).value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
      if (this.tasks.find(x => x.id == e.option.value) && this.savedTasks.filter(u => u.id == e.option.value).length == 0) {
        this.savedTasks.push(this.tasks.find(x => x.id == e.option.value))
      }
    }
    this.form.patchValue({
      [key]: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  onRemoved(value: string, key) {
    const values = this.form.get(key).value as string[];
    this.removeFirst(values, value);
    this.form.get(key).setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }

    this.isSubmit = true;
    let no_includesConsist = [];
    let no_includesPart = [];
    let no_includesRelated = [];

    this.consistsIds.forEach(element => {
      if (!this.form.value.consist_of_task_id.includes(element)) {
        no_includesConsist.push(element);
      }
    });
    
    this.partIds.forEach(element => {
      if (!this.form.value.part_of_task_id.includes(element)) {
        no_includesPart.push(element);
      }
    });

    this.relativeIds.forEach(element => {
      if (!this.form.value.related_task_id.includes(element)) {
        no_includesRelated.push(element);
      }
    });
    
    console.log(no_includesConsist);
    console.log(no_includesPart);
    console.log(no_includesRelated);
    
    let submitData = {...this.form.value};
    submitData['consist_of_task_id'] = {
      add: this.form.value['consist_of_task_id'],
      remove: no_includesConsist
    };
    submitData['part_of_task_id'] = {
      add: this.form.value['part_of_task_id'],
      remove: no_includesPart
    };
    submitData['related_task_id'] = {
      add: this.form.value['related_task_id'],
      remove: no_includesRelated
    };
    
    console.log("this.form.value", this.form.value);
    console.log("submitData", submitData);
    if (!!submitData.is_random_avatar) {
      submitData.is_random_avatar = 1
    } else {
      submitData.is_random_avatar = 0
    }
    if (!!submitData.custom_avatar_property) {
      submitData.custom_avatar_property = '1'
    } else {
      submitData.custom_avatar_property = ''
    }
    // delete submitData['consist_of_task_id'];
    // delete submitData['part_of_task_id'];

    if (this.data.task && this.data.task.channels && this.data.task.channels.length && submitData.group_id != this.data.task.group_id) {
      let deletedChannelsData = [];
      this.data.task.channels.filter(x => x.content_status_id == 1 && !x.content_url).forEach(channel => {
        deletedChannelsData.push({
          "path": `/api/task-channel/${channel.id}/`,
          "query": {},
          "method": "DELETE",
          "body": ''
        });
      });
      this.attachSubscriptions(
        this.taskService.multiRequest(deletedChannelsData).pipe(
          switchMap(() => this.taskService.editTask(this.task_id, submitData, this.company_id)),
          switchMap(res => {
            if ( this.form.get('consist_of_task_id').value.length > 0 || this.form.get('part_of_task_id').value.length > 0 || this.form.get('related_task_id').value.length > 0 ) {
              let sortData = [];
  
              this.form.get('consist_of_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-partition/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      consist_of_task_id: res.id,
                      part_of_task_id: el,
                      consist_of_order: i,
                      part_of_order: null
                    }
                  }
                )
              });
  
              this.form.get('part_of_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-partition/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      part_of_task_id: res.id,
                      consist_of_task_id: el,
                      part_of_order: i,
                      consist_of_order: null
                    }
                  }
                )
              });
  
              this.form.get('related_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-related/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      task_id: res.id,
                      related_task_id: el,
                      order: i
                    }
                  }
                )
              });
  
              return this.taskService.multiRequest(sortData).pipe(map(() => res))
            } else {
              return of(res)
            }
          }),
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.editTask(this.task_id, submitData, this.company_id).pipe(
          catchError(err => {
            this.layoutService.showSnackBar({name: ''}, err, SnackBarItem)
            return of(err)
          }),
          switchMap(res => {
            if ( this.form.get('consist_of_task_id').value.length > 0 || this.form.get('part_of_task_id').value.length > 0 || this.form.get('related_task_id').value.length > 0 ) {
              let sortData = [];
  
              this.form.get('consist_of_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-partition/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      consist_of_task_id: res.id,
                      part_of_task_id: el,
                      consist_of_order: i,
                      part_of_order: null
                    }
                  }
                )
              });
  
              this.form.get('part_of_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-partition/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      part_of_task_id: res.id,
                      consist_of_task_id: el,
                      part_of_order: i,
                      consist_of_order: null
                    }
                  }
                )
              });
  
              this.form.get('related_task_id').value.forEach((el, i) => {
                sortData.push(
                  {
                    "path": '/api/task-related/register/',
                    "query": {'company_id': this.data.company_id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: this.data.company_id,
                      task_id: res.id,
                      related_task_id: el,
                      order: i
                    }
                  }
                )
              });
  
              return this.taskService.multiRequest(sortData).pipe(map(() => res))
            } else {
              return of(res)
            }
          })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  ngAfterViewInit() {
    let conPhElement = this.consistPlaceholder.nativeElement;

    conPhElement.style.display = 'none';
    conPhElement.parentNode.removeChild(conPhElement);

    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    let relatedPhElement = this.relatedPlaceholder.nativeElement;

    relatedPhElement.style.display = 'none';
    relatedPhElement.parentNode.removeChild(relatedPhElement);
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }

  dragReleased(ph, dragPh) {
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }

  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  openContext({ x, y }: MouseEvent, contextData) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: contextData
    }));
    
    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  moveTo(contextData, targetControl) {
    console.log(contextData, targetControl)

    const fromValues = contextData.form.get(contextData.control).value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).setValue(fromValues); // To trigger change detection

    let toValues = contextData.form.get(targetControl).value as string[];
    toValues.push(String(contextData.id))
    contextData.form.get(targetControl).setValue(toValues); // To trigger change detection

    console.log(contextData.form.value)
    this.closeContext()
  }

  remove(contextData) {
    const fromValues = contextData.form.get(contextData.control).value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).setValue(fromValues);
    this.closeContext()
  }

  openCard(contextData) {
    this.closeContext()
    let task = this.findTask(contextData.id);
    console.log("task", task)
    if (!task) {
      return
    }
    window.open(`${this.origin}/task/${task.acm}`, '_blank')
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
