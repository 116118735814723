<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a work" | translate }}: {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || noChat ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">

            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group required formControlName="status_id">
                  <mat-radio-button color="primary" *ngFor="let status of operationStatuses" [value]="status.id"
                  [ngClass]="{
                    'first': status.id == '1',
                    'second': status.id == '2',
                    'third': status.id == '3',
                    'fours': status.id == '4',
                    'fives': status.id == '5',
                    'sixs': status.id == '6',
                    'nine': status.id == '97',
                    'seven': status.id == '98',
                    'eight': status.id == '99'}">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div> 
        </form>
        <form [formGroup]="workEmpl">  
            <ng-container *ngIf="isChangeRate">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Points' | translate }}</mat-label>
                    <input cdkFocusInitial formControlName="rate" matInput type="number" placeholder="{{ 'Points' | translate }}">
                </mat-form-field>
        
                <mat-form-field *ngIf="data?.empl_status || (!data?.empl_status && +data?.company?.rate_target == 0)" appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Price' | translate }}</mat-label>
                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price' | translate }}">
                </mat-form-field>
            </ng-container>
        </form>
        <form [formGroup]="form">
            <ng-container *ngIf="form.get('status_id').value == 3">
                <mat-checkbox color='primary' [checked]="is_checked" (change)="enableContentUrl($event.checked)" class="example-margin">{{ "This job consist of file or folder to be reviewed." | translate }}</mat-checkbox>
            </ng-container>

            <mat-tab-group *ngIf="showContentUrl" [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon mr-1">perm_media</mat-icon>
                        {{ 'File manager' | translate }}
                    </ng-template>
                    
                    <app-file-manager
                    [useOut]="true"
                    [initLocation]="'/to_approve'"
                    [initData]="{
                        'task': data?.task,
                        'work': data?.work,
                        'company': data?.company
                    }"></app-file-manager>
                </mat-tab>
                
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon mr-1">link</mat-icon>
                        {{ 'Content url' | translate }}
                    </ng-template>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Content url' | translate }}</mat-label>
                        <input formControlName="content_url" matInput placeholder="{{ 'Content url' | translate }}">
                        <mat-hint *ngIf="!form.get('content_url').value" style="color: red;">{{ "Put the link to file or folder you are submitting for approval" | translate }}</mat-hint>
                    </mat-form-field>
                </mat-tab>

            </mat-tab-group>

            
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('completed_at') && (form.get('status_id').value == 3 || form.get('status_id').value == 5)">
                <mat-label>{{ "Completed at" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                <mat-hint *ngIf="!form.get('completed_at').value" style="color: red;">{{ "When submitting a work to this status, you must indicate the completion date" | translate }}</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || noChat">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
