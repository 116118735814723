import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { forkJoin, ReplaySubject } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AddClipVariableComponent } from './add-clip-variable/add-clip-variable.component';
import { RootService } from 'src/app/shared/services/rest/root.service';

@Component({
  selector: 'app-card-clipboard',
  templateUrl: './card-clipboard.component.html',
  styleUrls: ['./card-clipboard.component.scss']
})
export class CardClipboardComponent extends BaseClass implements OnInit, OnDestroy  {
  public scheme: any;
  public isSubmit: boolean = false;
  public name:FormControl = new FormControl('');
  public activeIndex: number = 0;
  public submited: boolean = false;
  public is_active: FormControl = new FormControl(false);
  public devScheme: any[] = []

  
  public switched: boolean = false;
  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public form: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    employee_id: [[this.data.company.employees[0].id]],
    section: 'Card',
    type: 'Clipboard',
    settings: ['', Validators.required],
    order: 0,
    name: ['', Validators.required]
  })

  public cardVars:any = [
    {
      value: 'name',
      name: "Name"
    },
    {
      value: 'group',
      name: "Group"
    },
    {
      value: 'id',
      name: "ID"
    },
    {
      value: 'custom_id',
      name: "Custom ID"
    },
    {
      value: 'internal_id',
      name: "Internal ID"
    },
    {
      value: 'link',
      name: "Link"
    },
    {
      value: 'firstConsistOfName',
      name: "First Consist Of Card Name"
    }
  ]
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CardClipboardComponent>,
    private companyService: CompanyService,
    private taskService: TaskService,
    private rootService: RootService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private refreshService: RefreshService,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("CardClipboardComponent", this.data);
    this.getEmployees();

    if (this.data.scheme && this.data.scheme.settings && this.data.scheme.settings.devScheme) {
      this.devScheme = this.data.scheme.settings.devScheme;
      this.form.removeControl('employee_id');
      this.form.removeControl('company_id');

      this.form.patchValue({
        section: this.data.scheme.section,
        type: this.data.scheme.type,
        order: this.data.scheme.order,
        name: this.data.scheme.name
      })
    }

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  addSep() {
    this.devScheme.push({
      name: 'Separator',
      value: ''
    })
  }

  deleteItem(item, i) {
    this.devScheme.splice(i, 1);
  }

  toggleCardVar(e) {
    console.log("toggleCardVar", e)
  }

  log() {
    console.log("devScheme", this.devScheme)
    let dScheme = JSON.parse(JSON.stringify(this.devScheme));
    console.log("dScheme", dScheme)
  }
  
  addVar() {
    const dialogRef = this.dialog.open(AddClipVariableComponent, {
      disableClose: true,
      data: {
        company: this.data.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {
          this.devScheme.push(result.data)
        }
      })
    )
  }

  editEmployeeBookmark(employee, key) {
    employee[key] = employee[key] == 1 ? 0 : 1
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toggleChip = (chip: any) => {
    this.switched = true;
    if (!!this.form.value.employee_id && this.form.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.form.value.employee_id]
      val.splice(this.form.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.form.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.form.value.employee_id) {
        let val = [...this.form.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.form.patchValue({
          employee_id: val
        })
      } else {
        this.form.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    this.switched = false
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              conValues.map(x => {
                x.is_active = 1;
              })
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  changeTab(val) {
    console.log(val);
    if (val == 1) {
      this.form.patchValue({
        employee_id: [this.data.company.employees[0].id],
      })
    } else if (val == 0) {
      this.form.patchValue({
        employee_id: [this.data.company.employees[0].id],
      })
    }
    this.activeIndex = val;
  }

  getEmployeeById(id) {
    if (!this.employees || this.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  } 

  save() {
    if (!this.devScheme.length) {
      this.layoutService.showSnackBar({name: 'Error'}, "Need to fill scheme", SnackBarItem);
    }

    this.form.patchValue({
      settings: {
        devScheme: this.devScheme
      }
    })

    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      this.layoutService.showSnackBar({name: 'Error'}, "Form is invalid", SnackBarItem);
      return
    }

    this.submited = false;
    this.isSubmit = true;

    let addData = {...this.form.value}

    if (this.activeIndex == 1) {
      let postInData = {...this.form.value};
      delete postInData.employee_id;
      console.log("this.form.value.employee_id", this.form.value.employee_id)

      this.attachSubscriptions(
        this.companyService.addBookmark(Object.assign({
          employee_id: 0,
      }, postInData)).pipe(
        switchMap(book => {
          let postData = [];
          addData.employee_id.forEach(id => {
            postData.push({
              "path": `/api/section-to-employee/`,
              "query": {},
              "method": "POST",
              "body": Object.assign({
                [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                employee_id: id,
              }, {
                company_id: this.data.company.id,
                section_id: book.id,
                order: 0,
                is_active: !!this.getEmployeeById(id).is_active ? 1 : 0,
                is_shortcut: 0,
              }) 
            })
          });
          return this.taskService.multiRequest(postData)
        })
      ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next('CardClipboard')
          this.dialogRef.close({event: 'save'});
        })
      )
    } else if (this.activeIndex == 0) {
      addData.employee_id = addData.employee_id[0]
      this.attachSubscriptions(
        this.companyService.addBookmark(addData).pipe(
          switchMap(res => {
            return this.companyService.getBookmarksExp(this.data.company.id, 1, {id: [res.id]}).pipe(
              map(x => x.body[0])
            )
          }),
          switchMap(book => {
            return this.companyService.editBookmarkEmpl(book.sectionToEmployee.id, {
              is_active: !!this.is_active.value ? 1 : 0,
              is_shortcut: 0
            })
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false

          this.refreshService.refreshBookmarks$.next('CardClipboard')
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        })
      )
    } else if (this.activeIndex == 3) {
      addData.company_id = 0
      addData.employee_id = 0
      this.attachSubscriptions(
        this.rootService.addBookmark(Object.assign(addData, {is_shared: 1})).pipe(
          switchMap(book => {
            return this.rootService.editBookmark(book.id, {is_shared: 1})
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next('CardClipboard')
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        })
      )
    } else {
      addData.employee_id = 0
      this.attachSubscriptions(
        this.companyService.addBookmark(Object.assign(addData, {is_shared: 1})).pipe(
          switchMap(book => {
            return this.companyService.editBookmark(book.id, {is_shared: 1})
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next('CardClipboard')
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        })
      )
    }

  }

  edit() {
    if (!this.devScheme.length) {
      this.layoutService.showSnackBar({name: 'Error'}, "Need to fill scheme", SnackBarItem);
    }

    this.form.patchValue({
      settings: {
        devScheme: this.devScheme
      }
    })

    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      this.layoutService.showSnackBar({name: 'Error'}, "Form is invalid", SnackBarItem);
      return
    }

    this.submited = false;
    this.isSubmit = true;
    let editData = {...this.form.value}

    this.attachSubscriptions(
      this.companyService.editBookmark(this.data.scheme.id, editData).subscribe(resp => {
        this.isSubmit = false
        console.log("editBookmark", resp)
        this.refreshService.refreshBookmarks$.next('CardClipboard')
        this.dialogRef.close({event: 'edit'});
      }, error => {
        console.log(error)
        this.isSubmit = false
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
