<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Set privacy status" | translate }} {{this.data.collection ? ('for' | translate) + " " + this.data.collection.length + " " + ('selected posts' | translate) : ''}}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || !!isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form" style="margin: 20px 0;">
            <mat-radio-group
            formControlName="privacy"
            class="example-radio-group">
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'private'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">lock</mat-icon>
                        {{"Private" | translate}}
                    </div>
                </mat-radio-button>
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'public'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">visibility</mat-icon>
                        {{"Public" | translate}}
                    </div>
                </mat-radio-button>
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'unlisted'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">visibility_off</mat-icon>
                        {{"Unlisted" | translate}}
                    </div>
                </mat-radio-button>
            </mat-radio-group> 
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || !!isSubmit">
        {{ "Set" | translate }}
    </button>
</mat-dialog-actions>
