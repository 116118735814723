import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { catchError, debounceTime, finalize, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { forkJoin, from, of, ReplaySubject } from 'rxjs';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { mimeTypes } from 'mime-wrapper';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-export-audio',
  templateUrl: './export-audio.component.html',
  styleUrls: ['./export-audio.component.scss']
})
export class ExportAudioComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  public is_remember: boolean = !!this.sm.localStorageGetItem('rememberExportAudioTags') ? true : false;
  public parameters: any;
  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private fileService: FileService,
    private parametersService: ParametersService,
    public dialogRef: MatDialogRef<ExportAudioComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ExportAudioComponent")
    this.form = this.fb.group({
      parameter_id: []
    })

    if (!!this.sm.localStorageGetItem('rememberExportAudioTags')) {
      let rememberExportAudioTags = JSON.parse(this.sm.localStorageGetItem('rememberExportAudioTags'));
      if (this.data.company.id && rememberExportAudioTags.company_id == this.data.company.id) {
        this.form.patchValue({
          parameter_id: rememberExportAudioTags.parameter_id
        })
      }
    }

    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    
    this.getParameters();
  }

  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  multiSubmitForm() {
    this.rememberSelection();

    let exportedArr = [];
    this.data.files.filter(x => ["video"].includes(mimeTypes.getType(x.content_type)) || ["video"].includes(this.understandFileType(x.content_type))).forEach(file => {
      let outsFg:any = {
        [`oafile`]: {
          "options": [{
            "-map": `[outa]`
          }],
          "is_part": 1,
          "part_of_files_keys": `file_${file.original_file_id}`,
          "output_location": file.location,
          "output_filename": "Audio - " + file.filename.split('.')[0] + '.m4a',
          "output_content_type": "audio/mp4"
        },
      }
      
      if (this.form.get('parameter_id').value && this.form.get('parameter_id').value.length) {
        let tagsData = [];
        if (file.parameterValuesToTask) {
          file.parameterValuesToTask.forEach(tag => {
            let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
            if (this.form.get('parameter_id').value.includes(tag.parameter_id) && tagsData.filter(q => q.parameter_value_id == pvi).length == 0) {
              tagsData.push({
                parameter_value_id: pvi,
                is_primary: tag.is_primary
              })
            }
          });
        }
  
        outsFg[`oafile`].parameter_values = tagsData
      }
  
      let executeData:any = {
        inputs: {
          [`file_${file.original_file_id}`]: {
            original_file_id: file.original_file_id
          }
        },
        filtergraphs: {
          fg1: {
            inputs: {
              '[0]': {
                options: [],
                input: `file_${file.original_file_id}`
              }
            },
            filtergraph: `
  [0:a]
  asetpts=PTS-STARTPTS
  [outa]`,
            outputs: outsFg
          }
        }
      }

      exportedArr.push({
        editor_version: 'EA',
        company_id: file.company_id,
        task_id: file.task_id,
        task_operation_id: file.task_operation_id,
        discussion_id: file.discussion_id,
        name: `Export audio from ${file.filename}`,
        output_location: file.location,
        is_draft: 1,
        // is_draft: 0,
        // is_to_process: 1,
        output_width: file ? file.meta_width : 0,
        output_height: file ? file.meta_height : 0,
        output_frame_rate: 25,
        output_audio_bit_rate: 192000,
        output_bit_rate: 0,
        waiting_time_limit: 86400,
        execute_data: executeData
      })
    });

    let count = 0;
    let lenSelection = exportedArr.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There is no media from the selected files from which audio can be exported.'}, '', SnackBarItem)
      return
    }

    this.ls.requests$.next({
      value: 0,
      target: `Creating an Audio Export ${lenSelection} files`
    })

    from(exportedArr).pipe(
      mergeMap(x => this.fileService.createVideoProject(x, this.data.company_id).pipe(
        switchMap(project => {
          return this.fileService.editVideoProject(project.id, {
            is_draft: 0,
            is_to_process: 1,
          }, this.data.company_id).pipe(
            tap(k => {
              this.ls.requests$.next({
                value: Math.round((100 / lenSelection) * (count+1)),
                target: `Creating an Audio Export ${lenSelection} files`
              })
              count++;
              if (!!this.dialogRef) {
                this.close();
              }
            }),
            map(editedProject => {
              return {
                project: project,
                editedProject: editedProject
              }
            })
          )
        }),
        catchError(() => of([])),
      ), 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Audio export started'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
    })
  }

  rememberSelection() {
    if (this.is_remember) {
      let rememberExportAudioTags = {
        company_id: this.data.company.id,
        parameter_id: this.form.value.parameter_id
      };
      this.sm.localStorageSetItem('rememberExportAudioTags', rememberExportAudioTags)
    }
  }

  submitForm() {
    this.rememberSelection();
    this.data.file.isImportingAudio = true;
    let outsFg:any = {
      [`oafile`]: {
        "options": [{
          "-map": `[outa]`
        }],
        "is_part": 1,
        "part_of_files_keys": `file_${this.data.file.original_file_id}`,
        "output_location": this.data.file.location,
        "output_filename": "Audio - " + this.data.file.filename.split('.')[0] + '.m4a',
        "output_content_type": "audio/mp4"
      },
    }
    
    if (this.form.get('parameter_id').value && this.form.get('parameter_id').value.length) {
      let tagsData = [];
      this.data.file.parameterValuesToTask.forEach(tag => {
        let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
        if (this.form.get('parameter_id').value.includes(tag.parameter_id) && tagsData.filter(q => q.parameter_value_id == pvi).length == 0) {
          tagsData.push({
            parameter_value_id: pvi,
            is_primary: tag.is_primary
          })
        }
      });

      outsFg[`oafile`].parameter_values = tagsData
    }

    let executeData:any = {
      inputs: {
        [`file_${this.data.file.original_file_id}`]: {
          original_file_id: this.data.file.original_file_id
        }
      },
      filtergraphs: {
        fg1: {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${this.data.file.original_file_id}`
            }
          },
          filtergraph: `
[0:a]
asetpts=PTS-STARTPTS
[outa]`,
          outputs: outsFg
        }
      }
    }


    this.attachSubscriptions(
      this.fileService.createVideoProject({
        editor_version: 'EA',
        company_id: this.data.file.company_id,
        task_id: this.data.file.task_id,
        task_operation_id: this.data.file.task_operation_id,
        discussion_id: this.data.file.discussion_id,
        name: `Export audio from ${this.data.file.filename}`,
        output_location: this.data.file.location,
        is_draft: 1,
        // is_draft: 0,
        // is_to_process: 1,
        output_width: this.data.file ? this.data.file.meta_width : 0,
        output_height: this.data.file ? this.data.file.meta_height : 0,
        output_frame_rate: 25,
        output_audio_bit_rate: 192000,
        output_bit_rate: 0,
        waiting_time_limit: 86400,
        execute_data: executeData
      }, this.data.company_id).pipe(
        switchMap(project => {
          return this.fileService.editVideoProject(project.id, {
            is_draft: 0,
            is_to_process: 1,
          }, this.data.company_id).pipe(
            map(editedProject => {
              return {
                project: project,
                editedProject: editedProject
              }
            })
          )
        })
      ).subscribe(resp => {
        console.log("createProject", resp)
        this.close();
        this.layoutService.showSnackBar({name: 'Audio export started'}, marker("successfully!"), SnackBarItem)
        this.data.file.isImportingAudio = false;
      }, error => {
        console.log("createProject", error)
        this.data.file.isImportingAudio = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
