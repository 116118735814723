// 1 - Waiting
// 2 - In process
// 3 - To Approve
// 4 - Approved
// 5 - To correct
// 6 - Sources needed
// 97 - On hold
// 98 - Ready
// 99 - Canceled
// let allStatuses = [1,2,3,4,5,6,97,98,99]
export function getLessStatuses(id) {
    let array = [];
    switch (+id) {
        case 3:
            array = [1,2,5,6]
            break;
        case 4:
            array = [1,2,3,5,6]
            break;
        case 6:
            array = [1,5]
            break;
        case 97:
            array = [1,2,3,4,5,6]
            break;
        case 98:
            array = [1,2,3,4,5,6,97]
            break;
        case 99:
            array = [1,2,3,4,5,6,97,98]
            break;
    }
    return array;
}