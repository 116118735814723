import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-group-manager-add',
  templateUrl: './group-manager-add.component.html',
  styles: ['./group-manager-add.component.scss']
})

export class GroupManagerAddComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<GroupManagerAddComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {

    console.log("GroupManagerAddComponent", this.data);
    this.form = this.fb.group({
      group_id: this.data.group.id,
      employee_id: ['', Validators.required]
    })

    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.getEmployees();
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.addManagerToGroup(this.form.value).pipe(
        switchMap(x => this.taskService.addEmployeeRule({company_id: this.data.company.id, "employee_id": this.form.value.employee_id, permission: "employee_list"}, this.data.company.id).pipe(map(() => x)))
      ).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      })
    )
  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
