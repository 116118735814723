<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Card managers" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="">
        <mat-list role="list">
            <mat-list-item *ngFor="let manager of taskManagers; let i = index;" role="listitem" class="company_item">
                <div class="company_name">
                    <app-user [class]="'user_div'" [employee]="manager?.employee"></app-user>
                </div>

                <mat-checkbox [disabled]="manager.disabled" class="ml-1 mr-1" color="primary" (change)="toggleManager($event, manager)" [checked]="manager.is_price_manager > 0">
                    {{ "Access to prices" | translate }}
                </mat-checkbox>
                
                <div class="company_btns">
                    <mat-icon (click)="deleteTaskManager(manager, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="addTaskManager()">
            <mat-icon class="mr-1">add</mat-icon>
            {{ "Add manager" | translate }}
        </button>
    </div>
</mat-dialog-actions>
