import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { PushNotificationsService } from 'src/app/shared/services/rest/push-notifications.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-mob-push-settings',
  templateUrl: './mob-push-settings.component.html',
  styleUrls: ['./mob-push-settings.component.scss']
})
export class MobPushSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public origin = window.location.origin;
  public employees: any;
  public notificationSubs: any;
  public is_changed: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobPushSettingsComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private pushNotificationsService: PushNotificationsService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobPushSettingsComponent", this.data)
    this.getNotificationEmployees();
  }
  getNotificationEmployees() {
    if (this.data.post) {
      this.attachSubscriptions(
        this.pushNotificationsService.getPostNotificationEmployees(this.data.company.id, this.data.post.id).pipe(
          tap(x => {
            x.forEach(el => {
              if (el.notificationSubscription == null) {
                el.notificationSubscription = {
                  company_id: el.company_id,
                  employee_id: el.id,
                  discussion_id: this.data.chat.id,
                  discussion_post_id: this.data.post.id,
                  id: 0,
                  is_webpush: 0
                }
              }
            })
          })
        ).subscribe(resp => {
          console.log("getPostNotificationEmployees", resp);
          this.employees = resp;
          console.log("this.employees", this.employees)
        })
      )
    } else {
      this.attachSubscriptions(
        this.pushNotificationsService.getNotificationEmployees(this.data.company.id, this.data.chat.id).pipe(
          tap(x => {
            x.forEach(el => {
              if (el.notificationSubscription == null) {
                el.notificationSubscription = {
                  company_id: el.company_id,
                  employee_id: el.id,
                  discussion_id: this.data.chat.id,
                  id: 0,
                  is_webpush: 0
                }
              }
            })
          })
        ).subscribe(resp => {
          console.log("getNotificationEmployees", resp);
          this.employees = resp;
          this.is_changed = false;
          console.log("this.employees", this.employees)
        })
      )
    }
  }

  showError(error?) {
    this.layoutService.showSnackBar({name: ''}, marker(error ? error + ". You do not have permission to do this." : "You do not have permission to do this"), SnackBarItem)
  }

  save() {
    console.log("save", this.employees);
    let sbmtData = this.employees.slice().map(x => x.notificationSubscription)
    
    sbmtData.forEach(element => {
      element.is_webpush = !!element.is_webpush ? 1 : 0
      // element.is_sms = !!element.is_sms ? 1 : 0
      // element.is_email = !!element.is_email ? 1 : 0
    });
    
    
    let addData = [];
    
    let editIds = [];
    let editData = [];
    
    // return
    sbmtData.forEach(el => {
      if (!!el.id) {
        editIds.push(el.id);
        editData.push({
          is_webpush: !!el.is_webpush ? 1 : 0,
          is_sms: !!el.is_sms ? 1 : 0,
          is_email: !!el.is_email ? 1 : 0
        });
      } else {
        delete el.id
        addData.push(el)
      }
    })

    console.log("save", sbmtData, addData, editIds, editData);
    this.attachSubscriptions(
      forkJoin([...addData.map(x => this.pushNotificationsService.addNotificationSubs(x, this.data.company.id)), ...editData.map((x, i) => this.pushNotificationsService.editNotificationSub(editIds[i], x, this.data.company.id))]).subscribe(resp => {
        console.log(resp)
        this.close();
      }, error => {
        this.getNotificationEmployees();
      })
    )
  }

  changeItemVal(e, item, key) {
    if (e.checked) {
      item.notificationSubscription[key] = 1;
    } else {
      item.notificationSubscription[key] = 0;
    }
    console.log(e, item, key)
    this.is_changed = true
  }

  back() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'back'})}, 300);
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
