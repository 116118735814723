<ng-container *ngIf="!is_mobile; else elseTemplateIsDeskCP">
    <mat-dialog-title class="video_editor_title" [ngClass]="{'sp_b': data?.thumbMode}">
        <div class="left_ve">
            <span (click)="log()" class="version">
                {{(data.project ? "Edit 'Create Cards and Publish' Project" : "Create Cards and Publish Project" ) | translate}}
            </span>
            <mat-form-field appearance="outline" class="white_legacy_select">
                <mat-label>{{ 'Project Name' | translate }}</mat-label>
                <input [(ngModel)]="projectName" matInput placeholder="{{ 'Project Name' | translate }}">
            </mat-form-field>
        </div>
    
        <div class="right_ve">
            <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="cards_btn big" (click)="save(true)">
                <mat-spinner class="spin_24 mr-1" *ngIf="isSubmit"></mat-spinner> 
                <span>
                    {{"Save project"| translate}}
                </span>
            </button>
            <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="cards_btn green big" (click)="save()">
                <mat-spinner class="spin_24 mr-1" *ngIf="isSubmit"></mat-spinner> 
                <span>
                    {{"Export"| translate}}
                </span>
            </button>
    
            <button class="cards_btn close" (click)="close()">
                <mat-icon class="close_i">close</mat-icon>
            </button>
        </div>
    </mat-dialog-title>
    <mat-dialog-content class="video_editor_content">
        <div class="video_edtior_screen" cdkDropListGroup>
            <form [formGroup]="form" class="cards_form">
                <p>{{"Set for all" | translate}}:</p>
    
                <mat-form-field appearance="outline" class="white_legacy_select">
                    <mat-label>{{ 'Card template' | translate }}</mat-label>
                    <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                            [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                            {{ tmpl?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="outline" class="white_legacy_select ml-1">
                    <mat-label>{{ 'Card title' | translate }}</mat-label>
                    <input formControlName="card_name" matInput placeholder="{{ 'Card title' | translate }}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="white_legacy_select ml-1" *ngIf="publications && publications.length">
                    <mat-label>{{ 'Post title' | translate }}</mat-label>
                    <input formControlName="post_name" matInput placeholder="{{ 'Post title' | translate }}">
                </mat-form-field>
                <!-- , form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value).template_data.group_id: form.get('group_id').value -->
    
                <div class="ml-1 cards_btn" *ngIf="form.get('template_id').value && !(!!publications && !!publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, {})">
                    <mat-icon>public</mat-icon>
                    <span>{{"Outlets" | translate}}</span>
                </div>
                <div class="ml-1 cards_btn" *ngIf="!!publications && !!publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                    <mat-icon style="color: red !important;">public_off</mat-icon>
                    <span>{{"Clear Outlets" | translate}}</span>
                </div>
    
                <div class="ml-1 cards_btn" *ngIf="!!publications && !!publications.length" (click)="toggleMadeForKids()">
                    <mat-icon *ngIf="!!form.value.made_for_kids">check_box</mat-icon>
                    <mat-icon *ngIf="!form.value.made_for_kids">check_box_outline_blank</mat-icon>
                    <span>{{"Made for kids" | translate}}</span>
                </div>
                <div class="ai_block ml-1" *ngIf="!!publications && !!publications.length">
                    <mat-form-field appearance="outline" class="white_legacy_select">
                        <mat-label>{{ "AI model" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir  [formControl]="ai_model">
                          <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                [formControl]="aiModelControl"></ngx-mat-select-search>
                          </mat-option>
                          <ng-container *ngFor="let model of models$ | async">
                              <mat-option [value]="model.id">
                                {{model.name}}
                              </mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="white_legacy_select ml-1">
                        <mat-label>{{ "AI frames" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_frames">
                            <mat-option [value]="0">
                                {{"Without frames"}}
                            </mat-option>
                            <mat-option [value]="1">
                                1
                            </mat-option>
                            <mat-option [value]="2">
                                2
                            </mat-option>
                            <mat-option [value]="3">
                                3
                            </mat-option>
                            <mat-option [value]="4">
                                4
                            </mat-option>
                            <mat-option [value]="5">
                                5
                            </mat-option>
                            <mat-option [value]="6">
                                6
                            </mat-option>
                            <mat-option [value]="7">
                                7
                            </mat-option>
                            <mat-option [value]="8">
                                8
                            </mat-option>
                            <mat-option [value]="9">
                                9
                            </mat-option>
                            <mat-option [value]="10">
                                10
                            </mat-option>
                        </mat-select>
                        <button matSuffix mat-icon-button style="color: #fff;" (click)="createFrames()" [disabled]="!ai_frames.value">
                            <mat-icon>add</mat-icon>
                        </button>
                    </mat-form-field>
                    
                    <button mat-icon-button class="ml-1" color="primary" style="color: #fff;" (click)="selectAiRequest()" #tooltip="matTooltip" matTooltip="{{ 'AI info' | translate }}" [matTooltipPosition]="'above'">
                        <mat-icon>assistant</mat-icon>
                    </button>
                    <button mat-icon-button class="ml-1" color="primary" style="color: #fff;" (click)="openAiReqText()" #tooltip="matTooltip" matTooltip="{{ 'AI info using prompt' | translate }}" [matTooltipPosition]="'above'">
                        <mat-icon>smart_button</mat-icon>
                    </button>
                    <button mat-icon-button class="ml-1" color="primary" *ngIf="ai_frames.value" style="color: #4eb2cb;" (click)="selectAiRequest(null, null, true)" #tooltip="matTooltip" matTooltip="{{ 'AI info using frames' | translate }}" [matTooltipPosition]="'above'">
                        <mat-icon>screenshot_monitor</mat-icon>
                    </button>
                    <button mat-icon-button class="ml-1" color="primary" *ngIf="ai_frames.value" style="color: #4eb2cb;" (click)="openAiReqText(true)" #tooltip="matTooltip" matTooltip="{{ 'AI info using frames & prompt' | translate }}" [matTooltipPosition]="'above'">
                        <mat-icon>smart_button</mat-icon>
                    </button>
                </div>
    <!-- 
                <div class="tmpl_val ml-1" *ngIf="form.get('template_id').value && !(!!publications && !!publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, {})">
                    <div class="dark">
                        <mat-icon>public</mat-icon>
                    </div>
                </div>
    
                <div class="tmpl_val ml-1" *ngIf="!!publications && !!publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                    <div class="dark">
                        <mat-icon style="color: red !important;">public_off</mat-icon>
                    </div>
                </div>
    
                <div class="box_wrp ml-1" *ngIf="!!publications && !!publications.length">
                    <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                </div> -->
                <ng-container *ngIf="!!publications && !!publications.length">
                    <div class="publications_title">
                        {{"Outlets" | translate}}
                    </div>
                    <div class="publications">
                        <app-outlet-line *ngFor="let item of publications" [class]="'s_32'" [channel]="item.channel" [company_id]="data?.company?.id"></app-outlet-line> 
                    </div>
                </ng-container>
                
            </form>
            <div class="cards_out" cdkDropList (cdkDropListDropped)="dropCard($event)" [appDeviceClass]="'custom-scroll'">
                <div class="card" *ngFor="let item of cards; let i = index;" cdkDrag>
                    <div class="drag_icon" cdkDragHandle>
                        <mat-icon>drag_handle</mat-icon>
                    </div>
                    <div class="card_in">
                        <div class="files" 
                        [ngClass]="{'highlight': is_dragging}"
                        cdkDropList
                        [cdkDropListData]="item.files"
                        (cdkDropListDropped)="drop($event)">
                            <div class="card_file" *ngFor="let file of item.files" cdkDrag (cdkDragStart)="startDrag($event)" (cdkDragEnd)="endDrag($event)">
                                <div class="card_file_img" [ngClass]="{'opening': file.isOpen}">
                                <!-- <div class="card_file_img" [ngClass]="{'opening': file.isOpen}" (click)="openFile($event, file, item)" #tooltip="matTooltip" matTooltip="{{ 'Open file' | translate }}" [matTooltipPosition]="'above'"> -->
                                    <ng-container *ngIf="understandFileType(file.content_type) == 'video'; else elseTemplateIsVideo">
                                        <div class="thumb_btn" #tooltip="matTooltip" matTooltip="{{ 'Use as thumbnail' | translate }}" [matTooltipPosition]="'above'" (click)="useThumb(file)">
                                            <mat-icon>image</mat-icon>
                                        </div>
                                        <video width="100%" class="player" controls [id]="'video_'+file.cstID">
                                            <source [src]="data?.host + (file.preview1080 ? file.preview1080 : file.original) + '?company_id=' + data?.company_id" [type]="file.content_type == 'video/quicktime' ? 'video/mp4' : file.content_type"/>
                                            Your browser doesn't support HTML5 video tag.
                                        </video>
                                    </ng-container>
                                    <ng-template #elseTemplateIsVideo>
                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="data?.host + file.thumbnail + '?company_id=' + data?.company?.id">
                                    </ng-template>
                                    
                                    <!-- <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="data?.host + file.thumbnail + '?company_id=' + data?.company?.id"> -->
                                    <mat-spinner class="spin_open_file spin_64" *ngIf="file.isOpen"></mat-spinner>  
                                </div>
                                <div class="card_file_info">
                                    <div class="d-f-c">
                                        <div class="card_file_name text_2_line" [ngClass]="{'has_posts': file.publications && file.publications.length}">{{file?.filename}}</div>    
                                        <button matSuffix style="color: #fff;" class="dots_file" mat-icon-button [matMenuTriggerFor]="fileTitleMenu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #fileTitleMenu="matMenu" class="cp_value_menu_class" overlayPanelClass='ratio_value_menu' xPosition="before">
                                            <button mat-menu-item (click)="useThumb(file)">
                                                <span>{{'Use as thumbnail' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="addFrame(file)">
                                                <span>{{'Add Frame for AI' | translate}}</span>
                                            </button>
                                            <button mat-menu-item *ngIf="!!file.post_frame_ms" (click)="removeThumb(file)">
                                                <span>{{'Delete custom thumbnail' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="openFile(null, file, item)">
                                                <span>{{'Open file' | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="deleteFile(file, item)">
                                                <span>{{'Delete file' | translate}}</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div class="file_tags" *ngIf="file.parameterValuesToTask">
                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.125 4.31415C3.42337 4.31415 3.70952 4.19562 3.9205 3.98464C4.13147 3.77366 4.25 3.48752 4.25 3.18915C4.25 2.89078 4.13147 2.60463 3.9205 2.39365C3.70952 2.18267 3.42337 2.06415 3.125 2.06415C2.82663 2.06415 2.54048 2.18267 2.3295 2.39365C2.11853 2.60463 2 2.89078 2 3.18915C2 3.48752 2.11853 3.77366 2.3295 3.98464C2.54048 4.19562 2.82663 4.31415 3.125 4.31415ZM12.0575 6.24915C12.3275 6.51915 12.5 6.89415 12.5 7.31415C12.5 7.72665 12.335 8.10165 12.0575 8.37165L8.3075 12.1216C8.16839 12.2615 8.00302 12.3724 7.8209 12.4481C7.63878 12.5239 7.44348 12.5628 7.24625 12.5628C7.04902 12.5628 6.85372 12.5239 6.6716 12.4481C6.48948 12.3724 6.32411 12.2615 6.185 12.1216L0.9425 6.87915C0.665 6.60165 0.5 6.22665 0.5 5.81415V2.06415C0.5 1.23165 1.1675 0.564148 2 0.564148H5.75C6.1625 0.564148 6.5375 0.729148 6.8075 0.999148L12.0575 6.24915ZM9.155 1.84665L9.905 1.09665L15.0575 6.24915C15.335 6.51915 15.5 6.90165 15.5 7.31415C15.5 7.72665 15.335 8.10165 15.065 8.37165L11.03 12.4066L10.28 11.6566L14.5625 7.31415L9.155 1.84665Z" fill="white" fill-opacity="0.6"/>
                                        </svg>
                                        <div class="tag" *ngFor="let tag of file.parameterValuesToTask">
                                            {{tag?.parameterValue?.value}}
                                        </div>
                                    </div>

                                    <div class="frames_out">
                                        <div *ngIf="!!file.thumbFile" class="thumb_file">
                                            <div class="img_out" #tooltip="matTooltip" matTooltip="{{ 'Acive Thumbnail ' + (file?.thumbFile?.time | veTime) }}" [matTooltipPosition]="'above'">
                                                <div class="loading_info" *ngIf="!file.thumbFile.uploadedFile">
                                                    <mat-spinner class="spin_50"></mat-spinner>  
                                                </div>
                                                <div class="btn_remove" (click)="removeThumb(file)">
                                                    <mat-icon>close</mat-icon>
                                                </div>
                                                
                                                <img [src]="!!file?.thumbFile?.uploadedFile ? (data?.host + file.thumbFile.uploadedFile.original + '?company_id=' + data?.company?.id) : file.thumbFile.blobSrc" alt="">
                                            </div>
                                        </div>
                                        <div class="frames thumbs" *ngIf="file?.consists && file?.consists.length">
                                            <div class="frame" (click)="acitveFrame(file, el)" [ngClass]="{'load': !el.uploadedFile, 'active': file.thumbFile && el.time == file.thumbFile.time}" *ngFor="let el of file?.consists; let fInd = index;" #tooltip="matTooltip" matTooltip="{{ 'Thumbnail ' + (el?.time | veTime) }}" [matTooltipPosition]="'above'">
                                                <ng-container *ngIf="file.thumbFile && el.time == file.thumbFile.time">
                                                    <div class="btn_remove" (click)="removeThumb(file, fInd)">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                    <div class="loading_info" *ngIf="!file.thumbFile.uploadedFile">
                                                        <mat-spinner class="spin_50"></mat-spinner>  
                                                    </div>
                                                </ng-container>
                                                
                                                <img [src]="!!el?.uploadedFile ? (data?.host + el?.uploadedFile?.original + '?company_id=' + data?.company?.id) : el?.blobSrc" alt="">
                                            </div>
                                        </div>
                                        <div class="frames" *ngIf="file?.frames && file?.frames.length">
                                            <div class="frame" [ngClass]="{'load': !frame.uploadedFile}" *ngFor="let frame of file?.frames; let fInd = index;" #tooltip="matTooltip" matTooltip="{{ 'Ai frame ' + (frame?.time | veTime) }}" [matTooltipPosition]="'above'">
                                                <div class="loading_info" *ngIf="!frame.uploadedFile">
                                                    <mat-spinner class="spin_50"></mat-spinner>  
                                                </div>
                                                <div class="btn_remove" (click)="removeFrame(file, fInd)">
                                                    <mat-icon>close</mat-icon>
                                                </div>
                                                <img [src]="frame.blobSrc" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="card_info">
                            <div class="fields">
                                <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;" *ngIf="!hasFilesWithPubl(item)">
                                    <mat-label>{{ 'Card title' | translate }}</mat-label>
                                    <input [(ngModel)]="item.card_name" matInput placeholder="{{ 'Card title' | translate }}">
                                </mat-form-field>
    
                                <ng-container *ngFor="let file of item.files; let q = index;">
                                    <ng-container *ngIf="file.publications && file.publications.length">
                                        <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                            <mat-label>{{ !!hasFilesWithPubl(item) && q == 0 ? 'Card/Post title' : 'Post title' | translate }}</mat-label>
                                            <input [(ngModel)]="file.post_name" matInput placeholder="{{ !!hasFilesWithPubl(item) && q == 0 ? 'Card/Post title' : 'Post title' | translate }}">
                                            <button matSuffix style="color: #fff;" class="dots" mat-icon-button [matMenuTriggerFor]="fileTitleMenu" aria-label="Example icon-button with a menu">
                                                <mat-icon>assistant</mat-icon>
                                            </button>
                                            <mat-menu #fileTitleMenu="matMenu" class="cp_value_menu_class" overlayPanelClass='ratio_value_menu' xPosition="before">
                                                <button *ngFor="let title of file.titles" mat-menu-item (click)="setValue(file, 'post_name', title)">
                                                    <span>{{title}}</span>
                                                </button>
                                                <div class="loading_info" *ngIf="file.is_getting_ai || item.is_getting_ai">
                                                    <mat-spinner class="spin_open_file spin_50"></mat-spinner>  
                                                </div>
                                                <button mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); getValue(file, item)">
                                                    <span style="text-align: center; padding: 5px 0; color: green;">{{file.titles && file.titles.length ? 'Refresh' : 'Get' | translate}}</span>
                                                </button>
                                            </mat-menu>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                            <mat-label>{{ 'Post Description' | translate }}</mat-label>
                                            <textarea 
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="3"
                                            cdkAutosizeMaxRows="9" [(ngModel)]="file.post_description" matInput placeholder="{{ 'Post description' | translate }}"></textarea>
                                            <button matSuffix style="color: #fff;" class="dots" mat-icon-button [matMenuTriggerFor]="fileDescMenu" aria-label="Example icon-button with a menu">
                                                <mat-icon>assistant</mat-icon>
                                            </button>
                                            <mat-menu #fileDescMenu="matMenu" class="cp_value_menu_class" overlayPanelClass='ratio_value_menu' xPosition="before">
                                                <button *ngFor="let desc of file.descs" mat-menu-item (click)="setValue(file, 'post_description', desc)">
                                                    <span>{{desc}}</span>
                                                </button>
                                                <div class="loading_info" *ngIf="file.is_getting_ai || item.is_getting_ai">
                                                    <mat-spinner class="spin_open_file spin_50"></mat-spinner>  
                                                </div>
                                                <button mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); getValue(file, item)">
                                                    <span style="text-align: center; padding: 5px 0; color: green;">{{file.descs && file.descs.length ? 'Refresh' : 'Get' | translate}}</span>
                                                </button>
                                            </mat-menu>
                                        </mat-form-field>

                                        <!-- <ng-container *ngIf="data?.profile?.channel?.platform_id == 4 || [201, 202, 206].includes(form.get('content_type_id').value)"> -->
                                            <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                                <mat-label>{{"Location" | translate}}</mat-label>
                                                <input type="text" matInput [(ngModel)]="file.post_location" [matAutocomplete]="auto" (focus)="onFocusLocation($event)" (focusout)="onFocusOutLocation($event)" (input)="changeLocation($event)">
                                                <mat-icon matSuffix>place</mat-icon>
                                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event, file)">
                                                    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                                                    {{option.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-hint style="color: #ffffff8a;">{{"In case no matches enter code of location. Code you can find" | translate}} <a target="_blank" style="color: #ffffff !important;" href="https://www.facebook.com/places/">{{"here" | translate}}.</a></mat-hint>
                                            </mat-form-field>
                                        <!-- </ng-container> -->

                                        <!-- <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                            <mat-label>{{ 'Thumbnail frame (s)' | translate }}</mat-label>
                                            <input [(ngModel)]="file.post_frame_ms" type="number" matInput placeholder="{{ 'Thumbnail frame (s)' | translate }}">
                                        </mat-form-field> -->
                                    </ng-container>
                                </ng-container>
                            </div>
                            <!-- <button mat-icon-button color="warn" style="align-self: center;" (click)="deleteCard(item, i)">
                                <mat-icon>delete</mat-icon>
                            </button> -->
                            <svg style="width: 49px; height: 49px" class="delete_card" (click)="deleteCard(item, i)" width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.12309" y="1.6016" width="46.8388" height="46.8388" rx="23.4194" stroke="#FFFFFF66" stroke-width="2.07625"/>
                                <path d="M18.5425 32.021C18.5425 33.121 19.4425 34.021 20.5425 34.021H28.5425C29.6425 34.021 30.5425 33.121 30.5425 32.021V22.021C30.5425 20.921 29.6425 20.021 28.5425 20.021H20.5425C19.4425 20.021 18.5425 20.921 18.5425 22.021V32.021ZM30.5425 17.021H28.0425L27.3325 16.311C27.1525 16.131 26.8925 16.021 26.6325 16.021H22.4525C22.1925 16.021 21.9325 16.131 21.7525 16.311L21.0425 17.021H18.5425C17.9925 17.021 17.5425 17.471 17.5425 18.021C17.5425 18.571 17.9925 19.021 18.5425 19.021H30.5425C31.0925 19.021 31.5425 18.571 31.5425 18.021C31.5425 17.471 31.0925 17.021 30.5425 17.021Z" fill="#FFFFFF66"/>
                            </svg>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </mat-dialog-content>
</ng-container>
<ng-template #elseTemplateIsDeskCP>
    <div class="mobile_dark_modal">
        <mat-dialog-title class="video_editor_title" [ngClass]="{'sp_b': data?.thumbMode}">
            <div class="left_ve">
                <span (click)="log()" class="version">
                    {{(data.project ? "Edit 'Create Cards and Publish' Project" : "Create Cards and Publish Project" ) | translate}}
                </span>
                <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                    <mat-label>{{ 'Project Name' | translate }}</mat-label>
                    <input [(ngModel)]="projectName" matInput placeholder="{{ 'Project Name' | translate }}">
                </mat-form-field>
            </div>
        </mat-dialog-title>
        <mat-dialog-content class="video_editor_content">
            <div class="video_edtior_screen" cdkDropListGroup [appDeviceClass]="'custom-scroll'">
                <form [formGroup]="form" class="cards_form">
                    <div class="cards_form_head">
                        <p>{{"Set for all" | translate}}:</p>
                        <div class="show_hide" (click)="isShow = !isShow">
                            {{isShow ? "Hide Options" : "Show Options" | translate}}

                            <svg *ngIf="isShow" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80058 1.3142L13.3693 6.88295C13.8086 7.32222 13.8086 8.03442 13.3693 8.4737C12.9301 8.91297 12.2179 8.91297 11.7786 8.4737L7.0052 3.70032L2.23183 8.4737C1.79256 8.91297 1.08035 8.91297 0.641078 8.4737C0.201805 8.03443 0.201804 7.32222 0.641078 6.88295L6.20983 1.3142C6.4208 1.10329 6.70689 0.984813 7.0052 0.984813C7.30351 0.984813 7.58961 1.10329 7.80058 1.3142Z" fill="white"/>
                            </svg>
                            <svg *ngIf="!isShow" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.20137 8.37626L0.632623 2.80751C0.19335 2.36824 0.193351 1.65603 0.632624 1.21676C1.0719 0.777488 1.7841 0.777488 2.22337 1.21676L6.99675 5.99014L11.7701 1.21676C12.2094 0.777489 12.9216 0.777488 13.3609 1.21676C13.8001 1.65604 13.8001 2.36824 13.3609 2.80751L7.79212 8.37626C7.58116 8.58717 7.29506 8.70565 6.99675 8.70565C6.69844 8.70565 6.41234 8.58717 6.20137 8.37626Z" fill="white"/>
                            </svg>    
                        </div>
                    </div>
        
                    <ng-container *ngIf="isShow">
                        
                        <mat-form-field appearance="outline" class="white_legacy_select">
                            <mat-label>{{ 'Card template' | translate }}</mat-label>
                            <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                    [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                                    {{ tmpl?.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            
                        <mat-form-field appearance="outline" class="white_legacy_select ml-1">
                            <mat-label>{{ 'Card title' | translate }}</mat-label>
                            <input formControlName="card_name" matInput placeholder="{{ 'Card title' | translate }}">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="white_legacy_select ml-1" *ngIf="publications && publications.length">
                            <mat-label>{{ 'Post title' | translate }}</mat-label>
                            <input formControlName="post_name" matInput placeholder="{{ 'Post title' | translate }}">
                        </mat-form-field>

                        <div class="d-f-c">
                            <div class="ml-1 cards_btn" *ngIf="form.get('template_id').value && !(!!publications && !!publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, {})">
                                <mat-icon>public</mat-icon>
                                <span>{{"Outlets" | translate}}</span>
                            </div>
                            <div class="ml-1 cards_btn" *ngIf="!!publications && !!publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                                <mat-icon style="color: red !important;">public_off</mat-icon>
                                <span>{{"Clear Outlets" | translate}}</span>
                            </div>
                
                            <div class="ml-1 cards_btn" *ngIf="!!publications && !!publications.length" (click)="toggleMadeForKids()">
                                <mat-icon *ngIf="!!form.value.made_for_kids">check_box</mat-icon>
                                <mat-icon *ngIf="!form.value.made_for_kids">check_box_outline_blank</mat-icon>
                                <span>{{"Made for kids" | translate}}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="!!publications && !!publications.length">
                            <div class="publications_title">
                                {{"Outlets" | translate}}
                            </div>
                            <div class="publications">
                                <app-outlet-line *ngFor="let item of publications" [class]="'s_20'" [channel]="item.channel" [company_id]="data?.company?.id"></app-outlet-line> 
                            </div>
                        </ng-container>
                    </ng-container>
                    
                </form>
                <div class="cards_out">
                    <div class="card" *ngFor="let item of cards; let i = index;">
                    
                        <div class="card_in">
                            <div class="files" 
                            [ngClass]="{'highlight': is_dragging}"
                            cdkDropList
                            [cdkDropListData]="item.files"
                            (cdkDropListDropped)="drop($event)">
                                <div class="card_file" *ngFor="let file of item.files" cdkDrag (cdkDragStart)="startDrag($event)" (cdkDragEnd)="endDrag($event)">
                                    <div class="card_file_img" [ngClass]="{'opening': file.isOpen}" (click)="openFile($event, file, item)" #tooltip="matTooltip" matTooltip="{{ 'Open file' | translate }}" [matTooltipPosition]="'above'">
                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="data?.host + file.thumbnail + '?company_id=' + data?.company?.id">
                                        <mat-spinner class="spin_open_file spin_64" *ngIf="file.isOpen"></mat-spinner>  
                                    </div>
                                    <div class="card_file_info">
                                        <div class="d-f-c">
                                            <div class="card_file_name text_2_line" [ngClass]="{'has_posts': file.publications && file.publications.length}">{{file?.filename}}</div>    
                                        </div>
                                        <div class="file_tags" *ngIf="file.parameterValuesToTask">
                                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.125 4.31415C3.42337 4.31415 3.70952 4.19562 3.9205 3.98464C4.13147 3.77366 4.25 3.48752 4.25 3.18915C4.25 2.89078 4.13147 2.60463 3.9205 2.39365C3.70952 2.18267 3.42337 2.06415 3.125 2.06415C2.82663 2.06415 2.54048 2.18267 2.3295 2.39365C2.11853 2.60463 2 2.89078 2 3.18915C2 3.48752 2.11853 3.77366 2.3295 3.98464C2.54048 4.19562 2.82663 4.31415 3.125 4.31415ZM12.0575 6.24915C12.3275 6.51915 12.5 6.89415 12.5 7.31415C12.5 7.72665 12.335 8.10165 12.0575 8.37165L8.3075 12.1216C8.16839 12.2615 8.00302 12.3724 7.8209 12.4481C7.63878 12.5239 7.44348 12.5628 7.24625 12.5628C7.04902 12.5628 6.85372 12.5239 6.6716 12.4481C6.48948 12.3724 6.32411 12.2615 6.185 12.1216L0.9425 6.87915C0.665 6.60165 0.5 6.22665 0.5 5.81415V2.06415C0.5 1.23165 1.1675 0.564148 2 0.564148H5.75C6.1625 0.564148 6.5375 0.729148 6.8075 0.999148L12.0575 6.24915ZM9.155 1.84665L9.905 1.09665L15.0575 6.24915C15.335 6.51915 15.5 6.90165 15.5 7.31415C15.5 7.72665 15.335 8.10165 15.065 8.37165L11.03 12.4066L10.28 11.6566L14.5625 7.31415L9.155 1.84665Z" fill="white" fill-opacity="0.6"/>
                                            </svg>
                                            <div class="tag" *ngFor="let tag of file.parameterValuesToTask">
                                                {{tag?.parameterValue?.value}}
                                            </div>
                                        </div>
                                        <button style="color: #fff;" class="dots" mat-icon-button [matMenuTriggerFor]="cardMenu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #cardMenu="matMenu" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">

                                            <button mat-menu-item (click)="deleteCard(item, i)">
                                                <mat-icon style="color: red;">delete</mat-icon>
                                                <span>{{"Delete" | translate}}</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                
                            <div class="card_info">
                                <div class="fields">
                                    <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                        <mat-label>{{ 'Card title' | translate }}</mat-label>
                                        <input [(ngModel)]="item.card_name" matInput placeholder="{{ 'Card title' | translate }}">
                                    </mat-form-field>
        
                                    
                                    <ng-container *ngFor="let file of item.files">
                                        <ng-container *ngIf="file.publications && file.publications.length">
                                            <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                                <mat-label>{{ 'Post title' | translate }}</mat-label>
                                                <input [(ngModel)]="file.post_name" matInput placeholder="{{ 'Post title' | translate }}">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                                <mat-label>{{ 'Post Description' | translate }}</mat-label>
                                                <textarea 
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="3"
                                                cdkAutosizeMaxRows="9" [(ngModel)]="file.post_description" matInput placeholder="{{ 'Post description' | translate }}"></textarea>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="white_legacy_select" style="width: 100%;">
                                                <mat-label>{{"Location" | translate}}</mat-label>
                                                <input type="text" matInput [(ngModel)]="file.post_location" [matAutocomplete]="auto" (focus)="onFocusLocation($event)" (focusout)="onFocusOutLocation($event)" (input)="changeLocation($event)">
                                                <mat-icon matSuffix>place</mat-icon>
                                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event, file)">
                                                    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                                                    {{option.name}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-hint style="color: #ffffff8a;">{{"In case no matches enter code of location. Code you can find" | translate}} <a target="_blank" style="color: #ffffff !important;" href="https://www.facebook.com/places/">{{"here" | translate}}.</a></mat-hint>
                                            </mat-form-field>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!-- <button mat-icon-button color="warn" style="align-self: center;" (click)="deleteCard(item, i)">
                                    <mat-icon>delete</mat-icon>
                                </button> -->
                  
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </mat-dialog-content>
        <mat-dialog-actions align="end" class="bottom_btns">
            <button class="cards_btn close" (click)="close()">
                <mat-icon class="close_i">close</mat-icon>
            </button>
    
            <div class="d-f-c">
                <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="cards_btn big" (click)="save(true)">
                    <mat-spinner class="spin_24 mr-1" *ngIf="isSubmit"></mat-spinner> 
                    <span>
                        {{"Save project"| translate}}
                    </span>
                </button>
                <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="cards_btn green big" (click)="save()">
                    <mat-spinner class="spin_24 mr-1" *ngIf="isSubmit"></mat-spinner> 
                    <span>
                        {{"Export"| translate}}
                    </span>
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>




<ng-template #contextMenu let-contextdata>
	<section class="dark_menu with_search" [ngClass]="{'ai_menu': !!contextdata?.is_ai, with_mistake: !!aiMistake}">
        <ng-container *ngIf="!!contextdata?.is_ai; else elseTemplateIsAiMenu">
            <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasResHead">
                <div class="context_head no_focus">
                    <div class="cont_title">
                        <div>{{aiResults.length}} {{"another options" | translate}}</div>
                        <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); aiResults = []; aiMistake = undefined;">
                            <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                        </div>
                    </div>
                    <div class="cont_desc">
                        {{"Tap option to apply" | translate}}
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateHasResHead>
                <ng-container *ngIf="!!aiMistake; else elseTemplateHasMistake">
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Your response was received in the wrong format" | translate}}</div>
                            <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); resetRes();">
                                <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Try again by clicking on the 'Get Results'" | translate}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasMistake>
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Title composer" | translate}}</div>
                            <div>
                                <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                                </svg> 
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Here you may compose title for all video publications" | translate}}
                        </div>
                    </div>
                </ng-template>
            </ng-template>

    
            <div class="context_body" [ngClass]="{'with_results': aiResults && aiResults.length}">
                <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasRes">
                    <div class="res_list">
                        <div class="res text_one_line" [ngClass]="{'active': (!!contextdata.contextData && !!contextdata.contextData.is_cut && !!contextdata.contextData.form && contextdata.contextData.form.get('name').value == item) || ((!contextdata.contextData || !contextdata.contextData.is_cut) && form.get('name').value == item)}" *ngFor="let item of aiResults" (click)="$event.preventDefault(); $event.stopPropagation(); selectTitle(item, contextdata)">
                            {{item}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasRes>
                    <div class="body_left">
                        <form [formGroup]="aiForm">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'On the video' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="on_the_video" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('on_the_video').value" [ngClass]="{'red': aiForm.get('on_the_video').value.length > 999999999}" align="end">{{aiForm.get('on_the_video').value.length}}</mat-hint>
                            </mat-form-field>
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Your idea of title' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="your_idea_of_title" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('your_idea_of_title').value" [ngClass]="{'red': aiForm.get('your_idea_of_title').value.length > 999999999}" align="end">{{aiForm.get('your_idea_of_title').value.length}}</mat-hint>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 10px;">
                                <mat-label>{{"Words to use" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #chipGrid aria-label="keywords selection" formControlName="keywords">
                                    <mat-chip class="chip_el" *ngFor="let keyword of aiForm.get('keywords').value" (removed)="removeKeyword(keyword)">
                                    {{keyword}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + keyword">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #keyInput placeholder="Other Keyword..." [formControl]="savedTagsControl" (input)="$event.stopPropagation()"
                                    #triggerKeys="matAutocompleteTrigger"
                                    [matChipInputFor]="chipGrid" [matAutocomplete]="keywordsAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addKeyword($event, keyInput, triggerKeys)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerKeys.panelOpen ? triggerKeys.closePanel() : triggerKeys.openPanel()">
                                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #keywordsAuto="matAutocomplete" (optionSelected)="selectedKeyword($event, keyInput)">
                                    <div class="select_all_auto" *ngIf="checkIfCanSelectAll((savedTags$ | async), aiForm.get('keywords').value)" (click)="$event.stopPropagation(); $event.preventDefault(); selectAllKeywords(keywordsAuto, aiForm.get('keywords').value, aiForm, triggerKeys)">{{"Select All" | translate}}</div>
                                    <ng-container *ngFor="let keyword of savedTags$ | async">
                                        <ng-container *ngIf="!aiForm.get('keywords').value.includes(keyword.value)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="keyword.value">
                                                {{keyword.value}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 15px;">
                                <mat-label>{{"Remember" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #remGrid aria-label="remembers selection" formControlName="remembers">
                                    <mat-chip class="chip_el" *ngFor="let remember of aiForm.get('remembers').value" (removed)="removeRemember(remember)">
                                    {{remember}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + remember">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #remInput placeholder="Other Remember..." [formControl]="remembersControl" (input)="$event.stopPropagation()"
                                    #triggerRem="matAutocompleteTrigger"
                                    [matChipInputFor]="remGrid" [matAutocomplete]="remembersAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addRemember($event, remInput, triggerRem)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerRem.panelOpen ? triggerRem.closePanel() : triggerRem.openPanel()">
                                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H2.66667V2.66667H0V0ZM4 0.666667V2H12V0.666667H4ZM0 4H2.66667V6.66667H0V4ZM4 4.66667V6H12V4.66667H4ZM0 8H2.66667V10.6667H0V8ZM4 8.66667V10H12V8.66667H4Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #remembersAuto="matAutocomplete" (optionSelected)="selectedRemember($event, remInput)">
                                    <ng-container *ngFor="let remember of remembers$ | async">
                                        <ng-container *ngIf="!aiForm.get('remembers').value.includes(remember.message)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="remember.message">
                                                {{remember.message}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                                
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Also remember' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="2" formControlName="also_remember" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('also_remember').value" [ngClass]="{'red': aiForm.get('also_remember').value.length > 999999999}" align="end">{{aiForm.get('also_remember').value.length}}</mat-hint>
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="body_right">
                        <div class="msgs_preview">
                            <div class="msg" *ngIf="aiForm.get('on_the_video').value">
                                <b>On the video</b> {{aiForm.get('on_the_video').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('your_idea_of_title').value">
                                <b>Example of the title</b> {{aiForm.get('your_idea_of_title').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('keywords').value && aiForm.get('keywords').value.length">
                                <b>Use words</b> {{aiForm.get('keywords').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('remembers').value && aiForm.get('remembers').value.length">
                                <b>Remember</b> {{aiForm.get('remembers').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('also_remember').value">
                                <b>Also remember</b> {{aiForm.get('also_remember').value}}
                            </div>
                            <ng-container *ngIf="showSys">
                                <form class="form_sys" [formGroup]="aiSysForm">      
                                    <div class="sys_str" contenteditable="true" spellcheck="false" formControlName="str"></div>
                                </form>
                                <!-- <pre class="request_sys" *ngIf="!!getResultsReqObject() && getResultsReqObject().messages && getResultsReqObject().messages[0]">{{getResultsReqObject().messages[0]}}</pre> -->
                            </ng-container>
                        </div>
                        <div class="context_footer">
                            <div class="sys_btn" (click)="showSys = !showSys">
                                <ng-container *ngIf="showSys; else elseTemplate">
                                    {{"Hide full request" | translate}}
                                </ng-container>
                                <ng-template #elseTemplate>
                                    {{"Full request" | translate}}
                                </ng-template>
                            </div>
                            <button class="cntxt_btn blue" [disabled]="isGettingResults" (click)="getResults(aiForm, contextdata)">
                                <mat-spinner class="spin_24 mr-1" *ngIf="isGettingResults"></mat-spinner> 
                                {{ "Get Results" | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="mistake" *ngIf="!!aiMistake">{{aiMistake}}</div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #elseTemplateIsAiMenu>            
            <div class="context_head no_focus">
                <div class="cont_title">
                    <div>{{"Mass publish setup" | translate}}</div>
                    <div>
                        <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                        </svg> 
                    </div>
                </div>
                <div class="cont_desc">
                    {{"All exported files will be published to corresponded outlet on selected platform at defined time" | translate}}
                </div>
            </div>
    
            <div class="context_body with_results" [appDeviceClass]="'custom-scroll'">
                <ng-container [ngSwitch]="profilesTab">
                    <ng-container *ngSwitchCase="0">
                        <div class="context_body_desc no_focus">
                            {{"In current setup for exporting files cards there are available outlets." | translate}}
                        </div>
                        <div class="grouped_outlets" *ngFor="let item of contextdata.outlets">
                            <div class="group_items">
                                <div class="title">
                                    <app-platform [platform_id]="item.platform.id"></app-platform>
                                    <span>
                                        {{item.platform.name}}
                                    </span>
                                </div>
                                <div class="items" *ngFor="let arrItem of item.arr">
                                    <div class="item" [ngClass]="{'active': !!arrItem.is_selected}" (click)="arrItem.is_selected = !arrItem.is_selected">
                                        <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line> 
                                        <span>{{getTypeById(arrItem?.content_type_id)?.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <div class="context_body_desc no_focus">
                            {{"Media plans available for outlets." | translate}}<br>
                            {{"Define how frequently you want to publish files." | translate}}
                        </div>
                        <div class="selected_items">
                            <ng-container *ngFor="let item of contextdata.outlets">
                                <ng-container *ngFor="let arrItem of item.arr">
                                    <ng-container *ngIf="!!arrItem.is_selected">
                                        <div class="selected_item">
                                            <div class="chann">
                                                <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line>
                                            </div>
                                            <div class="chann_settings">
                                                <span class="time">
                                                    
                                                    <div (click)="regs.toggle()" [ngClass]="{'active': arrItem.publData.content_plan_content_schedules_ids.length}">
                                                        {{ arrItem.publData.content_plan_content_schedules_ids.length ? ('Selected ' + arrItem.publData.content_plan_content_schedules_ids.length + ' regulars') : ("All regular shedules"| translate) }}
                                                    </div>
                     
                                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                        <mat-select class="list_of_keywords" disableOptionCentering #regs [multiple]="true" [(ngModel)]="arrItem.publData.content_plan_content_schedules_ids">    
                                                            <mat-option *ngFor="let reg of arrItem.regulars" [value]="reg.id">
                                                                {{ reg.name | translate }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </span>
                                                <span>skipping</span>
                                                <input class="min_int" type="number" [(ngModel)]="arrItem.publData.content_plan_min_interval">
                                                <span>time slots</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <div class="context_body_desc no_focus">
                            {{"Almost done." | translate}}<br>
                        </div>
    
                        + Autoassign time slots after file are ready?
                    </ng-container>
                </ng-container>
            </div>
            <div class="context_footer">
                <div>
                    <button class="cntxt_btn" *ngIf="profilesTab != 0" (click)="$event.preventDefault; $event.stopPropagation(); profilesTab = profilesTab - 1">
                        {{ "Back" | translate }}
                    </button>
                </div>
                <div class="d-f-c">
                    <button class="cntxt_btn" (click)="$event.preventDefault; $event.stopPropagation(); closeContext()">
                        {{ "Cancel" | translate }}
                    </button>
                    <button class="cntxt_btn" style="color: #2F80ED;" *ngIf="profilesTab == 0 && checkOutletsCount(contextdata.outlets) > 5" (click)="$event.preventDefault; $event.stopPropagation(); selectAll(contextdata.outlets)">
                        {{ "Select All" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab < 2" (click)="$event.preventDefault; $event.stopPropagation(); nextTab()">
                        {{ "Next" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab == 2" (click)="$event.preventDefault; $event.stopPropagation(); finishPubl(contextdata.outlets)">
                        {{ "Finish" | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        
	</section>
</ng-template>